import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionBody, AccordionHeader, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import LogoE from "../../assets/images/logos/SVG/logo_e.svg";
import Footer from "../../components/Footer/Footer";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import TitlePage from "../../components/TitlePage";
import WrapperGrayZone from "../../components/WrapperGrayZone";
import CustomFontAwesomeIcon from "../../components/CustomFontAwesomeIcon";

const data = [
    {
        label: "generalites",
        value: "generalites",
        describe: "generalites_d",
        icon: "",
        desc: [
            {
                title: "generalites_t_1",
                desc: "generalites_d_1",
            },
            {
                title: "generalites_t_2",
                desc: "generalites_d_2",
            },
            {
                title: "generalites_t_3",
                desc: "generalites_d_3",
            },
            {
                title: "generalites_t_4",
                desc: "generalites_d_4",
            },
            {
                title: "generalites_t_5",
                desc: "generalites_d_5",
            },
            {
                title: "generalites_t_6",
                desc: "generalites_d_6",
            },
            {
                title: "generalites_t_7",
                desc: "generalites_d_7",
            },
            {
                title: "generalites_t_8",
                desc: "generalites_d_8",
            },
            {
                title: "generalites_t_9",
                desc: "generalites_d_9",
            },
            {
                title: "generalites_t_10",
                desc: "generalites_d_10",
            },
            {
                title: "generalites_t_11",
                desc: "generalites_d_11",
            },
            {
                title: "generalites_t_12",
                desc: "generalites_d_12",
            },
        ],
    },
    {
        label: "accountmanage",
        value: "accountmanage",
        describe: "accountmanage_d",
        icon: "user-gear",
        desc: [
            {
                title: "accountmanage_t_1",
                desc: "accountmanage_d_1",
            },
            {
                title: "accountmanage_t_2",
                desc: "accountmanage_d_2",
            },
            {
                title: "accountmanage_t_3",
                desc: "accountmanage_d_3",
            },
            {
                title: "accountmanage_t_4",
                desc: "accountmanage_d_4",
            },
            {
                title: "accountmanage_t_5",
                desc: "accountmanage_d_5",
            },
            {
                title: "accountmanage_t_6",
                desc: "accountmanage_d_6",
            },
            {
                title: "accountmanage_t_7",
                desc: "accountmanage_d_7",
            },
            {
                title: "accountmanage_t_8",
                desc: "accountmanage_d_8",
            },
            {
                title: "accountmanage_t_9",
                desc: "accountmanage_d_9",
            },
            {
                title: "accountmanage_t_10",
                desc: "accountmanage_d_10",
            },
            {
                title: "accountmanage_t_11",
                desc: "accountmanage_d_11",
            },
        ],
    },
    {
        label: "portfolioprestations",
        value: "portfolioprestations",
        describe: "portfolioprestations_d",
        icon: "hands-holding-child",
        desc: [
            {
                title: "portfolioprestations_t_1",
                desc: "portfolioprestations_d_1",
            },
            {
                title: "portfolioprestations_t_2",
                desc: "portfolioprestations_d_2",
            },
            {
                title: "portfolioprestations_t_3",
                desc: "portfolioprestations_d_3",
            },
            {
                title: "portfolioprestations_t_4",
                desc: "portfolioprestations_d_4",
            },
            {
                title: "portfolioprestations_t_5",
                desc: "portfolioprestations_d_5",
            },
            {
                title: "portfolioprestations_t_6",
                desc: "portfolioprestations_d_6",
            },
            {
                title: "portfolioprestations_t_7",
                desc: "portfolioprestations_d_7",
            },
            {
                title: "portfolioprestations_t_8",
                desc: "portfolioprestations_d_8",
            },
            {
                title: "portfolioprestations_t_9",
                desc: "portfolioprestations_d_9",
            },
        ],
    },
    {
        label: "picturesRightsWriter",
        value: "picturesRightsWriter",
        describe: "picturesRightsWriter_d",
        icon: "images",
        desc: [
            {
                title: "picturesRightsWriter_t_1",
                desc: "picturesRightsWriter_d_1",
            },
            {
                title: "picturesRightsWriter_t_2",
                desc: "picturesRightsWriter_d_2",
            },
            {
                title: "picturesRightsWriter_t_3",
                desc: "picturesRightsWriter_d_3",
            },
            {
                title: "picturesRightsWriter_t_4",
                desc: "picturesRightsWriter_d_4",
            },
            {
                title: "picturesRightsWriter_t_5",
                desc: "picturesRightsWriter_d_5",
            },
            {
                title: "picturesRightsWriter_t_6",
                desc: "picturesRightsWriter_d_6",
            },
            {
                title: "picturesRightsWriter_t_7",
                desc: "picturesRightsWriter_d_7",
            },
            {
                title: "picturesRightsWriter_t_8",
                desc: "picturesRightsWriter_d_8",
            },
            {
                title: "picturesRightsWriter_t_9",
                desc: "picturesRightsWriter_d_9",
            },
            {
                title: "picturesRightsWriter_t_10",
                desc: "picturesRightsWriter_d_10",
            },
            {
                title: "picturesRightsWriter_t_11",
                desc: "picturesRightsWriter_d_11",
            },
            {
                title: "picturesRightsWriter_t_12",
                desc: "picturesRightsWriter_d_12",
            },
            {
                title: "picturesRightsWriter_t_13",
                desc: "picturesRightsWriter_d_13",
            },
            {
                title: "picturesRightsWriter_t_14",
                desc: "picturesRightsWriter_d_14",
            },
            {
                title: "picturesRightsWriter_t_15",
                desc: "picturesRightsWriter_d_15",
            },
            {
                title: "picturesRightsWriter_t_16",
                desc: "picturesRightsWriter_d_16",
            },
            {
                title: "picturesRightsWriter_t_17",
                desc: "picturesRightsWriter_d_17",
            },
            {
                title: "picturesRightsWriter_t_18",
                desc: "picturesRightsWriter_d_18",
            },
            {
                title: "picturesRightsWriter_t_19",
                desc: "picturesRightsWriter_d_19",
            },
            {
                title: "picturesRightsWriter_t_20",
                desc: "picturesRightsWriter_d_20",
            },
        ],
    },
    {
        label: "adspay",
        value: "adspay",
        describe: "adspay_d",
        icon: "credit-card",
        desc: [
            {
                title: "adspay_t_1",
                desc: "adspay_d_1",
            },
            {
                title: "adspay_t_2",
                desc: "adspay_d_2",
            },
            {
                title: "adspay_t_3",
                desc: "adspay_d_3",
            },
            {
                title: "adspay_t_4",
                desc: "adspay_d_4",
            },
            {
                title: "adspay_t_5",
                desc: "adspay_d_5",
            },
            {
                title: "adspay_t_6",
                desc: "adspay_d_6",
            },
            {
                title: "adspay_t_7",
                desc: "adspay_d_7",
            },
            {
                title: "adspay_t_8",
                desc: "adspay_d_8",
            },
        ],
    },
    {
        label: "feedbacks",
        value: "feedbacks",
        describe: "feedbacks_d",
        icon: "comment-dots",
        desc: [
            {
                title: "feedbacks_t_1",
                desc: "feedbacks_d_1",
            },
            {
                title: "feedbacks_t_2",
                desc: "feedbacks_d_2",
            },
            {
                title: "feedbacks_t_3",
                desc: "feedbacks_d_3",
            },
        ],
    },
];

export default function Aide() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("help")}`;
    const [open, setOpen] = useState("");
    const [searchBar, setSearchBar] = useState(false);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        if (window.location.hash) {
            const id = window.location.hash.split("#")[1];
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "start" });
                handleOpen(id); // Pour ouvrir l'accordéon correspondant
            } else {
                handleOpen(id);
            }
        }
    }, []);
    
    const handleOpen = (index: string) => {
        if (open === index) return setOpen("");
        setOpen(index);
    };

    const topPaddingTop = (): string => {
        if (isVisible) {
        if (searchBar)
            return "top-[108px] pt-[7px]";
        else
            return "top-[60px] pt-[7px]";
        } else {
        if (searchBar)
            return "top-[48px] pt-[7px]";
        else
            return "top-[0px] pt-[7px]";
        }
    }

    return (
        <div className="min-h-full relative">
            <HeaderForAnimation />
            <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} isVisible={isVisible} setIsVisible={setIsVisible} />
            <div className="flex flex-col h-full bg-white -mt-4 pb-[340px] md:pb-36">
                <div
                    className={`${topPaddingTop()} flex flex-row md:flex-col items-end justify-between w-full sticky top-0 pb-2 md:static bg-white z-30 border-b border-gray-200`}
                >
                    <TitlePage mt={0} mb={0}>
                        {t("helpContact")}
                    </TitlePage>
                </div>
                <WrapperGrayZone pt="0" px="0">
                    <div className="mx-auto py-6">
                        <div className="flex flex-col h-full w-full md:w-2/3 px-4 md:px-0 text-justify gap-4 max-w-7xl mx-auto">
                            <Typography variant="small" className="text-base font-bold text-black">
                                {t("textContact")}
                            </Typography>
                            <div className="flex flex-col md:flex-row flex-wrap">
                                {data.map(({ label, value, describe, icon }, index: number) => (
                                    <Link to={value} smooth={true} key={value} className={`w-full md:w-1/2 ${index % 2 === 0 ? "md:pr-4" : "md:pl-4"} py-2`}>
                                        <div className="border flex flex-col gap-2 h-full w-full px-4 py-4 rounded-lg items-start justify-start bg-white hover:bg-gray-100 transition-all cursor-pointer">
                                            {value === "generalites" ? (
                                                <LogoE height={40} />
                                            ) : (
                                                <CustomFontAwesomeIcon icon={icon as any} className="text-orange-500 text-4xl" />
                                            )}
                                            <h2 className="text-lg font-bold text-black">{t(label)}</h2>
                                            <p className="text-sm">{t(describe)}</p>
                                        </div>
                                    </Link>
                                ))}
                            </div>

                            {data.map(({ value, desc, icon }) => (
                                <>
                                    <h2 className="text-2xl mt-6 flex gap-3" id={value}>
                                        {value === "generalites" ? (
                                            <LogoE height={30} />
                                        ) : (
                                            <CustomFontAwesomeIcon icon={icon as any} className="text-orange-500 text-3xl" />
                                        )}
                                        {t(value)}
                                    </h2>
                                    {desc.map(({ title, desc }, index) => (
                                        <Accordion key={title} open={open === value + index}  id={value + index} className="subpixel-antialiased">
                                            <AccordionHeader
                                                onClick={() => handleOpen(value.toString() + index.toString())}
                                                className="flex justify-between items-center w-full relative"
                                            >
                                                <span className="flex gap-2 font-bold text-black">{t(title)}</span>
                                                <CustomFontAwesomeIcon
                                                    icon={open === value + index ? "angle-up" : "angle-down"}
                                                    className="absolute right-0 bottom-4"
                                                />
                                            </AccordionHeader>
                                            <AccordionBody className="font-normal subpixel-antialiased">
                                                <div className="text-black text-base" dangerouslySetInnerHTML={{ __html: t(desc)}}></div>
                                            </AccordionBody>
                                        </Accordion>
                                    ))}
                                </>
                            ))}

                            <h2 className="text-2xl  mt-10 flex gap-3" id="contact">
                                <CustomFontAwesomeIcon icon="envelope" className="text-orange-500" />
                                {t("contact")}
                            </h2>
                            <span className="flex gap-2 text-lg font-bold text-black">{t("dontfountresponse")}</span>
                            <div className=" gap-2 text-base font-normal text-black" dangerouslySetInnerHTML={{ __html: t("dontfountresponse_d") }}></div>

                            <div className="flex flex-col gap-2 mt-4">
                                <span className="flex gap-2 text-lg font-bold text-black">{t("mentionslegales")}</span>
                                <span className="flex gap-2 text-base font-normal text-black" dangerouslySetInnerHTML={{ __html: t("mentionslegales_d") }}></span>
                            </div>
                        </div>
                    </div>
                </WrapperGrayZone>
            </div>
            <Footer />
        </div>
    );
}
