import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../../components/Footer/Footer";
import Loader from "../../../components/Loader";
import HeaderForAnimation from "../../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../../components/NavBar/HeaderMobile";
import {
  PortfolioDrawerForFilters,
  PortfolioResult,
} from "../../../components/SearchResult/impl/PortfolioResult";
import MobileFilterButton from "../../../components/SearchResult/mobile/MobileFilterButton";
import SearchResult from "../../../components/SearchResult/SearchResult";
import TitlePageNoMaxSize from "../../../components/TitlePageNoMaxSize";
import WrapperGrayZone from "../../../components/WrapperGrayZone";

export default function PopularPhotographers() {
  const { t } = useTranslation();
  document.title = `ezoom | ${t("photos")}`;
  const [loading, setLoading] = React.useState<boolean>(true);
  const [searchBar, setSearchBar] = useState(true);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const topPaddingTop = (): string => {
    if (isVisible) {
    if (searchBar)
        return "top-[108px] pt-[7px]";
    else
        return "top-[60px] pt-[7px]";
    } else {
    if (searchBar)
        return "top-[48px] pt-[7px]";
    else
        return "top-[0px] pt-[7px]";
    }
}

  return (
    <div className="min-h-screen bg-gray-50 relative">
      <Loader loading={loading} />
      <PortfolioDrawerForFilters />
      <HeaderForAnimation />
      <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} isVisible={isVisible} setIsVisible={setIsVisible} />
      <PortfolioResult
        overrides={{
          pagetype: "popular",
        }}
        hideFilters={["popular"]}
        defaultSort="popular"
      >
        <div className="flex flex-col h-full bg-white -mt-4 pt-4 pb-[340px] md:pb-0">
          <div
            className={`${topPaddingTop()} flex flex-row md:flex-col items-center justify-between w-full sticky top-0 pt-6 pb-2 md:static bg-white z-30 border-b border-gray-200`}
          >
            <TitlePageNoMaxSize mt={0} mb={0}>
              {/* <CustomFontAwesomeIcon icon="users" className="text-purple-500" />{" "} */}
              {t("popularsPhotographers")}
            </TitlePageNoMaxSize>
            <div className="flex md:hidden flex-row items-center justify-end gap-2 w-fit md:mt-4 pr-2">
              <MobileFilterButton />
            </div>
          </div>
          <WrapperGrayZone pt="4" pb="32" px="4">
            <SearchResult />
          </WrapperGrayZone>
        </div>
      </PortfolioResult>
      <Footer />
    </div>
  );
}
