import { useTranslation } from "react-i18next";
import InputWithSpan from "../../../../components/Form/Input/InputGrowAuto";
import { KeyboardEvent } from "react";

interface InlineInputProps {
    value: string;
    onChange: (value: string) => void;
    values: string[];
    label: string;
}

const autocompleteWord = (str: string, words: string[]) => {
    if (!str) {
        return "";
    }
    const wordsFiltered = words.filter((word) => word.toLocaleLowerCase().startsWith(str.toLocaleLowerCase()));
    if (str === wordsFiltered[0]) {
        return "";
    }
    return wordsFiltered.length > 0 ? wordsFiltered[0] : "";
};

export const InlineInput = ({ value, onChange, values, label }: InlineInputProps) => {
    const { t } = useTranslation();

    const autocompleteValue = autocompleteWord(value, values);

    const onAutocomplete = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key !== "Enter" || !autocompleteValue) {
            return;
        }
        onChange(autocompleteValue);
    };

    return (
        <div className="w-1/2 flex">
            <InputWithSpan
                placeholder={t(label)}
                inputValue={value}
                setInputValue={(e: any) => onChange(e.target.value)}
                handleEnterPress={(e: any) => onAutocomplete(e)}
                className="bg-white py-1 text-orange-700 text-[14px] w-1/2"
            />
            <p className="relative bg-white text-orange-500/40 z-1 pt-[3px]">
                {autocompleteValue && autocompleteValue.slice(value.length)}
                {autocompleteValue && <span className="border p-1 rounded">Entrer</span>}
            </p>
        </div>
    );
};
