import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import { updateGeneral } from "../services/redux/actions/generalActions";
import { RootState } from "../services/redux/store";
import { Storage } from "../services/storage";
import { dataLoggedIn, dataUnlogged } from "./fetchData";
import router from "./router";

const token = Storage.getToken();

export default function App() {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [dataFetched, setDataFetched] = React.useState<boolean>(true);
    const general = useSelector((state: RootState) => state.general);
    const dispatch = useDispatch();

    const fetchData = async (isLoggedin: boolean) => {
        try {
            if (!general.dataFetched) {
                const state = await (isLoggedin ? dataLoggedIn() : dataUnlogged());
                await dispatch(updateGeneral(state));
                setDataFetched(false);
                setLoading(false);
            } else {
                setDataFetched(false);
                setLoading(false);
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        fetchData(!!token);
    }, []);

    if (loading) {
        return <Loader loading={loading} />;
    }

    if (dataFetched) {
        return <Loader loading={dataFetched} />;
    }

    if (!general.dataFetched) {
        return <Loader loading={!general.dataFetched} />;
    }

    return (
        <RouterProvider router={router(token)} />
    );
}
