import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { DualInput } from "./DualInput";
import { useSelector, useDispatch } from "react-redux";
import { Setup } from "../../../../components/Interfaces/PortfolioType";
import CustomFontAwesomeIcon from "../../../../components/CustomFontAwesomeIcon";

export default function Flash({ brands }: Readonly<{ brands: { value: string; label: string }[] }>) {
    const portfolio = useSelector((state: any) => state.form);
    const dispatch = useDispatch();

    const t = useTranslation().t;

    const handleAddFlash = () => {
        const newFlashs = [...portfolio.flash];
        newFlashs.push({ brandLabel: "", modelLabel: "", type: "flash" });

        dispatch({ type: "UPDATE_FLASH", payload: newFlashs });
    };

    const modelsGetter = async (brand: string) => {
        return [];
    };

    return (
        <>
            <Typography className="text-base mt-2">{t("setup_f")}</Typography>
            {portfolio.flash.map((flash: Setup, index: number) => (
                <DualInput
                    leftChoices={brands}
                    onChange={(value: [string, string]) => {
                        const newFlashs = [...portfolio.flash];
                        newFlashs[index] = { brandLabel: value[0], modelLabel: value[1], type: "flash" };
                        dispatch({ type: "UPDATE_FLASH", payload: newFlashs });
                    }}
                    rightChoicesGetter={modelsGetter}
                    value={[flash.brandLabel, flash.modelLabel]}
                    key={index}
                />
            ))}
            <button onClick={() => handleAddFlash()} className="flex">
                <Typography className="text-base text-gray-500 mt-2 cursor-pointer">
                    <CustomFontAwesomeIcon icon="plus" /> {t("addsetup")}
                </Typography>
            </button>
        </>
    );
}
