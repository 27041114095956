import React from "react";
import ReactDOM from "react-dom/client";

// import { fas } from "@fortawesome/free-solid-svg-icons";
import { IconPack, library } from "@fortawesome/fontawesome-svg-core";
import { fat as thinIcons } from "@fortawesome/pro-thin-svg-icons";
import { fas as solidIcons } from "@fortawesome/pro-solid-svg-icons";

import { Provider } from "react-redux";
import store from "./services/redux/store";

import "./i18n";

import "react-confirm-alert/src/react-confirm-alert.css";
import "react-toastify/dist/ReactToastify.css";
import "react-photo-album/rows.css";

import "./assets/styles/index.css";

import App from "./pages/App";

library.add(thinIcons as IconPack, solidIcons as IconPack);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);
