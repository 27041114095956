import { Storage } from "../../storage";
import APIClient from "../APIClients";

const token = Storage.getToken();

export default class RegionAPI {
    public static async regions() {
        return APIClient.ezoom.get(`/regions`);
    }

    public static async regionsCountry() {
        return APIClient.ezoom.get(`/regions`);
    }

    public static async mostUsed() {
        if (token)
            return APIClient.ezoom.get(`/public/most-used-regions`)
        else
            return APIClient.noInterceptor.get(`/public/most-used-regions`)
    }

    public static async mostUsedPortfolio() {
        if (token)
            return APIClient.ezoom.get(`/public/most-used-portfolio-regions`)
        else
            return APIClient.noInterceptor.get(`/public/most-used-portfolio-regions`)
    }
}
