import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography, Input } from "@material-tailwind/react";
import { faExclamationCircle, faCheckCircle, faExclamationTriangle, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import CustomFontAwesomeIcon from "../../CustomFontAwesomeIcon";

const states = {
    error: { type: "error", color: "red", icon: faExclamationCircle },
    success: { type: "success", color: "green", icon: faCheckCircle },
    warning: { type: "warning", color: "orange", icon: faExclamationTriangle },
    info: { type: "info", color: "purple", icon: faQuestionCircle },
    default: { type: "default", color: "gray", icon: faQuestionCircle },
};

export default function InputError({
    type,
    name,
    label,
    text,
    state = "default",
    className,
    onChange,
    value,
    isDisabled = false,
    isRequired = false,
}: Readonly<{
    type: string;
    name?: string;
    label: string;
    text?: string;
    state?: keyof typeof states;
    className: string;
    onChange?: (arg: any) => void;
    value?: string;
    isDisabled?: boolean,
    isRequired?: boolean,
}>) {
    return (
        <div className={`flex flex-col ${className}`}>
            <Input
                type={type}
                name={name}
                label={label}
                color="orange"
                error={state === "error"}
                success={state === "success"}
                className="bg-white"
                onChange={onChange}
                value={value}
                containerProps={{ className: "min-w-[72px]" }}
                disabled={isDisabled}
                required={isRequired}
            />
            {text && (
                <Typography variant="small" color={states[state].color} className="mt-2 flex items-center gap-1 font-normal">
                    <CustomFontAwesomeIcon icon={states[state].icon as any} className={`text-${states[state].color}-500 -mt-0.5`} />
                    {text}
                </Typography>
            )}
        </div>
    );
}
