import ControlTop from "../../../../assets/images/home/waves/control_top.svg";
import ControlBottom from "../../../../assets/images/home/waves/control_bottom.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import CustomFontAwesomeIcon from "../../../../components/CustomFontAwesomeIcon";

export default function KeepControl() {
    const { t } = useTranslation();
    return (
        <div className="m-0 pb-10 relative bg-cover bg-no-repeat bg-white">
            <ControlTop />
            <div style={{ backgroundImage: "linear-gradient(90deg, #ffd3a6 0%, #fff9f3 85%)" }} className="pb-12 pt-20  px-0 -my-1.5 mx-0">
                <p className="mt-0 px-4 text-left md:text-center text-4xl font-sans max-w-7xl">{t("keep_control_title")}</p>
                <p className="px-4 text-left md:text-center mt-4 mx-auto font-sans pt-8">{t("keep_control_description_p1")}</p>
                <div className="flex flex-wrap flex-row justify-around max-w-6xl mx-auto">
                    <div data-entrance="fade" className=" mt-16">
                        <div className="bg-white w-72 h-72 text-center px-12 rounded-full flex flex-col justify-center items-center">
                            <CustomFontAwesomeIcon icon="arrow-right-arrow-left" className="text-6xl text-orange-500 mb-8" />
                            <p>{t("keep_control_feature_1")}</p>
                        </div>
                    </div>
                    <div data-entrance="fade" data-entrance-delay="300" className=" mt-16">
                        <div className="bg-white w-72 h-72 text-center px-12 rounded-full flex flex-col justify-center items-center">
                            <CustomFontAwesomeIcon icon="money-bill-1-wave" className="text-6xl text-orange-500 mb-8" />
                            <p>{t("keep_control_feature_2")}</p>
                        </div>
                    </div>
                    <div data-entrance="fade" data-entrance-delay="600" className=" mt-16">
                        <div className="bg-white w-72 h-72 text-center px-12 rounded-full flex flex-col justify-center items-center">
                            <span className="text-6xl text-orange-500 font-bold mb-8">0<CustomFontAwesomeIcon icon="percent" className="text-6xl text-orange-500" /></span>
                            <p>{t("keep_control_feature_3")}</p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-wrap flex-row justify-around max-w-6xl mx-auto">
                    <div data-entrance="fade" className=" mt-16">
                        <div className="bg-white w-72 h-72 text-center px-12 rounded-full flex flex-col justify-center items-center">
                            <CustomFontAwesomeIcon icon="hand-point-up" className="text-6xl text-orange-500 mb-8" />
                            <p>{t("keep_control_feature_4")}</p>
                        </div>
                    </div>
                    <div data-entrance="fade" data-entrance-delay="300" className=" mt-16">
                        <div className="bg-white w-72 h-72 text-center px-12 rounded-full flex flex-col justify-center items-center">
                            <CustomFontAwesomeIcon icon="comment-dots" className="text-6xl text-orange-500 mb-8" />
                            <p>{t("keep_control_feature_5")}</p>
                        </div>
                    </div>
                </div>
            </div>
            <ControlBottom />
        </div>
    );
}
