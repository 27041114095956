import { Checkbox, Option, Radio, Select } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import GradientButton from "../../../components/Form/Button/GradientButtonBig";
import {
    faCreativeCommons,
    faCreativeCommonsBy,
    faCreativeCommonsNc,
    faCreativeCommonsNd,
    faCreativeCommonsSa,
    faCreativeCommonsZero,
} from "@fortawesome/free-brands-svg-icons";
import leftZone from "../../../assets/images/elements/watermarkinfo/bl.jpg";
import rightZone from "../../../assets/images/elements/watermarkinfo/br.jpg";
import fullZone from "../../../assets/images/elements/watermarkinfo/full.jpg";

import ModalPreview from "../../../components/Modals/ModalPreview";
import { useTranslation } from "react-i18next";
import UserAPI from "../../../services/API/Clients/UserAPI";
import { Storage } from "../../../services/storage";
import { Language } from "../../../enums/Language";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateMe } from "../../../services/redux/actions/generalActions";
import { handleError } from "../../../services/Errors/handleErrors";
import { ConfirmLeaveModal, isDeepEqual, useNavigationBlocker } from "../../../services/SaveUtils";
import { useNavigate } from "react-router-dom";
import CustomFontAwesomeIcon from "../../../components/CustomFontAwesomeIcon";
import { useSelector } from "react-redux";

export default function Notifications() {
    const { t } = useTranslation();
    const general = useSelector((state: any) => state.general);
    const [initialPreferences, setInitialPreferences] = React.useState({} as any);
    const [preferences, setPreferences] = React.useState({} as any);
    const [openPreview, setOpenPreview] = React.useState(false);
    const [whichFiligrane, setWhichFiligrane] = React.useState("ezoom");
    const [alreadyImported, setAlreadyImported] = React.useState(false);
    const myInputFile = React.useRef<HTMLInputElement>(null);
    const [fileLogo, setFileLogo] = React.useState<File | null>(null);
    const [me, setMe] = React.useState<any>();
    const myId = Storage.getId()
    const { i18n } = useTranslation();
    const [lang, setLang] = useState(Storage.getLanguage() ?? Language.FR);
    const dispatch = useDispatch();

    const fetchMyId = async () => {
        if (!myId) return;
        const resp = await UserAPI.getPreferences(myId);
        if (resp.status === 200 || resp.status === 201) {
            setInitialPreferences(resp.body);
            setPreferences(resp.body);
        } else {
            handleError(resp);
        }
    }

    const fetchData = async () => {
        const response = await UserAPI.me();
        if (response.status === 200)
            setMe(response.body)
        else {
            handleError(response);
        }
    }

    useEffect(() => {
        fetchMyId();
        fetchData()
        setWhichFiligrane(general?.me?.defaultWatermark ?? "ezoom")
    }, []);

    const handleSubmit = async () => {
        if (!myId) return;
        changeLang();
        let success = false

        const datas = {
            defaultLicense: me.defaultLicense,
            enableNSFWFilter: me.enableNSFWFilter,
            enableWatermark: me.enableWatermark,
        }

        const response = await UserAPI.putUser(datas, myId)
        if (response.status === 201 || response.status === 200) {
            success = true
            const responseMe = await UserAPI.me()
            if (responseMe.status === 200)
                dispatch(updateMe(responseMe.body))
            else
                handleError(responseMe);
        } else {
            handleError(response);
            success = false
        }
        const datasNotifs = {
            newLikedPicture: preferences.notificationPreferences.newLikedPicture,
            newLikedGallery: preferences.notificationPreferences.newLikedGallery,
            newSubscriber: preferences.notificationPreferences.newSubscriber,
            newTestimonial: preferences.notificationPreferences.newTestimonial,
            newOffer: preferences.notificationPreferences.newOffer,
            newMessage: preferences.notificationPreferences.newMessage,
            pictureStatus: preferences.notificationPreferences.pictureStatus,
            newSale: preferences.notificationPreferences.newSale,
            subscription: preferences.notificationPreferences.subscription,
            newsletter: preferences.notificationPreferences.newsletter,
            favoritesPhotographers: preferences.notificationPreferences.favoritesPhotographers
        }
        const constRespNotifs = await UserAPI.putNotifications(preferences.notificationPreferences.id, datasNotifs)
        if (constRespNotifs.status === 201 || constRespNotifs.status === 200) {
            success = true
            const responseMe = await UserAPI.me()
            if (responseMe.status === 200) {
                dispatch(updateMe(responseMe.body))
                setInitialPreferences(preferences)
            }
            else
                handleError(responseMe);
        } else {
            handleError(constRespNotifs);
            success = false
        }

        if (fileLogo) {
            if (!myId) return;
            const formData = new FormData();
            formData.append("image", fileLogo);
            const response = await UserAPI.uploadLogo(formData, myId);
            if (response.status === 200 || response.status === 201) {
                if (success)
                    success = true
                const responseMe = await UserAPI.me()
                if (responseMe.status === 200) dispatch(updateMe(responseMe.body))
            } else {
                success = false
                handleError(response);
            }
        }
        const datasMe = { 
            defaultWatermark: whichFiligrane 
        }
        const responseMe = await UserAPI.putUser(datasMe, general.me.id)
        if (responseMe.status === 200 || responseMe.status === 201) {
            if (success)
                success = true
            dispatch(updateMe(responseMe.body))
        } else {
            success = false
            handleError(responseMe);
        }

        if (success) {
            toast.success(t("preferences_updated"))
        } else {
            toast.error(t("preferences_update_error"))
        }
    }

    const changeLang = () => {
        switch (lang) {
            case "en":
                i18n.changeLanguage(Language.EN);
                Storage.setLanguage("en");
                break;
            case "it":
                i18n.changeLanguage(Language.IT);
                Storage.setLanguage("it");
                break;
            case "de":
                i18n.changeLanguage(Language.DE);
                Storage.setLanguage("de");
                break;
            default:
                i18n.changeLanguage(Language.FR);
                Storage.setLanguage("fr");
                break;
        }
    }

    const licenses = [
        { value: "all-rights-reserved", label: t("all-rights-reserved") },
        { value: "own-license", label: t("own-license") },
        { value: "cc-by", label: t("cc-by_full"), icons: [faCreativeCommons, faCreativeCommonsBy] },
        { value: "cc-by-sa", label: t("cc-by-sa_full"), icons: [faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsSa] },
        { value: "cc-by-nc", label: t("cc-by-nc_full"), icons: [faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsNc] },
        { value: "cc-by-nc-sa", label: t("cc-by-nc-sa_full"), icons: [faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsNc, faCreativeCommonsSa] },
        { value: "cc-by-nd", label: t("cc-by-nd_full"), icons: [faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsNd] },
        { value: "cc-by-nc-nd", label: t("cc-by-nc-nd_full"), icons: [faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsNc, faCreativeCommonsNd] },
        { value: "cc0", label: t("cc0_full"), icons: [faCreativeCommons, faCreativeCommonsZero] },
    ];

    const navigate = useNavigate();
    const { modalVisible, handleConfirmLeave, handleCancelLeave } = useNavigationBlocker(
        initialPreferences !== null && !isDeepEqual(preferences, initialPreferences),
        navigate,
        (a: string, b: string) => a === b
    );

    useEffect(() => {
        if (me?.enableWatermark === "full" && me?.defaultLicense !== "own-license") {
            setMe({ ...me, enableWatermark: "bl" });
        }
    }, [me?.defaultLicense]);

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
    };

    const handleUploadPicture = () => {
        if (myInputFile.current) {
            myInputFile.current.click();
        }
    }

    const handleChangePicture = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setFileLogo(e.target.files[0]);
            setAlreadyImported(true);
        }
    }

    const removeUploadPicture = () => {
        setFileLogo(null);
        setAlreadyImported(false);
    }

    const handleSelectFiligrane = (arg: string | undefined) => {
        if (arg !== undefined)
            setWhichFiligrane(arg);
    };

    return (
        <div className="flex flex-col bg-gray-50 w-full md:px-4 py-4 rounded-lg">
            <ConfirmLeaveModal isVisible={modalVisible} onConfirm={handleConfirmLeave} onCancel={handleCancelLeave} />
            <ModalPreview open={openPreview} setOpen={setOpenPreview} type={me?.enableWatermark} />
            <p className="mb-2 text-xl font-sans font-extrabold">{t("notifsandpref")}</p>
            <p className="mt-2 mb-2 text-base font-sans font-bold">{t("myalerts")}</p>

            <div className="flex flex-col gap-2 mt-4 w-full">
                <div className="flex flex-row w-full gap-4 items-center">
                    <p color="black" className="flex flex-row text-sm w-1/2"></p>
                    <div className="flex w-1/4 justify-center">
                        <p color="black" className="flex flex-row justify-center gap-2 text-sm w-1/2">
                            <CustomFontAwesomeIcon icon="bell" className="w-4 h-4" />
                            {t("notification")}
                        </p>
                    </div>
                    <div className="flex w-1/4 justify-center">
                        <p color="black" className="flex flex-row justify-center gap-2 text-sm w-1/2">
                            <CustomFontAwesomeIcon icon="envelope" className="w-4 h-4" />
                            {t("mail")}
                        </p>
                    </div>
                </div>
                {["newLikedPicture", "newLikedGallery", "newSubscriber", "newTestimonial", "newOffer", "newMessage", "pictureStatus", "newSale", "subscription", "newsletter", "favoritesPhotographers"].map((notif, index) => (
                    <div className="flex flex-row w-full gap-4 items-center" key={notif}>
                        <p color="black" className="flex flex-row text-sm w-1/2">
                            {t(notif)}
                        </p>
                        <div className="flex w-1/4 justify-center">
                            <Checkbox
                                className="bg-white"
                                color="orange"
                                checked={
                                    (preferences && preferences.notificationPreferences && (preferences?.notificationPreferences[notif] === "inapp" || preferences?.notificationPreferences[notif] === "both"))
                                }
                                disabled={notif === "subscription" || notif === "newsletter" || notif === "favoritesPhotographers"}
                                onChange={(event) => {
                                    if (!preferences || !preferences.notificationPreferences || !preferences.notificationPreferences[notif]) return;
                                    if (preferences.notificationPreferences[notif] === "inapp")
                                        setPreferences({ ...preferences, notificationPreferences: { ...preferences.notificationPreferences, [notif]: "none" } })
                                    else if (preferences.notificationPreferences[notif] === "both")
                                        setPreferences({ ...preferences, notificationPreferences: { ...preferences.notificationPreferences, [notif]: "email" } })
                                    else if (preferences.notificationPreferences[notif] === "none")
                                        setPreferences({ ...preferences, notificationPreferences: { ...preferences.notificationPreferences, [notif]: "inapp" } })
                                    else if (preferences.notificationPreferences[notif] === "email")
                                        setPreferences({ ...preferences, notificationPreferences: { ...preferences.notificationPreferences, [notif]: "both" } })
                                    else return;
                                }}
                            />
                        </div>
                        <div className="flex w-1/4 justify-center">
                            <Checkbox
                                className="bg-white"
                                color="orange"
                                checked={
                                    (preferences && preferences.notificationPreferences && (preferences?.notificationPreferences[notif] === "email" || preferences?.notificationPreferences[notif] === "both"))
                                }
                                onChange={(event) => {
                                    if (!preferences || !preferences.notificationPreferences || !preferences.notificationPreferences[notif]) return;
                                    if (preferences.notificationPreferences[notif] === "email")
                                        setPreferences({ ...preferences, notificationPreferences: { ...preferences.notificationPreferences, [notif]: "none" } })
                                    else if (preferences.notificationPreferences[notif] === "both")
                                        setPreferences({ ...preferences, notificationPreferences: { ...preferences.notificationPreferences, [notif]: "inapp" } })
                                    else if (preferences.notificationPreferences[notif] === "none")
                                        setPreferences({ ...preferences, notificationPreferences: { ...preferences.notificationPreferences, [notif]: "email" } })
                                    else if (preferences.notificationPreferences[notif] === "inapp")
                                        setPreferences({ ...preferences, notificationPreferences: { ...preferences.notificationPreferences, [notif]: "both" } })
                                    else return;
                                }}
                            />
                        </div>
                    </div>
                ))}
            </div>

            <hr className="border-gray-300 mt-6" />

            <p className="mt-4 mb-2 text-base font-sans font-bold">{t("myimports")}</p>
            <p className="mb-4 text-sm font-sans">{t("myimports_d")}</p>

            <div className="flex flex-row gap-2 items-center justify-start w-full md:w-1/2">
                <Select
                    className="bg-white w-full"
                    color="orange"
                    label={t("defaultlicense")}
                    value={me?.defaultLicense}
                    onChange={(arg) => setMe({ ...me, defaultLicense: arg })}
                    containerProps={{
                        className: "w-full max-h-[100vw]",
                    }}
                    menuProps={{
                        className: "w-full max-h-[100vw]",
                    }}
                    id="licenceSelect"
                >
                    {licenses.map((license) => (
                        <Option key={license.value} value={license.value}>
                            <div className="flex justify-between flex-row">
                                <span className="whitespace-nowrap mr-auto">
                                    {license.label}
                                </span>
                                {license.icons && (
                                    <span className="float-right flex flex-row gap-1 text-lg">
                                        {license.icons.map((icon, index) => (
                                            <FontAwesomeIcon key={index} icon={icon as any} />
                                        ))}
                                    </span>
                                )}
                            </div>
                        </Option>
                    ))}
                </Select>
            </div>

            <div className="flex flex-row gap-2 items-start justify-start mt-2">
                <Checkbox className="bg-white" color={me?.enableWatermark !== "none" ? "orange" : "gray"} checked={me?.enableWatermark !== "none"} onChange={() => setMe({ ...me, enableWatermark: me?.enableWatermark === "none" ? "bl" : "none" })} />
                <p className={`text-sm font-sans h-11 align-middle flex items-center transition-all ${me?.enableWatermark !== "none" ? "text-black" : "text-gray-500"}`}>{t("addlogo")}</p>
            </div>

            {me?.enableWatermark !== "none" && (
                <div className="flex flex-col items-start justify-center w-full">
                    <div className="w-56 mx-auto">
                        <Select className="bg-white" color="orange" label={t("watermark")} value={whichFiligrane} onChange={handleSelectFiligrane}>
                            <Option value="ezoom">{t("logoezoom")}</Option>
                            <Option value="logo">{t("mylogo")}</Option>
                        </Select>
                    </div>
                    {whichFiligrane === "logo" && (
                        <>
                            <div className="flex flex-row mt-2 bg-white gap-4 p-2 rounded-lg border mx-auto">
                                <div className="bg-gray-200 rounded-lg w-14 h-14">
                                    {alreadyImported ? <img onContextMenu={handleContextMenu} src={fileLogo ? URL.createObjectURL(fileLogo) : general?.me?.logo && general.me.logo} className="w-full h-full object-cover object-center rounded" />
                                     : <img onContextMenu={handleContextMenu} src={
                                        general?.me?.logo
                                    } className="w-full h-full object-cover object-center rounded" />}    
                                </div> 
                                <div className="flex flex-col gap-2 pt-1">
                                    <p className="text-sm font-sans">
                                        {!alreadyImported ? general?.me?.logo ? t("yourlogo") : t("nologo") : t("yourlogo")}
                                    </p>
                                    <div className="flex flex-row gap-2 items-center justify-center">
                                        <input type="file" className="hidden" ref={myInputFile} onChange={handleChangePicture} />
                                        <button className="px-2 py-1 bg-white border w-fit rounded-full text-xs" onClick={() => handleUploadPicture()}>
                                            {!alreadyImported ? general?.me?.logo ? t("change") : t("import") : t("change") }
                                        </button>
                                        {alreadyImported && <FontAwesomeIcon icon="x" className="w-3 h-3 text-gray-700 cursor-pointer" onClick={() => removeUploadPicture()} />}
                                    </div>
                                </div>
                            </div>
                            <p color="gray" className="flex items-center gap-1 font-normal text-xs opacity-50 mx-auto mt-2">
                                <FontAwesomeIcon icon="circle-question" className="w-3 h-3 -mt-0.5" />
                                {t("logo_sugg")}
                            </p>
                        </>
                    )}
                    <div className="flex flex-row mt-6 justify-center w-full lg:w-2/3 mx-auto">
                        <Radio
                            color="orange"
                            name="side"
                            id="bl"
                            value="bl"
                            checked={me?.enableWatermark === "bl"}
                            onChange={() => setMe({ ...me, enableWatermark: "bl" })}
                            label={<img onContextMenu={handleContextMenu} alt="leftZone" src={leftZone} className="w-[100%]" />}
                            containerProps={{ className: "min-w-[44px]" }}
                        />
                        <Radio
                            color="orange"
                            name="side"
                            id="br"
                            value="br"
                            checked={me?.enableWatermark === "br"}
                            onChange={() => setMe({ ...me, enableWatermark: "br" })}
                            label={<img onContextMenu={handleContextMenu} alt="leftZone" src={rightZone} className="w-[100%]" />}
                            containerProps={{ className: "min-w-[44px]" }}
                        />
                        <Radio
                            color="orange"
                            name="side"
                            id="full"
                            value="full"
                            checked={me?.enableWatermark === "full"}
                            onChange={() => setMe({ ...me, enableWatermark: "full" })}
                            label={<img onContextMenu={handleContextMenu} alt="leftZone" src={fullZone} className="w-[100%]" />}
                            containerProps={{ className: "min-w-[44px]" }}
                            disabled={me?.defaultLicense !== "own-license"}
                        />
                    </div>
                    <button
                        className="mx-auto mt-6 border px-4 py-2 rounded-full bg-white hover:bg-gray-200 transition-all"
                        onClick={() => setOpenPreview(!openPreview)}
                    >
                        {t("preview")}
                    </button>
                </div>
            )}

            <hr className="border-gray-300 mt-2 " />

            <p className="mt-4 mb-2 text-base font-sans font-bold">{t("nsfw")}</p>

            <div className="flex flex-row gap-2 items-center justify-start">
                <Checkbox className="bg-white" color={me?.enableNSFWFilter ? "orange" : "gray"} checked={me?.enableNSFWFilter} onChange={(event) => setMe({ ...me, enableNSFWFilter: event.target.checked })} />

                <p className={`text-sm font-sans align-middle flex items-center ${me?.enableNSFWFilter ? "text-black" : "text-gray-500"} transition-all`}>{t("nsfwfilter_d")}</p>
            </div>

            <hr className="border-gray-300 mt-6" />

            <p className="mt-4 mb-3 text-base font-sans font-bold">{t("mylang")}</p>

            <div className="flex flex-col gap-2 items-start justify-start">
                <div className="w-full md:w-56">
                    <Select className="bg-white" color="orange" label={t("lang")} value={lang} onChange={(arg) => arg && setLang(arg)}>
                        <Option value='fr'>{t("french_d")}</Option>
                        <Option value='en'>{t("english_d")}</Option>
                        <Option value='de'>{t("deutsch_d")}</Option>
                        <Option value='it'>{t("italiano_d")}</Option>
                    </Select>
                </div>
                <button className="w-fit mx-auto mt-8" onClick={() => handleSubmit()}>
                    <GradientButton text={t("savechanges")} />
                </button>
            </div>
        </div>
    );
}
