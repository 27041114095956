import { Storage } from "../../storage";
import APIClient from "../APIClients";

const token = Storage.getToken();

export default class UserAPI {
    public static async me() {
        return APIClient.ezoom.get(`/me`);
    }

    public static async users() {
        return APIClient.ezoom.get(`/users`);
    }

    public static async getUser(id: string) {
        return APIClient.ezoom.get(`/users/${id}`);
    }

    public static async putUser(data: {}, id: string) {
        return APIClient.ezoom.put(`/users/${id}`, data);
    }

    public static async deleteUser(id: string) {
        return APIClient.ezoom.delete(`/users/${id}`);
    }

    public static async changePassword(data: {}, id: string) {
        return APIClient.ezoom.put(`/users/${id}/password`, data);
    }

    public static async disableUser(data: {}, id: string) {
        return APIClient.ezoom.post(`/users/${id}/disable`, data);
    }
    
    public static async getPreferences(id: string) {
        return APIClient.ezoom.get(`/users/${id}/notification-preferences`);
    }

    public static async getNotifications(id: string) {
        return APIClient.ezoom.get(`/notifications?user=${id}`);
    }

    public static async getNotificationsCount() {
        return APIClient.ezoom.get(`/notifications-count`);
    }
    
    public static async overview(id: string) {
        return APIClient.ezoom.get(`/users/${id}/overview`);
    }
    
    public static async roles(data: {}, id: string) {
        return APIClient.ezoom.put(`/users/${id}/roles`, data);
    }

    public static async setUploads(data: {}, id: string) {
        return APIClient.ezoom.post(`/users/${id}/set-uploads`, data);
    }
    
    public static async uploads(data: {}, id: string) {
        return APIClient.ezoom.put(`/users/${id}/uploads`, data);
    }

    public static async uploadLogo(data: FormData, id: string) {
        return APIClient.ezoom.postPicture(`/users/${id}/logo`, data);
    }

    public static async getLogLogin(id: string) {
        return APIClient.ezoom.get(`/login-logs?user=${id}`);
    }

    public static async closeDialog(id: string, data: {}) {
        return APIClient.ezoom.put(`users/${id}/close-dialog`, data);
    }

    public static async setDialogs(id: string, data: {}) {        
        return APIClient.ezoom.put(`users/${id}/close-dialog`, data);
    }

    public static async forgot(data: {}) {
        if (token)
            return APIClient.ezoom.post(`/public/forgot-password`, data);
        else
            return APIClient.noInterceptor.post(`/public/forgot-password`, data);
    }

    public static async recover(data: {}) {
        if (token)
            return APIClient.ezoom.post(`/public/reset-password`, data);
        else
            return APIClient.noInterceptor.post(`/public/reset-password`, data);
    }

    public static async putNotifications(id: string, data: {}) {
        return APIClient.ezoom.put(`/notification-preferences/${id}`, data);
    }

    public static async initTotp(id: string) {
        return APIClient.ezoom.postAsBlob(`/users/${id}/totp`, {});
    }

    public static async verifyTotp(id: string, data: {}) {
        return APIClient.ezoom.put(`/users/${id}/totp-verify`, data);
    }

    public static async completeProfil(id: string, dataTokens: { token: string, refresh_token: string, id: string }) {
        return APIClient.noInterceptor.getTotp(`/users/${id}/complete`, dataTokens);
    }

    public static async completeMyProfil(id: string, data: {}, dataTokens: { token: string, refresh_token: string, id: string }) {
        return APIClient.noInterceptor.putTotp(`/users/${id}/complete`, data, dataTokens);
    }

    public static async statistics(id: string, query: string) {
        return APIClient.ezoom.get(`/users/${id}/statistics${query}`);
    }

    public static async getWeeklyUploads(id: string) {
        return APIClient.ezoom.get(`/users/${id}/weekly-uploads`);
    }
}
