import { Storage } from "../../storage";
import APIClient from "../APIClients";

const token = Storage.getToken();

export default class OrderAPI {
    public static async orderTransaction(data: {}) {
        return APIClient.ezoom.post(`/order-transactions`, data);
    }
    
    public static async orderTransactioAssert(id: string, data: {}) {
        return APIClient.ezoom.post(`/transactions/${id}/assert`, data);
    }

    public static async orders(data: {}) {
        return APIClient.ezoom.post("/orders", data);
    }

    public static async getOrders(id: string) {
        return APIClient.ezoom.get(`/orders?user=${id}`);
    }

    public static async getOrder(id: string) {
        return APIClient.ezoom.get(`/orders/${id}`);
    }

    public static async getOrdersPagination(id: string, page: number) {
        return APIClient.ezoom.get(`/orders?user=${id}&page=${page}`);
    }

    public static async order(id: string) {
        return APIClient.ezoom.get(`/orders/${id}`);
    }

    public static async quittance(id: string) {
        return APIClient.ezoom.getPdf(`/orders/${id}`);
    }

    public static async quittanceHistory(id: string) {
        return APIClient.ezoom.getPdf(`/sales/${id}`);
    }

    public static async contratBuySell(id: string, picture?: string) {
        return APIClient.ezoom.getPdf(`/transaction-contracts/${id}${picture ? `?image=${picture}` : ""}`);
    }

    public static async getContractDetails(id: string) {
        return APIClient.ezoom.get(`/transaction-contracts/${id}`);
    }

    public static async contratSell(id: string) {
        if (!token)
            return APIClient.noInterceptor.getPdf(`/public/static-contracts/${id}`);
        else
            return APIClient.ezoom.getPdf(`/public/static-contracts/${id}`);
    }

    public static async aboHistory(id: string) {
        return APIClient.ezoom.getPdf(`/subscription-transactions/${id}`);
    }

    public static async getSubscriptions(myId: string) {
        return APIClient.ezoom.get(`/subscriptions?user=${myId}`);
    }

    public static async getHistoryOrder(myId: string) {
        return APIClient.ezoom.get(`/order-transactions?user=${myId}`);
    }

    public static async getHistorySubscribe(myId: string) {
        return APIClient.ezoom.get(`/subscription-transactions?user=${myId}`);
    }

    public static async cancelSubscription(id: string) {
        return APIClient.ezoom.put(`/subscriptions/${id}/cancel`, {});
    }
}
