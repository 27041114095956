import { faImages, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";

import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer/Footer";
import { SearchPicturesType } from "../../components/Interfaces/PictureType";
import { SearchPortfolioType } from "../../components/Interfaces/PortfolioType";
import Loader from "../../components/Loader";
import {
    PictureDrawerForFilters,
    PictureResult,
} from "../../components/SearchResult/impl/PictureResult";
import { PortfolioResult } from "../../components/SearchResult/impl/PortfolioResult";
import TitlePage from "../../components/TitlePage";
import FavoriteAPI from "../../services/API/Clients/FavoriteAPI";

import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import MobileFilterButton from "../../components/SearchResult/mobile/MobileFilterButton";
import SearchResult from "../../components/SearchResult/SearchResult";
import { handleError } from "../../services/Errors/handleErrors";
import CustomFontAwesomeIcon from "../../components/CustomFontAwesomeIcon";

const CurrentProvider: React.FC<{ children: ReactNode; actualUrl: string }> = ({
    children,
    actualUrl,
}) => {
    return actualUrl === "photos" ? (
        <PictureResult
            overrides={{
                pagetype: "favorites",
                favorites: true,
            }}
        >
            {children}
        </PictureResult>
    ) : (
        <PortfolioResult
            overrides={{
                pagetype: "favorites",
                favorites: true,
            }}
        >
            {children}
        </PortfolioResult>
    );
};

export default function Favorites() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("myfavs")}`;
    const [searchBar, setSearchBar] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [actualUrl, setActualUrl] = useState("");
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const [pictures, setPictures] = useState<SearchPicturesType>({
        data: [],
        pagination: {
            current: 0,
            last: 0,
            parPage: 0,
            totalItems: 0,
        },
    });
    const [photographers, setPhotographers] = useState<SearchPortfolioType>({
        data: [],
        pagination: {
            current: 0,
            last: 0,
            parPage: 0,
            totalItems: 0,
        },
    });

    const fetchDataBoth = async () => {
        const [picturesResponse, portfoliosResponse] = await Promise.all([
            FavoriteAPI.pictures(),
            FavoriteAPI.portfolios(),
        ]);
        if (picturesResponse.status === 200 || picturesResponse.status === 201)
            setPictures(picturesResponse.body);
        else handleError(picturesResponse);
        if (
            portfoliosResponse.status === 200 ||
            portfoliosResponse.status === 201
        )
            setPhotographers(portfoliosResponse.body);
        else handleError(portfoliosResponse);
        setLoading(false);
    };

    useEffect(() => {
        fetchDataBoth();
    }, []);

    const { type } = useParams();

    const handleLinkClick = (url: string) => {
        navigate(url);
    };

    useEffect(() => {
        if (type === "photographers") setActualUrl("photographers");
        else setActualUrl("photos");
    }, [type]);

    const topPaddingTop = (): string => {
        if (isVisible) {
        if (searchBar)
            return "top-[108px] pt-[7px]";
        else
            return "top-[60px] pt-[7px]";
        } else {
        if (searchBar)
            return "top-[48px] pt-[7px]";
        else
            return "top-[0px] pt-[7px]";
        }
    }

    return (
        <>
            <PictureDrawerForFilters />
            <div className="min-h-screen bg-gray-50 relative">
                <Loader loading={loading} />
                <HeaderForAnimation />
                <HeaderMobile
                    searchBar={searchBar}
                    openSearchBar={setSearchBar}
                    isVisible={isVisible}
                    setIsVisible={setIsVisible}
                />
                <CurrentProvider actualUrl={actualUrl}>
                    <div className="flex flex-col h-full bg-white -mt-4 pt-4 pb-[340px] md:pb-36">
                        <div className="flex flex-row md:flex-col items-end justify-between w-full pb-2">
                            <TitlePage>{t("myfavs")}</TitlePage>
                            <div className="flex md:hidden flex-row items-center justify-end gap-2 w-1/2 md:mt-4 pr-2">
                                <MobileFilterButton />
                            </div>
                        </div>
                        <div className="flex flex-col w-full">
                            <div
                              className={`${topPaddingTop()} border-b border-gray-200 w-full sticky md:static top-0 bg-white z-30`}
                            >
                                <ul className="flex flex-row flex-nowrap -mb-px text-sm font-medium text-center text-gray-500 justify-center w-full">
                                    <li className="mr-2">
                                        <button
                                            onClick={() =>
                                                handleLinkClick("/favorites")
                                            }
                                            className={`${
                                                actualUrl === "photos"
                                                    ? "border-orange-400 text-orange-400"
                                                    : "border-transparent hover:text-gray-600 hover:border-gray-300"
                                            } border-b-2 flex-grow inline-flex items-center flex-row gap-2 justify-center p-4 rounded-t-lg group`}
                                        >
                                            <CustomFontAwesomeIcon icon="images" />
                                            <span className="hidden md:flex">
                                                {t("photos")}
                                            </span>
                                            <div
                                                className={`${
                                                    actualUrl === "photos"
                                                        ? "text-orange-700 bg-white-100 border border-orange-300"
                                                        : "text-gray-500 bg-white-100 border border-gray-500"
                                                } ml-1 flex justify-center items-center font-medium py-1 px-2 bg-white rounded-full`}
                                            >
                                                <div className="text-xs font-normal leading-none max-w-full flex-initial">
                                                    {pictures
                                                        ? pictures.pagination
                                                              .totalItems
                                                        : "0"}
                                                </div>
                                            </div>
                                        </button>
                                    </li>
                                    <li className="mr-2">
                                        <button
                                            onClick={() =>
                                                handleLinkClick(
                                                    "/favorites/photographers"
                                                )
                                            }
                                            className={`${
                                                actualUrl === "photos"
                                                    ? "border-transparent hover:text-gray-600 hover:border-gray-300"
                                                    : "border-purple-400 text-purple-400"
                                            } border-b-2 flex-grow inline-flex items-center flex-row gap-2 justify-center p-4 rounded-t-lg group`}
                                        >
                                            <CustomFontAwesomeIcon icon="users" />
                                            <span className="hidden md:flex">
                                                {t("photographs")}
                                            </span>
                                            <div
                                                className={`${
                                                    actualUrl === "photos"
                                                        ? "text-gray-500 bg-white-100 border border-gray-500"
                                                        : "text-purple-700 bg-white-100 border border-purple-300"
                                                } ml-1 flex justify-center items-center font-medium py-1 px-2 bg-white rounded-full`}
                                            >
                                                <div className="text-xs font-normal leading-none max-w-full flex-initial">
                                                    {photographers
                                                        ? photographers.data
                                                              .length
                                                        : "0"}
                                                </div>
                                            </div>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div className="search-result-box flex-grow w-full bg-gray-50 rounded-b-lg md:rounded-b-none md:rounded-r-lg pb-20 px-2 md:px-8">
                                <div className="w-full flex-grow max-w-7xl mx-auto px-4 pt-4">
                                    <SearchResult />
                                </div>
                            </div>
                        </div>
                    </div>
                </CurrentProvider>
                <Footer />
            </div>
        </>
    );
}
