import { ReactConfirmAlertProps, confirmAlert } from "react-confirm-alert";
import PicturesAPI from "../../../../services/API/Clients/PicturesAPI";
import { handleError } from "../../../../services/Errors/handleErrors";
import { Storage } from "../../../../services/storage";
import CustomFontAwesomeIcon from "../../../CustomFontAwesomeIcon";

const deleteAlert = (deleteRoutine: () => void, t: any): ReactConfirmAlertProps => ({
    customUI: ({ onClose }) => (
        <div className="ezoom-alert-container relative transform overflow-hidden rounded-2xl bg-white p-6 py-12 text-left align-middle shadow-xl transition-all max-w-3xl">
            {/* Header avec une croix pour fermer */}
            <div className="ezoom-alert-header">
                <h3 className="text-3xl font-medium leading-6 text-gray-900 text-center">{t("deletepicture")}</h3>
            </div>
            <CustomFontAwesomeIcon icon="x" className="absolute top-2 right-2 text-gray-500 cursor-pointer" onClick={onClose} />
            <div className="mt-6">
                <p className="text-lg text-center text-gray-500" dangerouslySetInnerHTML={{ __html: t("delete_d")}}>
                </p>
            </div>
            <div className="flex justify-center gap-4 mt-6">
                <button
                        className="rounded-full border text-white bg-orange-500 hover:bg-orange-100 px-4 py-2 text-sm font-medium  hover:shadow-xl transition-all font-sans"
                    onClick={onClose}
                >
                    {t("keep")}
                </button>
                <button
                        className="rounded-full border bg-white hover:bg-gray-50 px-4 py-2 text-sm font-medium  hover:shadow-xl transition-all font-sans"
                    onClick={deleteRoutine}
                    dangerouslySetInnerHTML={{ __html: t("delete") }}
                />
            </div>
        </div>
    ),
    closeOnEscape: true,
    closeOnClickOutside: true,
    keyCodeForClose: [8, 32],
    overlayClassName: "ezoom-alert-overlay",
});

export const handleDelete = (actualId: string, t: any, navigate: any) => {
    confirmAlert(
        deleteAlert(async () => {
            const response = await PicturesAPI.deletePicture(actualId);
            if (response.status === 200 || response.status === 201 || response.status === 204) {
                Storage.setDelete();
                navigate(0);
            } else {
                handleError(response);
            }
        }, t)
    );
};
