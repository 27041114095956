import Footer from "../../components/Footer/Footer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Input, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import MiniLogo from "../../assets/images/logos/SVG/logo_e.svg";
import GradientButton from "../../components/Form/Button/GradientButtonBig";

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import BackgroundDefault from "../../assets/images/elements/default-banner.png";
import { PictureType } from "../../components/Interfaces/PictureType";
import Loader from "../../components/Loader";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import PicturesAPI from "../../services/API/Clients/PicturesAPI";
import UserAPI from "../../services/API/Clients/UserAPI";
import { handleError } from "../../services/Errors/handleErrors";
import CustomFontAwesomeIcon from "../../components/CustomFontAwesomeIcon";
import { PictureBackgroundType } from "../../components/Interfaces/PictureBackgroundType";

export default function RecoverPassword() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("forgotpassword_t")}`;
    const [searchBar, setSearchBar] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [password, setPassword] = useState("");
    const [verifPassword, setVerifPassword] = useState("");
    const [backgroundPicture, setBackgroundPicture] = useState<PictureBackgroundType | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await PicturesAPI.backgroundPicture();
            if (response.status === 200) {
                setBackgroundPicture(response.body);
                setLoading(false);
            } else {
                handleError(response);
            }
        }
        fetchData();
    }, [])

    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    const handleSubmit = async () => {
        if (!token)
            return;

        if (password !== verifPassword) {
            toast.warning(t("signupPasswordMatch"))
            return
        }

        const data = {
            code: token,
            newPassword: password
        }

        const response = await UserAPI.recover(data)
        if (response.status === 200) {
            toast.success(t("recoverpassword_alert"))
            window.location.href = "/signin"
        } else {
            handleError(response);
        }
    }

    useEffect(() => {
        setLoading(false)
    }, [])

    return (
        <div className="relative min-h-screen">
            <HeaderForAnimation />
            <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} isVisible={isVisible} setIsVisible={setIsVisible} />
            <Loader loading={loading} />
            <div className="hidden md:block h-full" style={{ backgroundImage: `url('${backgroundPicture?.picture?.publicPath ?? BackgroundDefault}')`, backgroundAttachment: "fixed", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", width: "100vmax", zIndex: -1, position: "fixed", top: 0, left: "50%", transform: 'translate(-50%, 0)', pointerEvents: 'none', backgroundPositionX: 'center'}}></div>
            <div className="relative min-h-screen flex justify-start items-center">
                <div className="relative w-screen md:min-h-auto mt-0 mb-0 md:h-auto md:w-2/3 xl:w-1/3 bg-white rounded-0 md:rounded-lg md:mt-8 md:mb-16 px-8 py-12 mx-auto shadow-xl flex justify-center flex-col max-w-3xl">
                    <Link to="/" className="absolute top-4 right-4 flex md:hidden">
                        <IconButton color="orange" className="w-10 h-10 rounded-0 md:rounded-lg flex items-center justify-center">
                            <CustomFontAwesomeIcon icon="x" className="text-white h-4 w-4 mx-auto my-3" aria-hidden="true" />
                        </IconButton>
                    </Link>
                    <MiniLogo className="w-14 h-14 my-2.5 mx-auto" />
                    <h1 className="text-3xl mb-4 text-center">{t("forgotpassword_t")}</h1>
                    {token ? (
                        <>
                            <h3 className="text-sm mb-6 text-center leading-6">{t("forgetpassword_recover")}</h3>
                            <div className="flex flex-col gap-3 w-full mb-6">
                                <Input type="password" color="orange" value={password} onChange={(e) => setPassword(e.target.value)} label={t("pwd")} className="rounded-md" />
                                <Input type="password" color="orange" value={verifPassword} onChange={(e) => setVerifPassword(e.target.value)} label={t("rpwd")} className="rounded-md" />
                            </div>
                            <button className="w-full mx-auto" onClick={handleSubmit}>
                                <GradientButton text="save" />
                            </button>
                        </>
                    ) : (
                        <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100 mt-4">
                            <Typography className="text-base text-gray-700">{t("recoverpassword_alert2")}</Typography>
                        </div>
                    )}
                </div>
            </div>
            <Footer isAbsolute={false} />
        </div>
    );
}
