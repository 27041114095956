import { Link } from "react-router-dom";
import DefaultPictureProfil from "../../../assets/images/elements/default-profile.png";
import { CountryFlag } from "../../CountryFlags";

export default function Avatar({ portfolio } : Readonly<{ portfolio: {
    country: string,
    id: string,
    name: string,
    picture: string,
    region: string
}}>) {

    return (
        <Link className="mx-2 w-fit min-w-[100px] flex flex-nowrap overflow-hidden py-1 pl-1 pr-3 bg-gray-200 hover:bg-gray-100 transition-all rounded-full" to={`/portfolio/${portfolio.id}/photos`}>
            <div className="h-10 w-10 aspect-square rounded-full">
                <img src={portfolio?.picture ?? DefaultPictureProfil} alt={portfolio.name} className="object-cover w-full h-full min-w-[40px] rounded-full" />
            </div>
            <div className={`flex flex-col justify-center ${portfolio?.region ? "ml-4" : "ml-2"} w-fit`}>
                <p className="text-base font-medium text-gray-900 whitespace-nowrap">{portfolio.name}</p>
                {(portfolio?.region) && (
                    <p className="text-xs font-medium text-gray-900 whitespace-nowrap flex gap-1 items-center">
                        <CountryFlag countryCode={portfolio.country} width={15} height={15} />
                        {portfolio.region}
                    </p>
                )}
            </div>
        </Link>
    );
}
