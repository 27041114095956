
import { Card, CardBody, CardHeader, Input, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { LicenceProps } from "./LicenceInterface";
import CustomFontAwesomeIcon from "../../../../../components/CustomFontAwesomeIcon";

export default function CardSell({
    selectedLicense,
    setSelectedLicense,
    handleRemoveLicense,
    cantDelete = false,
}: Readonly<{
    selectedLicense: LicenceProps;
    handleRemoveLicense: () => void;
    setSelectedLicense: Function;
    cantDelete?: boolean;
}>) {
    const { t } = useTranslation();

    return (
        <Card color="white" className="w-full pt-4 mt-4">
            <CardHeader variant="gradient" color="orange" className="py-2 flex flex-row gap-2 justify-between items-center px-2">
                <div></div>
                <Typography variant="h6" color="white">
                    {selectedLicense.name}
                </Typography>
                {cantDelete 
                    ? <div></div> 
                    : (
                        <CustomFontAwesomeIcon onClick={handleRemoveLicense} className="h-3 w-3 ml-2 cursor-pointer hover:bg-white/40 p-2 rounded transition-all" icon="x" />
                    )
                }
            </CardHeader>
            <CardBody className="flex flex-col gap-4">
                <div className="flex flex-row gap-2 justify-around">
                    <div className="flex flex-col gap-1 w-1/3">
                        <CustomFontAwesomeIcon icon="image" className="text-gray-600 h-8 mb-1" />
                        <Typography className="text-center text-xs text-gray-500">{t("imagejpeg")}</Typography>
                        <Typography className="text-center text-xs text-gray-500">XXXX x XXXX px</Typography>
                    </div>
                    <div className="flex flex-col gap-1 w-1/3">
                        {selectedLicense.available === "UNLIMITED" ? (
                            <>
                                <CustomFontAwesomeIcon icon="infinity" className="text-gray-600 h-8 mb-1" />
                                <Typography className="text-center text-xs text-gray-500">{t("unlimited_content")}</Typography>
                            </>
                        ) : selectedLicense.available === "SINGLE" ? (
                            <>
                                <CustomFontAwesomeIcon icon="medal" className="text-gray-600 h-8 mb-1" />
                                <Typography className="text-center text-xs text-gray-500">{t("unique")}</Typography>
                            </>
                        ) : (
                            <>
                                <CustomFontAwesomeIcon icon="boxes-stacked" className="text-gray-600 h-8 mb-1" />
                                <Typography className="text-center text-xs text-gray-500">{t("limited_content", { count: selectedLicense?.originalCount ?? 0 })}</Typography>
                            </>
                        )}
                    </div>
                    <div className="flex flex-col gap-1 w-1/3">
                        {selectedLicense.exclusive ? (
                            <>
                                <CustomFontAwesomeIcon icon="file-shield" className="text-gray-600 h-8 mb-1" />
                                <Typography className="text-center text-xs text-gray-500">{t("exclusive_contract")}</Typography>
                            </>
                        ) : (
                            <>
                                <CustomFontAwesomeIcon icon="file" className="text-gray-600 h-8 mb-1" />
                                <Typography className="text-center text-xs text-gray-500">{t("inherit")}</Typography>
                            </>
                        )}
                    </div>
                </div>
                <div className="flex flex-row gap-2 justify-around">
                    <div className="flex flex-col gap-1 w-1/3">
                        <CustomFontAwesomeIcon icon="ban" className="text-gray-600 h-8 mb-1" />
                        <Typography className="text-center text-xs text-gray-500">{t("illegal_uses")}</Typography>
                    </div>
                    <div className="flex flex-col gap-1 w-1/3">
                        {selectedLicense.commercialUse ? (
                            <>
                                <CustomFontAwesomeIcon icon="shop" className="text-gray-600 h-8 mb-1" />
                                <Typography className="text-center text-xs text-gray-500">{t("commercial_uses_s_a")}</Typography>
                            </>
                        ) : (
                            <>
                                <CustomFontAwesomeIcon icon="shop-slash" className="text-gray-600 h-8 mb-1" />
                                <Typography className="text-center text-xs text-gray-500">{t("commercial_uses_s")}</Typography>
                            </>
                        )}
                    </div>
                    <div className="flex flex-col gap-1 w-1/3">
                        <Typography className="text-center text-base font-bold h-8 align-middle flex items-center justify-center mb-1" color="gray">
                            {t("chf")} {selectedLicense?.price ? Number(selectedLicense?.price)?.toFixed(2) : selectedLicense?.basePrice?.toFixed(2)}
                        </Typography>
                        <Typography className="text-center text-xs text-gray-500">{t("pricettc")}</Typography>
                    </div>
                </div>
                {/* {cantDelete 
                    ? <></> 
                    : (
                        <> */}
                            <Input
                                className="w-full bg-white"
                                type="text"
                                value={selectedLicense.price}
                                color="orange"
                                label={t("pricepic")}
                                onChange={(e) => setSelectedLicense({ ...selectedLicense, price: e.target.value })}
                                // disabled={cantDelete}
                            />
                            <Typography className="-mt-2 flex items-start font-normal text-xs text-justify text-gray-500">
                                <CustomFontAwesomeIcon icon="question-circle" className="h-3 w-3 mr-1 pt-[1px]" />
                                {t("pricepic_info")}
                            </Typography>
                        {/* </>
                    )
                } */}
            </CardBody>
        </Card>
    );
}
