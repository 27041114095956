import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Collapse, Typography } from "@material-tailwind/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { updateUseByEmployerClient, updateUseBySubcontractor } from "../../../services/redux/actions/contractActions";
import CustomFontAwesomeIcon from "../../../components/CustomFontAwesomeIcon";

export default function WhoSousLicence() {
    const { t } = useTranslation();
    const [openDescription, setOpenDescription] = React.useState(false);
    const contract = useSelector((state: any) => state.contract);
    const dispatch = useDispatch();

    const handleCheckEmployerClient = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updateUseByEmployerClient(e.target.checked))
    }
    
    const handleCheckSubContractor = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updateUseBySubcontractor(e.target.checked))
    }
    
    return (
        <div className="">
            <button
                type="button"
                onClick={() => setOpenDescription(!openDescription)}
                className="flex flex-row justify-between items-center w-full mt-4 pb-3 border-b border-gray-300"
            >
                <div className="flex flex-col text-left w-full pr-2">
                    <p className="text-lg font-sans">5. {t("who_else_can_use")}</p>
                </div>
                <div className="flex w-fit">
                    <CustomFontAwesomeIcon icon={openDescription ? "chevron-up" : "chevron-down"} className="text-gray-500" />
                </div>
            </button>
            <Collapse open={openDescription} className="flex flex-col gap-4 pt-2 px-4">
                <Typography variant="paragraph" className="font-bold text-justify">
                    {t("who_else_can_use_d")}
                </Typography>

                <Checkbox
                    className="min-w-[20px] forceSizeRadioButtons bg-white"
                    color="orange"
                    name="useEmployeur"
                    value="one"
                    onChange={handleCheckEmployerClient}
                    label={<Typography variant="paragraph" className="font-semibold text-justify w-full md:w-full break-words">{t("employer_client")}</Typography>}
                    checked={contract.useByEmployerClient}
                />
                <Typography variant="small" className={`text-justify -mt-4 px-4 ${contract.useByEmployerClient ? "" : "text-gray-500"}`}>
                    {t("employer_client_d")}
                </Typography>

                <Checkbox
                    className="min-w-[20px] forceSizeRadioButtons bg-white"
                    color="orange"
                    name="useTraitant"
                    value="allOrga"
                    onChange={handleCheckSubContractor}
                    label={<Typography variant="paragraph" className="font-semibold text-justify w-full md:w-full break-words">{t("subcontractors")}</Typography>}
                    checked={contract.useBySubcontractor}
                />
                <Typography variant="small" className={`text-justify -mt-4 px-4 ${contract.useBySubcontractor ? "" : "text-gray-500"}`}>
                    {t("subcontractors_d")}
                </Typography>
            </Collapse>
        </div>
    );
}
