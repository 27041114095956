import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer/Footer";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import TitlePage from "../../components/TitlePage";
import WrapperGrayZone from "../../components/WrapperGrayZone";

export default function Privacy() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("privacy")}`;
    const [searchBar, setSearchBar] = React.useState(false);
    const [isVisible, setIsVisible] = useState(true);

    const topPaddingTop = (): string => {
        if (isVisible) {
        if (searchBar)
            return "top-[108px] pt-[7px]";
        else
            return "top-[60px] pt-[7px]";
        } else {
        if (searchBar)
            return "top-[48px] pt-[7px]";
        else
            return "top-[0px] pt-[7px]";
        }
    }

    return (
        <div className="relative min-h-full">
            <HeaderForAnimation />
            <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} isVisible={isVisible} setIsVisible={setIsVisible} />
            <div className="flex flex-col h-full bg-white -mt-4 pb-[340px] md:pb-36">
                <div 
                    className={`${topPaddingTop()} flex flex-row md:flex-col items-end justify-between w-full sticky top-0 pb-2 md:static bg-white z-30 border-b border-gray-200`}>
                    <TitlePage mt={0} mb={0}>
                        {t("privacy_title")}
                    </TitlePage>
                </div>
                <WrapperGrayZone pt="0" px="8">
                    <div className="mx-auto py-6">
                        <div className="-m-1 flex flex-col flex-wrap md:-m-2">
                            <div className="w-full md:w-2/3 mx-auto"></div>

                            <div className="max-w-[1280px] w-full mx-auto">
                                <span dangerouslySetInnerHTML={{ __html: t("privacy_subtitle_1")}} />

                                <h1 className="mt-10 text-xl font-bold">{t("privacy_title_1")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_1")}}></p>
                                <p className="mt-4 font-bold italic">{t("privacy_resume_1")}</p>

                                <h1 className="mt-6 text-base font-bold underline">{t("privacy_title_2")}</h1>
                                <ul className="list-decimal pl-8">
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_ul_2_1")}}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_ul_2_2")}}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_ul_2_3")}}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_ul_2_4")}}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_ul_2_5")}}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_ul_2_6")}}></li>
                                </ul>
                                {/* <p className="mt-4 font-bold italic">{t("privacy_resume_2")}</p> */}

                                <h1 className="mt-6 text-base font-bold underline">{t("privacy_title_3")}</h1>
                                <ul className="list-decimal pl-8">
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_ul_3_1")}}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_ul_3_2")}}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_ul_3_3")}}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_ul_3_4")}}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_ul_3_5")}}></li>
                                </ul>
                                <p className="mt-4 font-bold italic">{t("privacy_resume_3")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("privacy_title_4")}</h1>
                                <h1 className="mt-6 text-base font-bold underline">{t("privacy_title_4_sub_1")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_4_1")}}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_4_2")}}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_4_3")}}></p>
                                <h1 className="mt-6 text-base font-bold underline">{t("privacy_title_4_sub_2")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_4_4")}}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_4_5")}}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_4_6")}}></p>
                                <p className="mt-4 font-bold italic">{t("privacy_resume_4")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("privacy_title_5")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_5_1")}}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_5_2")}}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_5_3")}}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_5_4")}}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_5_5")}}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_5_6")}}></p>
                                <p className="mt-4 font-bold italic">{t("privacy_resume_5")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("privacy_title_6")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_6")}}></p>
                                <p className="mt-4 font-bold italic">{t("privacy_resume_6")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("privacy_title_7")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_7_1")}}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_7_2")}}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_7_3")}}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_7_4")}}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_7_5")}}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_7_6")}}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_7_7")}}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_7_8")}}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_7_9")}}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_7_10")}}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_7_11")}}></p>
                                <p className="mt-4 font-bold italic">{t("privacy_resume_7")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("privacy_title_8")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_8_1")}}></p>
                                <ul className="list-disc pl-8">
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_8_2")}}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_8_3")}}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_8_4")}}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_8_5")}}></li>
                                </ul>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_8_6")}}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_8_7")}}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_8_8")}}></p>
                                <p className="mt-4 font-bold italic">{t("privacy_resume_8")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("privacy_title_9")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_9_1")}}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_9_2")}}></p>
                                <p className="mt-4 font-bold italic">{t("privacy_resume_9")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("privacy_title_10")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_10_1")}}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_10_2")}}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_10_3")}}></p>
                                <ul className="list-disc pl-8">
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_10_4")}}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_10_5")}}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_10_6")}}></li>
                                </ul>
                                <p className="mt-4 font-bold italic">{t("privacy_resume_10")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("privacy_title_11")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_11")}}></p>
                                <p className="mt-4 font-bold italic">{t("privacy_resume_11")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("privacy_title_12")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_12")}}></p>
                                <p className="mt-4 font-bold italic">{t("privacy_resume_12")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("privacy_title_13")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_13")}}></p>

                                <h1 className="mt-10 text-xl font-bold">{t("privacy_title_14")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_14")}}></p>
                            </div>
                        </div>
                    </div>
                </WrapperGrayZone>
            </div>
            <Footer />
        </div>
    );
}
