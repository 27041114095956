import { Dialog } from "@headlessui/react";
import ModalWrapper from "../../../components/Modals/ModalWrapper";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { updateBackground } from "../../../services/redux/actions/formAction";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { SearchPicturesType } from "../../../components/Interfaces/PictureType";
import GalleryAPI from "../../../services/API/Clients/GalleryAPI";
import { toast } from "react-toastify";
import { handleError } from "../../../services/Errors/handleErrors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomFontAwesomeIcon from "../../../components/CustomFontAwesomeIcon";

export default function ModalChangeBackgroundGallry({ open, setOpen, handleSelectBackground } : Readonly<{ open: boolean; setOpen: () => void, handleSelectBackground: Function }>) {
    const { t } = useTranslation();
    const { hashid } = useParams();
    const [pictures, setPictures] = useState<SearchPicturesType>({
        pagination: {
            current: 0,
            last: 0,
            parPage: 0,
            totalItems: 0,
        },
        data: [],
    });
    
    useEffect(() => {
        const fetchPictures = async () => {
            if (!hashid) return;
            const res = await GalleryAPI.getGalleriePictures(hashid);
            if (res.status === 200 || res.status === 201) {
                setPictures(res.body)
            } else {
                handleError(res);
            }
        };
        fetchPictures();
    }, []);

    return (
        <ModalWrapper open={open} setOpen={setOpen}>
        <Dialog.Panel className="transform overflow-hidden rounded-2xl bg-white p-6 py-12 text-left align-middle shadow-xl transition-all max-w-3xl max-h-screen overflow-y-auto">
            <Dialog.Title as="h3" className="text-2xl font-medium leading-6 text-gray-900 text-center">
                    {t("selectbannergallery")}
                </Dialog.Title>
                <CustomFontAwesomeIcon icon="x" className="absolute top-4 right-4 text-gray-500 cursor-pointer" onClick={() => setOpen()} />

                {pictures && pictures.data.length > 0 ? (
                    <div className="grid grid-cols-4 gap-2 mt-4">
                        {pictures.data.map((picture: { id: string, minPath: string }) => (
                            <button className="aspect-square" key={picture.id} onClick={() => handleSelectBackground(picture.id, picture.minPath)}>
                                <img src={picture.minPath} alt={picture.id} className="object-cover h-full w-full rounded-lg" />
                            </button>  
                        ))}
                    </div>
                ) : (
                    <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100 mt-4">
                        <CustomFontAwesomeIcon icon="image" className="text-3xl text-gray-500/50 mt-4" />
                        <span className="text-base text-gray-500 mb-12 text-center" dangerouslySetInnerHTML={{ __html: t("selectbanner_noPicture")}}></span>
                    </div>
                )}

                <div className="flex justify-center gap-4 mt-8">
                    <button
                        type="button"
                        className="rounded-full border bg-white px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
                        onClick={() => setOpen()}
                    >
                        {t("cancel")}
                    </button>
                </div>
            </Dialog.Panel>
        </ModalWrapper>
    );
}
