import { Storage } from "../../storage";
import APIClient from "../APIClients";

const token = Storage.getToken();
const idStorage = Storage.getId();

export default class PortfolioAPI {
    public static async portfolio(id: string) {
        if (!token)
            return APIClient.noInterceptor.get(`/public/portfolios/${id}`);
        else
            return APIClient.ezoom.get(`/public/portfolios/${id}`);
    }

    public static async report(data: {}) {
        return APIClient.ezoom.post(`/portfolio-reports`, data);
    }

    public static async searchPortfoliosPagination(query: string, page: number) {
        const parameters = query ? `${query}&page=${page}` : `page=${page}`;
        if (!token)
            return APIClient.noInterceptor.get(`/public/search/portfolios?${parameters}`);
        else
            return APIClient.ezoom.get(`/public/search/portfolios?${parameters}`);
    }

    public static async compatibleFilters(query: string) {
        if (!token)
            return APIClient.noInterceptor.get(`/public/search/portfolios/compatible-filters?${query}`);
        else
            return APIClient.ezoom.get(`/public/search/portfolios/compatible-filters?${query}`);
    }


    public static async path(id: string) {
        if (!token)
            return APIClient.noInterceptor.get(`/public/portfolio-path/${id}`);
        else
            return APIClient.ezoom.get(`/public/portfolio-path/${id}`);
    }

    public static async putPortfolio(id: string, data: {}) {
        return APIClient.ezoom.put(`/portfolios/${id}`, data);
    }

    public static async picture(id: string, data: FormData) {
        return APIClient.ezoom.postPicture(`/portfolios/${id}/picture`, data);
    }

    public static async publish(id: string) {
        return APIClient.ezoom.put(`/portfolios/${id}/publish`, {});
    }

    public static async follow(data: {}) {
        return APIClient.ezoom.post(`/portfolio-subscriptions`, data);
    }

    public static async unfollow(data: {}) {
        return APIClient.ezoom.post(`/unsubscribe-portfolio`, data);
    }

    public static async organize(data: OrganizeRequest) {
        return APIClient.ezoom.post(`/organize-pictures`, data);
    }

    public static async complete(id: string) {
        return APIClient.ezoom.get(`/portfolios/${id}/completion`);
    }

    public static async getHistory(id: string) {
        return APIClient.ezoom.get(`/portfolios/${id}/account-history`);
    }

    public static async getPhotos(id: string) {
        if (!token)
            return APIClient.noInterceptor.get(`/public/search/pictures?pagetype=portfolio&portfolio=${id}`);
        else
            return APIClient.ezoom.get(`/public/search/pictures?pagetype=portfolio&portfolio=${id}`);
    }

    public static async getGalleries(id: string) {
        if (!token)
            return APIClient.noInterceptor.get(`/public/search/galleries?portfolio=${id}`);
        else
            return APIClient.ezoom.get(`/public/search/galleries?portfolio=${id}`);
    }

    public static async getPhotosPages(id: string, page: number) {
        if (!token)
            return APIClient.noInterceptor.get(`/public/search/pictures?pagetype=portfolio&portfolio=${id}&page=${page}`);
        else
            return APIClient.ezoom.get(`/public/search/pictures?pagetype=portfolio&portfolio=${id}&page=${page}`);
    }

    public static async getOpinions(id: string) {
        if (!token)
            return APIClient.noInterceptor.get(`/public/testimonials?portfolio=${id}`);
        else
            return APIClient.ezoom.get(`/public/testimonials?portfolio=${id}`);
    }

    public static async getServices(id: string) {
        if (!token)
            return APIClient.noInterceptor.get(`/public/services?portfolio=${id}`);
        else
            return APIClient.ezoom.get(`/public/services?portfolio=${id}`);
    }

    public static async getServicesWithId(id: string, myId: string) {
        if (!token)
            return APIClient.noInterceptor.get(`/public/services?portfolio=${id}`);
        else {
            if (myId === idStorage)
                return APIClient.ezoom.get(`/public/services?portfolio=${id}&draft=true`);
            else
                return APIClient.ezoom.get(`/public/services?portfolio=${id}`);

        }
    }

    public static async getServicesDraft(id: string) {
        if (!token)
            return APIClient.noInterceptor.get(`/public/services?portfolio=${id}`);
        else
            return APIClient.ezoom.get(`/public/services?portfolio=${id}&draft=true`);
    }

    public static async getRecentPictures(id: string) {
        if (!token)
            return APIClient.noInterceptor.get(`/public/search/pictures?pagetype=recent&portfolio=${id}`);
        else
            return APIClient.ezoom.get(`/public/search/pictures?pagetype=recent&portfolio=${id}`);
    }

    public static async getRepartition(id: string) {
        if (!token)
            return APIClient.noInterceptor.get(`/public/portfolios/${id}/favorites-repartition`);
        else
            return APIClient.ezoom.get(`/public/portfolios/${id}/favorites-repartition`);
    }

    public static async getFeatures(id: string) {
        if (!token)
            return APIClient.noInterceptor.get(`/public/portfolios/${id}/features`);
        else
            return APIClient.ezoom.get(`/public/portfolios/${id}/features`);
    }

    public static async portfolios() {
        if (!token)
            return APIClient.noInterceptor.get(`/public/search/portfolios`);
        else
            return APIClient.ezoom.get(`/public/search/portfolios`);
    }

    public static async portfoliosPagination(page: number) {
        if (!token)
            return APIClient.noInterceptor.get(`/public/search/portfolios?page=${page}`);
        else
            return APIClient.ezoom.get(`/public/search/portfolios?page=${page}`);
    }

    public static async portfoliosWithParameter(param: string) {
        if (!token)
            return APIClient.noInterceptor.get(`/public/search/portfolios?${param}`);
        else
            return APIClient.ezoom.get(`/public/search/portfolios?${param}`);
    }

    public static async portfoliosWithParameterPagination(param: string, page: number) {
        if (!token)
            return APIClient.noInterceptor.get(`/public/search/portfolios?${param}&page=${page}`);
        else
            return APIClient.ezoom.get(`/public/search/portfolios?${param}&page=${page}`);
    }

    public static async searchPortfolioPA(region: string, country: string, hideMe: boolean = false) {
        if (!token)
            return APIClient.noInterceptor.get(`/public/search/portfolios?pagetype=search&region=${region}&country=${(country !== undefined && country !== "") ? country : "CHE"}&hideSelf=${hideMe}`);
        else
            return APIClient.ezoom.get(`/public/search/portfolios?pagetype=search&region=${region}&country=${(country !== undefined && country !== "") ? country : "CHE"}&hideSelf=${hideMe}`);
    }

    public static async searchPortfolioPAWithoutRegion() {
        if (!token)
            return APIClient.noInterceptor.get(`/public/search/portfolios?pagetype=popular&popular=true`);
        else
            return APIClient.ezoom.get(`/public/search/portfolios?pagetype=popular&popular=true`);
    }

    public static async searchPortfolioPaLogOut() {
        if (!token)
            return APIClient.noInterceptor.get(`/public/search/portfolios?pagetype=search&hasServices=true&plan=PRO`);
        else
            return APIClient.ezoom.get(`/public/search/portfolios?pagetype=search&hasServices=true&plan=PRO`);
    }

    public static async autocomplete(query: string) {
        if (!token)
            return APIClient.noInterceptor.get(`/public/search/portfolios/autocomplete?query=${query}`);
        else
            return APIClient.ezoom.get(`/public/search/portfolios/autocomplete?query=${query}`);
    }

    public static async addView(data: {}) {
        if (!token)
            return APIClient.noInterceptor.post(`/public/portfolio-views`, data);
        else
            return APIClient.ezoom.post(`/public/portfolio-views`, data);
    }

    public static async getAllPictures(portfolioId: string) {
        return APIClient.ezoom.get(`/portfolios/${portfolioId}/public-pictures`)
    }

}
