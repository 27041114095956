import { Typography } from "@material-tailwind/react";
import { confirmAlert } from "react-confirm-alert";
import { Storage } from "../../../../services/storage";
import GradientButton from "../../../Form/Button/GradientButton";
import { PictureType } from "../../../Interfaces/PictureType";

import ApplePayCard from "../../../../assets/images/logos/paiement/card_apple-pay.svg";
import GooglePayCard from "../../../../assets/images/logos/paiement/card_google-pay.svg";
import MasterCard from "../../../../assets/images/logos/paiement/card_mastercard.svg";
import PfPayCard from "../../../../assets/images/logos/paiement/card_post-finance-pay.svg";
import TwintCard from "../../../../assets/images/logos/paiement/card_twint.svg";
import VisaCard from "../../../../assets/images/logos/paiement/card_visa.svg";
import CustomFontAwesomeIcon from "../../../CustomFontAwesomeIcon";

const buyMyPicture = async (onClose: Function, dataActualItem: PictureType, actualId: string) => {
    Storage.setToBuy({ minPath: dataActualItem.minPath, id: actualId, price: dataActualItem?.contract?.basePrice ?? 0 });
    onClose();
    window.location.href = "/checkout?from=uniq";
};

export const handleBuyIt = (handleAddToCart: (onClose: Function) => void, actualId: string, dataActualItem: PictureType, t: any) => {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className="py-4 bg-white rounded-lg shadow-lg border-2 border-gray-300 w-screen lg:w-[60vw] relative">
                    <CustomFontAwesomeIcon icon="x" className="absolute top-4 right-4 text-black cursor-pointer" onClick={onClose} />
                    <Typography className="text-center w-full text-2xl">{t("get_these_rights")}</Typography>
                    <div className="w-full flex mt-4 justify-center">
                        <div className="w-full flex flex-row flex-wrap px-2">
                            <div className="w-1/3 flex items-center justify-center">
                                <div className="relative w-2/3 h-2/3 py-4 rounded-lg flex flex-col gap-4 items-center">
                                    <CustomFontAwesomeIcon icon="image" className="text-black h-12 mb-1" />
                                    <div className="flex flex-col">
                                        <Typography className="text-center text-xs text-black font-bold">{t("imagejpeg")}<br />{dataActualItem?.width} x {dataActualItem?.height} px</Typography>
                                    </div>
                                </div>
                            </div>
                            <div className="w-1/3 flex items-center justify-center">
                                <div className="relative w-2/3 h-2/3 py-4 rounded-lg flex flex-col gap-4 items-center">

                                    {dataActualItem?.contract?.available === "UNLIMITED" ? (
                                        <>
                                            <CustomFontAwesomeIcon icon="infinity" className="text-black h-12 mb-1" />
                                            <Typography className="text-center text-xs text-black font-bold">{t("unlimited_content")}</Typography>
                                        </>
                                    ) : dataActualItem?.contract?.available === "SINGLE" ? (
                                        <>
                                            <CustomFontAwesomeIcon icon="medal" className="text-black h-12 mb-1" />
                                            <Typography className="text-center text-xs text-black font-bold">{t("unique")}</Typography>
                                        </>
                                    ) : (
                                        <>
                                            <CustomFontAwesomeIcon icon="boxes-stacked" className="text-black h-12 mb-1" />
                                            <Typography className="text-center text-xs text-black font-bold">{t("limited_content", { count: dataActualItem?.contract?.originalCount ?? "0" })}</Typography>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="w-1/3 flex items-center justify-center">
                                <div className="relative w-2/3 h-2/3 py-4 rounded-lg flex flex-col gap-4 items-center">
                                    {dataActualItem?.contract?.exclusive ? (
                                        <>
                                            <CustomFontAwesomeIcon icon="file-shield" className="text-black h-12 mb-1" />
                                            <Typography className="text-center text-xs text-black font-bold">{t("exclusive_contract")}</Typography>
                                        </>
                                    ) : (
                                        <>
                                            <CustomFontAwesomeIcon icon="file" className="text-black h-12 mb-1" />
                                            <Typography className="text-center text-xs text-black font-bold">{t("inherit")}</Typography>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="w-1/2 flex items-center justify-center">
                                <div className="relative w-2/3 h-2/3 py-4 rounded-lg flex flex-col gap-4 items-center">
                                    <CustomFontAwesomeIcon icon="ban" className="text-black h-12 mb-2" />
                                    <div className="flex flex-col">
                                        <Typography className="text-center text-xs text-black font-bold">{t("illegal_uses")}</Typography>
                                    </div>
                                </div>
                            </div>
                            <div className="w-1/2 flex items-center justify-center">
                                <div className="relative w-2/3 h-2/3 py-4 rounded-lg flex flex-col gap-4 items-center">
                                    {dataActualItem?.contract?.commercialUse ? (
                                        <>
                                            <CustomFontAwesomeIcon icon="shop" className="text-black h-12 mb-2" />
                                            <Typography className="text-center text-xs text-black font-bold">{t("commercial_uses_s_a")}</Typography>
                                        </>
                                    ) : (
                                        <>
                                            <CustomFontAwesomeIcon icon="shop-slash" className="text-black h-12 mb-2" />
                                            <Typography className="text-center text-xs text-black font-bold">{t("commercial_uses_s")}</Typography>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex flex-col px-2 items-center justify-center">
                            <h1 className="mt-4 w-full text-center text-2xl">{t("chf")} {dataActualItem?.contract?.basePrice?.toFixed(2)} </h1>
                            <button className="w-fit mx-auto mt-4" onClick={() => buyMyPicture(onClose, dataActualItem, actualId)}>
                                <GradientButton text={t("proceed")} />
                            </button>
                            <button
                                className="rounded-full border bg-white px-4 py-2 text-sm font-medium  hover:shadow-xl transition-all font-sans my-2"
                                onClick={() => handleAddToCart(onClose)}
                            >
                                {t("addtocart")}
                            </button>
                            <div className="flex flex-row gap-4 mx-auto text-3xl text-gray-400">
                                <VisaCard />
                                <MasterCard />
                                <PfPayCard />
                                <TwintCard />
                                <ApplePayCard />
                                <GooglePayCard />
                            </div>
                        </div>
                    </div>
                </div>
            );
        },
    });
};
