import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import LocaleAPI from "../../../../services/API/Clients/LocaleAPI";
import { Locale } from "../../../../components/Interfaces/PortfolioType";
import { updateLocales } from "../../../../services/redux/actions/formAction";
import { InlineInput } from "./InlineInput";
import { handleError } from "../../../../services/Errors/handleErrors";
import CustomFontAwesomeIcon from "../../../../components/CustomFontAwesomeIcon";

export default function Langs() {
    const { t } = useTranslation();
    const portfolio = useSelector((state: any) => state.form);
    const dispatch = useDispatch();
    const [locales, setLocales] = useState<Locale[]>([]);
    const [inputValues, setInputValues] = useState<string[]>([]);

    useEffect(() => {
        const getLangs = async () => {
            const resp = await LocaleAPI.locales();
            if (resp.status === 200) {
                setLocales(resp.body);
            } else {
                handleError(resp);
            }
        };
        getLangs();

        setInputValues(portfolio.locales.map((e: Locale) => e.label));
    }, []);

    useEffect(() => {
        const identifiedLocales = inputValues.reduce((acc: Locale[], curr: string) => {
            const locale = locales.find((locale: Locale) => locale.label === curr);
            if (locale) {
                acc.push(locale);
            }
            return acc;
        }, []);

        dispatch(updateLocales(identifiedLocales));
    }, [inputValues]);

    const handleAddLocale = () => {
        const newInputValues = [...inputValues];
        newInputValues.push("");
        setInputValues(newInputValues);
    };

    const handleLocaleInput = (e: string, index: number) => {
        const newInputValues = [...inputValues];
        newInputValues[index] = e;
        setInputValues(newInputValues);
    };

    const handleRegionRemove = (index: number) => {
        const newInputValues = [...inputValues];
        newInputValues.splice(index, 1);
        setInputValues(newInputValues);
    };

    return (
        <div className="flex flex-col w-full p-4 rounded-lg bg-gray-100 mt-4">
            <Typography className={`text-lg font-bold`}>{t("mylangs")}</Typography>
            <div className="flex flex-row flex-wrap items-center mt-2 gap-2">
                <CustomFontAwesomeIcon icon="circle-plus" className="text-gray-500 cursor-pointer" onClick={() => handleAddLocale()} />
                {inputValues.map((locale: string, index: number) => (
                    <div className="bg-white text-orange-700 py-0.5 pl-3 pr-1 rounded-full flex justify-between items-center" key={index}>
                        <InlineInput
                            value={locale}
                            onChange={(e: string) => handleLocaleInput(e, index)}
                            values={locales.map((e: Locale) => e.label)}
                            label={t("langTab")}
                        />
                        <CustomFontAwesomeIcon icon="circle-xmark" className="ml-2 cursor-pointer" onClick={() => handleRegionRemove(index)} />
                    </div>
                ))}
            </div>
        </div>
    );
}
