import { useTranslation } from "react-i18next";
import { Services } from "../../Interfaces/PortfolioType";
import DefaultEzoomBanner from "../../../assets/images/elements/default-banner.png";
import { Chip } from "@material-tailwind/react";

export default function Performance ({ data } : Readonly<{ data: Services }>) {
    const { t } = useTranslation();

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
    };
    
    return (
        <div className="relative h-72 w-full active:opacity-75 active:md:opacity-100 md:hover:opacity-75 transition-all">
            <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-white/0 to-black/50 rounded-xl" />
            <img
                onContextMenu={handleContextMenu}
                className="h-full w-full rounded-xl object-cover object-center"
                src={data?.banner?.minPath ?? DefaultEzoomBanner}
                alt={data.title}
            />
            <div className="absolute top-1/2 -translate-y-1/2 left-0 justify-center w-full text-center">
                <p className="text-white line-clamp-2 text-3xl font-sans" style={{ textShadow: "#000000BB 1px 0 10px" }}>
                    {data.title}
                </p>
                <p className="text-white mt-2 font-normal text-lg" style={{ textShadow: "#000000BB 1px 0 10px" }}>
                    {t(data.priceType)} {data.priceValue && `${t("chf")} ${Number(data.priceValue).toFixed(2)}`}{data.priceCeil && ` ${t("to")} ${t("chf")} ${Number(data.priceCeil).toFixed(2)}`} 
                </p>
                <button className="text-sm px-4 py-2 mt-2 bg-gray-50 hover:bg-gray-50/75 rounded-full hover:text-black">
                    {t("learnmore")}
                </button>
            </div>
            {!data.published &&
                <div className="bg-orange-500 text-white text-xs font-bold px-2 py-1 rounded-full w-fit absolute left-2 bottom-2">
                    {t("unpublished")}
                </div>
            }
        </div>
    );
}
