import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import CustomFontAwesomeIcon from "../../CustomFontAwesomeIcon";

export default function AlertCloser({ message, disabled = false, handleDisabled } : Readonly<{ message: string, disabled: boolean, handleDisabled: Function }>) {
    const { t } = useTranslation();
    
    if (!disabled) return; 

    return (
        <div className={`${disabled ? "flex" : "hidden"} bg-orange-50/20 text-sm text-black px-4 py-3 font-sans mb-4 rounded-lg`} role="alert">
            <p className="flex flex-col">
                <span dangerouslySetInnerHTML={{ __html: message }} />
                <button
                    className="ml-auto border-2 bg-orange-500 hover:bg-orange-200 border-transparent inline w-fit text-white hover:text-black py-1 pl-3 pr-4 mt-2 rounded-full"
                    style={{
                        transition: "all .25s ease-in-out",
                    }}
                    onClick={() => handleDisabled()}
                >
                    <span className="flex items-center justify-between"><CustomFontAwesomeIcon icon="check-circle" className="mr-1" /> {t("understand")}</span>
                </button>
            </p>
        </div>
    );
}
