import React, { FC } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";


interface ItemProps {
    id: string;
    children: React.ReactNode;
    className?: string;
}

const SortableItem: FC<ItemProps> = (props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition || undefined,
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            className={props.className}
            {...attributes}
            {...listeners}
        >
            {props.children}
        </div>
    );
};

export default SortableItem;
