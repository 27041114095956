import { FilterConfiguration } from "../filters/FilterConfiguration"

export type FilterList = { [key: string]: any }
export type CompatibleFilterList = { [key: string]: any[] }

export type FilterSchema<FiltersType extends FilterList> = { [key in keyof FiltersType]: 'string' | 'boolean' }

export interface SearchInterface<FiltersType extends FilterList> {
    filters: FilterConfiguration<FiltersType>,
    page: number
}

export interface PaginatedResponse<Entity> {
    pagination: {
        current: number,
        last: number,
        totalItems: number,
        parPage: number
    },
    data: Entity[]
}

export type FetchResultFunction<FiltersType extends FilterList, Entity> = (props: SearchInterface<FiltersType>) => Promise<PaginatedResponse<Entity> | undefined>

export type FetchFiltersFunction<FiltersType extends FilterList, CompatibleFiltersType extends CompatibleFilterList> = (filters: FilterConfiguration<FiltersType>) => Promise<CompatibleFiltersType | undefined>

export enum FilterType {
    BOOLEAN = "boolean",
    DROPDOWN = "dropdown",
    DATEPICKER = "datepicker",
    MAPPICKER = "mappicker",
    CHIPS = "chips"
}

interface FilterRendererProps<CompatibleFiltersType extends CompatibleFilterList> {
    type: FilterType,
    mobile: FilterType,
    getKey: (o: CompatibleFiltersType[keyof CompatibleFiltersType][keyof CompatibleFiltersType[keyof CompatibleFiltersType]]) => string,
    getValue: (o: CompatibleFiltersType[keyof CompatibleFiltersType][keyof CompatibleFiltersType[keyof CompatibleFiltersType]]) => string,
}

export type FilterRenderConfiguration<CompatibleFiltersType extends CompatibleFilterList> = Readonly<{ [key in keyof CompatibleFiltersType]: FilterRendererProps<CompatibleFiltersType> }>

export type FilterRenderer<CompatibleFiltersType extends CompatibleFilterList> = (
    filterName: keyof CompatibleFiltersType,
    values: CompatibleFiltersType[keyof CompatibleFiltersType],
    selected: string | (true | undefined),
    configuration: FilterRendererProps<CompatibleFiltersType>
) => JSX.Element

export interface SortOption {
    key: string,
    value: string
}