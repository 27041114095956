export default function WrapperGrayZone({ children, pt, px, pb, hr = true }: Readonly<{ children: React.ReactNode, pt?: string, px?: string, pb?: string, hr?: boolean }>) {
    const paddingBottom = pb ? `pb-0` : "pb-0";
    const paddingTop = pt ? `pt-${pt}` : "pt-16";
    const paddingRight = px ? `px-${px}` : "px-0";

    return (
        <div className={`flex flex-col w-full ${paddingBottom}`}>
            {hr && <div className="md:border-gray-200 md:border-t-0 md:border-l-0 md:border-r-0 md:border-b-0 w-full sticky top-0 bg-white z-30"></div>}
            <div className={`flex-grow w-full bg-gray-50 rounded-b-lg md:rounded-b-none md:rounded-r-lg md:${paddingTop} ${paddingRight} pb-52`}>{children}</div>
        </div>
    );
}
