import { IconButton, Spinner, Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CustomFontAwesomeIcon from "../../../components/CustomFontAwesomeIcon";
import ContractsAPI from "../../../services/API/Clients/ContractAPI";
import { handleError } from "../../../services/Errors/handleErrors";
import { Storage } from "../../../services/storage";

export default function ContractsAccount() {
    const { t } = useTranslation();
    const myId = Storage.getId()
    const [contracts, setContracts] = React.useState<any[]>([]);
    const [popup, setPopup] = useState<Window | null>(null);
    const [isPopupClosed, setIsPopupClosed] = useState(false);
    const [loading, setLoading] = useState(true);

    const openPopup = (id: string) => {
        const newPopup = window.open(`/contracts/${id}`, 'popup', 'width=600,height=400');
        setPopup(newPopup);
    };

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;
    
        if (popup) {
            interval = setInterval(() => {
                if (popup.closed) {
                    setIsPopupClosed(true);
                    if (interval) clearInterval(interval);
                }
            }, 1000);
        }
    
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [popup]);
    
    useEffect(() => {
        if (isPopupClosed) {
            fetchContracts();
        }
    }, [isPopupClosed]);
    
    const fetchContracts = async () => {
        if (!myId) return; 
        setLoading(true);
        const response = await ContractsAPI.contracts(myId);
        if (response.status === 200) {
            setContracts(response.body.data)
            setLoading(false);
        } else {
            handleError(response);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchContracts();
    }, []);

    const getMyPdf = async (id: string) => {
        const response = await ContractsAPI.getContractPdf(id);
        if (response.status === 200) {
            const url = window.URL.createObjectURL(response.body);
            const link = document.createElement("a");
            link.href = url;
            link.target = "_blank";
            link.rel = "noopener noreferrer";
            link.click();
        } else {
            handleError(response);
        }
    };

    const deleteContract = async (id: string) => {
        const response = await ContractsAPI.deleteContract(id);
        if (response.status === 204) {
            fetchContracts();
            toast.success(t("contractdeleted"));
        } else {
            handleError(response);
        }
    };

    return (
        <div className="flex flex-col bg-gray-50 w-full md:px-4 py-4 rounded-lg">
            <p className="text-xl font-sans font-extrabold">{t("yourcontracts")}</p>

            {loading ? (
                <Spinner color="orange" className="mt-4 mx-auto w-8 h-8" />
            ) : (
                <div className="w-full overflow-x-auto">
                    <table className="w-full min-w-max table-auto text-left mt-4">
                        <thead>
                            <tr>
                                <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 rounded-tl">
                                    <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold">{t("name")}</Typography>
                                </th>
                                <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                    <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold">{t("pricettc")}</Typography>
                                </th>
                                <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 rounded-tr text-center">
                                    <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold">{t("contratLicence")}</Typography>
                                </th>
                                <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 rounded-tr">
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {contracts.length > 0 ? contracts.map((contract: any, key: number) => (
                                <tr key={contract.id} className={`${key % 2 ? "bg-blue-gray-50/75" : "bg-white"} hover:bg-gray-200 transition-all`}>
                                    <td className="border-b border-blue-gray-100 p-4">
                                        <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-90">{contract.name}</Typography>
                                    </td>
                                    <td className="border-b border-blue-gray-100 p-4">
                                        <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-90">{t("chf")} {contract.basePrice.toFixed(2)}</Typography>
                                    </td>
                                    <td className="border-b border-blue-gray-100 p-4 text-center">
                                        <IconButton
                                            onClick={() => getMyPdf(contract.id)}
                                            variant="outlined"
                                            color="orange"
                                            className="rounded-full  hover:text-orange4500 hover:border-orange-400 mr-4"
                                        >
                                            <CustomFontAwesomeIcon icon="file-pdf" />
                                        </IconButton>
                                    </td>
                                    <td className="border-b border-blue-gray-100 p-4">
                                        <IconButton
                                            onClick={() => openPopup(contract.id)}
                                            variant="outlined"
                                            color="orange"
                                            className="rounded-full hover:text-orange4500 hover:border-orange-400"
                                        >
                                            <CustomFontAwesomeIcon icon="pen" />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => deleteContract(contract.id)}
                                            variant="outlined"
                                            color="red"
                                            className="rounded-full hover:text-red-400 hover:border-red-400 ml-4"
                                        >
                                            <CustomFontAwesomeIcon icon="trash" />
                                        </IconButton>
                                    </td>
                                </tr>
                            )) : 
                            (
                                <tr>
                                    <td colSpan={4} className="text-center rounded-b bg-white p-4">
                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                            Aucun contrat
                                        </Typography>  
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}
