import { Checkbox, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

export default function CheckBoxDescribe({ label, name, describe, checked, index, setIdentification, disabled = false }: Readonly<{ label: string, name: string, describe: string, checked: boolean, index: number, setIdentification: Function, disabled?: boolean }>) {
    const { t } = useTranslation();
    
    return (
        <>
            <Checkbox className={`bg-white  ${checked ? "text-black" : "text-gray-500"}`} label={t(label)} name={name} color="orange" onChange={() => setIdentification()} checked={checked} disabled={disabled} />
            <Typography
                className={`flex items-center gap-1 font-normal ${checked ? "text-black" : "text-gray-500"} transition-all text-xs text-justify pr-2`}
            >
                {t(describe)}
            </Typography>
        </>
    )
}