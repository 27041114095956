import { Tab, TabPanel, Tabs, TabsBody, TabsHeader, Typography } from "@material-tailwind/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import OrderAPI from "../../../services/API/Clients/OrderAPI";
import PortfolioAPI from "../../../services/API/Clients/PortfolioAPI";
import { Storage } from "../../../services/storage";
import PurchaseOrder from "./components/PurchaseOrder";
import PurchaseSub from "./components/PurchaseSub";
import { handleError } from "../../../services/Errors/handleErrors";
import { handleDelete } from "../../../components/Box/Photo/components/ModalDeleteUser";
import { useNavigate } from "react-router-dom";

export default function SubscriptionAccount() {
    const { t } = useTranslation();
    const general = useSelector((state: any) => state.general);
    const myId = Storage.getId();
    const navigate = useNavigate();
    const [accountEzoom, setAccountEzoom] = useState<{
        createdAt: string;
        photographerSince: string;
        photographer: boolean;
    }>({
        createdAt: "",
        photographerSince: "",
        photographer: false,
    });
    const [historySubscription, setHistorySubscription] = useState<{
        pagination: {
            current: number;
            last: number;
            totalItems: number;
            parPage: number;
        };
        data: {
            id: string;
            plan: string;
            status: string;
            period: string;
            createdAt: string;
        }[];
    }>({
        pagination: {
            current: 0,
            last: 0,
            totalItems: 0,
            parPage: 0,
        },
        data: [],
    });
    const [historyPaymentOrder, setHistoryPaymentOrder] = useState<{
        pagination: {
            current: number;
            last: number;
            totalItems: number;
            parPage: number;
        };
        data: {}[];
    }>({
        pagination: {
            current: 0,
            last: 0,
            totalItems: 0,
            parPage: 0,
        },
        data: [],
    });
    const [historyPaymentSub, setHistoryPaymentSub] = useState<{
        pagination: {
            current: number;
            last: number;
            totalItems: number;
            parPage: number;
        };
        data: {
            status: string;
            createdAt: string;
            paymentMethod: string;
            paymentMethodName: string;
            displayText: string;
        }[];
    }>({
        pagination: {
            current: 0,
            last: 0,
            totalItems: 0,
            parPage: 0,
        },
        data: [],
    });

    const data = [
        {
          label: t("sub"),
          value: "subscription",
          desc: <PurchaseSub historyPaymentSub={historyPaymentSub}  />,
        },
        {
          label: t("photos"),
          value: "order",
          desc: <PurchaseOrder historyPaymentOrder={historyPaymentOrder} />,
        },
    ];

    useEffect(() => {
        const fetchData = async () => {
            if (!general && !general.me && !general.me.portfolio) {
                if (!myId) return;
                const responseGetSub = await OrderAPI.getSubscriptions(myId);
                if (responseGetSub.status === 200) {
                    setHistorySubscription(responseGetSub.body);
                } else handleError(responseGetSub);
                const response = await OrderAPI.getHistoryOrder(myId);
                if (response.status === 200) {
                    setHistoryPaymentOrder(response.body);
                } else handleError(response);
                const responseSub = await OrderAPI.getHistorySubscribe(myId);
                if (responseSub.status === 200) {
                    setHistoryPaymentSub(responseSub.body);
                } else handleError(responseSub);
            } else {
                const response = await PortfolioAPI.getHistory(general.me.portfolio.id);
                if (response.status === 200) {
                    setAccountEzoom(response.body);
                } else handleError(response);
                const responseGetSub = await OrderAPI.getSubscriptions(general.me.id);
                if (responseGetSub.status === 200) {
                    setHistorySubscription(responseGetSub.body);
                } else handleError(responseGetSub);
                const responseOrder = await OrderAPI.getHistoryOrder(general.me.id);
                if (responseOrder.status === 200) {
                    setHistoryPaymentOrder(responseOrder.body);
                } else handleError(responseOrder);
                const responseSub = await OrderAPI.getHistorySubscribe(general.me.id);
                if (responseSub.status === 200) {
                    setHistoryPaymentSub(responseSub.body);
                } else handleError(responseSub);
            }
        };
        fetchData();
    }, []);

    const handleCancelSubscription = async (id: string) => {
        const response = await OrderAPI.cancelSubscription(id);
        if (response.status === 200) {
            const responseGetSub = await OrderAPI.getSubscriptions(general.me.id);
            if (responseGetSub.status === 200) {
                setHistorySubscription(responseGetSub.body);
            } else {
                handleError(responseGetSub);
            }
        } else {
            handleError(response);
        }
    }

    return (
        <div className="flex flex-col bg-gray-50 w-full md:px-4 py-4 rounded-lg">
            <p className="mb-2 text-xl font-sans font-extrabold">{t("subscription")}</p>

            <p className="text-base font-sans mt-2 font-bold">{t("myezoomaccount")}</p>
            <div className="w-full overflow-x-auto">
                <table className="w-full min-w-max table-auto text-left mt-4">
                    <thead>
                        <tr>
                            <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 rounded-tl">
                                <Typography variant="small" color="blue-gray" className="font-bold leading-none opacity-90 rounded-tl">
                                    {t("typestyle")}
                                </Typography>
                            </th>
                            <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                <Typography variant="small" color="blue-gray" className="font-bold leading-none opacity-90 rounded-tl">
                                    {t("date")}
                                </Typography>
                            </th>
                            <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 rounded-tr">
                                <Typography variant="small" color="blue-gray" className="font-bold leading-none opacity-90 rounded-tl">
                                    {t("status")}
                                </Typography>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {accountEzoom && accountEzoom.photographerSince && (
                            <tr className="bg-white hover:bg-gray-200 transition-all">
                                <td className="p-4">
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {t("photographer")}
                                    </Typography>
                                </td>
                                <td className="p-4">
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {moment(accountEzoom.photographerSince).format("DD.MM.YYYY")}
                                    </Typography>
                                </td>
                                <td className="p-4">
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {t("active")}
                                    </Typography>
                                </td>
                            </tr>
                        )}
                        {general && general.me && general.me.createdAt && (
                            <tr className="bg-blue-gray-50/75 hover:bg-gray-200 transition-all  ">
                                <td className="p-4">
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {t("user")}
                                    </Typography>
                                </td>
                                <td className="p-4">
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {moment(general.me.createdAt).format("DD.MM.YYYY")}
                                    </Typography>
                                </td>
                                <td className="p-4">
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {t("active")}
                                    </Typography>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <hr className="border-gray-300 mt-6" />

            <p className="text-base font-sans mt-4 font-bold">{t("mysubscription")}</p>
            {historySubscription.data.length > 0 ? (
                <div className="w-full overflow-x-auto">
                    <table className="w-full min-w-max table-auto text-left mt-4">
                        <thead>
                            <tr className="bg-white hover:bg-gray-200 transition-all">
                                <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 rounded-tl">
                                    <Typography variant="small" color="blue-gray" className="font-bold leading-none opacity-90 rounded-tl">
                                        {t("type")}
                                    </Typography>
                                </th>
                                <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                    <Typography variant="small" color="blue-gray" className="font-bold leading-none opacity-90 rounded-tl">
                                        {t("period")}
                                    </Typography>
                                </th>
                                <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                    <Typography variant="small" color="blue-gray" className="font-bold leading-none opacity-90 rounded-tl">
                                        {t("status")}
                                    </Typography>
                                </th>
                                <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 rounded-tr">
                                    <Typography variant="small" color="blue-gray" className="font-bold leading-none opacity-90 rounded-tl">
                                        {t("nextpay")}
                                    </Typography>
                                </th>
                                <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 rounded-tr">
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {historySubscription.data.map((item: any, key: number) => (
                                <tr className={`${key % 2 ? "bg-blue-gray-50/75" : "bg-white"}  hover:bg-gray-200 transition-all`} key={item.id}>
                                    <td className="p-4">
                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                            {t(item.plan)}
                                        </Typography>
                                    </td>
                                    <td className="p-4">
                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                            {t(item.period)}
                                        </Typography>
                                    </td>
                                    <td className="p-4">
                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                            {t(item.status)}
                                        </Typography>
                                    </td>
                                    <td className="p-4">
                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                            {moment(item.createdAt).add(1, item.period === "MONTHLY" ? "M": "y").format("DD.MM.YYYY")}
                                        </Typography>
                                    </td>
                                    {item.status === "ACTIVE" ? (
                                        <td className="p-4">
                                            <button onClick={() => handleCancelSubscription(item.id)} className="text-xs bg-red-500 text-white px-2 py-1 rounded-md">{t("cancel")}</button>
                                        </td>
                                    ) : <td></td>}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100 mt-4">
                    <Typography className="text-base text-gray-700 my-4">{t("noAbo")}</Typography>
                </div>
            )}

            <hr className="border-gray-300 mt-6" />

            <p className="text-base font-sans mt-4 mb-2 font-bold">{t("payementhistory")}</p>
            <Tabs value="subscription">
                <TabsHeader>
                    {data.map(({ label, value }) => (
                        <Tab key={value} value={value}>
                            {label}
                        </Tab>
                    ))}
                </TabsHeader>
                <TabsBody>
                    {data.map(({ value, desc }) => (
                        <TabPanel key={value} value={value}>
                            {desc}
                        </TabPanel>
                    ))}
                </TabsBody>
            </Tabs>

            <p className="text-base font-sans mt-4 mb-2 font-bold">{t("deleteAccount")}</p>
            <p className="text-sm font-sans mt-2" dangerouslySetInnerHTML={{ __html: t("deleteAccount_d")}}></p>
            <button className="text-xs bg-red-500 hover:bg-red-400 transition-all text-white px-4 py-2 w-fit mx-auto rounded-md mt-2" onClick={() => handleDelete(general?.me?.id, t, navigate)}>{t("deleteAccount")}</button>
        </div>
    );
}
