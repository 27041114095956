import Performance from "../../../components/Box/Photograph/Performance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CustomFontAwesomeIcon from "../../../components/CustomFontAwesomeIcon";

export default function PerformancesEdit() {
    const navigate = useNavigate();
    const portfolio = useSelector((state: any) => state.form);
    const general = useSelector((state: any) => state.general);

    useEffect(() => {
        if (general === undefined || general.me === undefined || general.me.plan !== "PRO") {
            navigate("/portfolio/edit/photos")
        }
    }, []);

    return (
        <div className="flex flex-wrap mt-4 w-full justify-around mx-auto max-w-6xl">
            {portfolio.services && portfolio.services.data && portfolio.services.data.length > 0 && portfolio.services.data.map((perf: any, index: number) => (
                <Link key={perf.id} to={`/portfolio/edit/performance/${perf.id}`} className="w-full md:w-1/3 p-2 cursor-pointer">
                    <Performance data={perf} />
                </Link>
            ))}
            <Link to="/portfolio/edit/addPerf" className="hidden md:flex w-full md:w-1/3 p-2 cursor-pointer">
                <figure className="relative h-72 w-full flex justify-center items-center border-2 rounded-xl">
                    <CustomFontAwesomeIcon icon="plus" className="text-gray-400 hover:text-gray-500 text-4xl" />
                </figure>
            </Link>
        </div>
    );
}
