import { Dialog } from "@headlessui/react";
import ModalWrapper from "./ModalWrapper";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input, Spinner } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { Storage } from "../../services/storage";
import { toast } from "react-toastify";
import UserAPI from "../../services/API/Clients/UserAPI";
import { handleError } from "../../services/Errors/handleErrors";
import CustomFontAwesomeIcon from "../CustomFontAwesomeIcon";

export default function ModalOauth({
    open,
    setOpen,
    token,
    dataTokens,
    need2fa,
    handleChange2fa
}: Readonly<{ open: boolean; setOpen: () => void; token: string; dataTokens: { token: string; refresh_token: string; id: string }, need2fa: boolean, handleChange2fa: Function }>) {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [dataForGoogle, setDataForGoogle] = useState<{ firstname: string; lastname: string; birthdate: string }>({
        firstname: "",
        lastname: "",
        birthdate: "",
    });

    const handleConnect = async () => {
        if (dataForGoogle.firstname === "" || dataForGoogle.lastname === "" || dataForGoogle.birthdate === "")
            return toast.warning(t("fill_all_inputs"));
        setIsLoading(true);

        const datas = {
            firstname: dataForGoogle.firstname,
            lastname: dataForGoogle.lastname,
            birthdate: dataForGoogle.birthdate
        }

        const response = await UserAPI.completeMyProfil(dataTokens.id, datas, dataTokens)
        if (response.status === 200) {
            if (need2fa) {
                handleChange2fa();
                setOpen();
                setIsLoading(false);
            } else {
                Storage.setToken(dataTokens.token);
                Storage.setRefreshToken(dataTokens.refresh_token);
                Storage.setId(dataTokens.id);
                toast.success(t("signin_success"));
                window.location.href = "/";
                setOpen();
                setIsLoading(false);
            }
        } else {
            handleError(response);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (dataTokens.id === "") return;
            const response = await UserAPI.completeProfil(dataTokens.id, dataTokens)
            if (response.status === 200) {
                setDataForGoogle({
                    firstname: response?.body?.firstname ?? "",
                    lastname: response?.body?.lastname ?? "",
                    birthdate: response?.body?.birthdate ?? ""
                });
            } else {
                handleError(response);
            }
        }
        fetchData();
    }, [dataTokens.id]);

    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white p-6 py-12 text-left align-middle shadow-xl transition-all max-w-3xl">
                <Dialog.Title as="h3" className="text-3xl font-medium leading-6 text-gray-900 text-center">
                    {t("completeProfil")}
                </Dialog.Title>
                <CustomFontAwesomeIcon icon="x" className="absolute top-4 right-4 text-gray-500 cursor-pointer" onClick={() => setOpen()} />
                <div className="mt-6">
                    <p className="text-lg text-center text-gray-500 mb-4">{t("completeInfosGA")}</p>
                </div>

                <div className="flex flex-col gap-3">
                    <Input color="orange" type="text" onChange={(e) => setDataForGoogle({... dataForGoogle, lastname: e.target.value })} value={dataForGoogle.lastname} label={t("name")} />
                    <Input color="orange" type="text" onChange={(e) => setDataForGoogle({... dataForGoogle, firstname: e.target.value })} value={dataForGoogle.firstname} label={t("firstname")} />
                    <Input color="orange" type="date" onChange={(e) => setDataForGoogle({... dataForGoogle, birthdate: e.target.value })} value={dataForGoogle.birthdate} label={t("birth")} />
                </div>

                <div className="flex justify-center gap-4 mt-6">
                    <button
                        type="button"
                        className="rounded-full border text-white bg-orange-500 hover:bg-orange-100 px-4 py-2 text-sm font-medium  hover:shadow-xl transition-all font-sans"
                        onClick={() => handleConnect()}
                    >
                        {isLoading 
                            ? <Spinner color="orange" />
                            : t("signin")}
                    </button>
                    <button
                        type="button"
                        className="rounded-full border bg-white hover:bg-gray-50 px-4 py-2 text-sm font-medium  hover:shadow-xl transition-all font-sans"
                        onClick={() => setOpen()}
                    >
                        {t("close")}
                    </button>
                </div>
            </Dialog.Panel>
        </ModalWrapper>
    );
}
