import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, MenuHandler, MenuItem, MenuList, Option, Select } from "@material-tailwind/react";
import { JSXElementConstructor, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import JsonIconDico from "../../../../../components/Interfaces/IconDico.json";
import { Storage } from "../../../../../services/storage";
import CustomFontAwesomeIcon from "../../../../../components/CustomFontAwesomeIcon";

interface SelectTypePrestaProps {
    selectedValue: string;
    onChange: (value: string) => void;
    list: {
        enumValue: string;
        frLabel: string;
        enLabel: string;
    }[];
}

export default function SelectTypePresta({ selectedValue, onChange, list }: SelectTypePrestaProps) {
    const [options, setOptions] = useState<{ label: string; value: string }[]>([]);
    const [value, setValue] = useState<string>("");
    const { t } = useTranslation();

    useEffect(() => {
        const lang = Storage.getLanguage();
        const listFiltered = list.map((place) => {
            return {
                value: place.enumValue,
                label: lang === "fr" ? place.frLabel : lang === "en" ? place.enLabel : place.frLabel,
            };
        });
        setOptions(listFiltered);
    }, [list]);

    useEffect(() => {
        setValue(selectedValue);
    }, [selectedValue]);

    // const handleChange = (value: ReactElement<any, string | JSXElementConstructor<any>> | undefined) => {
    //     setValue(value);
    // };

    const displayLabel = (value: string) => {
        const option = options.find((option) => option.value === value);
        return option ? option.label : t("nooption");
    };

    return (
            <Menu placement="bottom-end">
                <MenuHandler>
                    <div className="relative">
                        <label
                            htmlFor="selectLabelPerf"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                            {t("performance")}
                        </label>
                        <div
                            id="selectLabelPerf"
                            className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-orange-500 bg-white"
                        >
                            {selectedValue !== "" && <CustomFontAwesomeIcon icon={JsonIconDico[selectedValue as keyof typeof JsonIconDico] as any} className="mr-1" />}{" "}
                            {displayLabel(selectedValue)}
                        </div>
                    </div>
                </MenuHandler>
                <MenuList className="max-h-48">
                    {list && list.length > 0 ? (
                        list?.map((service: any) => {
                            const iconValue = JsonIconDico[service.enumValue as keyof typeof JsonIconDico] as IconProp;
                            return (
                                <MenuItem value={service.enumValue} key={service.enumValue} onClick={() => onChange(service.enumValue)}>
                                    <CustomFontAwesomeIcon icon={iconValue as any} className="mr-2 w-4 h-4" />
                                    {service.frLabel}
                                </MenuItem>
                            );
                        })
                    ) : (
                        <MenuItem value="none">{t("nooption")}</MenuItem>
                    )}
                </MenuList>
            </Menu>
    );
}
