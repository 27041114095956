import { Dialog } from "@headlessui/react";
import ModalWrapper from "../../../components/Modals/ModalWrapper";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GradientButton from "../../../components/Form/Button/GradientButton";
import { Collapse, Rating, Textarea, Typography } from "@material-tailwind/react";
import React, { useEffect } from "react";
import CardUserForm from "../../../components/Box/CardUserForm";
import { Storage } from "../../../services/storage";
import TestimonialAPI from "../../../services/API/Clients/Testimonial";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { handleError } from "../../../services/Errors/handleErrors";
import CustomFontAwesomeIcon from "../../../components/CustomFontAwesomeIcon";
import { RootState } from "../../../services/redux/store";

export default function ModalOpinion({ open, setOpen, handleReload }: Readonly<{ open: boolean; setOpen: () => void; handleReload: () => void }>) {
    const { t } = useTranslation();
    const { id } = useParams();
    const [openCollapse, setOpenCollapse] = React.useState(false)
    const iHaveToken = Storage.getToken();
    const [myId, setMyId] = React.useState<string>("");
    const portfolio = useSelector((state: RootState) => state.form);
    const general = useSelector((state: any) => state.general);
    const [data, setData] = React.useState<{
        company?: string;
        firstname: string;
        lastname: string;
        country?: string;
        place?: string;
        phone?: string;
        email?: string;
        plan?: string;
    }>({
        company: "",
        firstname: "",
        lastname: "",
        country: "",
        place: "",
        phone: "",
        email: "",
    });
    const [dataForm, setDataForm] = React.useState<{
        qualityPicture: number;
        professionalism: number;
        qualityPrice: number;
        comment: string;
    }>({
        qualityPicture: 0,
        professionalism: 0,
        qualityPrice: 0,
        comment: "",
    });

    const fetchMyID = async () => {
        if (iHaveToken) {
            if (general && general.me && general.me.portfolio) {
                setMyId(general.me.portfolio.id);
                setData({
                    firstname: general.me.firstname,
                    lastname: general.me.lastname,
                    email: general.me.email,
                    plan: general.me.plan,
                });
                if (general.me.company) setData({ ...data, company: general.me.company });
                if (general.me.country) setData({ ...data, country: general.me.country.code });
                if (general.me.place) setData({ ...data, place: general.me.place.id });
                if (general.me.phone) setData({ ...data, phone: general.me.phone });
            }
        }
    };

    useEffect(() => {
        fetchMyID();
    }, []);

    const handleChangeData = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleChangeDataPhone = (value: string) => {
        setData({ ...data, phone: value });
    }

    const handleChangeRating = (e: number, name: string) => {
        setDataForm({ ...dataForm, [name]: e });
    };

    const handleChangeTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDataForm({ ...dataForm, comment: e.target.value });
    };

    const handleSubmit = async () => {
        if (dataForm.qualityPicture === 0) {
            toast.warning(t("missingDataPictureQuality"));
            return;
        }
        if (dataForm.comment === "") {
            toast.warning(t("missingDataComment"));
            return;
        }
        const datas: {
            sender?: {
                company?: string;
                firstname: string;
                lastname: string;
                country?: string;
                place?: string;
                phone?: string;
                email: string;
            };
            message: string;
            pictureQuality: number;
            professionalism?: number;
            qualityPrice?: number;
            receiver: string;
        } = {
            message: dataForm.comment,
            pictureQuality: dataForm.qualityPicture,
            receiver: `/api/public/portfolios/${portfolio.id}`,
        };
        if (!iHaveToken) {
            if (!data.firstname || data.firstname === "") {
                toast.warning(t("missingDataFirstname"));
                return;
            }
            if (!data.lastname || data.lastname === "") {
                toast.warning(t("missingDataLastname"));
                return;
            }
            if (!data.email || data.email === "") {
                toast.warning(t("missingDataEmail"));
                return;
            }
            datas.sender = {
                ...(data.company && { company: data.company }),
                firstname: data.firstname,
                lastname: data.lastname,
                ...(data.country && { country: `/api/public/countries/${data.country}` }),
                ...(data.place && { place: `/api/public/places/${data.place}` }),
                ...(data.phone && { phone: data.phone }),
                email: data.email,

            }
        }
        if (dataForm.professionalism !== 0 && dataForm.qualityPrice !== 0) {
            datas.professionalism = dataForm.professionalism;
            datas.qualityPrice = dataForm.qualityPrice;
        } else if (dataForm.professionalism !== 0 && dataForm.qualityPrice === 0) {
            toast.warning(t("missingDataQualityPrice"));
            return;
        } else if (dataForm.professionalism === 0 && dataForm.qualityPrice !== 0) {
            toast.warning(t("missingDataProfessionalism"));
            return;
        }
        const resp = await TestimonialAPI.newTestimonial(datas);
        if (resp.status === 200 || resp.status === 201) {
            toast.success(t("opinionadded"));
            clearForm()
            handleReload()
            setOpen();
        } else 
            handleError(resp);
    };

    const clearForm = () => {
        setDataForm({
            qualityPicture: 0,
            professionalism: 0,
            qualityPrice: 0,
            comment: "",
        });
    }

    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white p-6 pt-12 text-left align-middle shadow-xl transition-all w-screen md:w-1/2 h-screen md:h-3/4 overflow-y-auto">
                <Dialog.Title as="h3" className="text-2xl font-medium leading-6 text-gray-500 text-center w-full mb-6">
                    {t("addOpinion")}
                </Dialog.Title>
                <CustomFontAwesomeIcon icon="x" className="absolute top-4 right-4 text-gray-500 cursor-pointer" onClick={() => setOpen()} />
                <div className="flex flex-col md:flex-row w-full">
                    <CardUserForm data={data} handleChangeData={handleChangeData} myId={myId} handleChangeDataPhone={handleChangeDataPhone} showChip={false} />
                </div>
                <div className="flex items-center ml-3 gap-2">
                    <Rating value={dataForm.qualityPicture} onChange={(e) => handleChangeRating(e, "qualityPicture")} ratedColor="orange" unratedColor="gray" />
                    <Typography color="blue-gray" className="font-medium">
                        {t("picturequality")}
                    </Typography>
                </div>
                <h2 className="px-4 pt-3 pb-2 text-gray-800 text-lg" onClick={() => setOpenCollapse(!openCollapse)}>
                    {t("noteprestation")} <CustomFontAwesomeIcon icon={!openCollapse ? "chevron-down" : "chevron-up"} className="ml-2" />
                </h2>
                <Collapse open={openCollapse}>
                    <div className="flex items-center ml-6 gap-2">
                        <Rating
                            value={dataForm.professionalism}
                            onChange={(e) => handleChangeRating(e, "professionalism")}
                            ratedColor="orange"
                            unratedColor="gray"
                        />
                        <Typography color="blue-gray" className="font-medium">
                            {t("prolevel")}
                        </Typography>
                    </div>
                    <div className="flex items-center ml-6 gap-2 mt-2">
                        <Rating value={dataForm.qualityPrice} onChange={(e) => handleChangeRating(e, "qualityPrice")} ratedColor="orange" unratedColor="gray" />
                        <Typography color="blue-gray" className="font-medium">
                            {t("qualityprice")}
                        </Typography>
                    </div>
                </Collapse>
                <div className="flex flex-wrap mb-6">
                    <h2 className="px-4 pt-3 pb-2 text-gray-800 text-lg">{t("comment")}</h2>
                    <div className="w-full md:w-full px-3 mb-2 mt-2">
                        <Textarea color="orange" className="bg-white" label="Votre commentaire" value={dataForm.comment} onChange={handleChangeTextarea} />
                    </div>
                    <button type="button" className="flex w-full justify-center" onClick={handleSubmit}>
                        <GradientButton text={t("send")} />
                    </button>
                </div>
            </Dialog.Panel>
        </ModalWrapper>
    );
}
