import { useTranslation } from "react-i18next";

import Footer from "../../components/Footer/Footer";

import { Option, Select, Textarea } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CardUserForm from "../../components/Box/CardUserForm";
import GradientButton from "../../components/Form/Button/GradientButtonBig";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import TitlePage from "../../components/TitlePage";
import WrapperGrayZone from "../../components/WrapperGrayZone";
import ContactsAPI from "../../services/API/Clients/ContactAPI";
import { handleError } from "../../services/Errors/handleErrors";
import { Storage } from "../../services/storage";

export default function Contact() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("message")}`;
    const general = useSelector((state: any) => state.general);
    const [searchBar, setSearchBar] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [myId, setMyId] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<{
        company?: string;
        firstname: string;
        lastname: string;
        country?: string;
        place?: string;
        phone?: string;
        email?: string;
        plan?: string;
    }>({
        company: "",
        firstname: "",
        lastname: "",
        country: "",
        place: "",
        phone: "",
        email: "",
    });
    const [dataForm, setDataForm] = useState<{
        object: string;
        message: string;
    }>({
        object: "",
        message: "",
    });

    const fetchMyID = async () => {
        const iHaveToken = Storage.getToken();
        if (iHaveToken) {
            if (!general || !general.me) return;
            setMyId(general.me.portfolio.id);
            setData({
                firstname: general.me.firstname,
                lastname: general.me.lastname,
                email: general.me.email,
                plan: general.me.plan,
            });
            if (general.me.company) setData({ ...data, company: general.me.company });
            if (general.me.country) setData({ ...data, country: general.me.country });
            if (general.me.place) setData({ ...data, place: general.me.place });
            if (general.me.phone) setData({ ...data, phone: general.me.phone });
        }
    };

    useEffect(() => {
        fetchMyID();
    }, []);

    const handleChangeData = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleChangeDataPhone = (value: string) => {
        setData({ ...data, phone: value });
    };

    const handleSubmit = async () => {
        const iHaveToken = Storage.getToken();

        if (isLoading) return;
        setIsLoading(true);

        if (dataForm.message === "" || dataForm.message === undefined) {
            toast.warning(t("missingDataMessage"));
        } else if (dataForm.object === "" || dataForm.object === undefined) {
            toast.warning(t("missingDataSubject"));
        } else if (!general?.me && (data.firstname === "" || data.firstname === undefined)) {
            toast.warning(t("missingFirstname"));
        } else if (!general?.me && (data.lastname === "" || data.lastname === undefined)) {
            toast.warning(t("missingLastname"));
        } else if (!general?.me && (data.email === "" || data.email === undefined)) {
            toast.warning(t("missingEmail"));
        } else {
            const datas: {
                sender?: {
                    company?: string;
                    firstname?: string;
                    lastname?: string;
                    country?: string;
                    place?: string;
                    phone?: string;
                    email?: string;
                };
                message: string;
                subject: string;
            } = {
                message: dataForm.message,
                subject: dataForm.object,
                sender: {},
            };
            if (!iHaveToken) {
                if (!data.firstname || data.firstname === "") {
                    toast.warning(t("missingDataFirstname"));
                    return;
                }
                if (!data.lastname || data.lastname === "") {
                    toast.warning(t("missingDataLastname"));
                    return;
                }
                if (!data.email || data.email === "") {
                    toast.warning(t("missingDataEmail"));
                    return;
                }
                datas.sender = {
                    ...(data.company && { company: data.company }),
                    firstname: data.firstname,
                    lastname: data.lastname,
                    ...(data.country && { country: `/api/public/countries/${data.country}` }),
                    ...(data.place && { place: `/api/public/places/${data.place}` }),
                    ...(data.phone && { phone: data.phone }),
                    email: data.email,
                };
            }
            const response = await ContactsAPI.newSupport(datas);
            if (response.status === 200 || response.status === 201) {
                toast.success(t("messagesend_success"));
                setDataForm({
                    object: "",
                    message: "",
                });
            } else handleError(response);
        }
        setIsLoading(false);
    };

    const topPaddingTop = (): string => {
        if (isVisible) {
        if (searchBar)
            return "top-[108px] pt-[7px]";
        else
            return "top-[60px] pt-[7px]";
        } else {
        if (searchBar)
            return "top-[48px] pt-[7px]";
        else
            return "top-[0px] pt-[7px]";
        }
    }

    return (
        <div className="relative min-h-full">
            <HeaderForAnimation />
            <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} isVisible={isVisible} setIsVisible={setIsVisible} />
            <div className="flex flex-col h-full bg-white -mt-4  pb-[340px] md:pb-36 min-h-screen">
                <div
                    className={`${topPaddingTop()} flex flex-row md:flex-col items-end justify-between w-full sticky top-0 pb-2 md:static bg-white z-30 border-b border-gray-200`}
                >
                    <TitlePage mt={0} mb={0}>
                        {t("contact")}
                    </TitlePage>
                </div>
                <WrapperGrayZone pt="0" px="8">
                    <div className="mx-auto py-6 min-h-[50vh]">
                        <div className="-m-1 flex flex-wrap md:-m-2">
                            <div className="w-full md:w-2/3 mx-auto">
                                <div className="flex flex-col lg:flex-row gap-4 items-start">
                                    <CardUserForm myId={myId} data={data} handleChangeData={handleChangeData} handleChangeDataPhone={handleChangeDataPhone} />
                                    <div className="bg-gray-200 p-4 rounded-lg w-full max-h-96  h-fit overflow-y-scroll text-justify flex flex-col gap-4">
                                        <Select
                                            color="purple"
                                            placeholder="Sujet du message"
                                            label={t("subject")}
                                            onChange={(e) => setDataForm({ ...dataForm, object: e ?? "" })}
                                            className="bg-white"
                                        >
                                            <Option value="info">Besoin d'un renseignement</Option>
                                            <Option value="commercial">Contact commercial</Option>
                                            <Option value="bug">Signaler un bug</Option>
                                            <Option value="other">Autre</Option>
                                        </Select>
                                        <Textarea
                                            color="purple"
                                            label="Contenu du message"
                                            className="bg-white min-w-[72px]"
                                            value={dataForm.message}
                                            onChange={(e) => setDataForm({ ...dataForm, message: e.target.value })}
                                        />
                                        <button onClick={handleSubmit} className="w-full">
                                            <GradientButton text={t("send")} loading={isLoading} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </WrapperGrayZone>
            </div>
            <Footer />
        </div>
    );
}
