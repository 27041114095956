import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Option, Select, Typography } from "@material-tailwind/react";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import GalleryAPI from "../../services/API/Clients/GalleryAPI";
import { handleError } from "../../services/Errors/handleErrors";
import CustomFontAwesomeIcon from "../CustomFontAwesomeIcon";


const reportGallery = async (onClose: Function, valueToReport: string, actualId: string, t: any) => {
    if (valueToReport === "") return toast.warning(t("reportNeedReason"));
    const datas = {
        resource: `/api/public/galleries/${actualId}`,
        reason: valueToReport,
    };
    const response = await GalleryAPI.report(datas);
    if (response.status === 200 || response.status === 201) {
        toast.success(t("reportSuccessGallery"));
        onClose();
    } else if (response.status === 409) {
        toast.warning(t("alreadyReportedGallery"));
    } else {
        handleError(response);
    }
};

export const OpenGalleryReport = (actualId: string, t: any) => {
    let valueToReport: string = "";
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className="py-4 bg-white rounded-lg shadow-lg border-2 border-gray-300 w-screen lg:w-[60vw] relative">
                    <CustomFontAwesomeIcon icon="x" className="absolute top-4 right-4 text-gray-500 cursor-pointer" onClick={onClose} />
                    <Typography className="text-center w-full text-2xl">{t("signalGallery")}</Typography>
                    <div className="w-full flex mt-4 justify-center">
                        <div className="w-full flex flex-col px-2 items-center justify-center">
                            <div className="w-[300px] mx-auto">
                                <Select color="orange" label={t("reasonSignal")} className="w-full" onChange={(e) => e && (valueToReport = e)}>
                                    <Option value="INAPPROPRIATE" color="orange">
                                        {t("INAPPROPRIATE")}
                                    </Option>
                                    <Option value="SPAM" color="orange">
                                        {t("SPAM")}
                                    </Option>
                                    <Option value="HATEFUL" color="orange">
                                        {t("HATEFUL")}
                                    </Option>
                                    <Option value="VIOLENT" color="orange">
                                        {t("VIOLENT")}
                                    </Option>
                                    <Option value="BULLYING" color="orange">
                                        {t("BULLYING")}
                                    </Option>
                                    <Option value="COPYRIGHT" color="orange">
                                        {t("COPYRIGHT")}
                                    </Option>
                                    <Option value="OTHER" color="orange">
                                        {t("OTHER")}
                                    </Option>
                                </Select>
                            </div>
                            <div className="flex gap-4 items-center justify-center mt-2">
                                <button
                                    className="rounded-full border border-orange-500 hover:border-orange-200 bg-orange-500 hover:bg-orange-200 text-white px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans my-2"
                                    onClick={() => reportGallery(onClose, valueToReport, actualId, t)}
                                >
                                    {t("report")}
                                </button>
                                <button
                                    className="rounded-full border bg-white px-4 py-2 text-sm font-medium  hover:shadow-xl transition-all font-sans my-2"
                                    onClick={() => onClose()}
                                >
                                    {t("cancel")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        },
    });
};
