import { useTranslation } from "react-i18next";

const URL_REDIRECT = process.env.REACT_APP_URL_REDIRECT

export default function JoinFirst() {
    const { t } = useTranslation();
    return (
        <div className="m-0 relative bg-white">
            <div className="max-w-7xl mx-auto py-8">
                <p className="mt-4 px-2 md:px-0 text-center text-2xl md:text-4xl w-full md:w-2/3 mx-auto">{t("youarephotographs")}</p>
                <p className="mt-4 px-2 md:px-0 text-center text-lg w-full md:w-2/3 mx-auto font-extrabold">{t("beta")}</p>
                <p className="px-2 md:px-0 text-center text-lg w-full md:w-1/2 mx-auto font-light">{t("beta_long")}</p>
                <div className="flex justify-center items-center px-0 gap-4 max-w-7xl mx-auto w-full md:w-[90%] mt-6">
                    <a
                            className="border-2 bg-orange-500 hover:bg-orange-200 w-44 text-center border-transparent inline w-auto text-white hover:text-black py-2 px-4 rounded-full disabled:bg-gray-200 disabled:hover:text-white"
                        style={{
                            transition: "all .25s ease-in-out",
                        }}
                        href={`${URL_REDIRECT}signup`}
                    >
                        <span className="flex text-center justify-center items-center">{t("signup")}</span>
                    </a>
                    <a
                        className="border-2 bg-purple-500 hover:bg-purple-200 w-44 text-center border-transparent inline w-auto text-white hover:text-black py-2 px-4 rounded-full disabled:bg-gray-200 disabled:hover:text-white"
                        style={{
                            transition: "all .25s ease-in-out",
                        }}
                        href={`${URL_REDIRECT}iam-photographer`}
                    >
                        <span className="flex text-center justify-center items-center">{t("learnMore")}</span>
                    </a>
                </div>
            </div>
        </div>
    );
}
