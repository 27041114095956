import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { Avatar, Badge, Spinner, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import UserAPI from "../../../services/API/Clients/UserAPI";
import { Storage } from "../../../services/storage";
import { DateDiffBetweenTwo } from "../../DateDiffBetweenTwo";
import moment from "moment";
import DefaultPictureEzoom from "../../../assets/images/elements/default-profile.png"
import { returnSubtitle, returnTitle, returnUrl } from "../../../enums/Notifications";
import { handleError } from "../../../services/Errors/handleErrors";
import CustomFontAwesomeIcon from "../../CustomFontAwesomeIcon";

export default function Notifications({ isOpen, side = "right", handleOpen }: Readonly<{ isOpen: boolean; side?: string; handleOpen: () => void }>) {
    const { t } = useTranslation();
    const myId = Storage.getId();
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [notifications, setNotifications] = useState<{
        pagination: {
            current: 0,
            last: 0,
            totalItems: 0,
            itemsPerPage: 0,
        },
        data: {
            context: {
                picture?: string,
                status?: string,
                author?: string,
                image?: string,
                portfolio?: string,
                contact?: string
            },
            type: string,
            createdAt: string,
            read: boolean
        }[]
    }>({
        pagination: {
            current: 0,
            last: 0,
            totalItems: 0,
            itemsPerPage: 0,
        },
        data: []
    });
    const [count, setCount] = useState<{count: number}>({
        count: 0
    });

    useEffect(() => {
        const fetchData = async () => {
            if (!myId) return;
            setIsLoading(true);
            const response = await UserAPI.getNotificationsCount()
            if (response.status === 200) {
                setCount(response.body);
                setIsLoading(false);
            } else {
                handleError(response);
                setIsLoading(false);
            }
        }
        fetchData();
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            if (!open) return;
            if (!myId) return;
            setIsLoading(true);
            const response = await UserAPI.getNotifications(myId);
            if (response.status === 200) {
                setNotifications(response.body);
                setIsLoading(false);
            } else {
                handleError(response);
                setIsLoading(false);
            }
        }
        fetchData();
    }, [open]);

    const hasUnreadNotifications = notifications.data.some(notification => notification.read === false);

    const indexOfFirstReadTrue = notifications.data.findIndex((notification, index) => {
        if (notification.read === true && (index === 0 || notifications.data[index - 1].read === false)) {
            return true;
        }
        return false;
    });

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button onClick={() => setOpen(!open)} className="flex items-center justify-center text-center cursor-pointer text-base font-extralight w-8 h-8 hover:bg-gray-100 rounded-full bg-white text-gray-500 hover:text-gray-700 ml-2">
                    {count.count > 0 ? (
                        <Badge content={count.count > 9 ? "9+" : count.count} color="orange" withBorder className="text-[8px] w-[20px] h-[20px] flex items-center justify-center min-w-[10px] min-h-[10px] p-0 top-0">
                            <CustomFontAwesomeIcon icon="bell" />
                        </Badge>
                    ) : (
                        <CustomFontAwesomeIcon icon="bell" />
                    )}
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className={`absolute ${side === 'right' ? "right-0" : "left-0"} z-50 top-9 w-96 h-40 overflow-y-auto rounded-md bg-white shadow-lg`}>
                    <div
                        className={`flex flex-col justify-center text-center px-2 ${notifications.data.length === 0 ? "h-full py-4" : "py-2"} items-center absolute right-0 z-10 w-full rounded-md`}
                        role="menu"
                    >
                        {isLoading 
                            ? <Spinner color="orange" />
                            : notifications.data.length !== 0 ? (
                            <>
                                {notifications.data.map((notif, index) => (
                                    <>
                                        {(index === indexOfFirstReadTrue && hasUnreadNotifications) && (
                                            <Typography className="text-xs font-comfortaa font-bold text-black text-left w-full">
                                                {t("old_notifs")}
                                            </Typography>
                                        )}
                                        {index === 0 && !hasUnreadNotifications && (
                                            <>
                                                <div className="flex flex-col w-full mb-2 py-4">
                                                    <CustomFontAwesomeIcon icon="bell-slash" className="text-3xl my-4 text-gray-400" />
                                                    <span className="text-lg text-gray-400 italic mb-2 font-comfortaa">{t("no_notifs")}</span>
                                                    <Link to="/account/preferences" className="text-sm text-orange-500">
                                                        {t("notif_settings")}
                                                    </Link>
                                                </div>
                                                <Typography className="text-xs font-comfortaa font-bold text-black py-1 px-2 mb-2 text-left w-full">
                                                    {t("old_notifs")}
                                                </Typography>
                                            </>
                                        )}
                                        <Link to={returnUrl(notif.type, notif.context)} className="w-full flex flex-row p-1 items-center mb-2 shadow-lg bg-white rounded-lg" key={notif.createdAt}>
                                            <Avatar size="lg" variant="rounded" src={notif.context.image ?? notif.context.picture ?? DefaultPictureEzoom} />
                                            <div className="flex flex-col ml-2">
                                                <Typography color="blue-gray" className="font-bold pb-0.5 pt-1 text-sm font-comfortaa text-left">
                                                    {returnTitle(t, notif.type)}
                                                </Typography>
                                                <Typography className="font-light pb-0.5 text-xs font-comfortaa text-left">{returnSubtitle(t, notif.type, notif.context)}</Typography>
                                                <Typography className="flex flex-row font-light text-xs font-comfortaa text-gray-500 text-left">
                                                    <CustomFontAwesomeIcon icon="clock" className="mt-0.5 mr-1 rounded-full" />
                                                    {DateDiffBetweenTwo(moment(notif.createdAt), moment())}
                                                </Typography>
                                            </div>
                                        </Link>
                                    </>
                                ))}
                            </>
                        ) : (
                            <div className="flex flex-col">
                                <CustomFontAwesomeIcon icon="bell-slash" className="text-3xl my-4 text-gray-400" />
                                <span className="text-lg text-gray-400 italic mb-2 font-comfortaa">{t("no_notifs")}</span>
                                <Link to="/account/preferences" className="text-sm text-orange-500">
                                    {t("notif_settings")}
                                </Link>
                            </div>
                        )}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
}
