import { Typography } from "@material-tailwind/react";
import { confirmAlert } from "react-confirm-alert";
import OrderAPI from "../../../../services/API/Clients/OrderAPI";
import { handleError } from "../../../../services/Errors/handleErrors";
import CustomFontAwesomeIcon from "../../../CustomFontAwesomeIcon";
import { PictureType } from "../../../Interfaces/PictureType";

export const handleContractIt = (dataActualItem: PictureType, t: any) => {
    const handleOpenContract = async () => {
        const response = await OrderAPI.contratSell(dataActualItem?.contract?.id ?? "");
        if (response.status === 200) {
            const url = window.URL.createObjectURL(response.body);
            const link = document.createElement("a");
            link.href = url;
            link.target = "_blank";
            link.rel = "noopener noreferrer";
            link.click();
        } else {
            handleError(response);
        }
    }

    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className="py-4 bg-white rounded-lg shadow-lg border-2 border-gray-300 w-screen lg:w-[60vw] relative">
                    <CustomFontAwesomeIcon
                        icon="x"
                        className="absolute top-4 right-4 text-black font-bold cursor-pointer"
                        onClick={onClose}
                    />
                    <Typography className="text-center w-full text-2xl">
                        {t("contract_of_picture")}
                    </Typography>
                    <div className="w-full flex flex-col mt-4 justify-center gap-4">
                        <div className="flex flex-row gap-2 justify-around">
                            <div className="flex flex-col gap-1 w-1/3">
                                <CustomFontAwesomeIcon
                                    icon="image"
                                    className="text-black h-12 mb-1"
                                />
                                <Typography className="text-center text-sm text-black font-bold">
                                    {t("imagejpeg")}
                                </Typography>
                                <Typography className="text-center text-sm text-black font-bold">
                                    {dataActualItem?.width} x {dataActualItem?.height} px
                                </Typography>
                            </div>
                            <div className="flex flex-col gap-1 w-1/3">
                                {dataActualItem?.contract?.available ===
                                "UNLIMITED" ? (
                                    <>
                                        <CustomFontAwesomeIcon
                                            icon="infinity"
                                            className="text-black h-12 mb-1"
                                        />
                                        <Typography className="text-center text-sm text-black font-bold">
                                            {t("unlimited_content")}
                                        </Typography>
                                    </>
                                ) : dataActualItem?.contract?.available ===
                                  "SINGLE" ? (
                                    <>
                                        <CustomFontAwesomeIcon
                                            icon="medal"
                                            className="text-black h-12 mb-1"
                                        />
                                        <Typography className="text-center text-sm text-black font-bold">
                                            {t("unique")}
                                        </Typography>
                                    </>
                                ) : (
                                    <>
                                        <CustomFontAwesomeIcon
                                            icon="boxes-stacked"
                                            className="text-black h-12 mb-1"
                                        />
                                        <Typography className="text-center text-sm text-black font-bold">
                                            {t("limited_content", { count: dataActualItem?.contract?.originalCount ?? "0" })}
                                        </Typography>
                                    </>
                                )}
                            </div>
                            <div className="flex flex-col gap-1 w-1/3">
                                {dataActualItem?.contract?.exclusive ? (
                                    <>
                                        <CustomFontAwesomeIcon
                                            icon="file-shield"
                                            className="text-black h-12 mb-1"
                                        />
                                        <Typography className="text-center text-sm text-black font-bold">
                                            {t("exclusive_contract")}
                                        </Typography>
                                    </>
                                ) : (
                                    <>
                                        <CustomFontAwesomeIcon
                                            icon="file"
                                            className="text-black h-12 mb-1"
                                        />
                                        <Typography className="text-center text-sm text-black font-bold">
                                            {t("inherit")}
                                        </Typography>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-row gap-2 justify-around">
                            <div className="flex flex-col gap-1 w-1/3">
                                <CustomFontAwesomeIcon
                                    icon="ban"
                                    className="text-black h-12 mb-1"
                                />
                                <Typography className="text-center text-sm text-black font-bold">
                                    {t("illegal_uses")}
                                </Typography>
                            </div>
                            <div className="flex flex-col gap-1 w-1/3">
                                {dataActualItem?.contract?.commercialUse ? (
                                    <>
                                        <CustomFontAwesomeIcon
                                            icon="shop"
                                            className="text-black h-12 mb-1"
                                        />
                                        <Typography className="text-center text-sm text-black font-bold">
                                            {t("commercial_uses_s_a")}
                                        </Typography>
                                    </>
                                ) : (
                                    <>
                                        <CustomFontAwesomeIcon
                                            icon="shop-slash"
                                            className="text-black h-12 mb-1"
                                        />
                                        <Typography className="text-center text-sm text-black font-bold">
                                            {t("commercial_uses_s")}
                                        </Typography>
                                    </>
                                )}
                            </div>
                            <div className="flex flex-col gap-1 w-1/3">
                                <Typography
                                    className="text-center text-2xl font-bold h-12 align-middle flex items-center justify-center mb-1"
                                    color="gray"
                                >
                                    {t("chf")}{" "}
                                    {dataActualItem?.contract?.basePrice?.toFixed(2)}
                                </Typography>
                                <Typography className="text-center text-sm text-black font-bold">
                                    {t("pricettc")}
                                </Typography>
                            </div>
                        </div>
                        <div className="flex flex-row gap-2 justify-center">
                            <button
                                type="button"
                                className="rounded-full border text-white bg-orange-500 hover:bg-orange-100 px-4 py-2 text-sm font-medium  hover:shadow-xl transition-all font-sans"
                                onClick={() => {
                                    handleOpenContract();
                                }}
                            >
                                {t("openPdfContract")}
                            </button>
                        </div>
                    </div>
                </div>
            );
        },
    });
};
