import { useTranslation } from "react-i18next";
import CustomFontAwesomeIcon from "../CustomFontAwesomeIcon";
import { Pagination } from "../Interfaces/PictureType";

export default function PaginationCallNextOne({ pagination, callNextPicture }: Readonly<{ pagination: Pagination, callNextPicture: Function }>) {
    const { t } = useTranslation();

    return (
        <div className={`flex items-center justify-between ${pagination.totalItems !== 0 && "border-t border-gray-200"} px-4 py-3 sm:px-6`}>
            <div className="flex flex-1 items-center justify-between">
                <div>
                    <p className="text-sm text-gray-700">
                        {pagination.totalItems !== 0 && (
                            <>
                                {t("sell")} <span className="font-medium">{pagination.totalItems === 0 ? 0 : 1}</span> {t("to")} <span className="font-medium">{pagination.totalItems > 10 ? 10 : pagination.totalItems}</span>{" "}
                                {t("on")} <span className="font-medium">{pagination.totalItems}</span> {t("results")}
                            </>
                        )}
                    </p>
                </div>
                {pagination.totalItems > 0 && (
                    <div>
                        <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                            <button className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                                <span className="sr-only">Previous</span>
                                <CustomFontAwesomeIcon icon="chevron-left" className="h-3 w-3" aria-hidden="true" />
                            </button>
                            {[...Array(pagination.last)].map((_, index) => (
                                <button
                                    key={index}
                                    aria-current="page"
                                    className={`relative z-10 inline-flex items-center ${(index + 1 === pagination.current && pagination.last !== 1) ? "bg-orange-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600" : "bg-white text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"} px-4 py-2 text-sm font-semibold focus:z-20 `}
                                    onClick={() => callNextPicture(index + 1)}
                                >
                                    {index + 1}
                                </button>
                            ))}
                            <button className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                                <span className="sr-only">Next</span>
                                <CustomFontAwesomeIcon icon="chevron-right" className="h-3 w-3" aria-hidden="true" />
                            </button>
                        </nav>
                    </div>
                )}
            </div>
        </div>
    );
}
