import { Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

export default function TableResume({ currentUrl, monthly, codePromoIsValid, discount }: { currentUrl: string, monthly: boolean, codePromoIsValid: string, discount: number }) {
    const { t } = useTranslation();

    const calculPrice = () => {
        if (currentUrl === "pro") {
            if (codePromoIsValid === "valid" && discount > 0) {
                if (monthly) {
                    return (24 * (1 - (discount/100))).toString() === "0" ? t("free") : t("chfSpace") + (24 * (1 - (discount/100))).toString()
                } else {
                    return (216 * (1 - (discount/100))).toString() === "0" ? t("free") : t("chfSpace") + (216 * (1 - (discount/100))).toString()
                }
            } else
                return monthly ? t("chfSpace") + "24.00" : t("chfSpace") + "216.00";
        } else {
            if (codePromoIsValid === "valid" && discount > 0) {
                if (monthly) {
                    return (10.00 * (1 - (discount/100))).toString() === "0" ? t("free") : t("chfSpace") + (10.00 * (1 - (discount/100))).toString()
                } else {
                    return (90 * (1 - (discount/100))).toString() === "0" ? t("free") : t("chfSpace") + (90 * (1 - (discount/100))).toString()
                }
            } else
            return monthly ? t("chfSpace") + "10.00" : t("chfSpace") + "90.00";
        }
    }

    return (
        <table className="w-full table-auto text-left overflow-x-auto">
            <tr>
                <th className="bg-gray-200 p-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold leading-none text-black"
                    >
                        {t("licence")}
                    </Typography>
                </th>
                <th className="bg-gray-200 p-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold leading-none text-black"
                    >
                        {t("periodicity")}
                    </Typography>
                </th>
                <th className="bg-gray-200 p-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold leading-none text-center text-black"
                    >
                        {monthly ? t("pricettcMonth") : t("pricettcYear")}
                    </Typography>
                </th>
            </tr>
            <tr>
                <td className="p-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                    >
                        {currentUrl === "pro" ? t("proSubscription") : t("premiumSubscription")}
                    </Typography>
                </td>
                <td className="p-4">
                    
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                    >
                        {t(monthly ? "monthly" : "yearly")}
                    </Typography>
                </td>
                <td className="p-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold text-center"
                    >
                        {calculPrice()}
                    </Typography>
                </td>
            </tr>
        </table>
    )
}