import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, Input, Radio, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateAvailable, updateOriginalCount } from "../../../services/redux/actions/contractActions";
import CustomFontAwesomeIcon from "../../../components/CustomFontAwesomeIcon";

enum Availability {
    UNLIMITED = "UNLIMITED",
    LIMITED = "LIMITED",
    SINGLE = "SINGLE",
}

export default function DispoSousLicence({
    openGeneralites,
    setOpenDescription,
}: Readonly<{
    openGeneralites: boolean;
    setOpenDescription: (open: boolean) => void;
}>) {
    const { t } = useTranslation();
    const contract = useSelector((state: any) => state.contract);
    const dispatch = useDispatch();

    const handleChangeAvailability = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updateAvailable(
            event.target.value as Availability
        ));
    };

    const handleChangeNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updateOriginalCount(
            parseInt(event.target.value)
        ));
    }

    const labelParticular = (
        <div id="zone2" className="flex flex-col md:flex-row md:items-center gap-2 w-full">
            <p className="font-sans">{t("limited_original_p1")}</p>
            <div className="flex flex-row items-center gap-2">
                <Input
                    type="number"
                    color="orange"
                    label={t("nombre_exemplaire")}
                    onChange={handleChangeNumber}
                    value={contract.originalCount}
                    min={2}
                    className="bg-white"
                    error={contract.available === "LIMITED" && contract.originalCount < 2}
                    success={contract.available === "LIMITED" && contract.originalCount >= 2}
                    disabled={contract.available === "UNLIMITED" || contract.available === "SINGLE"}
                />
            </div>
            <p className="font-sans text-center md:text-left">{t("limited_original_p2")}</p>
        </div>
    );

    return (
        <div className="">
            <button
                type="button"
                onClick={() => setOpenDescription(!openGeneralites)}
                className="flex flex-row justify-between items-center w-full mt-4 pb-3 border-b border-gray-300"
            >
                <div className="flex flex-col text-left w-full pr-2">
                    <p className="text-lg font-sans">2. {t("content_availability")}</p>
                </div>
                <div className="flex w-fit">
                    <CustomFontAwesomeIcon icon={openGeneralites ? "chevron-up" : "chevron-down"} className="text-gray-500" />
                </div>
            </button>
            <Collapse open={openGeneralites}>
                <div className="flex flex-col gap-4 pt-2 px-4" id="section1">
                    <Typography variant="small" className="text-justify">
                        {t("content_availability_d")}
                    </Typography>

                    <Radio
                        className="min-w-[20px] forceSizeRadioButtons bg-white"
                        color="orange"
                        name="limitLicence"
                        value="UNLIMITED"
                        onChange={handleChangeAvailability}
                        label={<Typography variant="paragraph" className="font-semibold text-justify w-full md:w-full break-words">{t("unlimited")}</Typography>}
                        checked={contract.available === "UNLIMITED"}
                    />
                    <Typography variant="small" className={`text-justify -mt-4 px-4 ${contract.available === "UNLIMITED" ? "" : "text-gray-500"}`}>
                        {t("unlimited_original_d")}
                    </Typography>

                    <Radio
                        className="min-w-[20px] forceSizeRadioButtons bg-white"
                        color="orange"
                        name="limitLicence"
                        value="LIMITED"
                        onChange={handleChangeAvailability}
                        label={labelParticular}
                        checked={contract.available === "LIMITED"}
                    />
                    <Typography
                        variant="small"
                        className={`text-justify -mt-4 px-4 ${contract.available === "LIMITED" ? "" : "text-gray-500"}`}
                    >
                        {t("limited_explain")}
                    </Typography>

                    <Radio
                        className="min-w-[20px] forceSizeRadioButtons bg-white"
                        color="orange"
                        name="limitLicence"
                        value="SINGLE"
                        onChange={handleChangeAvailability}
                        label={<Typography variant="paragraph" className="font-semibold text-justify w-full md:w-full break-words">{t("unique")}</Typography>}
                        checked={contract.available === "SINGLE"}
                    />
                    <Typography variant="small" className={`text-justify -mt-4 px-4 ${contract.available === "SINGLE" ? "" : "text-gray-500"}`}>
                        {t("unique_d")}
                    </Typography>
                </div>
            </Collapse>
        </div>
    );
}
