import React, { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import PictureModal from '../components/Box/Photo/PictureModal';
import { PictureSearch } from '../components/Interfaces/PictureType';

interface OpenPictureData {
    id: string;
    context: PictureSearch[];
    loadMore: LoadMoreFunction;
}

interface PictureModalContextType {
    open: OpenPictureData | undefined;
    setOpenSkeleton: React.Dispatch<React.SetStateAction<boolean>>;
    setOpen: React.Dispatch<React.SetStateAction<OpenPictureData | undefined>>;
}

const PictureModalContext = createContext<PictureModalContextType | undefined>(undefined);

type LoadMoreFunction = () => void;

export const PictureModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [open, setOpen] = useState<OpenPictureData | undefined>(undefined);
    const [openSkeleton, setOpenSkeleton] = useState<boolean>(false);

    const values = useMemo(() => ({ open, setOpen, setOpenSkeleton }), [open, setOpen, setOpenSkeleton]);

    const closeModal = () => {
        setOpen(undefined);
        setOpenSkeleton(false);
    }

    return (
        <PictureModalContext.Provider value={values}>
            <PictureModal
                open={open !== undefined || openSkeleton}
                setOpen={closeModal}
                ids={open?.id ?? ""}
                pictures={open?.context ?? []}
            />
            {children}
        </PictureModalContext.Provider>
    );
};

// Custom hook to consume the context
export const usePictureModal = () => {
    const context = useContext(PictureModalContext);
    if (!context) {
        throw new Error('usePictureModal must be used within a PictureModalProvider');
    }

    return context;
};