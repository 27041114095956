import { IconButton, Spinner } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import BackgroundDefault from "../../assets/images/elements/default-banner.png";
import MiniLogo from "../../assets/images/logos/SVG/logo_e.svg";
import CustomFontAwesomeIcon from "../../components/CustomFontAwesomeIcon";
import MinimalFooter from "../../components/Footer/MinimalFooter";
import { PictureType } from "../../components/Interfaces/PictureType";
import Loader from "../../components/Loader";
import NewsletterAPI from "../../services/API/Clients/NewsletterAPI";
import PicturesAPI from "../../services/API/Clients/PicturesAPI";
import { handleError } from "../../services/Errors/handleErrors";
import { PictureBackgroundType } from "../../components/Interfaces/PictureBackgroundType";

export default function UnSubscribe() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("inscription_confirmation")}`;
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [isUnsubscribed, setIsUnsubscribed] = useState(false);
    const [error, setError] = useState(false);
    const [backgroundPicture, setBackgroundPicture] = useState<PictureBackgroundType | null>(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    
    useEffect(() => {
        const fetchData = async () => {
            const response = await PicturesAPI.backgroundPicture();
            if (response.status === 200) {
                setBackgroundPicture(response.body);
                setLoading(false);
            } else {
                handleError(response);
                setLoading(false);
            }
        }
        fetchData();
    }, [])

    const unsubscribe = async () => {
        if (id) {
            const response = await NewsletterAPI.unSubscribe(id);

            if (response.status === 200 || response.status === 201 || response.status === 204) {
                setIsUnsubscribed(true);
                setLoading(false);
            } else {
                handleError(response);
                setLoading(false);
                setIsUnsubscribed(false);
            }
        }
    };

    useEffect(() => {
        unsubscribe();
    }, []);
    

    return (
        <div className="min-h-full">
            <Loader loading={loading} />
            <div
                className="hidden md:block h-full"
                style={{
                    backgroundImage: `url('${backgroundPicture?.picture?.publicPath ?? BackgroundDefault}')`,
                    backgroundAttachment: "fixed",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "100vmax",
                    zIndex: -1,
                    position: "fixed",
                    top: 0,
                    left: "50%",
                    transform: "translate(-50%, 0)",
                    pointerEvents: "none",
                    backgroundPositionX: "center",
                }}
            ></div>
            <div className="relative w-screen screen md:min-h-auto mt-0 mb-0 md:h-auto md:w-2/3 bg-white rounded-lg md:mt-8 md:mb-16 px-8 py-12 mx-auto shadow-xl flex justify-center flex-col max-w-3xl">
                <Link to="/" className="absolute top-4 right-4 flex md:hidden">
                    <IconButton color="orange" className="w-10 h-10 rounded-0 md:rounded-lg flex items-center justify-center">
                        <CustomFontAwesomeIcon icon="x" className="text-white h-4 w-4 mx-auto my-3" aria-hidden="true" />
                    </IconButton>
                </Link>
                <MiniLogo className="w-14 h-14 my-2.5 mx-auto" />
                {loading ? (
                    <Spinner color="orange" className="h-10 w-10 mx-auto mt-8" />
                ) : error ? (
                    <h1 className="text-3xl mb-2 text-center mt-8">{t("unsubscribeerror")}</h1>
                ) : isUnsubscribed ? (
                    <h1 className="text-3xl mb-2 text-center mt-8">{t("unsubscribe")}</h1>
                ) : (
                    <h1 className="text-3xl mb-2 text-center mt-8">{t("unsubscribAlready")}</h1>
                )}
            </div>
            <div className="fixed bottom-0 left-0 right-0">
                <MinimalFooter />
            </div>
        </div>
    );
}
