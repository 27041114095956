import InputError from "../../../components/Form/Input/InputError";
import GradientButton from "../../../components/Form/Button/GradientButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import UserAPI from "../../../services/API/Clients/UserAPI";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { Storage } from "../../../services/storage";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { handleError } from "../../../services/Errors/handleErrors";
import CustomFontAwesomeIcon from "../../../components/CustomFontAwesomeIcon";

export default function Security() {
    const { t } = useTranslation();
    const myId = Storage.getId();
    const general = useSelector((state: any) => state.general);
    const [pass, setPass] = React.useState({
        current: "",
        new: "",
        repeat: "",
    });
    const [loginLog, setLoginLog] = useState<{
        pagination: {
            current: number;
            last: number;
            totalItems: number;
            parPage: number;
        };
        data: {
            requestDetails: {
                operatingSystem: string;
                deviceType: string;
                browser: string;
                country?: {
                    code: string;
                    currency: string;
                    enLabel: string;
                    frLabel: string;
                    alpha2: string;
                };
            };
            createdAt: string;
        }[]
    }>({
        pagination: {
            current: 0,
            last: 0,
            totalItems: 0,
            parPage: 0,
        },
        data: [],
    });

    useEffect(() => {
        const fetchData = async () => {
            if (!general && !general.me && !general.me.id) {
                if (!myId) return;
                const response = await UserAPI.getLogLogin(myId);
                if (response.status === 200) {
                    setLoginLog(response.body);
                } else handleError(response);
            } else {
                const response = await UserAPI.getLogLogin(general.me.id);
                if (response.status === 200) {
                    setLoginLog(response.body);
                } else handleError(response);
            }
        };
        fetchData();
    }, []);

    const onChange = (arg: any) => {
        // changeUnsaved(true);
        setPass({
            ...pass,
            [arg.target.name]: arg.target.value,
        });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (!myId) return;
        if (pass.new !== pass.repeat) return toast.warning(t("passwordsdontmatch"));
        const datas = {
            oldPassword: pass.current,
            plainPassword: pass.new,
        };
        const response = await UserAPI.changePassword(datas, myId);
        if (response.status === 200 || response.status === 201) {
            toast.success(t("passwordchanged"));
            setPass({
                current: "",
                new: "",
                repeat: "",
            });
        } else handleError(response);
    };
    return (
        <div className="flex flex-col bg-gray-50 w-full md:px-4 py-4 rounded-lg">
            <p className="mb-2 text-xl font-sans font-extrabold">{t("securityandconnection")}</p>
            <p className="mt-2 mb-2 text-base font-sans font-bold">{t("editpassword")}</p>
            <form onSubmit={handleSubmit} className="flex flex-row flex-wrap">
                <InputError value={pass.current} name="current" label={t("currentpassword")} onChange={onChange} type="password" className="w-full md:w-1/2 ms:pr-2 pt-4" />
                <span className="hidden md:flex w-1/2"></span>
                <InputError value={pass.new} name="new" label={t("newpassword")} onChange={onChange} type="password" className="w-full md:w-1/2 ms:pr-2 pt-4 mb-4" />
                <InputError value={pass.repeat} name="repeat" label={t("repeatnewpassword")} onChange={onChange} type="password" className="w-full md:w-1/2 md:pl-2 md:pt-4 mb-4" />
                <button
                    className=" border-2 bg-orange-500 hover:bg-orange-200 border-transparent inline w-fit text-white hover:text-black py-1 pl-3 pr-4 rounded-full text-sm"
                    style={{
                        transition: "all .25s ease-in-out",
                    }}
                    type="submit"
                >
                    <span className="flex items-center">{t("save")}</span>
                </button>
            </form>

            <hr className="border-gray-300 mt-4" />

            <p className="mb-2 text-base font-sans mt-4 font-bold">{t("twofactor")}</p>
            <p className="mb-4 text-sm font-sans">{t("twofactor_desc")}</p>
            {general.me?.totpAuthenticationEnabled ?
                <p className="mb-2 text-xs font-sans text-green-700">
                    <CustomFontAwesomeIcon icon="check" className="mr-1" />
                    {t("activated_f")}
                </p>
            :
                <>
                    <p className="mb-2 text-xs font-sans text-red-700">
                        <CustomFontAwesomeIcon icon="x" className="mr-1" />
                        {t("desactivated_f")}
                    </p>
                    <a target="_blank" href="/2fa">
                        <button
                            className=" border-2 bg-gray-500 hover:bg-gray-200 border-transparent inline w-fit text-white hover:text-black py-1 pl-3 pr-4 rounded-full text-sm mb-2"
                            style={{
                                transition: "all .25s ease-in-out",
                            }}
                            type="button"
                        >
                            <span className="flex items-center">{t("setup")}</span>
                        </button>
                    </a>
                </>
            }

            {loginLog.data.length !== 0 && (            
                <>
                    <hr className="border-gray-300 mt-2" />
                    <p className="mb-4 text-base font-sans mt-4 font-bold">{t("lastconnections")}</p>
                    <div className="w-full overflow-x-auto">
                        <table className="w-full min-w-max table-auto text-left">
                            <thead>
                                <tr>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 rounded-tl">
                                        <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold">
                                            {t("date")}
                                        </Typography>
                                    </th>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                        <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold">
                                            {t("time")}
                                        </Typography>
                                    </th>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                        <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold">
                                            {t("browser")}
                                        </Typography>
                                    </th>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                        <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold">
                                            {t("os")}
                                        </Typography>
                                    </th>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 rounded-tr">
                                        <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold">
                                            {t("country")}
                                        </Typography>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {loginLog.data.map((log, index) => (
                                    <tr className={`${index % 2 ? "bg-blue-gray-50/75" : "bg-white"}  hover:bg-gray-200 transition-all`} key={log.createdAt}>
                                        <td className="p-4">
                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                {moment(log.createdAt).format("DD.MM.YYYY")}
                                            </Typography>
                                        </td>
                                        <td className="p-4">
                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                {moment(log.createdAt).format("HH:mm")}
                                            </Typography>
                                        </td>
                                        <td className="p-4">
                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                {log.requestDetails.browser}
                                            </Typography>
                                        </td>
                                        <td className="p-4">
                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                {log.requestDetails.operatingSystem}
                                            </Typography>
                                        </td>
                                        <td className="p-4">
                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                {log.requestDetails?.country?.frLabel ?? "/"}
                                            </Typography>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </div>
    );
}
