import SimplicityTop from "../../../../assets/images/home/waves/simplicity_top.svg";
import SimplicityBottom from "../../../../assets/images/home/waves/simplicity_bottom.svg";
import Imac from "../../../../assets/images/home/ezoom_imac.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import CustomFontAwesomeIcon from "../../../../components/CustomFontAwesomeIcon";

export default function Advantages() {
    const { t } = useTranslation();
    return (
        <>
            <div className="m-0 relative bg-white bg-cover bg-no-repeat">
                <SimplicityTop />
                <div style={{ backgroundImage: "linear-gradient(90deg, #ffd3a6 0%, #fff9f3 85%)" }} className="pb-12 pt-20  px-0 -my-1.5 mx-0 flex flex-col justify-start md:justify-center items-start md:items-center">
                    <p className="mt-0 text-left px-4 md:px-0 md:text-center text-3xl font-sans max-w-7xl">{t("home_section4_title")}</p>
                    <p className="text-left px-4 md:px-0 md:text-center my-4 mx-auto font-sans pt-8 w-full">{t("home_section4_subtitle")}</p>
                </div>
                <SimplicityBottom />
            </div>
            <div className="bg-white m-0 relative px-4 md:px-16 pb-8 md:pb-24">
                <div className="grid grid-cols-1 md:grid-cols-3 grid-rows-1 gap-y-8 gap-x-16 w-full py-4 text-left md:text-center mt-0 mx-auto mb-12 max-w-7xl">
                    <div>
                        <CustomFontAwesomeIcon icon="database" className="text-7xl mt-8 text-orange-500" />
                        <h4 className="text-3xl font-semibold font-sans my-12">{t("advantage_1_title")}</h4>
                        <p className="text-left">
                            {t("advantage_1_description")}
                            <b className="block mt-1.5">{t("pro_members")}</b> 
                        </p>
                    </div>
                    <div>
                        <CustomFontAwesomeIcon icon="shield-halved" className="text-7xl mt-8 text-orange-500" />
                        <h4 className="text-3xl font-semibold font-sans my-12">{t("advantage_2_title")}</h4>
                        <p className="text-left">
                            {t("advantage_2_description")}
                            <b className="block mt-1.5">{t("pro_members")}</b>
                        </p>
                    </div>
                    <div>
                        <CustomFontAwesomeIcon icon="paper-plane" className="text-7xl mt-8 text-orange-500" />
                        <h4 className="text-3xl font-semibold font-sans my-12">{t("advantage_3_title")}</h4>
                        <p className="text-left">
                            {t("advantage_3_description")}
                        </p>
                    </div>
                </div>
            </div>
            <img alt="Imac" src={Imac} className="hidden md:flex w-1/4 z-20 absolute -mt-32" style={{ marginLeft: "15%" }} />
        </>
    );
}
