import { Chip, IconButton, Typography } from "@material-tailwind/react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateName, updatePicture } from "../../../../services/redux/actions/formAction";

import AutosizeInput from "react-input-autosize";
import { Link } from "react-router-dom";
import Select from "react-select";
import DefaultBannerEzoom from "../../../../assets/images/elements/default-banner.png";
import DefaultPictureEzoom from "../../../../assets/images/elements/default-profile.png";
import SharingBox from "../../../../components/Box/SharingBox";
import { NavigationCustomStyles } from "../../../../components/CustomStyles/NavigationCustomStyles";
import ModalOrganize from "../../../../components/Modals/Organize/ModalOrganize";
import { PictureDrawerForFilters } from "../../../../components/SearchResult/impl/PictureResult";
import MobileFilterButton from "../../../../components/SearchResult/mobile/MobileFilterButton";
import { formatLongNumber } from "../../../../services/redux/actions/formatNumber";
import { RootState } from "../../../../services/redux/store";
import ModalChangeBackgroundPortfolio from "./ModalChangeBackgroundPortfolio";
import CustomFontAwesomeIcon from "../../../../components/CustomFontAwesomeIcon";

export default function MobileHeader({
    searchBar,
    isVisible,
    bgPosition,
    setBgPosition,
    actualUrl,
    handleLinkClick,
    handleSubmit,
    handleSubmitWithoutPublish
}: Readonly<{
    searchBar: boolean,
    isVisible: boolean,
    bgPosition: number;
    setBgPosition: Function;
    actualUrl: string;
    handleLinkClick: Function;
    handleSubmit: Function,
    handleSubmitWithoutPublish: Function
}>) {
    const { t } = useTranslation();
    const [tmpPicture, setTmpPicture] = React.useState<string>("");
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const [openModal, setOpenModal] = React.useState(false);
    const formData = useSelector((state: RootState) => state.form);
    const general = useSelector((state: RootState) => state.general);
    const dispatch = useDispatch();
    const [hide, setHide] = React.useState(false);
    const [openModalOrganize, setOpenModalOrganize] = React.useState(false);
    const parentRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [inputWidth, setInputWidth] = useState('100px');
    
    const handleOpenModal = () => {
        setOpenModalOrganize(true);
    };

    const handleCloseModal = () => {
        setOpenModalOrganize(false);
    }

    const handleButtonClick = () => {
        if (fileInputRef.current)
            fileInputRef.current.click();
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (!selectedFile) return;
        dispatch(updatePicture(selectedFile))
        setTmpPicture(URL.createObjectURL(selectedFile));
    };

    const MenuList = [
        {
            value: "photos",
            url: "/portfolio/edit/photos",
            label: `${t("photos")} (${formatLongNumber(formData.pictures.pagination.totalItems ?? 0)})`,
        },
        {
            value: "about",
            url: "/portfolio/edit/about",
            label: `${t("about")}`,
        },
        {
            value: "performances",
            url: "/portfolio/edit/performances",
            label: `${t("performances")} (${formatLongNumber(formData.services.pagination.totalItems ?? 0)})`,
        },
    ];

    const MenuListLight = [
        {
            value: "photos",
            url: "/portfolio/edit/photos",
            label: `${t("photos")} (${formatLongNumber(formData.pictures.pagination.totalItems ?? 0)})`,
        },
        {
            value: "about",
            url: "/portfolio/edit/about",
            label: `${t("about")}`,
        }
    ];

    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updateName(e.target.value))
    }

    const redirectionAfterCancel = () => {
        const currentUrl = window.location.pathname.split("/")[3];
        if (currentUrl === "photos") return `${formData.path !== "" ? `/${formData.path}/photos` : `/portfolio/${formData.id}/photos`}`;
        else if (currentUrl === "about") return `${formData.path !== "" ? `/${formData.path}/about` : `/portfolio/${formData.id}/about`}`;
        else if (currentUrl === "performances") return `${formData.path !== "" ? `/${formData.path}/performances` : `/portfolio/${formData.id}/performances`}`;
        else if (currentUrl === "addPerf") return `${formData.path !== "" ? `/${formData.path}/performances` : `/portfolio/${formData.id}/performances`}`;
        else if (currentUrl === "performance") return `${formData.path !== "" ? `/${formData.path}/performances` : `/portfolio/${formData.id}/performances`}`;
        else return `${formData.path !== "" ? `/${formData.path}/photos` : `/portfolio/${formData.id}/photos`}`;
    };

    useEffect(() => {
        if (parentRef.current) {
            const parentHeight = parentRef.current.offsetHeight;
            setInputWidth(`calc(${parentHeight}px - 60px)`);
        }
    }, [window.innerWidth, window.outerWidth]);


    const topPaddingTop = (): string => {
        if (isVisible) {
        if (searchBar)
            return "top-[108px] pt-[7px]";
        else
            return "top-[60px] pt-[7px]";
        } else {
        if (searchBar)
            return "top-[48px] pt-[7px]";
        else
            return "top-[0px] pt-[7px]";
        }
    }

    const topPaddingTopBis = (): string => {
        if (isVisible) {
            if (searchBar)
                return "top-[164px] pt-[7px]";
            else
                return "top-[116px] pt-[7px]";
        } else {
            if (searchBar)
                return "top-[104px] pt-[7px]";
            else
                return "top-[56px] pt-[7px]";
        }
    }

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
    };

    return (
        <>
            <ModalOrganize open={openModalOrganize} onClose={handleCloseModal} />
            <ModalChangeBackgroundPortfolio open={openModal} setOpen={() => setOpenModal(!openModal)} />
            <PictureDrawerForFilters />
            <div
                className="flex md:hidden relative bg-cover aspect-[18/6] mt-[23px] lg:mt-0"
                style={{
                    backgroundPositionY: `${100 - bgPosition}%`,
                    backgroundImage: `url(${
                    (
                        formData.backgroundForModif && formData.backgroundForModif.minPath !== "" && formData.backgroundForModif.minPath
                            ? formData.backgroundForModif.minPath
                            : formData.banner ?? DefaultBannerEzoom
                    )}`,
                }}
            >
                <div
                    ref={parentRef}
                    style={{ height: "calc(100% - 60px)", width: "40px" }}
                    className={`absolute bottom-0 top-2 right-2 flex flex-col items-center justify-center ${((formData.backgroundForModif && formData.backgroundForModif.minPath !== "" && formData.backgroundForModif.minPath) || formData.banner) ? "opacity-100" : "opacity-0"}`}
                >
                    <input
                        type="range"
                        min={0}
                        max={100}
                        value={bgPosition}
                        onChange={(e) => setBgPosition(Number(e.target.value))}
                        ref={inputRef}
                        style={{ width: inputWidth }}
                        className="-rotate-90 transparent h-[4px] cursor-pointer appearance-none border-transparent bg-neutral-200 dark:bg-neutral-600 rounded-full"
                        id="customRange1"
                    />
                </div>
                <div style={{ height: "40px" }} className="absolute bottom-2 right-2 flex flex-col items-center justify-center">
                    <IconButton color="white" className="ring-1 ring-gray-400/50 rounded-full hover:bg-gray-50 cursor-pointer" onClick={() => setOpenModal(true)}>
                        <CustomFontAwesomeIcon icon="pen" />
                    </IconButton>
                </div>
            </div>

            {/* <div className="md:hidden sticky md:static h-14 md:h-0 bg-white z-30 px-1 py-2 md:py-0 md:overflow-hidden flex flex-row justify-between items-end"> */}
            {window.innerWidth < 768 && (
                <div
                    className={`${topPaddingTop()} flex md:hidden sticky h-14 md:h-0 bg-white z-30 px-1 pb-2 md:pb-0 md:overflow-hidden flex-row justify-between items-end duration-300`}
                >
                    <div className="md:hidden relative h-12 pt-1">
                        <div className="flex md:hidden flex-row gap-4 items-end">
                            <div className="md:hidden w-12 h-12  bg-white rounded-full mt-1">
                                <img
                                    onContextMenu={handleContextMenu}
                                    alt="vector"
                                    className="md:hidden w-full h-full object-cover object-center rounded-full  border-4 border-purple-500/50"
                                    src={tmpPicture !== "" ? tmpPicture : formData.picture ?? DefaultPictureEzoom}
                                />
                            </div>
                            <div className="md:hidden absolute left-1 -bottom-1 flex flex-col justify-center">
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                                <CustomFontAwesomeIcon icon="pen" className="md:hidden text-black bg-white text-[8px] p-2 rounded-full hover:bg-gray-50 ring ring-gray-500/20 cursor-pointer" onClick={handleButtonClick} />
                            </div>
                            <div className="md:hidden flex flex-col justify-center">
                                <Typography className="md:hidden text-xl font-bold">
                                    <AutosizeInput
                                        type="text"
                                        value={formData.name}
                                        placeholder="Titre"
                                        onChange={handleChangeName}
                                        className="md:hidden border-b border-b-gray-500 w-24 overflow-hidden"
                                    />
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div className="md:hidden flex relative flex-row justify-end gap-2 px-2">
                        {!formData.published && (
                            <button onClick={() => handleSubmit()}>
                                <IconButton className="md:hidden rounded-full bg-orange-500 ring-1 ring-orange-400/50 hover:bg-orange-50 transition-all duration-400" color="orange" size="sm">
                                    <CustomFontAwesomeIcon icon="check" />
                                </IconButton>
                            </button>
                        )}
                        <button onClick={() => handleSubmitWithoutPublish()}>
                            <IconButton className="md:hidden rounded-full bg-orange-500 ring-1 ring-orange-400/50 hover:bg-orange-50 transition-all duration-400" color="orange" size="sm">
                                <CustomFontAwesomeIcon icon="save" />
                            </IconButton>
                        </button>
                        <Link to={`/upload`}>
                            <IconButton color="white" size="sm" className="md:hidden ring-1 ring-gray-400/50 rounded-full hover:bg-gray-50 transition-all duration-400">
                                <CustomFontAwesomeIcon icon="plus" />
                            </IconButton>
                        </Link>
                        <Link to={redirectionAfterCancel()}>
                            <IconButton color="white" size="sm" className="md:hidden ring-1 ring-gray-400/50 rounded-full hover:bg-gray-50 transition-all duration-400">
                                <CustomFontAwesomeIcon icon="x" />
                            </IconButton>
                        </Link>
                        {formData.public && (
                            <SharingBox isPicture={false} />
                        )}
                    </div>
                </div>
            )}

            <div className="flex md:hidden flex-row gap-2 mt-12 items-center w-full justify-center flex-wrap">
                {general?.me?.plan === "PRO" &&
                    (
                        <Chip
                            className="w-fit h-fit bg-black text-white"
                            color="purple"
                            variant="ghost"
                            value={t("pro")}
                            icon={<CustomFontAwesomeIcon icon="crown" className="h-3 ml-0.5" />}
                            size="sm"
                        />
                )}
                {general?.me?.plan === "PREMIUM" && (
                    <Chip
                        className="w-fit h-fit bg-white text-black border border-black"
                        color="orange"
                        variant="ghost"
                        value={t("premium")}
                        icon={<CustomFontAwesomeIcon icon="crown" className="h-3 ml-0.5" />}
                        size="sm"
                    />
                )}
                <Chip 
                    className="w-fit h-fit" 
                    color="purple" 
                    variant="ghost"  
                    value={`${formData.subscriberCount ?? 0} ${t("subs")}`}
                    icon={<CustomFontAwesomeIcon icon="users" className="h-3 ml-0.5" />} 
                    size="sm"
                />
                {(formData.avgNote !== -1 && formData.features.actions.testimonial) && (
                    <Chip
                        className="w-fit h-fit"
                        color="purple"
                        variant="ghost"
                        value={`${formData.avgNote === -1 ? t("nonote") : formData.avgNote.toFixed(2)}`}
                        icon={<CustomFontAwesomeIcon icon="star" className="h-3 ml-0.5" />}
                        size="sm"
                    />
                )}
            </div>
           {window.innerWidth < 768 && (
                <div
                    className={`${topPaddingTopBis()} flex md:hidden border-b border-gray-200 w-full mx-auto bg-white mt-4 sticky z-30 duration-300`}
                >
                    <div className={`${actualUrl === "photos"  ? "w-1/3" : "w-full"} px-2 pb-2`}>
                        {general.me && general.me.plan === "PRO" ? (
                            <Select
                                placeholder="Menu"
                                name="Menu"
                                className="w-full relative top-0 bg-white text-xs"
                                options={MenuList}
                                styles={NavigationCustomStyles}
                                menuPortalTarget={document.body}
                                menuPosition="absolute"
                                value={MenuList.find((obj) => actualUrl === "performance" ? obj.value === "performances" : obj.value === actualUrl)}
                                onChange={(e) => e !== null && handleLinkClick(e.url)}
                                isSearchable={false}
                            />
                        ) : (
                            <Select
                                placeholder="Menu"
                                name="Menu"
                                className="w-full relative top-0 bg-white text-xs"
                                options={MenuListLight}
                                styles={NavigationCustomStyles}
                                menuPortalTarget={document.body}
                                menuPosition="absolute"
                                value={MenuList.find((obj) => obj.value === actualUrl)}
                                onChange={(e) => e !== null && handleLinkClick(e.url)}
                                isSearchable={false}
                            />
                        )}
                    </div>
                    <div className={`${actualUrl === "photos" || actualUrl === "performances" ? "flex" : "hidden"} w-2/3 px-2  gap-2 justify-end`}>
                        {actualUrl === "photos" ? (
                            <>
                                <div className="flex pr-1.5 border-r border-r-gray-200">
                                    <button onClick={handleOpenModal} className="border inline w-fit hover:text-black py-2 mt-0.5 px-3 rounded-full text-xs h-[37.2px]">
                                        <span className="flex">{t("organize")}</span>
                                    </button>
                                </div>

                                <MobileFilterButton />
                            </>
                        ) : (
                            <Link to="/portfolio/edit/addPerf" className="flex ">
                                <button className="border inline w-fit hover:text-black py-2 mt-0.5 px-3 rounded-full text-xs h-[37.2px]">
                                    <span className="flex">
                                        <CustomFontAwesomeIcon icon="plus" />
                                    </span>
                                </button>
                            </Link>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
