import AsyncSelect from 'react-select/async';
import PlaceAPI from '../../../services/API/Clients/PlaceAPI';
import { useTranslation } from 'react-i18next';
import { PlaceCustomStyle } from '../../CustomStyles/PlaceCustomStyle';
import { handleError } from '../../../services/Errors/handleErrors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomFontAwesomeIcon from '../../CustomFontAwesomeIcon';
// import { v4 as uuidv4 } from 'uuid';

export default function InputPlaceWithValue({ uuid, onSelect, disabled = false, title = "tplace", value } : Readonly<{ uuid: string, onSelect: (value: string, label: string) => void, disabled?: boolean, title?: string, value: {id: string, label: string} }>) {
    const { t } = useTranslation();

    const loadOptions = async (inputValue: string) => {
        if (uuid === "") return;
        if (inputValue.length === 0) return;
        const response = await PlaceAPI.place(uuid, inputValue)
        if (response.status === 200) {
            return response.body.map((place: any) => {
                return {
                    value: place.id,
                    label: place.label
                }
            })
        } else {
            handleError(response);
        }
    }

    const handleChange = async (value: any) => {
        const data = {
            refId: value.value
        }

        const resp = await PlaceAPI.newPlace(uuid, data)
        if (resp.status === 200 || resp.status === 201)
            onSelect(resp.body.id, value.label ?? "")
        else
            handleError(resp);
    }

    return (
        <div className='relative w-full'>
            <AsyncSelect 
                cacheOptions 
                loadOptions={loadOptions} 
                defaultOptions 
                menuPortalTarget={document.body}
                menuPosition="absolute"
                styles={PlaceCustomStyle}
                className="w-full relative top-0 bg-white border-orange-500"
                placeholder={t(title)}
                onChange={handleChange}
                isDisabled={disabled}
                value={value.id && { value: value.id, label: value.label }}
                name="lieu"
                noOptionsMessage={() => t("noplaces")}
            />
            {value.id && value.id !== "" && (
                <CustomFontAwesomeIcon icon="xmark" className='absolute right-12 top-3 text-gray-300 cursor-pointer' onClick={() => onSelect("", "")} />
            )}
        </div>
    )
}