
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Storage } from "../../services/storage";
import { HashLink } from "react-router-hash-link";
import CustomFontAwesomeIcon from "../CustomFontAwesomeIcon";

export default function Pro({ monthly, isMiddle = true }: Readonly<{ monthly: boolean; isMiddle?: boolean }>) {
    const { t } = useTranslation();
    const currentPage = window.location.pathname;

    const isLogin = Storage.getToken();

    return (
        <div className={`w-full ${isMiddle ? "md:w-[48%]" : "md:w-full mx-auto"} max-w-[400px] bg-gray-100 rounded-lg py-4 relative`}>
            <div className="absolute -top-5 left-1/2 -translate-x-1/2 w-fit bg-black text-white text-center rounded-lg px-4 py-2">
                <span className="text-base flex gap-2 items-center">
                    <CustomFontAwesomeIcon icon="crown" />
                    {t("PRO")}
                </span>
            </div>
            <div className="text-center flex flex-col mt-3">
                {!monthly ? (
                    <>
                        <span className="text-base font-bold text-purple-500">
                            {t("chf")} 18.00 {t("pricesttcMonthly")}
                        </span>
                        <span className="text-xs font-bold text-gray-800">
                            {t("orYearly")} {t("chf")} 216.00 {t("pricesttcYearly")}
                        </span>
                        <div className="px-2 py-1 bg-purple-500 w-fit mx-auto text-xs text-white mt-4 rounded-lg">{t("threeMonthsOffers")}</div>
                    </>
                ) : (
                    <span className="text-base font-bold text-purple-500">
                        {t("chf")} 24.00 {t("pricesttcMonthly")}
                    </span>
                )}
            </div>
            <div className="text-center flex flex-col mt-2 px-4 border-t-4 border-b-4 border-t-white border-b-white py-4">
                {isMiddle ? (
                    <>
                        <span className="text-sm font-bold text-gray-900 text-left mb-4 gap-6 flex items-center">
                            <span className="w-6 flex justify-center">
                                <CustomFontAwesomeIcon icon="crown" className="text-purple-500 text-2xl" />
                            </span>
                            {t("allPremiumAdvantages")}
                        </span>
                        <hr className="mb-5 mt-1 xl:mb-7 xl:mt-3" />
                    </>
                ) : (
                    <>
                        <span className="text-sm font-bold text-gray-900 text-left mb-4 gap-6 flex items-center">
                            <span className="w-6 flex justify-center">
                                <CustomFontAwesomeIcon icon="infinity" className="text-purple-500 text-2xl" />
                            </span>
                            {t("unlimitedUploads")}
                        </span>
                        <span className="text-sm font-bold text-gray-900 text-left mb-4 gap-6 flex items-center">
                            <span className="w-6 flex justify-center">
                                <CustomFontAwesomeIcon icon="rocket" className="text-purple-500 text-2xl" />
                            </span>
                            {t("visibilityBoosted")}
                        </span>
                        <span className="text-sm font-bold text-gray-900 text-left mb-4 gap-6 flex items-center">
                            <span className="w-6 flex justify-center">
                                <CustomFontAwesomeIcon icon="globe" className="text-purple-500 text-2xl" />
                            </span>
                            {t("subdomainPersonnel")}
                        </span>
                        <span className="text-sm font-bold text-gray-900 text-left mb-4 gap-6 flex items-center">
                            <span className="w-6 flex justify-center">
                                <CustomFontAwesomeIcon icon="chart-column" className="text-purple-500 text-2xl" />
                            </span>
                            {t("statistic")}
                        </span>
                        <span className="text-sm font-bold text-gray-900 text-left mb-4 gap-6 flex items-center">
                            <span className="w-6 flex justify-center">
                                <CustomFontAwesomeIcon icon="headset" className="text-purple-500 text-2xl" />
                            </span>
                            {t("prioritaryAssistance")}
                        </span>
                    </>
                )}

                <span className="text-sm font-bold text-gray-900 text-left mb-4 gap-6 flex items-center">
                    <span className="w-6 flex justify-center">
                        <CustomFontAwesomeIcon icon="users" className="text-purple-500 text-2xl" />
                    </span>
                    {t("referenceEzoom")}
                </span>
                <span className="text-sm font-bold text-gray-900 text-left mb-4 gap-6 flex items-center">
                    <span className="w-6 flex justify-center">
                        <CustomFontAwesomeIcon icon="hand-holding-dollar" className="text-purple-500 text-2xl" />
                    </span>
                    {t("sellPrestations")}
                </span>
                <span className="text-sm font-bold text-gray-900 text-left mb-4 gap-6 flex items-center">
                    <span className="w-6 flex justify-center">
                        <CustomFontAwesomeIcon icon="lock" className="text-purple-500 text-2xl" />
                    </span>
                    {t("privatesGalleries")}
                </span>
                <HashLink to="/tarifs#detailTarifs" className="text-sm font-bold text-purple-500 hover:text-purple-100 transition-all mx-auto">
                    {t("detail")}
                </HashLink>
            </div>
            <div className="text-center flex flex-col mt-2 px-4 pt-3">
                {currentPage !== "/subscription/pro" && (
                    <Link
                        to={isLogin ? "/subscription/pro" : "/signup?redirect=pro"}
                        className="px-4 py-2 mb-2 bg-purple-500 text-white rounded-full hover:bg-purple-200 transition-all w-fit mx-auto"
                        dangerouslySetInnerHTML={{ __html: t("obtainPro") }}
                    ></Link>
                )}
                <span className="flex gap-2 text-xs mx-auto" dangerouslySetInnerHTML={{ __html: t("renewSubscription") }}></span>
            </div>
        </div>
    );
}
