import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import ModalWrapper from "./ModalWrapper";
import CustomFontAwesomeIcon from "../CustomFontAwesomeIcon";

export default function ModalSignInUp({ open, setOpen, closeModal }: Readonly<{ open: boolean; setOpen: any, closeModal?: Function }>) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const login = () => {
        navigate("/signin");
        closeModal && closeModal();
        setOpen(false);
    }

    const register = () => {
        navigate("/signup");
        closeModal && closeModal();
        setOpen(false);
    }


    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <Dialog.Panel className="transform overflow-hidden rounded-2xl bg-white p-6 pt-12 pb-6 text-left align-middle shadow-xl transition-all max-w-3xl">
                <Dialog.Title as="h3" className="text-2xl font-medium leading-6 text-orange-500 text-center">
                    {t("next_step")}
                </Dialog.Title>
                <CustomFontAwesomeIcon icon="x" className="absolute top-4 right-4 text-gray-500 cursor-pointer" onClick={() => setOpen(false)} />
                <div className="flex justify-center gap-4 mt-4">
                    <div className="flex flex-row items-center justify-between text-center gap-2 my-2">
                        <button
                            className={`w-1/2 border-2 border-transparent bg-gray-200/30 hover:bg-white py-1 px-4 rounded-full text-black hover:shadow-lg transition-all text-center whitespace-nowrap cursor-pointer backdrop-blur-sm flex justify-center`}
                            onClick={login}
                        >
                            <span className="flex">{t("signin")}</span>
                        </button>
                        <button
                            className="w-1/2 border-2 border-transparent bg-orange-500 hover:bg-orange-200 py-1 px-4 rounded-full text-white hover:text-black transition-all text-center whitespace-nowrap cursor-pointer flex justify-center"
                            onClick={register}
                        >
                            <span className="flex">{t("signup")}</span>
                        </button>
                    </div>
                </div>
            </Dialog.Panel>
        </ModalWrapper>
    );
}
