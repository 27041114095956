import { Accordion, AccordionBody, Button, Collapse, Input, Textarea } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import React, { useEffect } from "react";
import CustomFontAwesomeIcon from "../../../../components/CustomFontAwesomeIcon";
import InputPlace from "../../../../components/Form/Input/InputPlace";
import IconOpen from "./components/IconOpen";
import TitleDescribe from "./components/TitleDescribe";

export default function Description({
    openDescription,
    toggleOpenDescription,
    descriptions,
    setDescriptions,
    handleChange,
    handleChangeDescriptionLieu,
    isIndivOrNot,
    setConfigIndivOrNot,
    bulkIds,
    withOpeners = true,
}: Readonly<{
    openDescription: boolean;
    toggleOpenDescription: () => void;
    descriptions: { titre: string; description: string; lieu: { id: string; label: string }; date: string }[];
    setDescriptions: any;
    handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => void;
    handleChangeDescriptionLieu: (id: string, label: string, index: number) => void;
    isIndivOrNot: boolean;
    setConfigIndivOrNot: any;
    bulkIds: string[];
    withOpeners?: boolean;
}>) {
    const { t } = useTranslation();
    const [uuid, setUuid] = React.useState<string>("");
    const [open, setOpen] = React.useState<number>(0);

    useEffect(() => {
        setUuid(uuidv4());
    }, []);

    const onSelect = (value: string, label: string | undefined) => {
        if (label === undefined) return;
        handleChangeDescriptionLieu(value, label, 0);
    };

    const onSelectMulti = (value: string, label: string | undefined, index: number) => {
        if (label === undefined) return;
        handleChangeDescriptionLieu(value, label, index);
    };

    const areAllValuesSame = (field: string, descriptions: any) => {
        const uniqueValues = descriptions.map((desc: any) => desc[field]).filter((value: any, index: any, self: any) => self.indexOf(value) === index);
        return uniqueValues.length === 1 ? uniqueValues[0] : "valeurs multiples";
    };
    
    // Vérifie si tous les lieux sont identiques
    const areAllLieuSame = (descriptions: any) => {
        const uniqueLieux = descriptions.map((desc: any) => desc.lieu.id).filter((id: any, index: any, self: any) => self.indexOf(id) === index);
        return uniqueLieux.length === 1
            ? descriptions[0].lieu
            : { id: "multipleValues", label: t("multipleValues") };
    };
    

    return (
        <div className=" pb-4 border-b border-gray-300">
            <button onClick={toggleOpenDescription} className="flex flex-row items-center w-full mt-4 text-left">
                <TitleDescribe title="picturedesc" describe="picturedescex" />
                {withOpeners && <IconOpen open={openDescription} />}
            </button>
            <Collapse open={openDescription} className="flex flex-col gap-4">
                {isIndivOrNot ? (
                    <div className="flex flex-col gap-4 pt-2">
                        <Input
                            value={descriptions[0].titre}
                            name="titre"
                            onChange={(e) => handleChange(e, 0)}
                            className="bg-white"
                            color="orange"
                            label={t("titlep")}
                        />
                        <Textarea
                            value={descriptions[0].description}
                            name="description"
                            onChange={(e) => handleChange(e, 0)}
                            className="bg-white"
                            color="orange"
                            label={t("picturedesc")}
                        />
                        <InputPlace
                            uuid={uuid}
                            onSelect={onSelect}
                            value={areAllLieuSame(descriptions)}
                        />
                        <div className="relative">
                            {areAllValuesSame("date", descriptions) === "valeurs multiples" && (
                                <div className="text-gray-500 text-sm absolute top-2.5 left-3 bg-white z-10">{t("multipleValues")}</div>
                            )}
                            <Input
                                value={areAllValuesSame("date", descriptions) || ""}
                                name="date"
                                onChange={(e) => handleChange(e, 0)}
                                className="bg-white"
                                color="orange"
                                label={t("tdate")}
                                type="date"
                            />
                        </div>

                        {bulkIds.length > 1 && (
                            <Button onClick={() => setConfigIndivOrNot(false)} color="white" className="flex flex-row items-center gap-2 font-sans w-full justify-center hover:bg-gray-200 transition-all">
                                <p className="mt-0.5 font-sans">{t("singleconfiguration")}</p>
                            </Button>
                        )}
                    </div>
                ) : (
                    <>
                        {bulkIds.map((id, index) => {
                            return (
                                <Accordion open={open === index} key={bulkIds[index]} className="w-full">
                                    <button
                                        onClick={() => (index === open ? setOpen(-1) : setOpen(index))}
                                        color="orange"
                                        className={`flex flex-row justify-between items-center w-full border-b ${
                                            open === index && "text-orange-500 border-b-orange-500"
                                        } pb-2`}
                                    >
                                        <p className="text-xs">
                                            {t("photo")} {index + 1}
                                        </p>
                                        <CustomFontAwesomeIcon
                                            icon={open === index ? "angle-up" : "angle-down"}
                                            className={`text-xs  ${open === index && "text-orange-500"}`}
                                        />
                                    </button>
                                    <AccordionBody className="flex flex-col gap-4 pt-2">
                                        <Input
                                            value={descriptions[index] ? descriptions[index].titre : ""}
                                            name="titre"
                                            onChange={(e) => handleChange(e, index)}
                                            className="bg-white"
                                            color="orange"
                                            label={t("titlep")}
                                        />
                                        <Textarea
                                            value={descriptions[index] ? descriptions[index].description : ""}
                                            name="description"
                                            onChange={(e) => handleChange(e, index)}
                                            className="bg-white"
                                            color="orange"
                                            label={t("picturedesc")}
                                        />
                                        <InputPlace
                                            uuid={uuid}
                                            value={descriptions[index] ? descriptions[index].lieu : { id: "", label: "" }}
                                            onSelect={(value: string, value2: string | undefined) => onSelectMulti(value, value2, index)}
                                        />
                                        <Input
                                            value={descriptions[index] ? descriptions[index].date : ""}
                                            name="date"
                                            onChange={(e) => handleChange(e, index)}
                                            className="bg-white"
                                            color="orange"
                                            label={t("tdate")}
                                            type="date"
                                        />
                                    </AccordionBody>
                                </Accordion>
                            );
                        })}

                        {bulkIds.length > 1 && (
                            <Button onClick={() => setConfigIndivOrNot(true)} color="white" className="flex flex-row items-center gap-2 font-sans w-full justify-center hover:bg-gray-200 transition-all">
                                <p className="mt-0.5 font-sans">{t("multipleconfiguration")}</p>
                            </Button>
                        )}
                    </>
                )}
            </Collapse>
        </div>
    );
}
