import { Dialog } from "@headlessui/react";
import ModalWrapper from "../../../components/Modals/ModalWrapper";
import { useTranslation } from "react-i18next";

export default function ModalAlert({ open, setOpen, onDelete }: { open: boolean; setOpen: () => void, onDelete: () => void; }) {
    const { t } = useTranslation();
    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <Dialog.Panel className="transform overflow-hidden rounded-2xl bg-white p-6 py-12 text-left align-middle shadow-xl transition-all max-w-3xl">
                <Dialog.Title as="h3" className="text-3xl font-medium leading-6 text-gray-900 text-center">
                    {t("deletegallery")}
                </Dialog.Title>
                <div className="mt-6">
                    <p className="text-lg text-center text-gray-500" dangerouslySetInnerHTML={{ __html: t("deletegallery_d")}}>
                    </p>
                </div>

                <div className="flex justify-center gap-4 mt-6">
                    <button
                        type="button"
                        className="rounded-full border text-white bg-orange-500 hover:bg-orange-100 px-4 py-2 text-sm font-medium  hover:shadow-xl transition-all font-sans"
                        onClick={() => {
                            setOpen();
                        }}
                    >
                        {t("keep")}
                    </button>
                    <button
                        type="button"
                        className="rounded-full border bg-white hover:bg-gray-50 px-4 py-2 text-sm font-medium  hover:shadow-xl transition-all font-sans"
                        onClick={() => {
                            onDelete();
                        }}
                    >
                        {t("delete")}
                    </button>
                </div>
            </Dialog.Panel>
        </ModalWrapper>
    );
}
