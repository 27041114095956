import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomFontAwesomeIcon = ({ icon, isFAT = false, ...props } : { 
    icon: string | [string, string]; 
    isFAT?: boolean;
    [key: string]: any;
}) => {
  // Ajout automatique du préfixe "fat" si ce n'est pas un tableau
  const iconWithPrefix = Array.isArray(icon) ? icon : isFAT ? ["fat", icon] : ["fas", icon];

  return <FontAwesomeIcon icon={iconWithPrefix as IconProp} {...props} />;
};

export default CustomFontAwesomeIcon;
