import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Avatar, IconButton, Typography } from "@material-tailwind/react";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Footer from "../../components/Footer/Footer";
import Loader from "../../components/Loader";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import PaginationCallNextOne from "../../components/Tables/PaginationCallNextOne";
import TitlePage from "../../components/TitlePage";
import WrapperGrayZone from "../../components/WrapperGrayZone";
import OrderAPI from "../../services/API/Clients/OrderAPI";
import { Storage } from "../../services/storage";
import { handleError } from "../../services/Errors/handleErrors";
import { Link } from "react-router-dom";
import CustomFontAwesomeIcon from "../../components/CustomFontAwesomeIcon";

export default function Purchases() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("purchases")}`;
    const myId = Storage.getId()
    const [searchBar, setSearchBar] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [loading, setLoading] = useState(true);
    const [purchases, setPurchases] = useState<{
        pagination: {
            current: number;
            last: number;
            totalItems: number;
            parPage: number;
        },
        data: {
            id: string,
            totalPrice: number,
            orderId: string,
            status: string,
            createdAt: string,
            contracts: {
                id: string,
                picture: {
                    id: string,
                    watermarkUpToDate: boolean,
                    minPath: string,
                    uploadedAt: string,
                    width: number,
                    height: number
                },
                seller: {
                    id: string,
                    name:string,
                    picture: string,
                    plan: string,
                    publicLink: string,
                    verified: boolean,
                    isSubscribed: boolean
                },
                sellerActive: boolean
            }[]
        }[]
    }>({
        pagination: {
            current: 0,
            last: 0,
            totalItems: 0,
            parPage: 0
        },
        data: []
    })
    const [expandedRows, setExpandedRows] = useState<boolean[]>([]);

    const openThisOne = (index: number) => {
        const oldRows = expandedRows;
        const isRowExpanded = oldRows[index];
        const changeMyRow = isRowExpanded ? false : true;
        oldRows[index] = changeMyRow;
        setExpandedRows([...oldRows]);
    };
    
    const fetchData = async () => {
        if (!myId) return
        const response = await OrderAPI.getOrders(myId);
        if (response.status === 200) {
            setPurchases(response.body)
            setLoading(false)
        } else {
            handleError(response);
        }
    }

    const callNextOrders = async (page: number) => {
        if (!myId) return
        const response = await OrderAPI.getOrdersPagination(myId, page);
        if (response.status === 200) {
            const data: any = {
                pagination: response.body.pagination,
                data: response.body.data
            }
            setPurchases(data)
            setLoading(false)
        } else {
            handleError(response);
        }
    }

    const getQuittances = async (id: string) => {
        const response = await OrderAPI.quittance(id);
        if (response.status === 200) {
            const url = window.URL.createObjectURL(response.body);
            const link = document.createElement("a");
            link.href = url;
            link.target = "_blank";
            link.rel = "noopener noreferrer";
            link.click();
        } else {
            handleError(response);
        }
    };

    const getContratLicence = async (id: string, picture: string) => {
        const response = await OrderAPI.contratBuySell(id, picture);
        if (response.status === 200) {
            const url = window.URL.createObjectURL(response.body);
            const link = document.createElement("a");
            link.href = url;
            link.target = "_blank";
            link.rel = "noopener noreferrer";
            link.click();
        } else {
            handleError(response);
        }
    };

    useEffect(() => {
        fetchData()
    }, [])

    return (
            <div className="min-h-screen bg-gray-50 relative">
                <Loader loading={loading} />
                <HeaderForAnimation />
                <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} isVisible={isVisible} setIsVisible={setIsVisible} />
                <div className="flex flex-col h-full bg-white -mt-4 pt-4 pb-[340px] md:pb-36">
                    <TitlePage>{t("purchases")}</TitlePage>
                    <WrapperGrayZone pt="0">
                        <div className="px-4 w-full max-w-7xl mx-auto">
                            {purchases.data.length > 0 ? (
                                <>
                                    <div className="w-full overflow-x-auto px-4">
                                        <table className="w-full min-w-max table-auto text-left mt-4">
                                        <thead>
                                                <tr>
                                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 rounded-tl">
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-bold leading-none opacity-90 rounded-tl"
                                                        >
                                                            {t("date")}
                                                        </Typography>
                                                    </th>
                                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-bold leading-none opacity-90 rounded-tl"
                                                        >
                                                            {t("time")}
                                                        </Typography>
                                                    </th>
                                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 rounded-tr">
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-bold leading-none opacity-90 rounded-tl"
                                                        >
                                                            {t("amount")}
                                                        </Typography>
                                                    </th>
                                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 rounded-tr">
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-bold leading-none opacity-90 rounded-tl"
                                                        >
                                                            {t("command_number_title")}
                                                        </Typography>
                                                    </th>
                                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 rounded-tr">
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-bold leading-none opacity-90 rounded-tl"
                                                        >
                                                            {t("seller")}
                                                        </Typography>
                                                    </th>
                                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 rounded-tr text-center">
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-bold leading-none opacity-90 rounded-tl"
                                                        >
                                                            {t("reciept")}
                                                        </Typography>
                                                    </th>
                                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 rounded-tr text-center"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {purchases.data.map((item, index) => (
                                                    <Fragment key={item.id}>
                                                        <tr key={item.id} className={`${index % 2 ? "bg-blue-gray-50/75" : "bg-white"} hover:bg-gray-200 transition-all`}>
                                                            <td className="p-4">
                                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                                    {moment(item.createdAt).format("DD.MM.YYYY")}
                                                                </Typography>
                                                            </td>
                                                            <td className="p-4">
                                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                                    {moment(item.createdAt).format("HH:mm")}
                                                                </Typography>
                                                            </td>
                                                            <td className="p-4">
                                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                                    {t("chf")} {item.totalPrice.toFixed(2)}
                                                                </Typography>
                                                            </td>
                                                            <td className="p-4">
                                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                                    {item.orderId}
                                                                </Typography>
                                                            </td>
                                                            <td className="p-4">
                                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                                    {item?.contracts[0].sellerActive
                                                                        ? <Link to={`/${item?.contracts[0].seller.publicLink}/photos`}>{item?.contracts[0].seller.name}</Link> 
                                                                        : item?.contracts[0].seller.name
                                                                    }
                                                                </Typography>
                                                            </td>
                                                            <td className="p-4 text-center">
                                                                <IconButton
                                                                    onClick={() => getQuittances(item.id)}
                                                                    variant="outlined"
                                                                    color="orange"
                                                                    className="rounded-full  hover:text-orange4500 hover:border-orange-400"
                                                                >
                                                                    <CustomFontAwesomeIcon icon="file-pdf" />
                                                                </IconButton>
                                                            </td>
                                                            <td className="p-4 text-center">
                                                                <IconButton
                                                                    onClick={() => openThisOne(index)}
                                                                    variant="outlined"
                                                                    color="orange"
                                                                    className="rounded-full hover:text-orange-400 hover:border-orange-400"
                                                                >
                                                                    <CustomFontAwesomeIcon icon={!expandedRows[index] ? "chevron-down" : "chevron-up"} />
                                                                </IconButton>
                                                            </td>
                                                        </tr>
                                                        {expandedRows[index] && (
                                                            <tr>
                                                                <td colSpan={7}>
                                                                    <table className="w-full min-w-max table-auto text-left">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                                                                <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                                                                    {t("quantity")}
                                                                                </Typography>
                                                                            </th>
                                                                            <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                                                                <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                                                                    {t("image")}
                                                                                </Typography>
                                                                            </th>
                                                                            <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                                                                <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                                                                    {t("product")}
                                                                                </Typography>
                                                                            </th>
                                                                            <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                                                                <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                                                                    {t("ref")}
                                                                                </Typography>
                                                                            </th>
                                                                            <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-2 rounded-tr text-center">
                                                                                <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                                                                    {t("contrat")}
                                                                                </Typography>
                                                                            </th>
                                                                            <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-2 rounded-tr text-center">
                                                                                <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                                                                    {t("download")}
                                                                                </Typography>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                        <tbody>
                                                                            {item.contracts.map((contract, contractIndex) => (
                                                                                <tr key={contract.id} className={`${contractIndex % 2 ? "bg-blue-gray-50/75" : "bg-white"} hover:bg-gray-200 transition-all`}>
                                                                                    <td className="p-2">
                                                                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                                                                            1
                                                                                        </Typography>
                                                                                    </td>
                                                                                    <td className="p-2">
                                                                                        <Link to={`/download/${contract.id}/${contract.picture.id}`}>
                                                                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                                                                <Avatar src={contract.picture.minPath} variant="rounded" />
                                                                                            </Typography>
                                                                                        </Link>
                                                                                    </td>
                                                                                    <td className="p-2">
                                                                                        <Link to={`/download/${contract.id}/${contract.picture.id}`}>
                                                                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                                                                {t("articleJpeg", { height: contract.picture.height, width: contract.picture.width })}
                                                                                            </Typography>
                                                                                        </Link>
                                                                                    </td>
                                                                                    <td className="p-2">
                                                                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                                                                            {contract.picture.id}
                                                                                        </Typography>
                                                                                    </td>
                                                                                    <td className="p-2 text-center">
                                                                                        <IconButton
                                                                                            onClick={() => getContratLicence(contract.id, contract.picture.id)}
                                                                                            variant="outlined"
                                                                                            color="orange"
                                                                                            className="rounded-full hover:text-orange-400 hover:border-orange-400"
                                                                                        >
                                                                                            <CustomFontAwesomeIcon icon="file-pdf" />
                                                                                        </IconButton>
                                                                                    </td>
                                                                                    <td className="p-2 text-center">
                                                                                        <Link to={`/download/${contract.id}/${contract.picture.id}`}>
                                                                                            <IconButton
                                                                                                variant="outlined"
                                                                                                color="orange"
                                                                                                className="rounded-full hover:text-orange-400 hover:border-orange-400"
                                                                                            >
                                                                                                <CustomFontAwesomeIcon icon="download" />
                                                                                            </IconButton>
                                                                                        </Link>
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </Fragment>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <PaginationCallNextOne pagination={purchases.pagination} callNextPicture={callNextOrders} />
                                </>
                            ) : (
                                <Alert className="w-2/3 mx-auto my-4 bg-gray-300 text-black text-sm text-center">{t("nopurchase")}</Alert>
                            )}
                        </div>
                    </WrapperGrayZone>
                </div>
                <Footer />
            </div>
    );
}
