import { useState, useEffect, useRef } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import CustomFontAwesomeIcon from "../../CustomFontAwesomeIcon";

interface Item {
    key: string;
    value: string;
}

interface DropDownProps {
    title: string;
    list: Item[];
    side?: "right" | "left";
    selected: string | undefined;
    setSelected: (item: string) => void;
    removeSelected: () => void;
}

export default function DropDown({
    title,
    list,
    side = "left",
    selected,
    setSelected,
    removeSelected,
}: DropDownProps) {
    const selectedValue = list.find((v) => v.key === selected);
    const { t } = useTranslation();
    const menuRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [computedSide, setComputedSide] = useState<"right" | "left">(side);
    const [forcedSide, setForcedSide] = useState<"right" | "left" | null>(null);


    const updatePosition = () => {
        if (menuRef.current && buttonRef.current) {
            const menuRect = menuRef.current.getBoundingClientRect();
            const buttonRect = buttonRef.current.getBoundingClientRect();
    
            const isOutOfScreenRight = menuRect.right > window.innerWidth;
            const isOutOfScreenLeft = menuRect.left < 0;
    
            if (!forcedSide) { // Vérifie si un côté a déjà été forcé
                if (isOutOfScreenLeft && side === "right") {
                    setComputedSide("left");
                    setForcedSide("left");
                } else if (isOutOfScreenRight && side === "left") {
                    setComputedSide("right");
                    setForcedSide("right");
                } else {
                    setComputedSide(side);
                }
            }
        }
    };

    return (
        <Menu as="div" className="relative inline-block text-left">
            {({ open }) => {
                useEffect(() => {
                    if (open) {
                        setTimeout(() => {
                            updatePosition(); 
                        }, 0); 
                
                        window.addEventListener("resize", updatePosition);
                        window.addEventListener("scroll", updatePosition, true);
                    } else {
                        setForcedSide(null); // Réinitialise le côté forcé une fois fermé
                        setComputedSide(side); 
                    }
                
                    return () => {
                        window.removeEventListener("resize", updatePosition);
                        window.removeEventListener("scroll", updatePosition, true);
                    };
                }, [open, side]);
                

                return (
                    <>
                        <div>
                            <Menu.Button
                                ref={buttonRef}
                                className={`${
                                    selected
                                        ? "text-white border-transparent bg-gradient-to-r from-orange-500 to-orange-100"
                                        : ""
                                } 
                                border flex px-4 py-1 rounded-full flex-row items-center gap-2 bg-white hover:bg-gray-50 transition-all`}
                            >
                                {selectedValue ? selectedValue.value : t(title)}
                                {selected ? (
                                    <CustomFontAwesomeIcon
                                        icon="times"
                                        className="ml-2"
                                        onClick={(e: any) => {
                                            e.stopPropagation();
                                            removeSelected();
                                        }}
                                    />
                                ) : (
                                    <CustomFontAwesomeIcon
                                        icon="chevron-down"
                                        className="-mr-1 h-3 w-3"
                                        aria-hidden="true"
                                    />
                                )}
                            </Menu.Button>
                        </div>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items
                                ref={menuRef}
                                className={`absolute ${computedSide}-0 z-50 flex-col px-2 py-0.5 w-fit origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none 
                                font-comfortaa font-thin md:max-h-[200px] lg:max-h-[445px] overflow-y-auto`}
                            >
                                <div className="py-1">
                                    {list.map((item) => (
                                        <Menu.Item key={item.key}>
                                            <button
                                                onClick={() =>
                                                    setSelected(item.key)
                                                }
                                                className={`${
                                                    selected === item.key &&
                                                    "bg-orange-500 text-white"
                                                } my-1 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg min-w-[8rem] whitespace-nowrap`}
                                            >
                                                {t(item.value)}
                                                {selected === item.key && (
                                                    <CustomFontAwesomeIcon
                                                        icon="check"
                                                        className="ml-2"
                                                    />
                                                )}
                                            </button>
                                        </Menu.Item>
                                    ))}
                                </div>
                            </Menu.Items>
                        </Transition>
                    </>
                );
            }}
        </Menu>
    );
}
