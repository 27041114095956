import { Avatar, Chip } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import DefaultPictureProfil from "../../assets/images/elements/default-profile.png";
import CustomFontAwesomeIcon from "../CustomFontAwesomeIcon";

export default function CardUserWithData({ data, isVerified }: Readonly<{ data?: { id: string, name: string, picture: string, publicLink: string, plan?: string, verified?: boolean }, isVerified: boolean }>) {
    const { t } = useTranslation();

    const returnColor = () => {
        if (data?.plan === "PRO") return "bg-black text-white";
        else if (data?.plan === "PREMIUM") return "bg-white text-black border border-black";
        else return;
    }

    const returnValue = () => {
        if (data?.plan === "PRO") return t("pro");
        else if (data?.plan === "PREMIUM") return t("premium");
        else return;
    }

    const returnIcon = () => {
        if (data?.plan === "PRO") return <CustomFontAwesomeIcon icon="crown" className="h-3 mx-0.5" />;
        else if (data?.plan === "PREMIUM") return <CustomFontAwesomeIcon icon="crown" className="h-3 mx-0.5" />;
        else return;
    }
    
    if (!data?.publicLink) return (
        <div className="flex flex-row items-center gap-2 px-2 w-auto rounded-xl py-2">
            <Avatar src={data?.picture ?? DefaultPictureProfil} />
            <div className="flex flex-col min-w-0">
                <p className="text-lg font-bold flex flex-row gap-2 items-center min-w-0">
                    <span className={`truncate min-w-0 text-black`}>{data?.name}</span>
                    {isVerified && (<CustomFontAwesomeIcon icon="check-circle" className="text-gray-500" />)}
                </p>
                {data?.plan !== "USER" && data?.plan !== "PHOTOGRAPHER" &&
                    <Chip
                        className={`w-fit h-fit ${returnColor()}`}
                        color="orange"
                        variant="ghost"
                        value={returnValue()}
                        icon={returnIcon()}
                        size="sm"
                    />
                }
            </div>
        </div>
    )

    return (
        <Link to={`/${data?.publicLink}/photos`} className="flex flex-row items-center gap-2 px-2 w-auto rounded-xl py-2 max-w-[70vw] overflow-hidden">
            <Avatar src={data?.picture ?? DefaultPictureProfil} />
            <div className="flex flex-col min-w-0">
                <p className="text-lg font-bold flex flex-row gap-2 items-center min-w-0">
                    <span className={`truncate min-w-0 text-black`}>{data?.name}</span>
                    {isVerified && (<CustomFontAwesomeIcon icon="check-circle" className="text-gray-500" />)}
                </p>
                {data?.plan !== "USER" && data?.plan !== "PHOTOGRAPHER" &&
                    <Chip
                        className={`w-fit h-fit ${returnColor()}`}
                        variant="ghost"
                        value={returnValue()}
                        icon={returnIcon()}
                        size="sm"
                    />
                }
            </div>
        </Link>
    );
}
