import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Input } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { appleAuthHelpers } from 'react-apple-signin-auth';
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import MiniLogo from "../../assets/images/logos/SVG/logo_e.svg";
import Footer from "../../components/Footer/Footer";
import GradientButton from "../../components/Form/Button/GradientButtonBig";
import Auth from "../../services/API/Clients/AuthAPI";
import { Storage } from "../../services/storage";

import { Link } from "react-router-dom";
import BackgroundDefault from "../../assets/images/elements/default-banner.png";
import Loader from "../../components/Loader";
import Modal2fa from "../../components/Modals/Modal2fa";

import { useGoogleLogin } from "@react-oauth/google";
import { PictureType } from "../../components/Interfaces/PictureType";
import ModalOauth from "../../components/Modals/ModalOauth";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import PicturesAPI from "../../services/API/Clients/PicturesAPI";
import { handleError } from "../../services/Errors/handleErrors";
import { APIResponse } from "../../services/API/API";
import CustomFontAwesomeIcon from "../../components/CustomFontAwesomeIcon";
import { PictureBackgroundType } from "../../components/Interfaces/PictureBackgroundType";

export default function Signin() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("signin")}`;
    const [showPassword, setShowPassword] = useState(false);
    const [open, setOpen] = useState(false);
    const [searchBar, setSearchBar] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [openComplete, setOpenComplete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingBg, setLoadingBg] = useState(true);
    const [token, setToken] = useState("");
    const [user, setUser] = useState<{ email: string; password: string }>({
        email: "",
        password: "",
    });
    const [dataTokens, setDataTokens] = useState<{ token: string; refresh_token: string; id: string }>({
        token: "",
        refresh_token: "",
        id: "",
    });
    const [need2fa, setNeed2fa] = useState(false);
    const [backgroundPicture, setBackgroundPicture] = useState<PictureBackgroundType | null>(null);

    const query = window.location.search;

    useEffect(() => {
        const fetchData = async () => {
            const response = await PicturesAPI.backgroundPicture();
            if (response.status === 200) {
                setBackgroundPicture(response.body);
                setLoadingBg(false);
            } else {
                handleError(response);
            }
        };
        fetchData();
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value,
        });
    };

    const handleSigninResponse = (response: APIResponse) => {
        if (response.status !== 200 && response.status !== 201) {
            handleError(response);
            setLoading(false);
            return;
        }
        if (response.body.needCompletion) {
            if (response.body?.totpToken) {
                setToken(response.body.totpToken);
                setOpenComplete(true);
                setNeed2fa(true);
            } else {
                setDataTokens({
                    token: response.body.token,
                    refresh_token: response.body.refresh_token,
                    id: response.body.id,
                });
                setNeed2fa(false);
                setOpenComplete(true);
            }
        } else if (response.body?.totpToken) {
            setToken(response.body.totpToken);
            setOpen(true);
        } else {
            Storage.setToken(response.body.token);
            Storage.setRefreshToken(response.body.refresh_token);
            Storage.setId(response.body.id);
            setLoading(false);
            toast.success(t("signin_success"));
            if (query === "?redirect=premium") {
                window.location.href = "/subscription/premium";
            } else if (query === "?redirect=pro") {
                window.location.href = "/subscription/pro";
            } else {
                window.location.href = "/";
            }
        }
    }

    const handleSignin = async (e: any) => {
        e.preventDefault();
        if (loading) return;
        setLoading(true);

        const datas = {
            username: user.email,
            password: user.password,
        };

        const response = await Auth.signin(datas);
        handleSigninResponse(response);
    };

    useEffect(() => {
        setLoadingBg(false);
    }, []);

    const handleChange2fa = () => {
        setOpen(!open);
        if (open) {
            setLoading(false);
        }
    };

    const handleOpenComplete = () => {
        setOpenComplete(!openComplete);
        setLoading(false);
    };

    const signinGoogle = async (tokenResponse: any) => {
        setLoading(true);
        const datas = {
            oAuthContext: {
                code: tokenResponse.code,
            }
        };

        const response = await Auth.signinGoogle(datas);
        handleSigninResponse(response);

    };

    const loginGoogle = useGoogleLogin({
        onSuccess: (tokenResponse) => signinGoogle(tokenResponse),
        onError: (error) => toast.error(t("signin_google_error")),
        flow: "auth-code",
        redirect_uri: `https://${window.location.hostname}/auth/callback`,
    });

    const responseFacebook = async (response: any) => {
        setLoading(true);
        const datas = {
            oAuthContext: {
                code: response.signedRequest,
            }
        };

        const resp = await Auth.signinFacebook(datas)
        handleSigninResponse(resp);
    };

    const signinApple = async (code: string, env: string) => {
        setLoading(true);
        const datas = {
            oAuthContext: {
                code,
                env
            }
        };

        const response = await Auth.signinApple(datas);
        handleSigninResponse(response);
    };

    const onSignin = async () => {
        await appleAuthHelpers.signIn({
            authOptions: {
                clientId: 'ch.ezoom.auth.client',
                scope: 'email name',
                redirectURI: `https://${window.location.hostname}/auth/callback`,
                state: 'state',
                nonce: 'nonce',
                usePopup: true,
            },
            onSuccess: (response: any) => signinApple(response.authorization.code, window.location.hostname),
            onError: (error: any) => toast.error(t("signin_apple_error")),
        });
    }

    return (
        <div className="min-h-full">
            <Modal2fa open={open} setOpen={handleChange2fa} token={token} />
            <ModalOauth
                open={openComplete}
                setOpen={handleOpenComplete}
                token={token}
                dataTokens={dataTokens}
                need2fa={need2fa}
                handleChange2fa={handleChange2fa}
            />
            <Loader loading={loadingBg} />
            <HeaderForAnimation />
            <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} isVisible={isVisible} setIsVisible={setIsVisible} />
            <div
                className="hidden md:block h-full min-h-screen"
                style={{
                    backgroundImage: `url('${backgroundPicture?.picture?.publicPath ?? BackgroundDefault}')`,
                    backgroundAttachment: "fixed",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "100vmax",
                    zIndex: -1,
                    position: "fixed",
                    top: 0,
                    left: "50%",
                    transform: "translate(-50%, 0)",
                    pointerEvents: "none",
                    backgroundPositionX: "center",
                }}
            ></div>
            <div className="relative min-h-screen flex justify-start items-center">
                <div className="relative w-screen md:min-h-auto mt-0 mb-0 md:h-auto md:w-2/3 xl:w-1/3 bg-white rounded-0 md:rounded-lg md:mt-8 md:mb-16 px-8 py-12 mx-auto shadow-xl flex justify-center flex-col max-w-3xl">
                    <Link to="/" className="absolute top-4 right-4 flex md:hidden">
                        <IconButton color="orange" className="w-10 h-10 rounded-0 md:rounded-lg flex items-center justify-center">
                            <CustomFontAwesomeIcon icon="x" className="text-white h-4 w-4 mx-auto my-3" aria-hidden="true" />
                        </IconButton>
                    </Link>
                    <MiniLogo className="w-14 h-14 my-2.5 mx-auto" />
                    <h1 className="text-3xl mb-6 text-center">{t("signin")}</h1>
                    <form onSubmit={handleSignin}>
                        <div className="flex flex-col w-full gap-4">
                            <Input
                                required
                                name="email"
                                type="email"
                                color="orange"
                                label={t("mail")}
                                className="rounded-md font-sans"
                                value={user.email}
                                onChange={handleChange}
                            />
                            <div className="w-full relative">
                                <Input
                                    required
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    color="orange"
                                    label={t("pwd")}
                                    className="rounded-md font-sans"
                                    value={user.password}
                                    onChange={handleChange}
                                />
                                <CustomFontAwesomeIcon
                                    icon={showPassword ? "eye-slash" : "eye"}
                                    onClick={() => setShowPassword(!showPassword)}
                                    className="absolute top-1/2 right-6 transform -translate-y-1/2 cursor-pointer text-gray-500"
                                />
                            </div>
                        </div>
                        <div className="mt-1 mb-4">
                            <Link to="/forgotpassword" className="text-right text-sm text-gray-500 hover:text-gray-400">
                                {t("forgotpassword")}
                            </Link>
                        </div>
                        <button className="mx-auto w-full">
                            <GradientButton loading={loading} text="connect" />
                        </button>
                    </form>
                    <div className="text-center uppercase my-4 flex mx-auto w-3/4 items-center">
                        <div className="flex-1 border-b border-gray-400 mr-1/4"></div>
                        <span className="text-gray-400 mx-2">{t("or")}</span>
                        <div className="flex-1 border-b border-gray-400 ml-1/4"></div>
                    </div>


                    <button
                        onClick={onSignin}
                        className="border-2 border-black inline w-auto transition-all hover:bg-black hover:text-white py-2 px-4 rounded-full text-black mt-2"
                    >
                        {t("signinap")}
                    </button>
                    <FacebookLogin
                        appId="1217121976359157"
                        fields="name,email,picture"
                        responseType="code"
                        callback={responseFacebook}
                        render={(renderProps) => (
                            <button
                                onClick={renderProps.onClick}
                                className="border-2 border-blue-900 inline w-auto transition-all hover:bg-blue-900 hover:text-white py-2 px-4 rounded-full text-blue-900 mt-2"
                            >
                                {t("signinfb")}
                            </button>
                        )}
                        version="19.0"
                    />
                    <button
                        onClick={() => loginGoogle()}
                        className="border-2 border-gray-900 inline w-auto transition-all hover:bg-gray-900 hover:text-white py-2 px-4 rounded-full text-gray-900 mt-2"
                    >
                        {t("signingl")}
                    </button>
                    <hr className="border-t border-t-gray-500 shadow-none rounded-lg mt-8 w-3/4 mx-auto" />
                    <p className="text-center text-gray-500 mt-4 flex flex-col gap-2">
                        {t("donthaveaccount")}{" "}
                        <Link to={"/signup" + query} className="text-orange-500 hover:text-orange-200">
                            {t("signup2")}
                        </Link>
                    </p>
                </div>
            </div>
            <Footer isAbsolute={false} />
        </div>
    );
}
