import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip, IconButton, Popover, PopoverContent, PopoverHandler, Tooltip, Typography } from "@material-tailwind/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import DefaultBannerEzoom from "../../../../assets/images/elements/default-banner.png";
import DefaultPictureEzoom from "../../../../assets/images/elements/default-profile.png";
import SharingBox from "../../../../components/Box/SharingBox";
import { NavigationCustomStyles } from "../../../../components/CustomStyles/NavigationCustomStyles";
import { OpenReportPortfolio } from "../../../../components/Modals/ModalPortfolioReport";
import ModalSignInUp from "../../../../components/Modals/ModalSignInUp";
import { PictureDrawerForFilters } from "../../../../components/SearchResult/impl/PictureResult";
import MobileFilterButton from "../../../../components/SearchResult/mobile/MobileFilterButton";
import PortfolioAPI from "../../../../services/API/Clients/PortfolioAPI";
import UserAPI from "../../../../services/API/Clients/UserAPI";
import { handleError } from "../../../../services/Errors/handleErrors";
import { updatePortfolio, updateRepartition, updateSubscribed } from "../../../../services/redux/actions/formAction";
import { formatLongNumber } from "../../../../services/redux/actions/formatNumber";
import { RootState } from "../../../../services/redux/store";
import { Storage } from "../../../../services/storage";
import { updateGeneral } from "../../../../services/redux/actions/generalActions";
import CustomFontAwesomeIcon from "../../../../components/CustomFontAwesomeIcon";
import { isValidHttpUrl } from "../../../../services/hooks/isValidUrl";

export default function HeaderMobile({
    searchBar,
    isVisible,
    actualUrl,
    isPersonnal,
    handleLinkClick,
}: Readonly<{ searchBar: boolean; isVisible: boolean; actualUrl: string; isPersonnal: boolean; handleLinkClick: (url: string) => void }>) {
    const [hidden, setHidden] = React.useState(false);
    const [openInvitation, setOpenInvitation] = React.useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { id } = useParams();
    const general = useSelector((state: RootState) => state.general);
    const portfolio = useSelector((state: RootState) => state.form);
    const myId = Storage.getId();
    const dispatch = useDispatch();

    const chooseUrl = () => {
        if (isPersonnal) {
            switch (window.location.pathname.split("/")[2]) {
                case "photos":
                    return `/portfolio/edit/photos`;
                case "about":
                    return `/portfolio/edit/about`;
                case "performances":
                    return `/portfolio/edit/performances`;
                case "performance":
                    return `/portfolio/edit/performance/${window.location.pathname.split("/")[3]}`;
                case "opinion":
                    return `/portfolio/edit/photos`;
                case "contact":
                    return `/portfolio/edit/photos`;
                default:
                    return `/portfolio/edit/photos`;
            }
        } else {
            switch (window.location.pathname.split("/")[3]) {
                case "photos":
                    return `/portfolio/edit/photos`;
                case "about":
                    return `/portfolio/edit/about`;
                case "performances":
                    return `/portfolio/edit/performances`;
                case "performance":
                    return `/portfolio/edit/performance/${window.location.pathname.split("/")[4]}`;
                case "opinion":
                    return `/portfolio/edit/photos`;
                case "contact":
                    return `/portfolio/edit/photos`;
                default:
                    return `/portfolio/edit/photos`;
            }
        }
    };

    const featurePages = portfolio?.features?.pages;

    const MenuList = Object.entries(featurePages ?? {})
                            .filter(([key, value]) => value) 
                            .map(([key, _]) => {
                                let item = {};

                                if (key === "photos") {
                                    item = {
                                        value: key,
                                        url: isPersonnal ? `/${portfolio.path}/about` : `/portfolio/${id}/${key}`,
                                        label: `${t("photos")} (${formatLongNumber(portfolio.pictureCount ?? 0)})`,
                                    };
                                } else if (key === "services") {
                                    item = {
                                        value: "performances",
                                        url: isPersonnal ? `/${portfolio.path}/performances` : `/portfolio/${id}/performances`,
                                        label: `${t("performances")} (${formatLongNumber(portfolio?.services.pagination.totalItems ?? 0)})`,
                                    };
                                } else if (key === "testimonials") {
                                    item = {
                                        value: "opinion",
                                        url: isPersonnal ? `/${portfolio.path}/opinion` : `/portfolio/${id}/opinion`,
                                        label: `${t("opinion")} (${portfolio.opinions.data.length ?? 0})`,
                                    };
                                } else {
                                    item = {
                                        value: key,
                                        url: isPersonnal ? `/${portfolio.path}/${key}` : `/portfolio/${id}/${key}`,
                                        label: t(key),
                                    };
                                }

                                return item;
                            });

    const followPortfolio = async () => {
        if (myId) {
            const datas = {
                owner: `/api/users/${myId}`,
                portfolio: `/api/public/portfolios/${id}`,
            };
            if (portfolio.isSubscribed) {
                const responseUnfollow = await PortfolioAPI.unfollow(datas);
                if (responseUnfollow.status === 200 || responseUnfollow.status === 201 || responseUnfollow.status === 204) {
                    toast.success(t("portfolio_unfollow_success"));
                    dispatch(updateSubscribed(false));
                    const regetPortfolio = await PortfolioAPI.portfolio(portfolio.id);
                    if (regetPortfolio.status === 200) {
                        dispatch(updatePortfolio(regetPortfolio.body));
                    } else {
                        handleError(regetPortfolio);
                    }
                    const regetRepas = await PortfolioAPI.getRepartition(portfolio.id);
                    if (regetRepas.status === 200) {
                        dispatch(updateRepartition(regetRepas.body));
                    } else {
                        handleError(regetRepas);
                    }
                } else {
                    handleError(responseUnfollow);
                }
            } else {
                const responseFollow = await PortfolioAPI.follow(datas);
                if (responseFollow.status === 200 || responseFollow.status === 201) {
                    toast.success(t("portfolio_follow_success"));
                    dispatch(updateSubscribed(true));
                    const regetPortfolio = await PortfolioAPI.portfolio(portfolio.id);
                    if (regetPortfolio.status === 200) {
                        dispatch(updatePortfolio(regetPortfolio.body));
                    } else {
                        handleError(regetPortfolio);
                    }
                    const regetRepas = await PortfolioAPI.getRepartition(portfolio.id);
                    if (regetRepas.status === 200) {
                        dispatch(updateRepartition(regetRepas.body));
                    } else {
                        handleError(regetRepas);
                    }
                } else {
                    handleError(responseFollow);
                }
            }
        }
    };

    const handleDisabled = async () => {
        if (!general?.me?.id) return;
        const dataListDisables = {
            "dialogToClose": "portfolio_welcome"
        }
        const response = await UserAPI.setDialogs(general?.me?.id, dataListDisables);
        if (response.status === 200 || response.status === 201) {
            navigate("/portfolio/edit/photos")
        } else {
            handleError(response);
        }
    }


    const topPaddingTop = (): string => {
        if (isVisible) {
        if (searchBar)
            return "top-[108px] pt-[7px]";
        else
            return "top-[60px] pt-[7px]";
        } else {
        if (searchBar)
            return "top-[48px] pt-[7px]";
        else
            return "top-[0px] pt-[7px]";
        }
    }

    const topPaddingTopBis = (): string => {
        if (isVisible) {
            if (searchBar)
                return "top-[163px] pt-[7px]";
            else
                return "top-[115px] pt-[7px]";
        } else {
            if (searchBar)
                return "top-[103px] pt-[7px]";
            else
                return "top-[53px] pt-[7px]";
        }
    }

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
    };

    return (
        <>
            <ModalSignInUp open={openInvitation} setOpen={setOpenInvitation} />
            <div className={`${(!general.me?.closedDialogs.includes("portfolio_welcome") && general?.me?.portfolio?.id === portfolio?.id) ? "flex md:hidden" : "hidden" } z-40 fixed top-0 left-0 bg-black/50 w-screen h-screen`}>
            </div>
            <PictureDrawerForFilters />
            <div
                className="flex md:hidden relative bg-cover aspect-[18/6] mt-[23px] lg:mt-0"
                style={{
                    backgroundPositionY: `${100 - (portfolio.bannerOffset ?? 0)}%`,
                    backgroundImage: `url(${isValidHttpUrl(portfolio.banner) ? portfolio.banner ?? DefaultBannerEzoom : DefaultBannerEzoom})`,
                }}
            ></div>

            {/* <div className="md:hidden sticky md:static h-14 md:h-0 bg-white z-30 px-1 pb-2 md:pb-0 md:overflow-hidden flex flex-row justify-between items-end"> */}
            
           {window.innerWidth < 768 && (
                <div
                    className={`${topPaddingTop()} md:hidden sticky h-14 md:h-0 bg-white z-30 px-1 pb-2 md:pb-0 md:overflow-hidden flex justify-between items-center duration-300`}
                >
                    {/* Avatar + Titre */}
                    <div className="flex items-center gap-4 flex-grow min-w-0">
                        <div className="w-12 h-12 bg-white rounded-full flex-shrink-0">
                            <img
                                onContextMenu={handleContextMenu}
                                alt="vector"
                                className="w-12 h-12 min-w-[48px] min-h-[48px] object-cover object-center rounded-full border-4 border-gray-50"
                                src={portfolio.loading ? DefaultPictureEzoom : portfolio.picture ?? DefaultPictureEzoom}
                            />
                        </div>
                        <div className="flex flex-col justify-center min-w-0 flex-grow">
                            <Typography className="text-xl font-bold flex items-center gap-2">
                                <div className="truncate min-w-0">{portfolio.name}</div>
                                {portfolio.owner.verified && (
                                    <CustomFontAwesomeIcon icon="check-circle" className="w-3 h-3 text-gray-500" />
                                )}
                            </Typography>
                        </div>
                    </div>
            
                    {/* Boutons d'actions */}
                    <div className="flex flex-row justify-end gap-2 px-2 flex-shrink-0">
                        {!portfolio.loading && myId && myId === portfolio.owner.id && (
                            <Tooltip className="md:hidden bg-white p-0" placement="bottom-end" content={(
                                <div className="bg-orange-50/20 text-sm text-black px-4 py-3 font-sans rounded-lg">
                                    <div className="absolute -top-2 right-16 w-0 h-0 border-l-8 border-l-transparent border-r-8 border-r-transparent border-b-8 border-b-[#fbf2e1]"></div>
                                    <p className="flex flex-col">
                                        <span dangerouslySetInnerHTML={{ __html: t("datainfoPortfolio") }} />
                                        <button
                                        className="ml-auto border-2 bg-orange-500 hover:bg-orange-200 border-transparent inline w-fit text-white hover:text-black py-1 pl-3 pr-4 mt-2 rounded-full"
                                        style={{ transition: "all .25s ease-in-out" }}
                                        onClick={() => handleDisabled()}
                                    >
                                        <span className="flex items-center justify-between"><CustomFontAwesomeIcon icon="check-circle" className="mr-1" /> {t("understand")}</span>
                                    </button>
                                </p>
                            </div>
                        )} open={(!general.me?.closedDialogs.includes("portfolio_welcome") && general?.me?.portfolio?.id === portfolio?.id)}>
                            <Link to={chooseUrl()}>
                                <IconButton 
                                    size="sm" 
                                    color="white"   
                                    variant="outlined"
                                    className="border-gray-400/50 shadow hover:shadow-lg hover:rounded-full transition-all duration-300 rounded-full bg-white hover:bg-gray-50 text-black"
                                >
                                    <CustomFontAwesomeIcon icon="pen" />
                                </IconButton>
                            </Link>
                        </Tooltip>
                    )}
                    {myId !== portfolio.owner.id && (
                        <IconButton
                            size="sm"
                            color="white"
                            variant="outlined"
                            className="flex border-gray-400/50 shadow hover:shadow-lg hover:rounded-full transition-all duration-300 rounded-full bg-white hover:bg-gray-50 text-black"
                            onClick={() => !Storage.getToken() ? setOpenInvitation(!openInvitation) : followPortfolio()}
                        >
                            {portfolio.isSubscribed ? <CustomFontAwesomeIcon icon="user-minus" /> : <CustomFontAwesomeIcon icon="user-plus" />}
                        </IconButton>
                    )}
                    {portfolio.public && <SharingBox isPicture={false} />}
                    {myId !== portfolio.owner.id && (
                        <Popover placement="bottom-end">
                            <PopoverHandler>
                                <IconButton
                                    size="sm"
                                    color="white"
                                    variant="outlined"
                                    className="flex border-gray-400/50 shadow hover:shadow-lg hover:rounded-full transition-all duration-300 rounded-full bg-white hover:bg-gray-50 text-black"
                                >
                                    <CustomFontAwesomeIcon icon="ellipsis-vertical" />
                                </IconButton>
                            </PopoverHandler>
                            <PopoverContent className="p-0">
                                <button
                                    className="py-2 px-4 rounded-lg hover:bg-orange-500 hover:text-white w-full"
                                    onClick={() => !Storage.getToken() ? setOpenInvitation(!openInvitation) : portfolio?.id && OpenReportPortfolio(portfolio.id, t)}
                                >
                                    {t("report")}
                                </button>
                            </PopoverContent>
                        </Popover>
                    )}
                </div>
            </div>
            
           )}

            {/* </div> */}

            <div className="flex md:hidden flex-row gap-2 mt-4 items-center w-full justify-center flex-wrap">
                {portfolio.plan === "PRO" && (
                    <Chip
                        className="w-fit h-fit bg-black text-white"
                        color="purple"
                        variant="ghost"
                        value={t("pro")}
                        icon={<CustomFontAwesomeIcon icon="crown" className="h-3 ml-0.5" />}
                        size="sm"
                    />
                )}
                {portfolio.plan === "PREMIUM" && (
                    <Chip
                        className="w-fit h-fit bg-white text-black border border-black"
                        color="orange"
                        variant="ghost"
                        value={t("premium")}
                        icon={<CustomFontAwesomeIcon icon="crown" className="h-3 ml-0.5" />}
                        size="sm"  
                    />
                )}

                <Chip
                    className="w-fit h-fit"
                    color="purple"
                    variant="ghost"
                    value={`${portfolio.subscriberCount ?? 0} ${t("subs")}`}
                    icon={<CustomFontAwesomeIcon icon="users" className="h-3 ml-0.5" />}
                    size="sm"
                />
                {(portfolio.avgNote !== -1 && portfolio.features.actions.testimonial) && (
                    <Chip
                        className="w-fit h-fit"
                        color="purple"
                        variant="ghost"
                        value={`${portfolio.avgNote === -1 ? t("nonote") : Number(portfolio.avgNote).toFixed(2)}`}
                        icon={<CustomFontAwesomeIcon icon="star" className="h-3 ml-0.5" />}
                        size="sm"
                    />
                )}
            </div>

            {window.innerWidth < 768 && (
                <div
                    className={`${topPaddingTopBis()} flex md:hidden border-b border-gray-200 w-full mx-auto bg-white mt-4 sticky z-30 duration-300`}
                >
                    <div className={`${actualUrl === "photos" ? "w-1/2" : "w-full"} px-2 pb-2`}>
                        <Select
                            placeholder="Menu"
                            name="Menu"
                            className="w-full relative top-0 bg-white text-xs"
                            options={MenuList}
                            styles={NavigationCustomStyles}
                            menuPortalTarget={document.body}
                            menuPosition="absolute"
                            value={MenuList.find((obj: any) => {
                                if (actualUrl === "photos?license=own-license") return obj.value === "photos";
                                if (actualUrl === "performance") return obj.value === "performances";
                                if (["appointment", "inquiry", "offers"].includes(actualUrl)) return obj.value === "contact";
                                return obj.value === actualUrl;
                            })}
                            onChange={(e: any) => e !== null && handleLinkClick(e.url)}
                            isSearchable={false}
                        />
                    </div>
                    <div className={`${actualUrl === "photos" ? "flex" : "hidden"} w-1/2 px-2  gap-2 justify-end`}>
                        <MobileFilterButton />
                    </div>
                </div>
            )}
        </>
    );
}
