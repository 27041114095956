import { Typography } from "@material-tailwind/react";
import { confirmAlert } from "react-confirm-alert";
import CustomFontAwesomeIcon from "../../../CustomFontAwesomeIcon";

export const removeNsfw = (setPrintNsfw: Function, t: any) => {
    confirmAlert({
        customUI: ({ onClose }) => {
            const wantToClose = () => {
                setPrintNsfw(true);
                onClose();
            };

            return (
                <div className="py-4 bg-white rounded-lg shadow-lg border-2 border-gray-300 w-screen lg:w-[60vw] relative">
                    <CustomFontAwesomeIcon icon="x" className="absolute top-4 right-4 text-gray-500 cursor-pointer" onClick={onClose} />
                    <Typography className="text-center w-full text-2xl">{t("nsfw")}</Typography>
                    <div className="w-full flex flex-col px-8 mt-4 justify-center">
                        <div className="mt-6">
                            <p className="text-lg text-center text-gray-500">{t("wanttoseensfw")}</p>
                        </div>
                        <div className="flex justify-center gap-4 mt-6">
                            <button
                                type="button"
                                className="rounded-full bg-orange-500 text-white px-4 py-2 text-sm font-medium  hover:shadow-xl transition-all font-sans"
                                onClick={() => {
                                    wantToClose();
                                }}
                            >
                                {t("show")}
                            </button>
                            <button
                                type="button"
                                className="rounded-full border bg-white px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
                                onClick={onClose}
                            >
                                {t("cancel")}
                            </button>
                        </div>
                    </div>
                </div>
            );
        },
    });
};
