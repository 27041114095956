import APIClient from "../APIClients";

export default class SalesAPI {
    public static async balance(id: string) {
        return APIClient.ezoom.get(`/users/${id}/balance`)
    }

    public static async sales(id: string) {
        return APIClient.ezoom.get(`/static-contracts?user=${id}`)
    }

    public static async salesPagination(id: string, page: number) {
        return APIClient.ezoom.get(`/static-contracts?user=${id}&page=${page}`)
    }

    public static async history(id: string) {
        return APIClient.ezoom.get(`/sales?user=${id}`)
    }

    public static async historyPagination(id: string, page: number) {
        return APIClient.ezoom.get(`/sales?user=${id}&page=${page}`)
    }
}
