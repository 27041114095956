import { Dialog } from "@headlessui/react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalWrapper from "../../Modals/ModalWrapper";

import { toast } from "react-toastify";
import { Storage } from "../../../services/storage";
import { PictureSearch, PictureType } from "../../Interfaces/PictureType";

import FavoriteAPI from "../../../services/API/Clients/FavoriteAPI";
import PicturesAPI from "../../../services/API/Clients/PicturesAPI";

import { Tooltip, Typography } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { A11y, Keyboard, Navigation, Scrollbar } from "swiper/modules";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { usePictureEdit } from "../../../providers/PictureEditProvider";
import { handleError } from "../../../services/Errors/handleErrors";
import CustomFontAwesomeIcon from "../../CustomFontAwesomeIcon";
import CardUserWithData from "../CardUserWithData";
import { handleBuyIt } from "./components/PictureModalBuyIt";
import { handleContractIt } from "./components/PictureModalContract";
import { handleDelete } from "./components/PictureModalDelete";
import { removeNsfw } from "./components/PictureModalNsfw";
import { openReportModal } from "./components/PictureModalReport";
import PictureModalWithoutFullScreen from "./components/PictureModalWithoutFullScreen";
import { createRoot } from "react-dom/client";


export default function PictureModal({
    open,
    setOpen,
    ids,
    pictures,
    callNextPictures,
}: Readonly<{ open: boolean; setOpen: (arg: boolean) => void; ids: string; pictures: PictureSearch[]; callNextPictures?: Function }>) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const general = useSelector((state: any) => state.general);
    const picturesNsfwReducer = useSelector((state: any) => state.picturesNsfwReducer);
    const idleTimeout = useRef<NodeJS.Timeout | null>(null);
    const [actualId, setActualId] = React.useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const [isHoverActive, setHoverActive] = useState<boolean>(false);
    const swiperRef = useRef<SwiperRef>(null);
    const [imageStyle, setImageStyle] = useState({
        backgroundSize: 'contain',
        backgroundPosition: 'center',
    });
    const [dataActualItem, setDataActualItem] = React.useState<PictureType>({
        id: "",
        minPath: "",
        publicPath: "",
        exifData: [],
        portfolio: {
            id: "",
            name: "",
            picture: "",
            publicLink: "",
            verified: false,
        },
        keywords: [],
        category: {
            enumValue: "",
            frLabel: "",
            enLabel: "",
        },
        watermark: "",
        nsfw: false,
        identifiablePeople: false,
        identifiableProperties: false,
        writtenContract: false,
        license: "",
        galleries: [],
        portfolios: [],
        uploadedAt: "",
        favoriteCount: 0,
        viewCount: 0,
        isFavorited: false,
        watermarkUptoDate: false,
        canBeBought: false,
        validationStatus: "",
        canUpdateIdentifiables: false,
        canUpdateNsfw: false,
        isOwned: false,
        publishedAt: "",
        public: false,
        width: 0,
        height: 0,
    });
    const [repartition, setRepartition] = useState<{
        users: number;
        photographers: number;
    }>({
        users: 0,
        photographers: 0,
    });
    const [hover, setHover] = React.useState(false);
    const [isFullScreen, setIsFullScreen] = React.useState(false);
    const [currentPicture, setCurrentPicture] = React.useState<number>(0);
    const myId = Storage.getId();

    const { editData } = usePictureEdit();

    useEffect(() => {
        if (!open) {
            setDataActualItem({
                id: "",
                minPath: "",
                publicPath: "",
                exifData: [],
                portfolio: {
                    id: "",
                    name: "",
                    picture: "",
                    publicLink: "",
                    verified: false,
                },
                keywords: [],
                category: {
                    enumValue: "",
                    frLabel: "",
                    enLabel: "",
                },
                watermark: "",
                nsfw: false,
                identifiablePeople: false,
                identifiableProperties: false,
                writtenContract: false,
                license: "",
                galleries: [],
                portfolios: [],
                uploadedAt: "",
                favoriteCount: 0,
                viewCount: 0,
                isFavorited: false,
                watermarkUptoDate: false,
                canBeBought: false,
                validationStatus: "",
                canUpdateIdentifiables: false,
                canUpdateNsfw: false,
                isOwned: false,
                publishedAt: "",
                public: false,
                width: 0,
                height: 0,
            });
            setRepartition({
                users: 0,
                photographers: 0,
            });
            setHover(false);
            setIsFullScreen(false);
        }
    }, [open]);

    useEffect(() => {
        const foundItem = pictures.find((item) => item.id === ids);
        if (!foundItem) return;
        const actualIndexOfItem = pictures.indexOf(foundItem);
        setActualId(ids);
        setCurrentPicture(actualIndexOfItem);
        swiperRef.current?.swiper?.slideTo(actualIndexOfItem);
        fetchData(true, ids);
    }, [ids]);

    const handleChangeFav = async () => {
        if (myId) {
            const data = {
                picture: `/api/public/pictures/${dataActualItem.id}`,
                owner: `/api/users/${myId}`,
            };
            if (!dataActualItem.isFavorited) {
                const response = await FavoriteAPI.fav(data);
                if (response.status === 200 || response.status === 201) toast.success(t("addToFav"));
                else handleError(response);
            } else {
                const response = await FavoriteAPI.unFav(data);
                if (response.status === 200 || response.status === 201 || response.status === 204) toast.success(t("removeFromFav"));
                else handleError(response);
            }
            fetchData(false);
        }
    };

    const fetchData = async (isLoading: boolean, ids?: string) => {
        if (isLoading)
            setLoading(true);
        if (ids === "" || !ids) {
            if (actualId === "") {
                return;
            } else {
                await PicturesAPI.addView({ picture: `/api/public/pictures/${actualId}` });
                const response = await PicturesAPI.getPicture(actualId);
                if (response.status === 200 || response.status === 201) {
                    if (response.body.isOwned) {
                        const responseDetails = await PicturesAPI.getPictureDetails(actualId);
                        if (responseDetails.status === 200 || responseDetails.status === 201) {
                            setDataActualItem(responseDetails.body);
                        } else {
                            handleError(responseDetails);
                        }
                    } else {
                        setDataActualItem(response.body);
                    }
                    const responseRepartition = await PicturesAPI.getRepartition(actualId);
                    if (response.status === 200 || response.status === 201) {
                        setRepartition(responseRepartition.body);
                        setLoading(false);
                    } else {
                        handleError(responseRepartition);
                    }
                } else {
                    handleError(response);
                }
            }
        } else {
            await PicturesAPI.addView({ picture: `/api/public/pictures/${ids}` });
            const response = await PicturesAPI.getPicture(ids);
            if (response.status === 200 || response.status === 201) {
                if (response.body.isOwned) {
                    const responseDetails = await PicturesAPI.getPictureDetails(ids);
                    if (responseDetails.status === 200 || responseDetails.status === 201) {
                        setDataActualItem(responseDetails.body);
                    } else {
                        handleError(responseDetails);
                    }
                } else {
                    setDataActualItem(response.body);
                }
                const responseRepartition = await PicturesAPI.getRepartition(ids);
                if (response.status === 200 || response.status === 201) {
                    setRepartition(responseRepartition.body);
                    setLoading(false);
                } else {
                    handleError(responseRepartition);
                }
            } else {
                handleError(response);
            }
        }
    };

    const myCallBackFunction = (swiper: any) => {
        setLoading(true);
        const targetIndex = swiper.realIndex;
        const targetItem = pictures[targetIndex];
    
        if (!targetItem) {
            return;
        }
    
        setActualId(targetItem.id);
        if (targetIndex >= pictures.length - 5 && targetIndex < pictures.length + 1 && callNextPictures) callNextPictures();
        fetchData(true, targetItem.id);
    };    

    const enterFullScreen = () => {
        if (document.documentElement.requestFullscreen) document.documentElement.requestFullscreen();
        setIsFullScreen(true);
    };

    const exitFullScreen = () => {
        if (document.exitFullscreen) document.exitFullscreen();
        setIsFullScreen(false);
    };

    const onSaveEdit = () => {
        fetchData(true);
    };

    useEffect(() => {
        const handleKeyUp = (event: KeyboardEvent) => {
            if (event.key === "Escape" && isFullScreen) {
                exitFullScreen();
            }
        };
        document.addEventListener("keyup", handleKeyUp);
        const handleFullscreenChange = () => {
            setIsFullScreen(!!document.fullscreenElement);
        };
        document.addEventListener("fullscreenchange", handleFullscreenChange);
        document.addEventListener("mozfullscreenchange", handleFullscreenChange);
        document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
        document.addEventListener("msfullscreenchange", handleFullscreenChange);
        return () => {
            document.removeEventListener("keyup", handleKeyUp);
            document.removeEventListener("fullscreenchange", handleFullscreenChange);
            document.removeEventListener("mozfullscreenchange", handleFullscreenChange);
            document.removeEventListener("webkitfullscreenchange", handleFullscreenChange);
            document.removeEventListener("msfullscreenchange", handleFullscreenChange);
        };
    }, [isFullScreen]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'ArrowTop' || event.key === 'ArrowUp') {
              setOpen(false);
            }
        };
    
        window.addEventListener('keydown', handleKeyDown);
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, []);


    const handleInteraction = (mouse: boolean) => () => {
        setHover(true);
        // if (mouse && window.innerWidth <= 768) return;
        // if (mouse) return;
        if (idleTimeout.current) {
            clearTimeout(idleTimeout.current);
        }
        idleTimeout.current = setTimeout(() => {
            setHover(false);
        }, 2500);
    };

    const handleMouseMove = handleInteraction(true);
    const handleTouch = handleInteraction(false);

    useEffect(() => {
        window.addEventListener("touchstart", handleTouch);
        window.addEventListener("mousemove", handleMouseMove);

        return () => {
            window.removeEventListener("touchstart", handleTouch);
            window.removeEventListener("mousemove", handleMouseMove);
            if (idleTimeout.current) {
                clearTimeout(idleTimeout.current);
            }
        };
    }, []);

    const handleRemoveNsfw = () => {
        const myNsfw = picturesNsfwReducer.pictures;
        myNsfw.push(dataActualItem.id);
        // dispatch(updatePicturesNsfw(nsfw));
    }

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
    };
    

    return (
        <ModalWrapper open={open} setOpen={setOpen} padding={false} persistant={editData !== undefined}>
            <Dialog.Panel className="transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all min-h-screen max-h-screen h-screen overflow-y-auto max-w-screen max-w-screen w-screen">
                <button
                    className="relative w-screen bg-gray-950 flex flex-col justify-center items-center"
                    style={{ height: isFullScreen ? "100vh" : window.innerWidth > 768 ? "calc(100dvh - 96px)" : "calc(100dvh - 130px)" }}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    <div
                        className={`absolute z-50 top-3 min-[1550px]:top-5 left-3 min-[1550px]:left-5 ${hover ? "transition-all opacity-100" : "transition-all opacity-0 "
                            } flex duration-300 transition-all flex-row justify-between cursor-default`}
                    >
                        <CustomFontAwesomeIcon
                            icon="x"
                            isFAT={true}
                            className="text-white z-50 text-2xl cursor-pointer p-3"
                            onClick={() => {
                                if (isFullScreen) exitFullScreen();
                                else setOpen(false);
                            }}
                        />
                    </div>
                    <div
                        className={`absolute z-50 top-3 min-[1550px]:top-5 right-3 min-[1550px]:right-5 ${hover ? "transition-all opacity-100" : "transition-all opacity-0 "
                            } flex duration-300 transition-all flex-row justify-between cursor-default`}
                    >
                        <CustomFontAwesomeIcon
                            icon="expand"
                            isFAT={true}
                            className="text-white text-2xl cursor-pointer p-3"
                            onClick={() => (isFullScreen ? exitFullScreen() : enterFullScreen())}
                        />
                    </div>
                    <div 
                        className="w-screen flex gap-0 items-center justify-center cursor-default relative" 
                        style={{ height: isFullScreen ? "100vh" : window.innerWidth > 768 ? "calc(100dvh - 100px)" : "calc(100dvh - 166px)" }}
                    >
                        {/* <div className={`swiper-button-prev-custom z-50 ${hover ? "transition-all opacity-100" : "transition-all opacity-0 "}`}>
                            <CustomFontAwesomeIcon icon="chevron-left" isFAT className="text-lg" />
                        </div>
                        <div className={`swiper-button-next-custom z-50 ${hover ? "transition-all opacity-100" : "transition-all opacity-0 "}`}>
                            <CustomFontAwesomeIcon icon="chevron-right" isFAT className="text-lg" />
                        </div> */}
                        <Swiper
                            ref={swiperRef}
                            modules={[Navigation, Scrollbar, A11y, Keyboard]}
                            spaceBetween={50}
                            slidesPerView={1}
                            className="relative"
                            
                            initialSlide={currentPicture}
                            // navigation={{
                            //     prevEl: '.swiper-button-prev-custom',
                            //     nextEl: '.swiper-button-next-custom',
                            // }}
                            navigation={hover}
                            onSwiper={(swiper) => myCallBackFunction(swiper)}
                            onSlideChange={(swiper) => myCallBackFunction(swiper)}
                            keyboard={{ enabled: true }}
                        >
                            {pictures.map((picture: any) => {
                                console.log(picture)
                                return (
                                    <SwiperSlide key={picture.id} onClick={() => (general?.me && general?.me?.enableNSFWFilter || general?.me?.enableNSFWFilter === undefined) ? picture.nsfw && !picturesNsfwReducer?.pictures.includes(dataActualItem.id) && removeNsfw(handleRemoveNsfw, t) : null} >
                                        <div
                                            key={picture.id}
                                            className={`w-screen flex items-center justify-center`}
                                        >
                                            <div className={`w-screen ${isFullScreen && "h-[100vh]"} flex items-center justify-center`}>
                                                <div
                                                    className="relative"
                                                    style={{
                                                        width: isFullScreen ? "100vw" : innerWidth < 700 ? "90vw" : "80vw",
                                                        height: isFullScreen 
                                                                        ? "100vh" 
                                                                        : innerWidth < 700 
                                                                            ? innerHeight < 500 
                                                                                ? innerHeight < 350 
                                                                                    ? innerHeight < 250 
                                                                                        ? "40vh"
                                                                                        : "50vh" 
                                                                                    : "40vh" 
                                                                                : "55vh"
                                                                            : innerHeight < 700
                                                                                ? innerHeight < 500 
                                                                                    ? innerHeight < 350 
                                                                                        ? innerHeight < 250 
                                                                                            ? "20vh"
                                                                                            : "30vh" 
                                                                                        : "45vh" 
                                                                                    : "60vh"
                                                                                : "75vh",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center"
                                                    }}
                                                    onMouseEnter={() => setHoverActive(true)}
                                                    onMouseLeave={() => setHoverActive(false)}
                                                >
                                                    <img 
                                                        src={picture.id !== dataActualItem.id ? picture.minPath : dataActualItem.publicPath}
                                                        style={{
                                                            maxWidth: "100%",
                                                            maxHeight: "100%",
                                                            objectFit: "contain",
                                                            height: picture.publicHeight ?? picture.height,
                                                            width: picture.publicWidth ?? picture.width,
                                                            pointerEvents: "none",
                                                        }}
                                                        onContextMenu={handleContextMenu}
                                                    />
                                                    {(general?.me && general?.me?.enableNSFWFilter || general?.me?.enableNSFWFilter === undefined) 
                                                        ? picture.nsfw && !picturesNsfwReducer.pictures?.includes(actualId)
                                                            ? (
                                                                <button className={`absolute top-0 left-0 w-full h-full opacity-100 transition-all`} onClick={() => removeNsfw(handleRemoveNsfw, t) }>
                                                                    <div
                                                                        className={`${isHoverActive ? "opacity-100" : "opacity-100 md:opacity-0"
                                                                            } absolute bottom-0 z-10 h-full w-full flex flex-col gap-2 justify-center items-center transition-all`}
                                                                    >
                                                                        <CustomFontAwesomeIcon icon="eye-slash" className="text-white text-4xl" />
                                                                        <Typography className="text-white text-2xl">{t("nsfw")}</Typography>
                                                                    </div>
                                                                    <div className="absolute bottom-0 w-full h-full rounded-lg bg-gradient-to-b backdrop-blur-lg"></div>
                                                                </button>
                                                            ) : (
                                                                <></>
                                                            )
                                                        : <></>
                                                    }
                                                </div>
                                                {!loading && !dataActualItem.watermarkUptoDate && (
                                                    <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-fit px-4 py-2 bg-orange-500/50 text-white text-xs sm:text-sm md:text-base font-bold">
                                                        {t("creating_watermark")}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                    {isFullScreen && (
                        <div className="absolute bottom-6 min-[1550px]:bottom-8 left-6 min-[1550px]:left-8 bg-white rounded-xl z-10">
                            <CardUserWithData data={dataActualItem?.portfolio} isVerified={dataActualItem?.portfolio?.verified ?? false} />
                        </div>
                    )}
                    {dataActualItem?.title && !isFullScreen && (
                        <p className={`hidden md:flex absolute bottom-6 min-[1550px]:bottom-8 left-6 min-[1550px]:left-8 text-xs sm:text-sm font-bold text-white text-left whitespace-nowrap overflow-hidden text-ellipsis pr-1 ${isFullScreen ? "w-[50vw]" : "w-[60vw] lg:w-[70vw] xl:w-[80vw]"}`}>
                            {dataActualItem.title}
                        </p>
                    )}
                    <div className={`absolute min-[1550px]:bottom-8 right-6 min-[1550px]:right-8 flex flex-col justify-end items-end w-screen z-10 ${(!isFullScreen && dataActualItem.validationStatus === "pending" && dataActualItem.isOwned) || (!isFullScreen && dataActualItem.validationStatus === "rejected" && dataActualItem.isOwned) ? "bottom-14 md:bottom-6" : "bottom-6"}`}>
                        {dataActualItem?.title && (
                            <>
                                <p className={`flex md:hidden justify-end text-xs sm:text-sm font-bold text-white text-right whitespace-nowrap overflow-hidden text-ellipsis ${isFullScreen ? "w-[80vw]" : "w-[90vw]"}`}>{dataActualItem.title}</p>
                                {isFullScreen && (
                                    <p className={`hidden md:flex text-xs sm:text-sm font-bold text-white text-right justify-end whitespace-nowrap overflow-hidden text-ellipsis pr-1 w-[80vw]`}>
                                        {dataActualItem.title}
                                    </p>
                                )}
                            </>
                        )}
                        {dataActualItem?.license && (
                            <div className="flex flex-row justify-end items-center w-full">
                                <CustomFontAwesomeIcon icon="copyright" className="text-xs sm:text-sm text-white mr-2" />
                                <p onClick={() => dataActualItem.license === "own-license" && handleContractIt(dataActualItem, t)} className="text-xs sm:text-sm font-bold text-white text-right">{t(dataActualItem.license)}</p>
                            </div>
                        )}
                    </div>
                    {(!isFullScreen && dataActualItem.validationStatus === "pending" && dataActualItem.isOwned) ? (
                        <div className="absolute bottom-4 left-4 min-[1550px]:bottom-6 min-[1550px]:left-6 z-10">
                            <Tooltip 
                                placement="bottom" 
                                className="border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10 z-40"
                                content={
                                    <div className="max-w-[320px]">
                                        <Typography
                                            variant="small"
                                            className="font-normal opacity-80 text-black"
                                        >
                                            {t("waiting_validation_d")}
                                        </Typography>
                                    </div>
                                }
                            >   
                                <div className="flex gap-1 bg-white px-2 py-1 rounded-full  items-center">
                                    <CustomFontAwesomeIcon icon="clock" className="text-orange-500 -mt-0.5 hover:opacity-70 transition-all rounded-full" />
                                    {t("waiting_validation")}
                                </div>
                            </Tooltip>
                        </div>
                    ) : <></>}
                    {(!isFullScreen && dataActualItem.validationStatus === "rejected" && dataActualItem.isOwned) ? (
                        <div className={`absolute bottom-4 left-4 min-[1550px]:bottom-6 min-[1550px]:left-6 z-10`}>
                            <Tooltip
                                placement="bottom" 
                                className="border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10 z-40"
                                content={
                                    <div className="max-w-[320px]">
                                        <Typography
                                            variant="small"
                                            className="font-normal opacity-80 text-black"
                                        >
                                            {t("reused_reasons")}
                                            <ul className="list-disc pl-4">
                                                {dataActualItem?.validationRequiredActions && 
                                                    dataActualItem?.validationRequiredActions.length > 0 && 
                                                    dataActualItem?.validationRequiredActions.map((action: string, index: number) => (
                                                        <li key={index}>{t("refused." + action)}</li>
                                                    ))
                                                }
                                            </ul>
                                        </Typography>
                                    </div>
                            }>
                                <div className="flex gap-1 bg-white px-2 py-1 rounded-full items-center">
                                    <CustomFontAwesomeIcon icon="circle-xmark" className="text-indian-500 -mt-0.5 hover:opacity-70 transition-all" />
                                    {t("refused_validation")}
                                </div>
                            </Tooltip>
                        </div>
                    ) : <></>}
                </button>
                {!isFullScreen && (
                    <PictureModalWithoutFullScreen
                        dataActualItem={dataActualItem}
                        repartition={repartition}
                        handleChangeFav={handleChangeFav}
                        actualId={actualId}
                        handleBuyIt={handleBuyIt}
                        handleDelete={handleDelete}
                        openReportModal={openReportModal}
                        loading={loading}
                        onSaveEdit={onSaveEdit}
                        setOpen={setOpen}
                    />
                )}
            </Dialog.Panel>
        </ModalWrapper >
    );
}
