import { useTranslation } from "react-i18next";

// import LogoEzoom from "../../../assets/images/logos/SVG/logo.svg";
// import LogoEzoomBeta from "../../../assets/images/logos/ezoombeta.png"
import CustomFontAwesomeIcon from "../../../components/CustomFontAwesomeIcon";
import RenderLogo from "../../../components/RenderLogo";

export default function HomeSection() {
    const { t } = useTranslation();
    return (
        <div className="m-0 relative bg-white">
            <div className="max-w-7xl mx-auto">
                {/* <LogoEzoom className="mx-auto w-2/3 py-8 md:w-1/3" /> */}
                {/* <img src={LogoEzoomBeta} alt="logo" className="mx-auto w-2/3 py-8 md:w-1/3" /> */}
                <RenderLogo 
                    className="mx-auto w-2/3 py-8 md:w-1/3"
                    classNameBeta="mx-auto w-2/3 py-8 md:w-1/3"
                />
                <p className="mt-4 px-2 md:px-0 text-center text-2xl md:text-4xl w-full md:w-2/3 mx-auto">{t("discoverEzoom")}</p>
                <div className="flex flex-wrap px-0 max-w-7xl mx-auto w-full md:w-[90%]">
                    <div className="flex flex-row w-full md:w-1/2 px-4 mt-12 justify-start items-start gap-4 md:mb-12 md:mt-16 pr-20">
                        <CustomFontAwesomeIcon icon="images" className="text-left md:text-center text-2xl md:text-4xl text-purple-500 mt-1" />
                        <h4 className="text-left text-lg font-bold">{t("publishPictures")}</h4>
                    </div>

                    <div className="flex flex-row w-full md:w-1/2 px-4 mt-8 md:mt-12 justify-start items-start gap-4 md:mb-12 md:mt-16 md:pl-20">
                        <CustomFontAwesomeIcon icon="coins" className="text-left md:text-center text-2xl md:text-4xl text-purple-500 mt-1" />
                        <h4 className="text-left text-lg font-bold">{t("sellServices")}</h4>
                    </div>

                    <div className="flex flex-row w-full md:w-1/2 px-4 mt-8 md:mt-4 justify-start items-start gap-4 md:mb-12 pr-20">
                        <CustomFontAwesomeIcon icon="eye" className="text-left md:text-center text-2xl md:text-4xl text-purple-500 mt-1" />
                        <h4 className="text-left text-lg font-bold">{t("createPortfolio")}</h4>
                    </div>

                    <div className="flex flex-row w-full md:w-1/2 px-4 mt-8 md:mt-4 justify-start items-start gap-4 md:mb-12 md:pl-20">
                        <CustomFontAwesomeIcon icon="magnifying-glass" className="text-left md:text-center text-2xl md:text-4xl text-purple-500 mt-1" />
                        <h4 className="text-left text-lg font-bold">{t("discoverTalents")}</h4>
                    </div>
                </div>
            </div>
        </div>
    );
}
