import { Avatar, Chip, Input } from "@material-tailwind/react";
import React, { ChangeEventHandler, useEffect } from "react";
import { useTranslation } from "react-i18next";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import DefaultProfilePicture from "../../assets/images/elements/default-profile.png";
import { RootState } from "../../services/redux/store";
import CustomFontAwesomeIcon from "../CustomFontAwesomeIcon";
import InputCountry from "../Form/Input/InputCountry";
import InputPlace from "../Form/Input/InputPlace";

export default function CardUserForm({
    disabled = false,
    myId,
    data,
    handleChangeData,
    handleChangeDataPhone,
    showChip = true,
}: Readonly<{
    disabled?: boolean;
    myId: string;
    data: {
        company?: string;
        firstname: string;
        lastname: string;
        country?: string;
        place?: string;
        phone?: string;
        email?: string;
        plan?: string;
    };
    handleChangeData: ChangeEventHandler<HTMLInputElement>;
    handleChangeDataPhone: (value: string) => void;
    showChip?: boolean;
}>) {
    const { t } = useTranslation();
    const [uuid, setUuid] = React.useState<string>("");
    const general = useSelector((state: RootState) => state.general);

    useEffect(() => {
        setUuid(uuidv4());
    }, []);

    const returnColor = () => {
        if (general?.me?.plan === "PRO") return "bg-black text-white";
        else if (general?.me?.plan === "PREMIUM") return " bg-white text-black border border-black";
        else return;
    };

    const returnValue = () => {
        if (general?.me?.plan === "PRO") return t("pro");
        else if (general?.me?.plan === "PREMIUM") return t("premium");
        else return;
    };

    const returnIcon = () => {
        if (general?.me?.plan === "PRO") return <CustomFontAwesomeIcon icon="crown" className="h-2.5 mx-0.5" />;
        else if (general?.me?.plan === "PREMIUM") return <CustomFontAwesomeIcon icon="crown" className="h-2.5 mx-0.5" />;
        else return;
    };

    if (!myId || myId === "" || myId === undefined) {
        return (
            <div className="flex flex-col gap-2 rounded-lg w-full">
                <div className="flex flex-col gap-4 mb-4 px-4 py-4 rounded-lg bg-gray-100 w-full">
                    <div className="w-full">
                        <Input
                            type="text"
                            color="purple"
                            label={t("society")}
                            name="company"
                            value={data.company}
                            onChange={handleChangeData}
                            className="bg-white"
                            disabled={disabled}
                        />
                    </div>
                    <div className="w-full flex flex-col md:flex-row gap-3">
                        <Input
                            type="text"
                            color="purple"
                            label={t("firstname")}
                            name="firstname"
                            value={data.firstname}
                            onChange={handleChangeData}
                            className="bg-white"
                            required
                            disabled={disabled}
                        />
                        <Input
                            type="text"
                            color="purple"
                            label={t("name")}
                            name="lastname"
                            value={data.lastname}
                            onChange={handleChangeData}
                            className="bg-white"
                            required
                            disabled={disabled}
                        />
                    </div>
                    <div className="w-full flex flex-col md:flex-row gap-3">
                        <div className="w-full md:w-1/3">
                            <InputCountry
                                onSelect={(place) => handleChangeData({ target: { name: "country", value: place } } as any)}
                                disabled={disabled}
                                title="country"
                                value={data.country ?? "CHE"}
                            />
                        </div>
                        <div className="w-full md:w-2/3">
                            <InputPlace
                                onSelect={(place) => handleChangeData({ target: { name: "place", value: place } } as any)}
                                uuid={uuid}
                                disabled={disabled}
                                title="adress"
                            />
                        </div>
                    </div>
                    <div className="w-full flex flex-col md:flex-row gap-3">
                        <IntlTelInput
                            inputClassName="z-50 peer w-full h-full bg-white text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 text-sm px-3 py-2.5 focus:py-2 rounded-[7px] border-blue-gray-200 focus:border-deep-purple-500"
                            defaultCountry="ch"
                            onPhoneNumberChange={(_status, _value, _countryData, number, _id) => handleChangeDataPhone(number)}
                            disabled={disabled}
                            value={data.phone}
                        />
                        <Input
                            type="text"
                            color="purple"
                            label={t("mail")}
                            name="email"
                            value={data.email}
                            onChange={handleChangeData}
                            className="bg-white"
                            required
                            disabled={disabled}
                        />
                    </div>
                </div>
                <div className="text-center uppercase flex mx-auto w-3/4 items-center">
                    <div className="flex-1 border-b border-gray-400 mr-1/4"></div>
                    <span className="text-gray-400 mx-2">{t("or")}</span>
                    <div className="flex-1 border-b border-gray-400 ml-1/4"></div>
                </div>
                <div className="flex justify-center gap-4">
                    <div className="flex flex-row items-center justify-between text-center gap-2 my-2">
                        <Link
                            className={`w-1/2 border-2 border-transparent bg-gray-200/30 hover:bg-white py-1 px-4 rounded-full text-black hover:shadow-lg transition-all text-center whitespace-nowrap cursor-pointer backdrop-blur-sm flex justify-center`}
                            to="/signin"
                        >
                            <span className="flex">{t("signin")}</span>
                        </Link>
                        <Link
                            to="/signup"
                            className="w-1/2 border-2 border-transparent bg-orange-500 hover:bg-orange-200 py-1 px-4 rounded-full text-white hover:text-black transition-all text-center whitespace-nowrap cursor-pointer flex justify-center"
                        >
                            <span className="flex">{t("signup")}</span>
                        </Link>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="mb-4 mx-auto w-full min-w-fit">
                <div className="flex md:hidden flex-row items-center gap-2 mt-4 px-2 w-auto rounded-xl py-2">
                    <Avatar size="sm" src={general?.me?.portfolio?.picture ?? DefaultProfilePicture} />
                    <div className="flex flex-col">
                        <p className="text-xs font-bold flex flex-row gap-2">
                            <span>{general?.me?.lastname ?? "NOM"} {general?.me?.firstname ?? "PRENOM"}</span>
                            {general?.me?.verified && (<CustomFontAwesomeIcon icon="check-circle" className="text-gray-500" />)}
                        </p>
                        {showChip && general?.me?.plan !== "USER" && general?.me?.plan !== "PHOTOGRAPHER" && (
                            <Chip
                                className={`w-fit h-fit ${returnColor()} p-1 text-[10px] gap-1`}
                                variant="ghost"
                                value={returnValue()}
                                icon={returnIcon()}
                                size="sm"
                            />
                        )}
                    </div>
                </div>
                <div className="hidden md:flex flex-row items-center gap-4 px-4 w-auto bg-gray-200 rounded-xl py-2">
                    <Avatar src={general?.me?.portfolio?.picture ?? DefaultProfilePicture} />
                    <div className="flex flex-col">
                        <p className="text-lg font-bold flex flex-row gap-2 items-center">
                            <span>{general?.me?.lastname ?? "NOM"} {general?.me?.firstname ?? "PRENOM"}</span>
                            {general?.me?.verified && (<CustomFontAwesomeIcon icon="check-circle" className="text-gray-500" />)}
                        </p>
                        {showChip && general?.me?.plan !== "USER" && general?.me?.plan !== "PHOTOGRAPHER" && (
                            <Chip
                                className={`w-fit h-fit ${returnColor()} p-1 text-[10px] gap-1`}
                                variant="ghost"
                                value={returnValue()}
                                icon={returnIcon()}
                                size="sm"
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
