import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Carousel, Chip, IconButton } from "@material-tailwind/react";
import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { PortfolioIncomplete, PortfolioSmallType } from "../../Interfaces/PortfolioType";
import PortfolioAPI from "../../../services/API/Clients/PortfolioAPI";
import JsonIconDico from "../../../components/Interfaces/IconDico.json";
import DefaultPictureEzoom from "../../../assets/images/elements/default-profile.png";
import DefaultBannerEzoom from "../../../assets/images/elements/default-banner.png";
import { Link } from "react-router-dom";
import { Storage } from "../../../services/storage";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { handleError } from "../../../services/Errors/handleErrors";
import ModalSignInUp from "../../Modals/ModalSignInUp";
import CustomFontAwesomeIcon from "../../CustomFontAwesomeIcon";

export default function Photographer({ data, isForSwiper = false, withSlider = true }: Readonly<{ data:  PortfolioSmallType, isForSwiper?: boolean, withSlider?: boolean }>) {
    const { t } = useTranslation();
    const [openInvitation, setOpenInvitation] = React.useState(false);
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [hoverActive, setHoverActive] = React.useState(false);
    const sliderRef = useRef<any>(null);
    const [dataDetails, setDataDetails] = React.useState<PortfolioIncomplete>({
        id: "",
        name: "",
        description: "",
        published: false,
        regions: [],
        socialNetworks: [],
        setup: [],
        locales: [],
        photoStyles: [],
        picture: "",
        banner: {
            publicPath: "",
            isFavorited: false,
        },
        owner: {
            id: "",
        },
        bannerOffset: 0,
        subscriberCount: 0,
        plan: "",
        canAddTestimonial: "",
        canContact: "",
        isSubscribed: false,
        verified: false,
    });
    const [services, setServices] = React.useState<any[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await PortfolioAPI.portfolio(data.id);
            if (response.status === 200) {
                setDataDetails(response.body);
            } else {
                handleError(response);
            }
            const responseServices = await PortfolioAPI.getServices(data.id);
            if (responseServices.status === 200) {
                setServices(responseServices.body.data);
            } else {
                handleError(responseServices);
            }
        };
        fetchData();
    }, [data.id]);

    const follow = async () => {
        const myId = Storage.getId();
        
        if (!myId) {
            setOpenInvitation(true)
            return;
        }
        
        const datas = {
            owner: `/api/users/${myId}`,
            portfolio: `/api/public/portfolios/${data.id}`   
        }
        const response = await PortfolioAPI.follow(datas);
        if (response.status === 200 || response.status === 201 || response.status === 204) {
            setDataDetails({ ...dataDetails, isSubscribed: true });
        } else {
            handleError(response);
        }
    };

    const unFollow = async () => {
        const myId = Storage.getId();
        
        if (!myId) {
            setOpenInvitation(true)
            return;
        }

        const datas = {
            owner: `/api/users/${myId}`,
            portfolio: `/api/public/portfolios/${data.id}`   
        }
        const response = await PortfolioAPI.unfollow(datas);
        if (response.status === 200 || response.status === 204) {
            setDataDetails({ ...dataDetails, isSubscribed: false });
        } else {
            handleError(response);
        }
    };

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);
    
      const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);

    useEffect(() => {
    }, [data?.lastPictures])

    const iconValue = (enumValue: string) => {
        return JsonIconDico[enumValue as keyof typeof JsonIconDico] as IconProp;
    }

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
    };

    return (
        <>
            <ModalSignInUp open={openInvitation} setOpen={setOpenInvitation} />
            <div className={`flex w-full ${!isForSwiper ? "md:w-[45vw] xl:w-[30vw] max-w-[550px] min-w-[400px]" : "w-full"} flex-wrap py-4`}>
                <button
                    onMouseEnter={() => setHoverActive(true)}
                    onMouseLeave={() => setHoverActive(false)}
                    className={`relative w-full block rounded-2xl bg-white ${withSlider ? "shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] hover:shadow-[0_15px_25px_-2px_rgba(0,0,0,0.20)]" : "shadow-sm"} transition-all`}
                >
                    <div className="relative w-full overflow-hidden bg-cover bg-no-repeat" data-te-ripple-init data-te-ripple-color="light">
                        {!withSlider ? (
                            <Carousel
                                className="rounded-t-2xl overflow-hidden"
                                prevArrow={({ handlePrev }) => (
                                    <IconButton
                                        variant="text"
                                        color="white"
                                        size="lg"
                                        onClick={(e) => {
                                            handlePrev();
                                            setActiveIndex(activeIndex - 1);
                                        }}
                                        className={`${
                                            activeIndex === 0 ? "hidden" : hoverActive ? "flex" : "hidden"
                                        } !absolute top-2/4 left-4 -translate-y-2/4 hover:rounded-full z-40`}
                                    >
                                        <CustomFontAwesomeIcon icon="chevron-left" className="h-6 w-6" />
                                    </IconButton>
                                )}
                                nextArrow={({ handleNext }) => (
                                    <IconButton
                                        variant="text"
                                        color="white"
                                        size="lg"
                                        onClick={(e) => {
                                            handleNext();
                                            setActiveIndex(activeIndex + 1);
                                        }}
                                        className={`${
                                            activeIndex === data.lastPictures.length - 1 ? "hidden" : hoverActive ? "flex" : "hidden"
                                        } !absolute top-2/4 !right-4 -translate-y-2/4 hover:rounded-full z-40`}
                                    >
                                        <CustomFontAwesomeIcon icon="chevron-right" className="h-6 w-6" />
                                    </IconButton>
                                )}
                                navigation={({ setActiveIndex, activeIndex }) => (
                                    <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                                        {data.lastPictures.length > 0 && data.lastPictures.map((picture, i) => (
                                            <button
                                                key={picture.picture.id}
                                                className={`block h-2 cursor-pointer rounded-full transition-all content-[''] ${
                                                    activeIndex === i ? "w-2 bg-white/60" : "w-2 bg-black/20"
                                                }`}
                                                onClick={() => setActiveIndex(i)}
                                            ></button>
                                        ))}
                                    </div>
                                )}
                            >
                                {data.lastPictures.length > 0 ? data.lastPictures.map((picture) => {
                                    return (
                                        <Link 
                                            key={picture.picture.id + picture?.service?.id}
                                            to={`${data?.publicLink !== undefined && data?.publicLink.startsWith("/portfolio") ? "" : "/"}${data?.publicLink !== undefined && data?.publicLink}${(picture.service !== undefined && picture.service.id !== undefined && picture.service.id !== "") ? `/performance/${picture?.service?.id}` : "/photos"}`}
                                        >
                                            <div className="relative w-full h-80">
                                                <img
                                                    onContextMenu={handleContextMenu}
                                                    src={picture.picture.minPath ?? DefaultBannerEzoom}
                                                    alt={`${picture.picture.id}`}
                                                    className={`object-cover w-screen rounded-t-2xl h-80`}
                                                />
                                                <div className="absolute left-2 top-2 z-10 flex flex-wrap gap-2 w-full h-full">
                                                    {picture.service && picture.service.type.enumValue &&  (
                                                        <div className={`w-fit h-fit z-10 text-[10px] uppercase py-1.5 px-3 bg-gray-500 hover:bg-gray-400 transition-all rounded-lg text-white flex items-center`}>
                                                            <CustomFontAwesomeIcon icon={iconValue(picture.service.type.enumValue) as any} className="mr-2" />
                                                            {picture?.service?.type?.frLabel}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                }): (
                                    <Link to={`${data?.publicLink !== undefined && data?.publicLink.startsWith("/portfolio") ? "" : "/"}${data?.publicLink !== undefined && data?.publicLink}/photos`}>
                                        <img
                                            onContextMenu={handleContextMenu}
                                            src={DefaultBannerEzoom}
                                            alt={`${dataDetails.name}`}
                                            className={`object-cover w-screen rounded-t-2xl md:w-1/2 h-80`}
                                        />
                                    </Link>
                                )}
                            </Carousel> 
                        ) : (
                            <Swiper
                                ref={sliderRef}
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                spaceBetween={0}
                                slidesPerView={1}
                                navigation={{
                                    nextEl: '.swiper-button-next-2',
                                    prevEl: '.swiper-button-prev-2',
                                }}
                                pagination={{ clickable: true, currentClass: "bg-white" }}
                                className="relative"
                                onMouseEnter={() => setHoverActive(true)}
                                onMouseLeave={() => setHoverActive(false)}
                                onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
                            >
                                {data.lastPictures.length > 0 ? data.lastPictures.map((picture, index) => (
                                    <SwiperSlide key={picture.picture.id} className="w-full">
                                        <Link 
                                            key={picture.picture.id + picture?.service?.id}
                                            to={`${data?.publicLink !== undefined && data?.publicLink.startsWith("/portfolio") ? "" : "/"}${data?.publicLink !== undefined && data?.publicLink}${(picture.service !== undefined && picture.service.id !== undefined && picture.service.id !== "") ? `/performance/${picture?.service?.id}` : "/photos"}`}
                                        >
                                            <img
                                                onContextMenu={handleContextMenu}
                                                src={picture.picture.minPath ?? DefaultBannerEzoom}
                                                alt={`${picture.picture.id}`}
                                                className={`object-cover w-screen rounded-t-2xl h-80`}
                                            />
                                            <div className="absolute left-2 top-2 z-10 flex flex-wrap gap-2 w-full h-full">
                                                {picture.service && picture.service.type.enumValue && (
                                                    <div className={`w-fit h-fit z-10 text-[10px] uppercase py-1.5 px-3 bg-gray-500 hover:bg-gray-400 transition-all rounded-lg text-white flex items-center`}>
                                                        <CustomFontAwesomeIcon icon={iconValue(picture.service.type.enumValue) as any} className="mr-2" />
                                                        {picture?.service?.type?.frLabel}
                                                    </div>
                                                )}
                                            </div>
                                        </Link>
                                    </SwiperSlide>
                                )): (
                                    <SwiperSlide key={`pictureFor${data.id}`} className="w-full">
                                        <Link to={`${data?.publicLink !== undefined && data?.publicLink.startsWith("/portfolio") ? "" : "/"}${data?.publicLink !== undefined && data?.publicLink}/photos`}>
                                            <img
                                                onContextMenu={handleContextMenu}
                                                src={DefaultBannerEzoom}
                                                alt={`${dataDetails.name}`}
                                                className={`object-cover w-screen rounded-t-2xl md:w-full ${!isForSwiper && "xl:w-[30vw]"} h-80`}
                                            />
                                        </Link>
                                    </SwiperSlide>
                                )}
                                {hoverActive && (
                                    <>  
                                        {activeIndex !== 0 && (
                                            <button onClick={handlePrev} className="swiper-button-prev-2"><CustomFontAwesomeIcon icon="chevron-left" /></button>
                                        )}
                                        {activeIndex !== data.lastPictures.length - 1 && data.lastPictures.length > 1 && (
                                            <button onClick={handleNext} className="swiper-button-next-2"><CustomFontAwesomeIcon icon="chevron-right" /></button>
                                        )}
                                    </>
                                )}
                            </Swiper>
                        )}
                    </div>
                    <div className="px-3 pt-1 pb-1.5 relative">
                        <Link to={`${data?.publicLink !== undefined && data?.publicLink.startsWith("/portfolio") ? "" : "/"}${data?.publicLink !== undefined && data?.publicLink}/photos`}>
                            <Avatar color="orange" size="xl" className="absolute -top-12 left-4 shadow z-10 h-24 w-24" src={dataDetails.picture ?? DefaultPictureEzoom} />
                        </Link>
                        <div className="flex flex-col w-full pl-28">
                            <div className="flex flex-nowrap justify-between items-center w-full gap-2">
                                <Link 
                                    to={`${data?.publicLink !== undefined && data?.publicLink.startsWith("/portfolio") ? "" : "/"}${data?.publicLink !== undefined && data?.publicLink}/photos`}
                                    className="flex items-center min-w-0"
                                >
                                    {dataDetails?.verified && <CustomFontAwesomeIcon icon="circle-check" className="lg:hidden text-base text-orange-500" />}
                                    <span className="text-xl font-medium leading-tight text-neutral-800 truncate min-w-0">
                                        {dataDetails.name} 
                                    </span>
                                    {dataDetails?.verified && <CustomFontAwesomeIcon icon="circle-check" className="hidden lg:block text-base text-orange-500" />}
                                </Link>
                                {!isForSwiper && (
                                    <>
                                        {dataDetails.isSubscribed ? (
                                            <button
                                                onClick={() => !Storage.getToken() ? setOpenInvitation(!openInvitation) : unFollow()}
                                                className="flex gap-2 px-2 text-[10px] border border-black rounded-full h-8 items-center justify-center bg-white hover:bg-gray-50 transition-all"
                                            >
                                                <CustomFontAwesomeIcon icon="user-minus" /> {t("unfollow")}
                                            </button>
                                        ) : (
                                            <button
                                                onClick={() => !Storage.getToken() ? setOpenInvitation(!openInvitation) : follow()}
                                                className="flex gap-2 px-2 text-[10px] border border-black rounded-full h-8 items-center justify-center bg-white hover:bg-gray-50 transition-all"
                                            >
                                                <CustomFontAwesomeIcon icon="user-plus" /> {t("follow")}
                                            </button>
                                        )}
                                    </>
                                )}
                            </div>
                            
                            {dataDetails.plan === "PREMIUM" ? (
                                <Link to={`${data?.publicLink !== undefined && data?.publicLink?.startsWith("/portfolio") ? "" : "/"}${data?.publicLink !== undefined && data?.publicLink}/photos`}>
                                    <Chip
                                        className="w-fit h-fit bg-white border border-black text-black"
                                        variant="ghost"
                                        value={t("premium")}
                                        icon={<CustomFontAwesomeIcon icon="crown" className="h-3 ml-0.5" />}
                                        size="sm"
                                    />
                                </Link>
                            ) : dataDetails.plan === "PRO" ? (
                                <Link to={`${data?.publicLink !== undefined && data?.publicLink.startsWith("/portfolio") ? "" : "/"}${data?.publicLink !== undefined && data?.publicLink}/photos`}>
                                    <Chip
                                        className="w-fit h-fit bg-black text-white"
                                        variant="ghost"
                                        value={t("pro")}
                                        icon={<CustomFontAwesomeIcon icon="crown" className="h-3 ml-0.5" />}
                                        size="sm"
                                    />
                                </Link>
                            ) : (
                                <div className="h-6"></div>
                            )}
                        </div>
                    </div>
                </button>
            </div>
        </>
    );
}
