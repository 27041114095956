export default function TitlePageNoMaxSize({ children, mt = 8, mb = 2 }: Readonly<{ children: React.ReactNode, mt?: number, mb?: number }>) {
    return (
        <div className={`w-fit md:w-full mt-${mt} md:mt-2 mb-${mb} md:mb-2
            bg-white
            px-2 md:px-8
        `}> 
            {/** md:px-8 px-2 */}
            <h1 className="text-base md:text-4xl w-full mx-auto whitespace-nowrap">{children}</h1>
        </div>
    );
}
