import { useTranslation } from "react-i18next";
import PopularWaves from "../../../../assets/images/home/waves/popular.svg";

import GradientButtonPurple from "../../../../components/Form/Button/GradientButtonPurple";
import GradientButton from "../../../../components/Form/Button/GradientButton";
import { Link } from "react-router-dom";
import Background from "../../../../assets/images/home/home_picture.jpg"

export default function JoinFree() {
    const { t } = useTranslation();

    return (
        <div className="m-0 relative bg-white overflow-hidden bg-no-repeat bg-image-popular bg-cover font-sans h-fit pb-5 md:pb-56">
            <PopularWaves className="absolute z-0" />
            <div className="relative z-10 pb-4 max-w-7xl mx-auto">
                <p className="mt-48 2xl:mt-64 mb-8 px-4 md:px-0 text-left md:text-center text-4xl">{t("joinUsFree")}</p>
                <div className="relative z-10 pb-4 max-w-7xl mx-auto flex flex-col md:flex-row px-4">
                    <div className="flex flex-col w-full md:w-1/2 pr-4">
                        <p className="mt-12 px-4 md:px-0 text-left text-2xl">{t("joinUsFree_1")}</p>
                        <p className="px-4 md:px-0 text-left mt-4 mx-auto">{t("joinUsFree_1_long")}</p>
                        <p className="mt-12 px-4 md:px-0 text-left text-2xl">{t("joinUsFree_2")}</p>
                        <p className="px-4 md:px-0 text-left mt-4 mx-auto">{t("joinUsFree_2_long")}</p>
                        <p className="px-4 md:px-0 text-left mt-4 mb-8 flex gap-4">
                            <Link to="/signup">
                                <GradientButton text={t("signup")} />
                            </Link>
                            <Link to="/iam-photographer">
                                <GradientButtonPurple text={t("learnMore")} />
                            </Link>
                        </p>
                    </div>
                    <div className="flex justify-center w-full lg:w-1/2 mt-12 pl-4">
                        <img src={Background} className="h-full object-cover rounded-lg w-full md:max-h-[350px]" alt="forJoinFree" />
                    </div>
                </div>
            </div>
        </div>
    );
}
