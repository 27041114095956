import { useTranslation } from "react-i18next";
import { Avatar, Typography } from "@material-tailwind/react";
import { Storage } from "../../../services/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import CustomFontAwesomeIcon from "../../../components/CustomFontAwesomeIcon";

export default function CardSide() {
    const { t } = useTranslation();

    const query = new URLSearchParams(useLocation().search);
    const pageQuery = query.get('from');

    const items = Storage.getCart();
    const itemsOneShot = Storage.getToBuy()

    const totalOfItems = () => {
        let total = 0;
        if (pageQuery && itemsOneShot && itemsOneShot.id !== undefined) {
            return itemsOneShot.price.toFixed(2);
        }
        if (items && items.length > 0) {
            items.forEach((item) => {
                total += item.price;
            });
        } else
            total = 0.00
        return total.toFixed(2);
    };

    return (
        <div className="w-full flex flex-col gap-4">
            {(items || itemsOneShot) ?
                <>
                    <h1 className="text-2xl">{t("cartsummary")}</h1>
                        <table className="w-full">
                            <tr>
                                <th className="text-left">{t("quantity")}</th>
                                <th className="text-left">{t("articles")}</th>
                                <th className="text-left">{t("picid")}</th>
                                <th className="text-left">{t("pricettc")}</th>
                            </tr>
                            {!pageQuery && items && items.length > 0 ? items.map((item) => (
                                <tr key={item.id}>
                                    <td>1</td>
                                    <td>
                                        <Avatar src={item.minPath} size="sm" variant="square" />
                                    </td>
                                    <td>{item.id}</td>
                                    <td>
                                        {t("chf")} {item.price.toFixed(2)}
                                    </td>
                                </tr>
                            )) : itemsOneShot ? (
                                <tr key={itemsOneShot.id}>
                                    <td>1</td>
                                    <td>
                                        <Avatar src={itemsOneShot.minPath} size="sm" variant="square" />
                                    </td>
                                    <td>{itemsOneShot.id}</td>
                                    <td>
                                        {t("chf")} {itemsOneShot.price.toFixed(2)}
                                    </td>
                                </tr>
                            ) : null}
                        </table>
                    <hr />
                    <div className="w-full flex justify-between">
                        <span className="text-lg">{t("total")}</span>
                        <span className="text-lg">{t("chf")} {totalOfItems()}</span>
                    </div>
                </>
            : (
                <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
                    <CustomFontAwesomeIcon icon="image" className="text-3xl text-gray-500/50 mt-4" />
                    <Typography className="text-base text-gray-500 font-sans mt-4 text-center mb-4">{t("noPicture")}</Typography>
                </div>
            )}
        </div>
    );
}
