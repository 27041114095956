import { Checkbox } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import CardMe from "../../components/Box/CardMe";
import Footer from "../../components/Footer/Footer";
import GradientButton from "../../components/Form/Button/GradientButton";
import Loader from "../../components/Loader";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import OrderAPI from "../../services/API/Clients/OrderAPI";
import { Storage } from "../../services/storage";
import CardSide from "./components/CardSide";

import TitlePage from "../../components/TitlePage";
import WrapperGrayZone from "../../components/WrapperGrayZone";
import { handleError } from "../../services/Errors/handleErrors";

import ApplePayCard from "../../assets/images/logos/paiement/card_apple-pay.svg";
import GooglePayCard from "../../assets/images/logos/paiement/card_google-pay.svg";
import MasterCard from "../../assets/images/logos/paiement/card_mastercard.svg";
import PfPayCard from "../../assets/images/logos/paiement/card_post-finance-pay.svg";
import TwintCard from "../../assets/images/logos/paiement/card_twint.svg";
import VisaCard from "../../assets/images/logos/paiement/card_visa.svg";

export default function CartTransaction() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("settings")}`;
    const navigate = useNavigate()
    const [searchBar, setSearchBar] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [cgvRead, setCgvRead] = React.useState(false);
    const [isVisible, setIsVisible] = useState(true);


    const items = Storage.getCart();
    const itemsOneShot = Storage.getToBuy()

    const query = new URLSearchParams(useLocation().search);
    const pageQuery = query.get('from');


    const proceedToPaiement = async () => {
        let data: { pictures: string[] } = { pictures: []}
        if (pageQuery) {
            if (itemsOneShot && itemsOneShot.id !== undefined)
                data.pictures = [`/api/public/pictures/${itemsOneShot.id}`]
            else
                return;
            const response = await OrderAPI.orders(data);
            if (response.status === 200 || response.status === 201) {
                const dataBis = {
                    pictureOrder: `/api/orders/${response.body.id}`
                }
                if (response?.body?.totalPrice === 0.0) {
                    navigate("/checkout-success/order")
                    return;
                }
                const resp = await OrderAPI.orderTransaction(dataBis);
                if (resp.status === 200 || resp.status === 201) {
                    const link = document.createElement("a");
                    link.href = resp.body.redirectUrl;
                    link.target = "_blank";
                    link.rel = "noopener noreferrer";
                    link.click();
                } else {
                    handleError(resp);
                }
            } else {
                handleError(response);
            }
        } else {
            if (items && items.length > 0 )
                data.pictures = items.map((item) => `/api/public/pictures/${item.id}`)
            else
                return
            const response = await OrderAPI.orders(data);
            if (response.status === 200 || response.status === 201) {
                const dataBis = {
                    pictureOrder: `/api/orders/${response.body.id}`
                }
                if (response?.body?.totalPrice === 0.0) {
                    navigate("/checkout-success/order")
                    return;
                }
                const resp = await OrderAPI.orderTransaction(dataBis);
                if (resp.status === 200 || resp.status === 201) {
                    const link = document.createElement("a");
                    link.href = resp.body.redirectUrl;
                    link.target = "_blank";
                    link.rel = "noopener noreferrer";
                    link.click();
                } else {
                    handleError(resp);
                }
            } else {
                handleError(response);
            }
        }
    }

    useEffect(() => {
        setLoading(false)
    }, [])

    const topPaddingTop = (): string => {
        if (isVisible) {
        if (searchBar)
            return "top-[108px] pt-[7px]";
        else
            return "top-[60px] pt-[7px]";
        } else {
        if (searchBar)
            return "top-[48px] pt-[7px]";
        else
            return "top-[0px] pt-[7px]";
        }
    }

    return (
        <>
            <Loader loading={loading} />
            <div className="min-h-screen bg-gray-50 relative">
                <HeaderForAnimation />
                <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} isVisible={isVisible} setIsVisible={setIsVisible} />
                <div className="flex flex-col h-full bg-white -mt-4  pb-[340px] lg:pb-36 min-h-screen">
                    <div
                        className={`${topPaddingTop()} flex flex-row lg:flex-col items-end justify-between w-full sticky top-0 pb-2 lg:static bg-white z-30 border-b border-gray-200`}
                    >
                        <TitlePage mt={0} mb={0}>
                            {t("myorder")}
                        </TitlePage>
                    </div>
                    <WrapperGrayZone pt="0" px="8">
                        <div className="flex flex-col h-full pb-[390px] lg:pb-40">
                            <div className="flex flex-col lg:flex-row px-2 lg:px-8 w-full h-full max-w-7xl mx-auto gap-6">
                                <div className="pt-4 lg:pt-0 w-full lg:w-1/2 min-h-full flex items-center">
                                    <CardSide />
                                </div>
                                <div className="w-full lg:w-1/2 bg-gray-50 min-h-full flex justify-center items-center">
                                    <div className="flex flex-col gap-4 items-center justify-center">
                                        <h1 className="text-xl mt-8">{t("payementdata")}</h1>
                                        <CardMe />
                                        <Checkbox
                                            color="orange"
                                            checked={cgvRead}
                                            onChange={(e) => setCgvRead(e.target.checked)}
                                            label={<div className="" dangerouslySetInnerHTML={{ __html: t("toProceedBr") }}></div>}
                                            containerProps={{ className: "min-w-[44px]", }}
                                        />
                                        <button className="w-fit mx-auto" onClick={() => proceedToPaiement()}>
                                            <GradientButton text={t("procedPaiement")} disabled={(!items && !itemsOneShot) || !cgvRead} />
                                        </button>
                                        <div className="flex flex-row gap-4 mx-auto text-3xl text-gray-400">
                                            <VisaCard />
                                            <MasterCard />
                                            <PfPayCard />
                                            <TwintCard />
                                            <ApplePayCard />
                                            <GooglePayCard />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WrapperGrayZone>
                </div>
                <Footer />
            </div>
        </>
        // <div className="min-h-screen bg-gray-50 relative">
        //     <Loader loading={loading} />
        //     <HeaderForAnimation />
        //     <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} />
        //     <Footer />
        // </div>
    );
}
