import {
    faCreativeCommons,
    faCreativeCommonsBy,
    faCreativeCommonsNc,
    faCreativeCommonsNd,
    faCreativeCommonsSa,
    faCreativeCommonsZero,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Option, Select } from "@material-tailwind/react";
import React from "react";
import { useTranslation } from "react-i18next";
import ModalChangePlan from "../../../../components/Modals/ModalChangePlan";
import LinkGeneric from "./components/LinkGeneric";
import TitleDescribe from "./components/TitleDescribe";
import CustomFontAwesomeIcon from "../../../../components/CustomFontAwesomeIcon";

export default function Licence({
    optionChoose,
    handleChangeLicence,
    cantChangeIt = false,
}: Readonly<{
    optionChoose: string;
    handleChangeLicence: any;
    cantChangeIt?: boolean;
}>) {
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const { t } = useTranslation();

    const licenses = [
        { value: "all-rights-reserved", label: t("all-rights-reserved") },
        { value: "own-license", label: t("own-license") },
        { value: "cc-by", label: t("cc-by_full"), icons: [faCreativeCommons, faCreativeCommonsBy] },
        { value: "cc-by-sa", label: t("cc-by-sa_full"), icons: [faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsSa] },
        { value: "cc-by-nc", label: t("cc-by-nc_full"), icons: [faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsNc] },
        { value: "cc-by-nc-sa", label: t("cc-by-nc-sa_full"), icons: [faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsNc, faCreativeCommonsSa] },
        { value: "cc-by-nd", label: t("cc-by-nd_full"), icons: [faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsNd] },
        { value: "cc-by-nc-nd", label: t("cc-by-nc-nd_full"), icons: [faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsNc, faCreativeCommonsNd] },
        { value: "cc0", label: t("cc0_full"), icons: [faCreativeCommons, faCreativeCommonsZero] },
    ];

    return (
        <div className=" pb-4 border-b border-gray-300">
            <ModalChangePlan open={openModal} setOpen={setOpenModal} />
            <div className="flex flex-col w-full mt-4">
                <TitleDescribe
                    title="userights_license"
                    describe="license_d"
                />
                <Select
                    // className={`
                    //     bg-white w-full appearance-none 
                    //     border border-gray-300 
                    //     focus:border-orange-500 focus:ring-2 focus:ring-orange-300
                    //     active:border-orange-500 active:ring-2 active:ring-orange-300
                    //     placeholder:text-orange-300 placeholder:opacity-100 
                    //     [&::-webkit-inner-spin-button]:appearance-none 
                    //     [&::-webkit-outer-spin-button]:appearance-none

                    // `}
                    className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-orange-500 bg-white"
                    color="orange"
                    value={optionChoose}
                    onChange={handleChangeLicence}
                    disabled={cantChangeIt}
                    containerProps={{
                        className: "w-full max-h-[100vw]",
                    }}
                    menuProps={{
                        className: "w-full max-h-[100vw]",
                    }}
                    labelProps={{
                        className: "hidden",
                    }}
                    id="licenceSelect"
                >
                    {licenses.map((license) => (
                        <Option key={license.value} value={license.value}>
                            <div className="flex justify-between flex-row">
                                <span className="whitespace-nowrap mr-auto">
                                    {license.label}
                                </span>
                                {license.icons && (
                                    <span className="float-right flex flex-row gap-1 text-lg">
                                        {license.icons.map((icon, index) => (
                                            <FontAwesomeIcon key={index} icon={icon as any} />
                                        ))}
                                    </span>
                                )}
                            </div>
                        </Option>
                    ))}
                </Select>
                <LinkGeneric
                    link="https://fr.wikipedia.org/wiki/Licence_Creative_Commons"
                    title="learnmorecc"
                />
            </div>
        </div>
    );
}
