import { Link } from "react-router-dom";
import moment from "moment";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import ModalSignInUp from "../../Modals/ModalSignInUp";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Storage } from "../../../services/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DefaultBannerEzoom from "../../../assets/images/elements/default-banner.png";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import FavoriteAPI from "../../../services/API/Clients/FavoriteAPI";
import { toast } from "react-toastify";
import { handleError } from "../../../services/Errors/handleErrors";
interface GalleryProps {
    id: string
    title: string
    owner: {
        id: string
        plan: string
        fullname: string
        verified: boolean
    }
    published: boolean
    createdAt: string
    favoriteCount: number
    viewCount: number
    banner: {
        publicPath: string
    }
    bannerOffset: number
    private: boolean
    place: {
        id: string
        label: string
    }
    date: string
    portfolio: {
        id: string
        name: string
        path: string
        picture: string
        plan: string
        publicLink: string
        isSubscribed: boolean
    }
    isFavorited: boolean
    isOwned: boolean
}
const myId = Storage.getId();
export default function GaleryForPhoto({ gallery, onClick }: { gallery: GalleryProps, onClick?: () => void }) {
    const { t } = useTranslation();
    const [openInvitation, setOpenInvitation] = useState(false);
    const [heartColor, setHeartColor] = useState(gallery.isFavorited);
    const [openModal, setOpenModal] = useState(false);
    const [isFav, setIsFav] = useState(false);
    const handleChangeFav = async () => {
        const undo = heartColor;
        setHeartColor(!heartColor);
        
        const datas = {
            gallery: `/api/public/galleries/${gallery.id}`,
            owner: `/api/users/${myId}`
        }
        
        if (isFav === false) {
            const response = await FavoriteAPI.favGallerie(datas);
            if (response.status === 200 || response.status === 201) {
                toast.success(t("addToFavGallerie"))
                setHeartColor(true);
                setIsFav(true);
            } else {
                handleError(response);
                setHeartColor(undo);
            }
        } else {
            const response = await FavoriteAPI.unFavGallerie(datas);
            if (response.status === 200 || response.status === 201 || response.status === 204) {
                toast.success(t("removeFromFavGallerie"))
                setHeartColor(false);
                setIsFav(false);
            } else {
                handleError(response);
                setHeartColor(undo);
            }
        }
    }
    

    return (
        <>
            <ModalSignInUp open={openInvitation} setOpen={setOpenInvitation} />
            <div className={`mx-auto relative w-full md:w-96 bg-white`}>
                {!gallery.isOwned && (
                    <div 
                        className="absolute bottom-24 right-3 flex gap-2 z-10"
                        onClick={() => !Storage.getToken() ? setOpenInvitation(true) : handleChangeFav()}
                    >
                        <FontAwesomeIcon icon={heartColor ? solidHeart as IconProp : regularHeart as IconProp} className={`${heartColor ? "text-indian-500" : "text-white"} text-xl hover:opacity-70 transition-all`} />
                    </div>
                )} 
                <Link className="w-full mb-5 hover:opacity-75 transition-all" to={`/gallery/${gallery.id}`} onClick={onClick}>
                    <div className={`w-full md:w-96 mx-auto rounded overflow-hidden shadow-lg h-full relative`} key={gallery.title}>
                        <div className="absolute bottom-[90px] left-2 z-10 flex flex-col gap-2">
                            {gallery.private && (
                                <div className="bg-orange-500 text-white text-xs font-bold px-2 py-1 rounded-full w-fit">
                                    {t("privateGallery")}
                                </div>
                            )}
                            {!gallery.published && (
                                <div className="bg-orange-500 text-white text-xs font-bold px-2 py-1 rounded-full w-fit">
                                    {t("unpublishedGallery")}
                                </div>
                            )}
                        </div>
                        <div
                            className={`w-full md:w-96 h-48 flex relative bg-cover`}
                            style={{
                                backgroundPositionY: `${100 - (gallery.bannerOffset ?? 0)}%`,
                                backgroundImage: `url(${(gallery.banner && gallery.banner.publicPath) ?? DefaultBannerEzoom})`,
                            }}
                        >
                        </div>
                        <div className={`absolute bottom-20 w-full h-24 md:w-96 bg-gradient-to-b from-white/0 to-black/20 rounded-t-lg`}></div>
                        <div className="px-6 pt-4 pb-4 flex flex-col items-center justify-center h-20">
                            <div className="font-bold text-base text-center truncate w-full">{gallery.title}</div>  
                            <div className="flex gap-2">  
                                {gallery?.place?.id && (
                                    <p className="text-gray-700 text-sm text-center">{gallery?.place?.label}</p>
                                )}
                                {gallery?.place?.id && gallery?.date ? (
                                    <p className="text-gray-700 text-sm text-center">•</p>
                                ) : <></>}
                                {gallery.date && (
                                    <p className="text-gray-700 text-sm text-center">{moment(gallery.date).format("DD.MM.YYYY")}</p>
                                )}
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </>
    )
}