import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { RowsPhotoAlbum } from "react-photo-album";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { A11y, FreeMode, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import GradientButton from "../../../../components/Form/Button/GradientButton";
import { SearchPicturesType } from "../../../../components/Interfaces/PictureType";
import PicturesAPI from "../../../../services/API/Clients/PicturesAPI";
import { handleError } from "../../../../services/Errors/handleErrors";


export default function Discover() {
    const { t } = useTranslation();
    const [pictures, setPictures] = React.useState<SearchPicturesType>({
        pagination: {
            current: 0,
            last: 0,
            totalItems: 0,
            parPage: 0
        },
        data: []
    })
    const [albumBreakpoint, setAlbumBreakpoint] = React.useState<number>(6);
    const albumRef = React.useRef<HTMLDivElement>(null);

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await PicturesAPI.searchPicturesPagination("pagetype=popular&popular=true", 1);
            if (response.status === 200)
                setPictures(response.body);
            else
                handleError(response);
        }
        fetchData();
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (!albumRef.current) return;
            if (albumBreakpoint > 9) return;
            const rowCount = albumRef.current.querySelectorAll(".react-photo-album--track").length;
            if (rowCount === 0 || rowCount > 1) return;
            setAlbumBreakpoint(albumBreakpoint + 1);
        }, 200);
    }, [pictures, albumBreakpoint])

    const albumWidth = Math.max(1, Math.round(pictures.data.slice(0, 10).length / albumBreakpoint)) * 100;


    return (
        <div className="bg-white w-full -mt-1">
            <div className="bg-white relative z-10 pb-4 max-w-7xl mx-auto flex flex-col md:flex-row px-8">
                <div className="flex flex-col w-full md:w-1/2 md:pr-4">
                    <p className="mt-12 text-left text-4xl">{t("home_section2_title")}</p>
                    <p className="text-left mt-4 mx-auto">{t("home_section2_subtitle_p1")}</p>
                    <p className="text-left mt-4 mb-8">
                        <Link to="/popular">
                            <GradientButton text={t("explore")} />
                        </Link>
                    </p>
                </div>
                <div className="flex justify-center w-full md:w-1/2 md:mt-12 md:pl-4">
                    <Swiper
                        modules={[FreeMode, Navigation, Pagination, Scrollbar, A11y]}
                        freeMode={true}
                        slidesPerView={'auto'}
                        navigation
                        pagination={{ clickable: true }}
                        className="w-full"
                    >
                        <SwiperSlide
                            className="pb-8" style={{ width: `${albumWidth}%` }}>
                            <RowsPhotoAlbum
                                ref={albumRef}
                                spacing={10}
                                targetRowHeight={150}
                                photos={pictures.data.slice(0, 10).map((picture) => ({
                                    src: picture.minPath,
                                    width: picture.width,
                                    height: picture.height
                                }))}
                                rowConstraints={{
                                    singleRowMaxHeight: 300,
                                }}
                            />
                        </SwiperSlide>
                    </Swiper>
                </div>
                {/* <div className="flex md:hidden px-2">
                    <Carousel
                        className="rounded-xl h-48"
                        navigation={({ setActiveIndex, activeIndex, length }) => (
                            <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                                {pictures.data.slice(0, 5).map((_, i) => (
                                    <span
                                        key={i}
                                        className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                                            activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
                                        }`}
                                        onClick={() => setActiveIndex(i)}
                                    />
                                ))}
                            </div>
                        )}
                    >
                        {pictures.data.slice(0, 5).map((picture, index) => (
                            <img
                                onContextMenu={handleContextMenu}
                                key={index}
                                src={picture.minPath}
                                alt={picture.id}
                                className="h-full w-full object-cover"
                            />
                        ))}
                    </Carousel>
                </div> */}
            </div>
        </div >
    );
}
