import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Avatar from "./Avatar";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { A11y, FreeMode, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { SearchAutocomplete } from "../../Interfaces/PortfolioType";

interface SuggestionsProps {
    suggestions: SearchAutocomplete;
    open: boolean;
    option: "photo" | "photographer";
    onSearch?: (search: string) => void;
    suggRef?: React.RefObject<HTMLDivElement>;
    activeIndex: number | null;
    setActiveIndex: React.Dispatch<React.SetStateAction<number | null>>;
}

export default function Suggestions({ suggestions, open, option, onSearch, activeIndex, setActiveIndex }: Readonly<SuggestionsProps>) {
    const { t } = useTranslation();

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (!open) return;

            if (event.key === "ArrowDown") {
                event.preventDefault();
                setActiveIndex((prev) =>
                    prev === null ? 0 : Math.min(prev + 1, suggestions.suggestions.length - 1)
                );
            } else if (event.key === "ArrowUp") {
                event.preventDefault();
                setActiveIndex((prev) => (prev === null ? 0 : Math.max(prev - 1, 0)));
            } else if (event.key === "Enter" && activeIndex !== null) {
                event.preventDefault();
                if (onSearch) onSearch(suggestions.suggestions[activeIndex]);
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => window.removeEventListener("keydown", handleKeyDown);
    }, [open, activeIndex, suggestions.suggestions, onSearch]);

    return (
        <div
            className={`${open ? "h-auto" : "opacity-0 h-0"} flex flex-col transition-all bg-white absolute top-10 w-full overflow-x-hidden overflow-y-auto border rounded-b-md border-t-0 ${
                option === "photo" ? "border-orange-500" : option === "photographer" && "border-purple-500"
            }`}
            style={{ borderRadius: "0px 0px 20px 20px" }}
        >
            {suggestions.suggestions.length > 0 && (
                <>
                    <div className="block text-base w-full bg-gray-400 text-white font-comfortaa px-2">{t("suggestions")}</div>
                    <div className="block text-base w-full">
                        {suggestions.suggestions.map((search, index) => (
                            <button
                                key={index}
                                className={`block py-2 px-4 w-full text-left ${
                                    activeIndex === index ? "bg-gray-200 hover:bg-gray-200" : "hover:bg-gray-100"
                                }`}
                                onClick={() => onSearch && onSearch(search)}
                                onMouseEnter={() => setActiveIndex(index)}
                            >
                                {search}
                            </button>
                        ))}
                    </div>
                </>
            )}
            {suggestions.portfolios.length > 0 && (
                <>
                    <div className="block text-base w-full bg-gray-400 text-white font-comfortaa px-2 mb-2">{t("portfolios")}</div>
                    <div className="flex flex-row gap-4 w-full px-4 overflow-hidden h-fit">
                        <Swiper
                            modules={[FreeMode, Navigation, Pagination, Scrollbar, A11y]}
                            spaceBetween={10}
                            freeMode={true}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                884: {
                                    slidesPerView: suggestions.portfolios.length > 1 ? 2 : 1,
                                },
                                1250: {
                                    slidesPerView: suggestions.portfolios.length > 1 ? 3 : 1,
                                },
                                1732: {
                                    slidesPerView: suggestions.portfolios.length > 1 ? 3 : 1,
                                },
                            }}
                            navigation
                            pagination={{ clickable: true }}
                            centeredSlides={suggestions.portfolios.length === 1}
                            className="w-full h-fit"
                        >
                            {suggestions.portfolios.map((portfolio) => (
                                <SwiperSlide key={portfolio.id} className="pb-2 h-fit">
                                    <Avatar key={portfolio.id} portfolio={portfolio} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </>
            )}
        </div>
    );
}
