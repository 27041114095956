import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ModalSearchMobile from "../Modals/ModalSearchMobile";
import CustomFontAwesomeIcon from "../CustomFontAwesomeIcon";

export default function DoubleBar() {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const [open, setOpen] = React.useState(false)
    const [openPhoto, setOpenPhoto] = React.useState(false)

    const handleOpenPhoto = () => { 
        setOpen(!open)
        setOpenPhoto(true) 
    }

    const handleOpenPhotograph = () => { 
        setOpen(!open) 
        setOpenPhoto(false) 
    }

    return (
        <>
            <ModalSearchMobile open={open} setOpen={() => setOpen(!open)} isPhoto={openPhoto} />
            <div
                className={`flex transition-all relative z-10 opacity-100 ${
                    pathname === "/" ? "w-full md:w-2/5 my-0 mx-auto max-w-[90vw] md:max-w-[40vw]" : "my-0 mx-auto w-full md:w-[40vw] md:max-w-[40vw]"
                }`}
            >
                <button
                    className={`transition-all ease-in-out left-0 bg-white h-10 border absolute border-orange-500 inline-block`}
                    style={{
                        width: `calc(50% + 20px)`,
                        borderRadius: `20px`,
                    }}
                    onClick={handleOpenPhoto}
                >
                    <div className="h-10 px-2 vertical-middle flex items-center" style={{ width: "calc(100% - 17px)" }}>
                        <CustomFontAwesomeIcon icon="search" className="-mt-1 text-orange-500" />
                        <p
                            className="mx-auto -mt-1 text-sm text-left text-gray-400 focus:border-0 focus:ring-0 focus:outline-none line-clamp-1"
                            placeholder=""
                            style={{ width: "calc(100% - 30px)" }}
                        >
                            <span className="flex custom-sm:hidden mt-0.5">{t("photos")}</span>
                            <span className="hidden custom-sm:flex md:hidden mt-0.5">{t("searchbar_photos_placeholder")}</span>
                        </p>
                    </div>
                </button>
                <button
                    className={`transition-all right-0 bg-white h-10 border border-purple-200 inline-block absolute`}
                    style={{
                        width: `calc(50% + 20px)`,
                        borderRadius: `20px`,
                    }}
                    onClick={handleOpenPhotograph}
                >
                    <div className="h-10 px-2 vertical-middle flex items-center" style={{ width: "calc(100% - 17px)" }}>
                        <CustomFontAwesomeIcon icon="search" className="-mt-1 text-purple-500" />
                        <p
                            className="mx-auto -mt-1 text-sm text-left text-gray-400 focus:border-0 focus:ring-0 focus:outline-none line-clamp-1"
                            placeholder=""
                            style={{ width: "calc(100% - 30px)" }}
                        >
                            <span className="flex custom-sm:hidden mt-0.5">{t("photographs")}</span>
                            <span className="hidden custom-sm:flex md:hidden whitespace-nowrap mt-0.5">{t("searchbar_photographer_placeholder")}</span>
                        </p>
                    </div>
                </button>
            </div>
        </>
    );
}
