import { Reducer } from "@reduxjs/toolkit";
import { GeneralReducerType } from "../../../components/Interfaces/GeneralReducerType";

const initialState: GeneralReducerType = {
    categories: [],
    typeStyles: [],
    licences: [],
    serviceTypes: [],
    photoStyles: [],
    regions: [],
    dataFetched: false,
    picturesNsfw: [],
};

const GeneralReducer: Reducer<GeneralReducerType, any> = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_CATEGORIES":
            return { ...state, categories: action.payload };
        case "UPDATE_TYPESTYLES":
            return { ...state, typeStyles: action.payload };
        case "UPDATE_LICENCES":
            return { ...state, licences: action.payload };
        case "UPDATE_SERVICETYPES":
            return { ...state, serviceTypes: action.payload };
        case "UPDATE_PHOTO_STYLES":
            return { ...state, photoStyles: action.payload };
        case "UPDATE_REGIONS":
            return { ...state, regions: action.payload };
        case "UPDATE_ME":
            return { ...state, me: action.payload };
        case "UPDATE_NSFW":
            return { ...state, picturesNsfw: action.payload };
        case "UPDATE_GENERAL":
            return { ...state, ...action.payload, dataFetched: true };
        default:
            return state;
    }
};

export default GeneralReducer;
