import { Dialog } from "@headlessui/react";
import ModalWrapper from "./ModalWrapper";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardBody, CardHeader, Chip, IconButton, Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import React from "react";
import { Storage } from "../../services/storage";
import { HashLink } from "react-router-hash-link";
import CustomFontAwesomeIcon from "../CustomFontAwesomeIcon";

export default function ModalChangePlanPro({ open, setOpen }: Readonly<{ open: boolean; setOpen: any }>) {
    const { t } = useTranslation();
    const [statePrices, setStatePrices] = React.useState({
        prenium: true,
        pro: true,
    });
    
    const isLogin = Storage.getToken();

    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white p-6 py-12 text-left align-middle shadow-xl transition-all w-fit max-w-lg">
                <Dialog.Title as="h3" className="text-2xl font-medium leading-6 text-orange-500 text-center">
                    {t("boostyouraccount")}
                    <CustomFontAwesomeIcon icon="crown" className="ml-2" />
                </Dialog.Title>
                <CustomFontAwesomeIcon icon="x" className="absolute top-4 right-4 text-gray-500 cursor-pointer" onClick={() => setOpen(false)} />
                <div className="flex justify-between items-start gap-4 mt-8">
                    <div data-entrance="fade" data-entrance-delay="600" className="w-full md:w-fit bg-gray-50 rounded-lg px-4 py-4 h-fit relative">
                        <div className="h-64 flex flex-col justify-around">
                            <div className="text-left md:text-center font-sans mb-8">
                                <p className="w-fit bg-black text-white py-2 px-4 rounded-lg mx-auto"><CustomFontAwesomeIcon icon="crown" className="mr-2" />{t("pro")}</p>
                                <p className="w-fit border-2 border-transparent text-black py-2 px-4 rounded-lg mx-auto text-xs">{t("pro_p")}</p>
                            </div>
                            <div className="flex flex-row items-center justify-around">
                                <span className={`text-sm font-medium ${statePrices.pro ? "text-gray-500" : "text-purple-500"}`}>{t("monthly")}</span>
                                <label className="relative inline-flex items-center cursor-pointer">
                                    <input
                                        type="checkbox"
                                        onChange={() => setStatePrices({ ...statePrices, pro: !statePrices.pro })}
                                        value=""
                                        className="sr-only peer"
                                        checked={statePrices.pro}
                                    />
                                    <div className="w-11 h-6 bg-gray-300 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-orange-600"></div>
                                </label>

                                <span className={`text-sm font-medium ${!statePrices.pro ? "text-gray-500" : "text-purple-500"}`}>{t("yearly")}</span>
                            </div>
                            <p className={`${statePrices.pro ? "opacity-100" : "opacity-0"} text-left md:text-center text-gray-500 font-sans font-base mt-8 flex justify-center gap-2 text-xs line-through`}>
                                {t("chf")}
                                <span>24.00 {t("ttc")}</span>
                                <span>{t("monthly_label")}</span>
                            </p>
                            <p className="text-left md:text-center text-orange-500 font-sans font-base mb-8 flex justify-center gap-2">
                                {!statePrices.pro ? (
                                    <div className="flex flex-col justify-center items-center">
                                        <span className="flex items-center justify-center flex-row gap-1">
                                            {t("chf")}
                                            <span> 24.00 {t("ttc")}</span>
                                            <span>{t("monthly_label")}</span>
                                        </span>
                                        <span className="text-xs font-bold text-gray-500 invisible">
                                            {t("orYearly")} {" "}
                                            {t("chf")}
                                            <span> 216.00 {t("ttc")}</span>
                                            <span>{t("yearly_label")}</span>
                                        </span>
                                    </div>
                                ) : (
                                    <div className="flex flex-col justify-center items-center">
                                        <span className="flex items-center justify-center flex-row gap-1">
                                            {t("chf")}
                                            <span> 18.00 {t("ttc")}</span>
                                            <span>{t("monthly_label")}</span>
                                            <Chip color="orange" size="sm" value="-25%" />
                                        </span>
                                        <span className="text-xs font-bold text-gray-500">
                                            {t("orYearly")} {" "}
                                            {t("chf")}
                                            <span> 216.00 {t("ttc")}</span>
                                            <span>{t("yearly_label")}</span>
                                        </span>
                                    </div>
                                )}
                            </p>
                        </div>
                        <div className="font-serif flex flex-col">
                            <div className="flex flex-row gap-2 w-full px-2 py-2 text-sm items-start">
                                <p dangerouslySetInnerHTML={{ __html: t("proText") }}></p>
                            </div>
                            <hr className="my-2 border-t-purple-500" />
                            <div className="flex flex-col w-full justify-center items-center gap-3">
                                <Link
                                    to={isLogin ? "/subscription/pro" : "/signup?redirect=pro"}
                                    className="px-4 py-2 bg-purple-500 text-white rounded-full hover:bg-purple-200 transition-all w-fit mx-auto"
                                    dangerouslySetInnerHTML={{ __html: t("obtainPro") }}
                                ></Link>
                                <HashLink to="/tarifs#detailTarifs" 
                                    className="text-sm text-purple-500 hover:text-purple-200 transition-all mx-auto w-full text-center mb-1"
                                >
                                    {t("learnmore")}
                                </HashLink>
                                <span className="flex gap-2 text-xs mx-auto text-center -mt-2" dangerouslySetInnerHTML={{ __html: t("renewSubscription") }}></span>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog.Panel>
        </ModalWrapper>
    );
}

