import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import SubMenu from "./SubMenu";

// import FullLogoEzoomBeta from "../../assets/images/logos/ezoombeta.png";
// import FullLogoEzoom from "../../assets/images/logos/logo.png"
import MenuHeader from "./composants/MenuHeader";
import MenuProfil from "./composants/MenuProfil";

import DoubleBar from "./DoubleBar";
import CustomFontAwesomeIcon from "../CustomFontAwesomeIcon";
import RenderLogo from "../RenderLogo";

interface HeaderMobileProps {
    searchBar: boolean, 
    openSearchBar: Function,
    portfolio?: {
        name: string;
        path: string;
    };
    gallery?: {
        name: string;
    };
    isVisible: boolean;
    setIsVisible: Function;
}

export default function HeaderMobile({ searchBar, openSearchBar, portfolio, gallery, isVisible, setIsVisible } : Readonly<HeaderMobileProps>) {
    const location = useLocation();
    const pathname = location.pathname;
    const signin = localStorage.getItem("token") ?? false;
    const [lastScrollPos, setLastScrollPos] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;

            // Contrôle uniquement basé sur le défilement
            setIsVisible(currentScrollPos < lastScrollPos || currentScrollPos < 50);

            setLastScrollPos(currentScrollPos);
        };

        window.addEventListener("scroll", handleScroll);

        // Nettoyage de l'événement
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [lastScrollPos]);

    useEffect(() => {
        if (searchBar) {
            setIsVisible(true);
        }
    }, [searchBar]);

    return (
            <div
                className={`lg:hidden sticky top-0 left-0 z-40 overflow-visible bg-white pt-0 shadow-md shadow-var transition-transform ${
                    isVisible ? "translate-y-0" : "-translate-y-[60px]"
                }`}
            >
                <div
                    className={`mx-2 pb-0 relative flex flex-col`}
                >
                    <div className="my-1.5 flex flex-around flex-row w-full gap-1 items-center">
                        <Link to="/" className="flex items-center ml-1 cursor-pointer hover:cursor-pointer" style={{ width: "200px" }}>
                            {/* <img src={FullLogoEzoomBeta} alt="logo" className="w-[200px] cursor-pointer hover:cursor-pointer" /> */}
                            {/* <img src={FullLogoEzoom} alt="logo" className="w-[200px] cursor-pointer hover:cursor-pointer" /> */}
                            <RenderLogo
                                isPng={true}
                                className="w-[200px] cursor-pointer hover:cursor-pointer"
                                classNameBeta="w-[200px] cursor-pointer hover:cursor-pointer"
                            />
                        </Link>
                        <div className="flex m-auto relative w-full justify-end pr-1 items-center gap-1" style={{ lineHeight: 0 }}>
                            <button
                                onClick={() => openSearchBar(!searchBar)}
                                className="flex items-center justify-center text-center cursor-pointer text-base font-extralight w-6 py-2 px-2 h-full hover:bg-gray-100 rounded-full bg-white text-gray-500 hover:text-gray-700"
                            >
                                <CustomFontAwesomeIcon icon="magnifying-glass" className="text-base text-gray-500 hover:text-orange-500" />
                            </button>
                            {signin && (
                                <>
                                    <Link
                                        className="flex items-center justify-center text-center cursor-pointer text-base font-extralight w-6 h-full hover:bg-gray-100 rounded-full bg-white text-gray-500 hover:text-gray-700"
                                        to="/upload"
                                    >
                                        <CustomFontAwesomeIcon icon="add" className="text-base text-gray-500 hover:text-orange-500" />
                                    </Link>
                                    <MenuProfil />
                                </>
                            )}
                            <MenuHeader />
                        </div>
                    </div>
                </div>
                {(pathname === "/search" ||
                    pathname === "/favorites" ||
                    pathname === "/favorites/photographers" ||
                    pathname === "/photographers" ||
                    pathname === "/popular-photographers" ||
                    pathname === "/recent" ||
                    pathname === "/recent-photographers" ||
                    pathname === "/galeries" ||
                    pathname === "/popular") && <SubMenu pathname={pathname} />}
                <div className={`${searchBar ? "flex h-12 py-1 opacity-1 pointer-events-auto" : "flex h-0 opacity-0 pointer-events-none"} w-full mx-auto flex-wrap justify-between text-base px-2 transition-all bg-white`}>
                    <DoubleBar portfolio={portfolio} gallery={gallery} />
                </div>
            </div>
    );
}
