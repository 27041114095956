import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@material-tailwind/react";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DefaultBannerEzoom from "../../../assets/images/elements/default-banner.png";
import FavoriteAPI from "../../../services/API/Clients/FavoriteAPI";
import GalleryAPI from "../../../services/API/Clients/GalleryAPI";
import { handleError } from "../../../services/Errors/handleErrors";
import { Storage } from "../../../services/storage";
import CustomFontAwesomeIcon from "../../CustomFontAwesomeIcon";
import { Galleries } from "../../Interfaces/PortfolioType";
import ModalAlert from "../../Modals/ModalAlert";
import ModalSignInUp from "../../Modals/ModalSignInUp";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const myId = Storage.getId();

export default function Gallery({ data }: Readonly<{ data: Galleries }>) {
    const navigate = useNavigate();
    const [openModal, setOpenModal] = React.useState(false);
    const [openInvitation, setOpenInvitation] = React.useState(false);
    const [heartColor, setHeartColor] = React.useState(data.isFavorited);
    const [isFav, setIsFav] = React.useState(false);
    const currentUrl = window.location.href.split("/")[3];
    const { t } = useTranslation();

    const handleClose = () => {
        setOpenModal(false);
    }

    const handleDeleteGallery = async () => {
        setOpenModal(false);
        const response = await GalleryAPI.deleteGallerie(data.id);
        if (response.status === 204) {
            toast.success(t("galleryDeleted"));
            navigate(0);
        } else handleError(response);
    }

    const handleChangeFav = async () => {
        const undo = heartColor;
        setHeartColor(!heartColor);
        
        const datas = {
            gallery: `/api/public/galleries/${data.id}`,
            owner: `/api/users/${myId}`
        }
        
        if (isFav === false) {
            const response = await FavoriteAPI.favGallerie(datas);
            if (response.status === 200 || response.status === 201) {
                toast.success(t("addToFavGallerie"))
                setHeartColor(true);
                setIsFav(true);
            } else {
                handleError(response);
                setHeartColor(undo);
            }
        } else {
            const response = await FavoriteAPI.unFavGallerie(datas);
            if (response.status === 200 || response.status === 201 || response.status === 204) {
                toast.success(t("removeFromFavGallerie"))
                setHeartColor(false);
                setIsFav(false);
            } else {
                handleError(response);
                setHeartColor(undo);
            }
        }
    }
    
    return (
        <>
            <ModalSignInUp open={openInvitation} setOpen={setOpenInvitation} />
            <ModalAlert open={openModal} setOpen={handleClose} handleSave={handleDeleteGallery} />
            <div className={`mx-auto relative w-full max-w-[24rem] ${(currentUrl !== undefined && currentUrl !== "") ? "md:w-96" : "md:w-96"}`}>
                {data.isOwned && (
                    <div className="absolute top-[152px] right-2 flex gap-2 z-10">
                        <Link to={`/gallery/${data.id}/edit`}>
                            <IconButton 
                                color="white" 
                                size="sm" 
                                variant="outlined"
                                className="flex border-gray-400/50 shadow hover:shadow-lg hover:rounded-full transition-all duration-300 rounded-full bg-white hover:bg-gray-50 text-black"
                            >
                                <CustomFontAwesomeIcon icon="pen" />
                            </IconButton>
                        </Link>
                        <IconButton 
                            color="white" 
                            size="sm" 
                            variant="outlined"
                            className="flex border-gray-400/50 shadow hover:shadow-lg hover:rounded-full transition-all duration-300 rounded-full bg-white hover:bg-gray-50 text-black"
                            onClick={() => setOpenModal(true)}
                        >
                            <CustomFontAwesomeIcon icon="trash" />
                        </IconButton>
                    </div>
                )} 
                {!data.isOwned && (
                    <div 
                        className="absolute bottom-24 right-3 flex gap-2 z-10"
                        onClick={() => !Storage.getToken() ? setOpenInvitation(true) : handleChangeFav()}
                    >
                        <FontAwesomeIcon icon={heartColor ? solidHeart as IconProp : regularHeart as IconProp} className={`${heartColor ? "text-indian-500" : "text-white"} text-xl hover:opacity-70 transition-all`} />
                    </div>
                )} 
                <Link className="w-full mb-5 hover:opacity-75 transition-all" to={`/gallery/${data.id}`}>
                    <div className={`w-full ${(currentUrl !== undefined && currentUrl !== "") ? "md:w-96" : "md:w-full"} mx-auto rounded overflow-hidden shadow-lg h-full relative`} key={data.title}>
                        <div className="absolute bottom-[90px] left-2 z-10 flex flex-col gap-2">
                            {data.private && (
                                <div className="bg-orange-500 text-white text-xs font-bold px-2 py-1 rounded-full w-fit">
                                    {t("privateGallery")}
                                </div>
                            )}
                            {!data.published && (
                                <div className="bg-orange-500 text-white text-xs font-bold px-2 py-1 rounded-full w-fit">
                                    {t("unpublishedGallery")}
                                </div>
                            )}
                        </div>
                        <div
                            className={`w-full ${(currentUrl !== undefined && currentUrl !== "") ? "md:w-96" : "md:w-full"} h-48 flex relative bg-cover`}
                            style={{
                                backgroundPositionY: `${100 - (data.bannerOffset ?? 0)}%`,
                                backgroundImage: `url(${(data.banner && data.banner.minPath) ?? DefaultBannerEzoom})`,
                            }}
                        >
                        </div>
                        <div className={`absolute bottom-20 w-full h-24 ${(currentUrl !== undefined && currentUrl !== "") ? "md:w-96" : "md:w-full"} bg-gradient-to-b from-white/0 to-black/20 rounded-t-lg`}></div>

                        <div className="px-6 pt-4 pb-4 flex flex-col items-center justify-center h-20">
                            <div className="font-bold text-base text-center truncate w-full">{data.title}</div>  
                            <div className="flex gap-2">  
                                {data?.place?.id && (
                                    <p className="text-gray-700 text-sm text-center">{data?.place?.label}</p>
                                )}
                                {data?.place?.id && data?.date ? (
                                    <p className="text-gray-700 text-sm text-center">•</p>
                                ) : <></>}
                                {data.date && (
                                    <p className="text-gray-700 text-sm text-center">{moment(data.date).format("DD.MM.YYYY")}</p>
                                )}
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </>
    );
}
