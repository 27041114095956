import { Typography } from "@material-tailwind/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RowsPhotoAlbum } from "react-photo-album";
import { useSelector } from "react-redux";
import GalleryAPI from "../../../services/API/Clients/GalleryAPI";
import PicturesAPI from "../../../services/API/Clients/PicturesAPI";
import { handleError } from "../../../services/Errors/handleErrors";
import { FilterStoreInterface } from "../../../services/redux/reducers/filtersReducer";
import PictureAlone from "../../Box/Photo/PictureAlone";
import CustomFontAwesomeIcon from "../../CustomFontAwesomeIcon";
import { PictureSearch } from "../../Interfaces/PictureType";
import { Galleries } from "../../Interfaces/PortfolioType";
import { FilterConfiguration } from "../filters/FilterConfiguration";
import DrawerForFilter from "../mobile/DrawerForFilter";
import { FilterProvider } from "../provider/FilterProvider";
import {
  CompatibleFilterList,
  FilterList,
  FilterRenderConfiguration,
  FilterSchema,
  FilterType,
  PaginatedResponse,
  SearchInterface,
} from "../types/global";
import { GalleryFilterSchema, GalleryResult } from "./GalleryResult";

interface EnumValue {
  enumValue: string;
  frLabel: string;
  enLabel: string;
}

interface PictureCompatibleFilters extends CompatibleFilterList {
  category: EnumValue[];
  typestyle: EnumValue[];
  date: {
    takeDate: string;
  }[];
  license: {
    license: string;
  }[];
  region: {
    code: string;
  }[];
}

export type PageType =
  | "gallery"
  | "portfolio"
  | "search"
  | "favorites"
  | "popular"
  | "recent"
  | "category"
  | "typestyle"
  | "license"
  | "region"
  | "date"
  | "feed";

interface PictureFilterList extends FilterList {
  pagetype: PageType;
  popular?: boolean;
  query?: string;
  category?: string;
  galleries?: boolean;
  typestyle?: string;
  portfolio?: string;
  gallery?: string;
  favorites?: boolean;
  from_followed?: boolean;
  country?: string;
  region?: string;
  date?: string;
  author_plan?: string;
  order?: string;
  keyword?: string;
  keyword_label?: string;
}

const PictureFilterSchema: FilterSchema<PictureFilterList> = {
  pagetype: "string",
  popular: "boolean",
  galleries: "boolean",
  query: "string",
  category: "string",
  typestyle: "string",
  portfolio: "string",
  license: "string",
  gallery: "string",
  favorites: "boolean",
  from_followed: "boolean",
  country: "string",
  region: "string",
  date: "string",
  author_plan: "string",
  order: "string",
  keyword: "string",
  keyword_label: "string",
};

const PictureFiltersRender: FilterRenderConfiguration<PictureCompatibleFilters> =
{
  recent: {
    type: FilterType.BOOLEAN,
    mobile: FilterType.BOOLEAN,
    getKey: () => "order",
    getValue: (value) => "recent",
  },
  popular: {
    type: FilterType.BOOLEAN,
    mobile: FilterType.BOOLEAN,
    getKey: () => "popular",
    getValue: () => "true",
  },
  galleries: {
    type: FilterType.BOOLEAN,
    mobile: FilterType.BOOLEAN,
    getKey: () => "galleries",
    getValue: () => "true",
  },
  category: {
    type: FilterType.DROPDOWN,
    mobile: FilterType.DROPDOWN,
    getKey: (o: EnumValue) => o.enumValue,
    getValue: (o: EnumValue) => o.frLabel,
  },
  typestyle: {
    type: FilterType.DROPDOWN,
    mobile: FilterType.DROPDOWN,
    getKey: (o: EnumValue) => o.enumValue,
    getValue: (o: EnumValue) => o.frLabel,
  },
  date: {
    type: FilterType.DATEPICKER,
    mobile: FilterType.DROPDOWN,
    getKey: (o: { takeDate: string }) => o.takeDate,
    getValue: (o: { takeDate: string }) => o.takeDate,
  },
  license: {
    type: FilterType.DROPDOWN,
    mobile: FilterType.DROPDOWN,
    getKey: (o: { license: string }) => o.license,
    getValue: (o: { license: string }) => o.license,
  },
  region: {
    type: FilterType.MAPPICKER,
    mobile: FilterType.DROPDOWN,
    getKey: (o: { code: string }) => o.code,
    getValue: (o: { code: string }) => o.code,
  },
  keyword: {
    type: FilterType.CHIPS,
    mobile: FilterType.DROPDOWN,
    getKey: (o: EnumValue) => o.enumValue,
    getValue: (o: EnumValue) => o.frLabel,
  },
};

const fetchPictures = async (search: SearchInterface<PictureFilterList>) => {
  const allFilters = search.filters.getAllFilters();
  if (allFilters.galleries === true) {
    return undefined;
  }
  const response = await PicturesAPI.searchPicturesPagination(
    search.filters.getApiQueryParams(),
    search.page
  );
  if (response.status === 200) {
    return response.body as PaginatedResponse<PictureSearch>;
  } else {
    handleError(response);
  }
};

const fetchGalleries = async (search: SearchInterface<PictureFilterList>) => {
  const allFilters = search.filters.getAllFilters();
  if (allFilters.galleries === true) {
    return undefined;
  }

  // Don't query galleries on unrecognized filters
  const EXCEPTIONS = ["pagetype", "galleries"];
  if (
    Object.keys(allFilters).some(
      (key) => {
        if (allFilters[key] !== undefined && Object.keys(PictureFilterSchema).includes(key) && !Object.keys(GalleryFilterSchema).includes(key) && !EXCEPTIONS.includes(key)) {
          return true;
        }
        return false;
      }
    )) {
    return undefined;
  }
  const response = await GalleryAPI.searchGalleriePagination(
    search.filters.getApiQueryParams(),
    search.page
  );
  if (response.status === 200) {
    return response.body as PaginatedResponse<Galleries>;
  } else {
    handleError(response);
  }
};

const fetchCompatibleFilters = async (
  filters: FilterConfiguration<PictureFilterList>
) => {
  const allFilters = filters.getAllFilters();
  if (allFilters.galleries === true) {
    return undefined;
  }
  const response = await PicturesAPI.compatibleFilters(
    filters.getApiQueryParams()
  );
  if (response.status === 200) {
    return response.body as PictureCompatibleFilters;
  } else {
    handleError(response);
  }
};

interface PictureResultProps {
  galleries?: boolean;
  filters?: boolean;
  hideFilters?: string[];
  overrides?: PictureFilterList;
  NoResults?: JSX.Element;
  defaultSort?: string;
  children?: React.ReactNode; // Accept children to be rendered within the FilterProvider
  reloadDate?: Date
}

const PictureNoResults = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
      <CustomFontAwesomeIcon
        icon="image"
        className="text-3xl text-gray-500/50 mt-4"
      />
      <Typography className="text-base text-gray-500 font-sans mt-4 text-center mb-4">
        {t("noPictures")}
      </Typography>
    </div>
  );
};

const ParentElement = ({
  items,
  loadMore,
}: {
  items: PictureSearch[];
  loadMore: () => void;
}) => {
  const [printNsfw, setPrintNsfw] = React.useState(false);
  const [availableWidth, setAvailableWidth] = React.useState(window.innerWidth);

  const handleOpenNsfw = (arg: boolean) => {
    setPrintNsfw(false);
  };

  useEffect(() => {
    setPrintNsfw(false);
  }, []);

  const parentRef = React.useRef<HTMLDivElement>(null);

  // TOTAL WIDTH for height of 300px
  const totalWidth =
    items.length < 3
      ? items.reduce((acc, item) => acc + item.width * (300 / item.height), 0)
      : 0;


  useEffect(() => {
    if (parentRef.current) {
      const container = parentRef.current.closest("div.search-result-box");
      if (container) {
        setAvailableWidth(container.clientWidth);
      }
    }
  }, [parentRef]);

  const maxWidth = Math.min(totalWidth, availableWidth - 40);
  
  return (
    <div
      ref={parentRef}
      className={`${items.length < 3 ? `mx-auto` : "w-full"}`}
      style={items.length < 3 ? { width: `${maxWidth}px` } : {}}
    >
      <RowsPhotoAlbum
        photos={items.map((item) => ({
          src: item.minPath,
          width: item.width,
          height: item.height,
          alt: item.id,
        }))}
        targetRowHeight={300}
        spacing={15}
        render={{
          image: (props, ctx) => (
          <PictureAlone
            key={props.alt}
            pictureStyle={props.style}
            index={ctx.index}
            data={items[ctx.index]}
            heart={true}
            printNsfw={printNsfw}
            handleChangeNsfw={handleOpenNsfw}
            pictures={items}
            loadMore={loadMore}
          />
        )
      }}
      />
    </div>
  );
};

const PictureResultAsPicture = (props: PictureResultProps) => {
  return (
    <FilterProvider
      hideFilters={props.hideFilters ?? []}
      filtersSchema={PictureFilterSchema}
      filters={props.filters ?? true}
      filtersRender={PictureFiltersRender}
      fetchResults={fetchPictures}
      fetchFilters={fetchCompatibleFilters}
      fetchGalleries={props.galleries === false ? undefined : fetchGalleries}
      filterOverrides={props.overrides}
      NoResults={props.NoResults ?? <PictureNoResults />}
      sortOptions={[
        { key: "viewcount", value: "sort.viewcount" },
        { key: "favoritecount", value: "sort.favoritecount" },
        { key: "publishdate", value: "sort.publishdate" },
        { key: "takedate", value: "sort.takedate" },
      ]}
      defaultSort={props.defaultSort}
      renderParent={(items, loadMore) => (
        <ParentElement items={items} loadMore={loadMore} />
      )}
      reloadDate={props.reloadDate}
      supportsPictureOpening={true}
    >
      {props.children}
    </FilterProvider>
  );
};

export const PictureResult = (props: PictureResultProps) => {
  const [galleries, setGalleries] = React.useState(false);
  const filters: FilterStoreInterface = useSelector(
    (state: any) => state.filters
  );

  useEffect(() => {
    if (filters.currentFilters?.galleries === true) {
      setGalleries(true);
    } else {
      setGalleries(false);
    }
  }, [filters]);

  if (galleries) {
    return (
      <GalleryResult
        filters={props.filters}
        overrides={props.overrides}
        children={props.children}
        defaultSort="popular"
      />
    );
  }
  return <PictureResultAsPicture {...props} />;
};

export const PictureDrawerForFilters = () => (
  <DrawerForFilter<PictureFilterList, PictureCompatibleFilters>
    renderConfiguration={PictureFiltersRender}
  />
);
