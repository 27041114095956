import moment from "moment"
import { GeneralReducerType } from "../../components/Interfaces/GeneralReducerType"

export const getPageName = (general: GeneralReducerType, type: string, value: string) => {
    if (type === "category") return general.categories.find(item => item.enumValue === value)?.frLabel ?? value
    if (type === "typestyle") return general.typeStyles.find(item => item.enumValue === value)?.frLabel ?? value
    if (type === "license") return general.licences.find(item => item.enumValue === value)?.frLabel ?? value
    if (type === "region") return general.regions.find(item => item.code === value)?.frLabel ?? value
    if (type === "date") return moment(value).locale("fr").format("LL")
    return null;
}

const ALLOWED_TYPES = ["category", "typestyle", "license", "region", "date", "keyword"];

export const getPageOverride = (type?: string, value?: string) => {
    if (!type || !value) return { pagetype: "search" };
    if (!ALLOWED_TYPES.includes(type)) return { pagetype: "search" };
    if (type === "region") return { pagetype: type, region: value, country: "CHE"};
    if (type === "keyword") {
        return { pagetype: type, keyword_label: value };
    }
    return {pagetype: type, [type]: value};
}