import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface InputFileButtonProps {
  onFilesSelected: any;
  buttonText: string;
}

const InputFileButton: React.FC<InputFileButtonProps> = ({ onFilesSelected, buttonText }) => {
  const { t } = useTranslation();
  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFilesSelected(event.target.files);
    if (inputFileRef.current) {
      inputFileRef.current.value = '';
    }
  };

  return (
    <div>
      <button
            className="border-2 bg-orange-500 hover:bg-orange-200 border-transparent inline w-auto text-white hover:text-black py-1 md:py-2 px-2 md:px-4 rounded-full"
            style={{
                transition: "all .25s ease-in-out",
            }}
            onClick={handleButtonClick}
        >
        <span className="flex">{t(buttonText)}</span>
      </button>
      <input
        type="file"
        multiple
        className="hidden"
        ref={inputFileRef}
        onChange={handleFileChange}
        accept=".jpeg,.jpg"
      />
    </div>
  );
};

export default InputFileButton;
