import { Collapse, Input } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import SelectForUpload from "./components/SelectForUpload";
import TitleDescribe from "./components/TitleDescribe";
import IconOpen from "./components/IconOpen";
import InputPlace from "../../../../components/Form/Input/InputPlace";
import React, { useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import GalleryAPI from "../../../../services/API/Clients/GalleryAPI";
import { Storage } from "../../../../services/storage";
import { handleError } from "../../../../services/Errors/handleErrors";

export default function Galerie({
    toggleOpenGalerie,
    openGalerie,
    handleChangeSelect,
    optionChoose,
    galeries,
    handleChange,
    handleChangeGaleriesLieu
}: Readonly<{
    toggleOpenGalerie: any;
    openGalerie: boolean;
    handleChangeSelect: any;
    optionChoose: any;
    galeries: { id: string, titre: string, lieu: { id: string, label: string }, date: string };
    handleChange: any;
    handleChangeGaleriesLieu: (id: string, label: string) => void;
}>) {
    const { t } = useTranslation();
    const [uuid, setUuid] = React.useState<string>("");
    const [listGallery, setListGallery] = React.useState<any[]>([]);
    const myId = Storage.getId();

    const fetchGallery = async () => {
        if (myId) {
            const response = await GalleryAPI.galleriesOwner(myId);
            if (response.status === 200 || response.status === 201) {
                const filtered = response.body.map((gallery: any) => {
                    return { value: gallery.id, label: gallery.title }
                })
                const listOfGalleryes = [{ value: "new", label: "+ Créer une galerie" }, ...filtered]
                setListGallery(listOfGalleryes);
            } else {
                const listOfGalleryes = [{ value: "new", label: "+ Créer une galerie" }]
                setListGallery(listOfGalleryes);
                handleError(response);
            }
        }
    }

    useEffect(() => {
        setUuid(uuidv4());
        fetchGallery();
    }, [openGalerie]);

    const onSelect = (value: string, label: string | undefined) => {
        if (label === undefined) return;
        handleChangeGaleriesLieu(value, label);
    }

    return (
        <div className=" pb-4 border-b border-gray-300">
            <button onClick={toggleOpenGalerie} className="flex flex-row justify-between items-center w-full mt-4 text-left">
                <TitleDescribe title={t("gallery")} describe={t("addtogallery")} />
                <IconOpen open={openGalerie} />
            </button>
            <Collapse open={openGalerie} className="flex flex-col gap-4">
                <div className="p-0.5">
                    <SelectForUpload name="selectgallery" optionChoose={optionChoose} options={listGallery} handleChangeSelect={handleChangeSelect} />
                </div>
                {optionChoose === "new" && (
                    <>
                        <Input name="titre" onChange={handleChange} value={galeries.titre} className="bg-white" color="orange" label={t("title")} />
                        <InputPlace uuid={uuid} onSelect={onSelect} title="place" value={galeries.lieu} />
                        <Input name="date" onChange={handleChange} value={galeries.date} className="bg-white" color="orange" label={t("date")} type="date" />
                    </>
                )}
            </Collapse>
        </div>
    );
}
