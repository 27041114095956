import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer/Footer";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import TitlePage from "../../components/TitlePage";
import WrapperGrayZone from "../../components/WrapperGrayZone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomFontAwesomeIcon from "../../components/CustomFontAwesomeIcon";

export default function About() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("about")}`;
    const [searchBar, setSearchBar] = useState(false);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    return (
        <div className="relative min-h-full">
            <HeaderForAnimation />
            <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} isVisible={isVisible} setIsVisible={setIsVisible} />
            <div className="flex flex-col h-full bg-white -mt-4 pt-4 pb-[340px] md:pb-36">
                <div className="flex flex-row md:flex-col items-center justify-between w-full sticky md:static top-2 bg-white z-30 border-b border-gray-200">
                    <TitlePage mt={0} mb={0}>
                        {t("apropos")}
                    </TitlePage>
                </div>
                <WrapperGrayZone pt="0" px="8">
                    <div className="mx-auto py-6">
                        <div className="-m-1 flex flex-col flex-wrap md:-m-2">
                            <div className="w-full md:w-2/3 mx-auto"></div>
                            <div className="max-w-[1280px] w-full mx-auto">
                                <div className="flex flex-col gap-2 mb-4">
                                    <span className="flex gap-2 text-base" dangerouslySetInnerHTML={{ __html: t("apropos_d1") }}></span>
                                    <span className="flex gap-2 text-base" dangerouslySetInnerHTML={{ __html: t("apropos_d2") }}></span>
                                </div>

                                <h1 className="mt-10 text-xl font-bold">{t("apropos_title_1")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("apropos_text_1_1") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("apropos_text_1_2") }}></p>
                                <ul className="list-disc pl-8">
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("apropos_ul_1_1") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("apropos_ul_1_2") }}></li>
                                </ul>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("apropos_text_1_3") }}></p>
                                <ul className="list-disc pl-8">
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("apropos_ul_1_3") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("apropos_ul_1_4") }}></li>
                                </ul>

                                <h1 className="mt-10 text-xl font-bold">{t("apropos_title_2")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("apropos_text_2_1") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("apropos_text_2_2") }}></p>

                                <div className="flex gap-4 mt-6">
                                    <CustomFontAwesomeIcon icon="quote-left" className="text-7xl text-orange-500" />
                                    <div className="flex flex-col gap-4 w-fit">
                                        <p className="mt-4 text-2xl text-left" dangerouslySetInnerHTML={{ __html: t("apropos_quote_1") }}></p>
                                        <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("apropos_quote_2") }}></p>
                                    </div>
                                </div>

                                <h1 className="mt-10 text-xl font-bold">{t("apropos_title_3")}</h1>

                                <ul className="list-disc pl-8">
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("apropos_ul_3_1") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("apropos_ul_3_2") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("apropos_ul_3_3") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("apropos_ul_3_4") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("apropos_ul_3_5") }}></li>
                                </ul>

                                <h1 className="mt-10 text-xl font-bold">{t("apropos_title_4")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("apropos_text_4") }}></p>

                                <ol className="list-disc pl-8">
                                    <li className="mt-4 font-bold" dangerouslySetInnerHTML={{ __html: t("apropos_ul_4_1") }}></li>
                                    <ul className="list-decimal pl-8">
                                        <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("apropos_ul_4_1_1") }}></li>
                                        <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("apropos_ul_4_1_2") }}></li>
                                        <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("apropos_ul_4_1_3") }}></li>
                                        <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("apropos_ul_4_1_4") }}></li>
                                        <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("apropos_ul_4_1_5") }}></li>
                                        <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("apropos_ul_4_1_6") }}></li>
                                    </ul>
                                    <li className="mt-4 font-bold" dangerouslySetInnerHTML={{ __html: t("apropos_ul_4_2") }}></li>
                                    <ul className="list-decimal pl-8">
                                        <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("apropos_ul_4_2_1") }}></li>
                                        <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("apropos_ul_4_2_2") }}></li>
                                        <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("apropos_ul_4_2_3") }}></li>
                                        <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("apropos_ul_4_2_4") }}></li>
                                        <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("apropos_ul_4_2_5") }}></li>
                                    </ul>
                                </ol>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("apropos_final_text") }}></p>
                            </div>
                        </div>
                    </div>
                </WrapperGrayZone>
            </div>
            <Footer />
        </div>
    );
}
