import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateRegions } from "../../../../services/redux/actions/formAction";
import { Region } from "../../../../components/Interfaces/PortfolioType";
import React, { useEffect } from "react";
import RegionAPI from "../../../../services/API/Clients/RegionAPI";
import { InlineInput } from "./InlineInput";
import { handleError } from "../../../../services/Errors/handleErrors";
import CustomFontAwesomeIcon from "../../../../components/CustomFontAwesomeIcon";

export default function Regions({ myLang }: Readonly<{ myLang: string }>) {
    const { t } = useTranslation();
    const portfolio = useSelector((state: any) => state.form);
    const dispatch = useDispatch();
    const [regions, setRegions] = React.useState<Region[]>([]);
    const [inputValues, setInputValues] = React.useState<string[]>([]);

    useEffect(() => {
        const getRegions = async () => {
            const resp = await RegionAPI.regions();
            if (resp.status === 200) {
                setRegions(resp.body);
            } else {
                handleError(resp);
            }
        };
        getRegions();

        setInputValues(portfolio.regions.map((e: Region) => (myLang === "fr" ? e.frLabel : e.enLabel)));
    }, []);

    useEffect(() => {
        const identifiedRegions = inputValues.reduce((acc: Region[], curr: string) => {
            const region = regions.find((region: Region) => (myLang === "fr" ? region.frLabel === curr : region.enLabel === curr));
            if (region) {
                acc.push(region);
            }
            return acc;
        }, []);
        dispatch(updateRegions(identifiedRegions));
    }, [inputValues]);

    const handleAddRegion = () => {
        const newInputValues = [...inputValues];
        newInputValues.push("");
        setInputValues(newInputValues);
    };

    const handleRegionInput = (e: string, index: number) => {
        const newInputValues = [...inputValues];
        newInputValues[index] = e;
        setInputValues(newInputValues);
    };

    const handleRegionRemove = (index: number) => {
        const newInputValues = [...inputValues];
        newInputValues.splice(index, 1);
        setInputValues(newInputValues);
    };

    return (
        <div className="flex flex-col w-full p-4 rounded-lg bg-gray-100">
            <Typography className={`text-lg font-bold`}>{t("myregions")}</Typography>
            <div className="flex flex-row flex-wrap items-center mt-2 gap-2">
                <CustomFontAwesomeIcon icon="circle-plus" className="text-gray-500 cursor-pointer" onClick={() => handleAddRegion()} />
                {inputValues.map((region: string, index: number) => (
                    <div className=" bg-white text-orange-700 py-0.5 pl-3 pr-1 rounded-full flex justify-between items-center" key={index}>
                        <InlineInput
                            value={region}
                            onChange={(e: string) => handleRegionInput(e, index)}
                            values={regions.map((region: Region) => (myLang === "fr" ? region.frLabel : region.enLabel))}
                            label="regionTab"
                        />
                        <CustomFontAwesomeIcon icon="circle-xmark" className="ml-2 cursor-pointer" onClick={() => handleRegionRemove(index)} />
                    </div>
                ))}
            </div>
        </div>
    );
}
