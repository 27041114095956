import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { PlaceCustomStyle } from '../../CustomStyles/PlaceCustomStyle';
import CountryAPI from '../../../services/API/Clients/CountryAPI';
import React, { useEffect } from 'react';
import { handleError } from '../../../services/Errors/handleErrors';
// import { v4 as uuidv4 } from 'uuid';

export default function({ onSelect, value, disabled = false, title = "tplace" } : { onSelect: (value: string) => void, value?: string, disabled?: boolean, title?: string }) {
    const { t } = useTranslation();
    const [options, setOptions] = React.useState<any[]>([])

    const loadOptions = async () => {
        const response = await CountryAPI.place()
        if (response.status === 200) {
            const listFiltered = response.body.map((place: any) => {
                return {
                    value: place.code,
                    label: place.frLabel
                }
            })
            setOptions(listFiltered)
        } else {
            handleError(response);
        }
    }

    useEffect(() => {
        loadOptions()
    }, [])

    const handleChange = async (value: any) => {
        onSelect(value.value)
    }

    return (
        <Select
            options={options} 
            menuPortalTarget={document.body}
            menuPosition="absolute"
            styles={PlaceCustomStyle}
            className="w-full relative top-0 bg-white border-orange-500"
            placeholder={t(title)}
            onChange={handleChange}
            isDisabled={disabled}
            value={value && value !== "" ? options.find((option) => value && option.value === value) : options.find((option) => option.value === "CHE")}
            name="lieu"
        />
    )
}