import { Typography } from "@material-tailwind/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CustomFontAwesomeIcon from "../../CustomFontAwesomeIcon";
import { PictureSearch } from "../../Interfaces/PictureType";
import PictureAlone from "./PictureAlone";
import { RowsPhotoAlbum } from "react-photo-album";

export default function PictureWithoutInfinite({ pictures, heart = true }: Readonly<{ pictures: PictureSearch[]; heart?: boolean }>) {
    const { t } = useTranslation();
    const [printNsfw, setPrintNsfw] = React.useState(false);

    const handleOpenNsfw = (arg: boolean) => {
        setPrintNsfw(false);
    };

    useEffect(() => {
        setPrintNsfw(false);
    }, []);

    return (
        <div>
            {pictures.length !== 0 ? (
                pictures &&
                pictures.length > 0 && (
                    <RowsPhotoAlbum
                        photos={pictures.map((item) => ({
                            src: item.minPath,
                            width: item.width,
                            height: item.height,
                            alt: item.id,
                        }))}
                        targetRowHeight={200}
                        render={{
                            image: (props, ctx) => (
                                <PictureAlone
                                    key={props.src}
                                    index={ctx.index}
                                    data={pictures[ctx.index]}
                                    heart={heart}
                                    printNsfw={printNsfw}
                                    handleChangeNsfw={handleOpenNsfw}
                                    pictures={pictures}
                                    loadMore={() => { }}
                                    paddingIsZero={true}
                                />
                            ),
                        } }
                    />
                )
            ) : (
                <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
                    <CustomFontAwesomeIcon icon="image" className="text-3xl text-gray-500/50 mt-4" />
                    <Typography className="text-base text-gray-500 font-sans mt-4 text-center mb-4">{t("noPictures")}</Typography>
                </div>
            )}
        </div>
    );
}
