import { IconButton, Popover, PopoverContent, PopoverHandler } from "@material-tailwind/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    PinterestIcon,
    PinterestShareButton,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    XIcon,
} from "react-share";
import CustomFontAwesomeIcon from "../CustomFontAwesomeIcon";
import SnapChatLogo from "../../assets/images/logos/snapchat.webp"

export default function SharingBox({ loading = false, isPicture = false, idPicture }: Readonly<{ loading?: boolean, isPicture?: boolean, idPicture?: string }>) {
    const { t } = useTranslation();
    const [isCopy, setIsCopy] = useState(false);

    const shareUrl = isPicture 
        ? window.location.href + (window.location.href.includes('?') ? '&' : '?') + "shared=" + idPicture 
        : window.location.href;

    const copyShareUrl = () => {
        navigator.clipboard.writeText(shareUrl);
        setIsCopy(true);
        setTimeout(() => {
            setIsCopy(false);
        }, 3000);
    };

    return (
        <>
            {loading ? (
                <IconButton color="white" className={`ring-1 bg-gray-400/50 animate-pulse ring-gray-500/40 rounded-full transition-all`}>
                    <></>
                </IconButton>
            ) : (
                <>
                    <Popover placement="bottom-end">
                        <PopoverHandler>
                            <IconButton
                                color="white"
                                variant="outlined"
                                className="hidden md:block border-gray-400/50 shadow hover:shadow-lg hover:rounded-full transition-all duration-300 rounded-full bg-white hover:bg-gray-50 text-black"
                            >
                                <CustomFontAwesomeIcon icon="share" />
                            </IconButton>
                        </PopoverHandler>
                        <PopoverContent className="p-0 z-40">
                            <div className="py-2 px-4  transition-all rounded-lg hover:bg-orange-500 hover:text-white w-full">
                                <FacebookShareButton url={shareUrl} className="Demo__some-network__share-button flex gap-2 items-center">
                                    <FacebookIcon size={16} round /> Facebook
                                </FacebookShareButton>
                            </div>
                            <div className="py-2 px-4  transition-all rounded-lg hover:bg-orange-500 hover:text-white w-full">
                                <LinkedinShareButton url={shareUrl} className="Demo__some-network__share-button flex gap-2 items-center">
                                    <LinkedinIcon size={16} round /> LinkedIn
                                </LinkedinShareButton>
                            </div>
                            <div className="py-2 px-4  transition-all rounded-lg hover:bg-orange-500 hover:text-white w-full">
                                <PinterestShareButton
                                    url={shareUrl}
                                    media={`Ezoom`}
                                    className="Demo__some-network__share-button flex gap-2 items-center"
                                >
                                    <PinterestIcon size={16} round /> Pinterest
                                </PinterestShareButton>
                            </div>
                            <a
                                href={`https://www.snapchat.com/add?shareURL=${encodeURIComponent(shareUrl)}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="Demo__some-network__share-button flex gap-2 items-center py-2 px-4  transition-all rounded-lg hover:bg-orange-500 hover:text-white w-full"
                            >
                                <img src={SnapChatLogo} className="w-4 rounded-full" /> Snapchat
                            </a>
                            <div className="py-2 px-4  transition-all rounded-lg hover:bg-orange-500 hover:text-white w-full">
                                <TwitterShareButton url={shareUrl} className="Demo__some-network__share-button flex gap-2 items-center">
                                    <XIcon size={16} round /> X
                                </TwitterShareButton>
                            </div>
                            <div className="py-2 px-4  transition-all rounded-lg hover:bg-orange-500 hover:text-white w-full">
                                <EmailShareButton
                                    url={shareUrl}
                                    subject={"Ezoom"}
                                    body=""
                                    className="Demo__some-network__share-button flex gap-2 items-center"
                                >
                                    <EmailIcon size={16} round /> Mail
                                </EmailShareButton>
                            </div>
                            <div className="py-2 px-4  transition-all rounded-lg hover:bg-orange-500 hover:text-white w-full">
                                <WhatsappShareButton url={shareUrl} className="Demo__some-network__share-button flex gap-2 items-center">
                                    <WhatsappIcon size={16} round /> Whatsapp
                                </WhatsappShareButton>
                            </div>
                            <div onClick={() => copyShareUrl()} className="py-2 px-4 rounded-lg transition-all hover:bg-orange-500 hover:text-white flex gap-2 w-full items-center">
                                <CustomFontAwesomeIcon icon={isCopy ? "check" : "file"} className="w-4" /> {t("copyLink")}
                            </div>
                            {navigator.share !== undefined && (
                                <div 
                                    onClick={async () => {
                                        try {
                                            await navigator.share({
                                                url: shareUrl,
                                                title: "Ezoom",
                                                text: "Ezoom",
                                            });
                                        } catch (error: any) {
                                            if (error.name === "AbortError") {
                                                console.error("Partage annulé par l'utilisateur");
                                            } else {
                                                console.error("Erreur lors du partage :", error);
                                            }
                                        }
                                    }}
                                    className="py-2 px-4 rounded-lg transition-all hover:bg-orange-500 hover:text-white flex gap-2 w-full items-center"
                                >
                                    <CustomFontAwesomeIcon icon="ellipsis" className="w-4" /> {t("other")}
                                </div>
                            )}
                        </PopoverContent>
                    </Popover>
                    <Popover placement="bottom-end">
                        <PopoverHandler>
                            <IconButton
                                color="white"
                                size="sm"
                                variant="outlined"
                                className="flex md:hidden border-gray-400/50 shadow hover:shadow-lg hover:rounded-full transition-all duration-300 rounded-full bg-white hover:bg-gray-50 text-black"
                            >
                                <CustomFontAwesomeIcon icon="share" />
                            </IconButton>
                        </PopoverHandler>
                        <PopoverContent className="p-0 z-40">
                            <div className="py-2 px-4  transition-all rounded-lg hover:bg-orange-500 hover:text-white w-full">
                                <FacebookShareButton url={shareUrl} className="Demo__some-network__share-button flex gap-2 items-center">
                                    <FacebookIcon size={16} round /> Facebook
                                </FacebookShareButton>
                            </div>
                            <div className="py-2 px-4  transition-all rounded-lg hover:bg-orange-500 hover:text-white w-full">
                                <LinkedinShareButton url={shareUrl} className="Demo__some-network__share-button flex gap-2 items-center">
                                    <LinkedinIcon size={16} round /> LinkedIn
                                </LinkedinShareButton>
                            </div>
                            <div className="py-2 px-4  transition-all rounded-lg hover:bg-orange-500 hover:text-white w-full">
                                <PinterestShareButton
                                    url={shareUrl}
                                    media={`Ezoom`}
                                    className="Demo__some-network__share-button flex gap-2 items-center"
                                >
                                    <PinterestIcon size={16} round /> Pinterest
                                </PinterestShareButton>
                            </div>
                            <a
                                href={`https://www.snapchat.com/add?shareURL=${encodeURIComponent(shareUrl)}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="Demo__some-network__share-button flex gap-2 items-center py-2 px-4  transition-all rounded-lg hover:bg-orange-500 hover:text-white w-full"
                            >
                                <img src={SnapChatLogo} className="w-4 rounded-full" /> Snapchat
                            </a>
                            <div className="py-2 px-4  transition-all rounded-lg hover:bg-orange-500 hover:text-white w-full">
                                <TwitterShareButton url={shareUrl} className="Demo__some-network__share-button flex gap-2 items-center">
                                    <XIcon size={16} round /> X
                                </TwitterShareButton>
                            </div>
                            <div className="py-2 px-4  transition-all rounded-lg hover:bg-orange-500 hover:text-white w-full">
                                <EmailShareButton
                                    url={shareUrl}
                                    subject={"Ezoom"}
                                    body="body"
                                    className="Demo__some-network__share-button flex gap-2 items-center"
                                >
                                    <EmailIcon size={16} round /> Mail
                                </EmailShareButton>
                            </div>
                            <div className="py-2 px-4  transition-all rounded-lg hover:bg-orange-500 hover:text-white w-full">
                                <WhatsappShareButton url={shareUrl} className="Demo__some-network__share-button flex gap-2 items-center">
                                    <WhatsappIcon size={16} round /> Whatsapp
                                </WhatsappShareButton>
                            </div>
                            <div onClick={() => copyShareUrl()} className="py-2 px-4 rounded-lg transition-all hover:bg-orange-500 hover:text-white flex gap-2 w-full items-center">
                                <CustomFontAwesomeIcon icon={isCopy ? "check" : "file"} className="w-4" /> {t("copyLink")}
                            </div>
                            {navigator.share !== undefined && (
                                <div 
                                    onClick={async () => {
                                        try {
                                            await navigator.share({
                                                url: shareUrl,
                                                title: "Ezoom",
                                                text: "Ezoom",
                                            });
                                        } catch (error: any) {
                                            if (error.name === "AbortError") {
                                                console.error("Partage annulé par l'utilisateur");
                                            } else {
                                                console.error("Erreur lors du partage :", error);
                                            }
                                        }
                                    }}
                                    className="py-2 px-4 rounded-lg transition-all hover:bg-orange-500 hover:text-white flex gap-2 w-full items-center"
                                >
                                    <CustomFontAwesomeIcon icon="ellipsis" className="w-4" /> {t("other")}
                                </div>
                            )}
                        </PopoverContent>
                    </Popover>
                </>
            )}
        </>
    );
}
