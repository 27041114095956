import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Card, CardBody, CardHeader, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { PictureType } from "../../components/Interfaces/PictureType";

import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import GradientButton from "../../components/Form/Button/GradientButtonBig";
import Loader from "../../components/Loader";
import TitlePage from "../../components/TitlePage";
import WrapperGrayZone from "../../components/WrapperGrayZone";
import PicturesAPI from "../../services/API/Clients/PicturesAPI";

import { Link, useParams } from "react-router-dom";
import AvatarDefaultEzoom from "../../assets/images/elements/default-profile.png";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import { handleError } from "../../services/Errors/handleErrors";
import OrderAPI from "../../services/API/Clients/OrderAPI";
import CustomFontAwesomeIcon from "../../components/CustomFontAwesomeIcon";

export default function Download() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("download")}`;
    const [searchBar, setSearchBar] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [loading, setLoading] = useState(true);
    const [picture, setPicture] = useState<PictureType>({
        id: "",
        exifData: [],
        keywords: [],
        category: {
            enumValue: "",
            frLabel: "",
            enLabel: "",
        },
        nsfw: false,
        license: "",
        contract: {
            id: "",
        },
        uploadedAt: "",
        favoriteCount: 0,
        viewCount: 0,
        publicPath: "",
        watermarkUptoDate: false,
        canBeBought: false,
        minPath: "",
        isFavorited: false,
        galleries: [],
        identifiablePeople: false,
        identifiableProperties: false,
        portfolios: [],
        watermark: "",
        writtenContract: false,
        canUpdateIdentifiables: false,
        canUpdateNsfw: false,
        isOwned: false,
        publishedAt: "",
        validationStatus: "",
        public: false
    });
    const [contractsDetails, setContractsDetails] = useState<{
        id: string
        picture: {
            id: string
            uploadedAt: string
            width: number
            height: number
            minPath: string
        }
        contractNumber: string
        name: string
        available: string
        exclusive: boolean
        limited: boolean
        commercialUse: boolean
        autonomous: boolean
        branding: boolean
        forSale: boolean
        digitalModels: boolean
        print: boolean
        useByEmployerClient: boolean
        useBySubcontractor: boolean
        userCount: string
        basePrice: number
        createdAt: string
        originalCount?: number
    }>();
    const [linkToDownload, setLinkToDownload] = useState("");
    const { id, contract } = useParams();

    const fetchData = async () => {
        const response = await PicturesAPI.getPicture(id ?? "");
        if (response.status === 200) {
            setPicture(response.body);
        } else {
            handleError(response);
        }
        const responseDownload = await PicturesAPI.downloadPicture(id ?? "");
        if (responseDownload.status === 200) {
            const imageUrl = responseDownload.body.originalPath;
            setLinkToDownload(imageUrl);
        } else {
            handleError(responseDownload);
        }
    };

    const fetchDetailsContracts = async () => {
        if (contract) {
            const response = await OrderAPI.getContractDetails(contract);
            if (response.status === 200) {
                setContractsDetails(response.body);
            } else {
                handleError(response);
            }
        }
    }

    useEffect(() => {
        setLoading(false);
        fetchData();
        fetchDetailsContracts();
    }, []);

    return (
        <>
            <div className="min-h-screen bg-gray-50 relative">
                <HeaderForAnimation />
                <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} isVisible={isVisible} setIsVisible={setIsVisible} />
                <Loader loading={loading} />
                <div className="flex flex-col h-full bg-white -mt-4 pt-4 pb-[340px] md:pb-36">
                    <TitlePage>
                        <CustomFontAwesomeIcon icon="download" className="mr-1 text-orange-500" />
                        {t("download")}
                    </TitlePage>
                    <WrapperGrayZone pt="6" px="8">
                        <div className="flex flex-col md:flex-row max-w-7xl mx-auto">
                            <div className="flex flex-col w-full md:w-1/2">
                                <Card shadow={false} className="relative grid h-[34rem] w-full items-end justify-center overflow-hidden text-center">
                                    <CardHeader
                                        floated={false}
                                        shadow={false}
                                        color="transparent"
                                        className="absolute inset-0 m-0 h-full w-full rounded-none bg-gray-100 bg-contain bg-center bg-no-repeat px-4"
                                        style={{ backgroundImage: `url(${picture.publicPath})` }}
                                    >
                                        <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/90 via-black/20" />
                                    </CardHeader>
                                    <CardBody className="relative py-14 px-6 md:px-12">
                                        {picture?.portfolio && (
                                            <>
                                                <Typography variant="h5" className="mb-2 text-gray-300">
                                                    {picture?.portfolio?.name}
                                                </Typography>
                                                <Avatar size="xl" variant="circular" alt="tania andrew" src={picture?.portfolio?.picture ?? AvatarDefaultEzoom} />
                                            </>
                                        )}
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="flex flex-col w-full md:w-1/2">
                                <table className="h-full w-full table-auto text-left">
                                    <tr>
                                        <th className="border-b p-4 border-r border-blue-gray-100/80 rounded-tl">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className={`border-r border-blue-gray-100/50 font-normal leading-none opacity-70 rounded-tl ${picture?.title ? "text-black" : "text-gray-500"}`}
                                            >
                                                {t("title")}
                                            </Typography>
                                        </th>
                                        <td className="p-4 border-b border-blue-gray-100/80">
                                            <Typography variant="small" color="blue-gray" className={`font-normal opacity-60 ${picture?.title ? "text-black" : "text-gray-500"}`}>
                                                {picture?.title ?? "-"}
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="border-b p-4 border-r border-blue-gray-100/80 rounded-tl">
                                            <Typography variant="small" color="blue-gray" className={`font-normal leading-none opacity-70 rounded-tl ${picture?.description ? "text-black" : "text-gray-500"}`}>
                                                {t("caption")}
                                            </Typography>
                                        </th>
                                        <td className="p-4 border-b border-blue-gray-100/80">
                                            <Typography variant="small" color="blue-gray" className={`font-normal opacity-60 ${picture?.description ? "text-black" : "text-gray-500"}`}>
                                                {picture?.description ?? "-"}
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="border-b p-4 border-r border-blue-gray-100/80 rounded-tl">
                                            <Typography variant="small" color="blue-gray" className={`font-normal leading-none opacity-70 rounded-tl ${picture?.exifData?.find((item) => item.key === "file.MimeType")?.value ? "text-black" : "text-gray-500"}`}>
                                                {t("format")}
                                            </Typography>
                                        </th>
                                        <td className="p-4 border-b border-blue-gray-100/80">
                                            <Typography variant="small" color="blue-gray" className={`font-normal opacity-60 ${picture?.exifData?.find((item) => item.key === "file.MimeType")?.value ? "text-black" : "text-gray-500"}`}>
                                                JPEG
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="border-b p-4 border-r border-blue-gray-100/80 rounded-tl">
                                            <Typography variant="small" color="blue-gray" className={`font-normal leading-none opacity-70 rounded-tl text-black`}>
                                                {t("dimensions")}
                                            </Typography>
                                        </th>
                                        <td className="p-4 border-b border-blue-gray-100/80">
                                            <Typography variant="small" color="blue-gray" className="font-normal opacity-60 text-black">
                                                {t(picture?.height?.toString() ?? "/")} x {t(picture?.width?.toString() ?? "/")} px
                                            </Typography>
                                        </td>
                                    </tr>
                                    {contractsDetails && (
                                        <tr>
                                            <th className="border-b p-4 border-r border-blue-gray-100/80 rounded-tl">
                                                <Typography variant="small" color="blue-gray" className={`font-normal leading-none opacity-70 rounded-tl ${picture?.description ? "text-black" : "text-gray-500"}`}>
                                                    {t("exemplaire")}
                                                </Typography>
                                            </th>
                                            <td className="p-4 border-b border-blue-gray-100/80">
                                                <Typography variant="small" color="blue-gray" className={`font-normal opacity-60 ${picture?.description ? "text-black" : "text-gray-500"}`}>
                                                    {contractsDetails?.available === "UNLIMITED" ? (
                                                        contractsDetails?.contractNumber + " / ∞"
                                                    ) : contractsDetails?.available === "SINGLE" ? (
                                                        "1 / 1"
                                                    ) : (
                                                        contractsDetails?.contractNumber + " / " + contractsDetails?.originalCount
                                                    )}
                                                </Typography>
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <th className="border-b p-4 border-r border-blue-gray-100/80 rounded-tl">
                                            <Typography variant="small" color="blue-gray" className={`font-normal leading-none opacity-70 rounded-tl text-black`}>
                                                {t("commercialUse")}
                                            </Typography>
                                        </th>
                                        <td className="p-4 border-b border-blue-gray-100/80">
                                            <Typography variant="small" color="blue-gray" className="font-normal opacity-60 text-black">
                                                {picture?.contract?.commercialUse ? t("yes") : t("nop")}
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="border-b p-4 border-r border-blue-gray-100/80 rounded-tl">
                                            <Typography variant="small" color="blue-gray" className={`font-normal leading-none opacity-70 rounded-tl ${picture?.contract?.basePrice ? "text-black" : "text-gray-500"}`}>
                                                {t("price")}
                                            </Typography>
                                        </th>
                                        <td className="p-4 border-b border-blue-gray-100/80">
                                            <Typography variant="small" color="blue-gray" className={`font-normal opacity-60  ${picture?.contract?.basePrice ? "text-black" : "text-gray-500"}`}>
                                                CHF {t(picture?.contract?.basePrice?.toFixed(2).toString() ?? "/")}
                                            </Typography>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div className="mt-4 max-w-7xl mx-auto w-fit">
                            <Link to={linkToDownload} download={true} target="_blank">
                                <GradientButton text="downloading" />
                            </Link>
                        </div>

                        <Typography
                            variant="h6"
                            className="mt-8 text-center text-blue-gray-700 border-b border-blue-gray-100/80 pb-4 max-w-7xl mx-auto font-bold"
                        >
                            {t("exif")}
                        </Typography>
                        <table className="max-w-7xl mx-auto w-full table-auto text-left">
                            {picture.exifData?.length > 0 &&
                                picture.exifData.map((exif) => {
                                    if (exif.value && exif.key !== "computed.html")
                                        return (
                                            <tr key={exif.key}>
                                                <th className="border-b p-4 border-r border-blue-gray-100/80 rounded-tl w-1/2">
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal leading-none opacity-70 rounded-tl text-black"
                                                    >
                                                        {t(exif.key)}
                                                    </Typography>
                                                </th>
                                                <td className="p-4 border-b border-blue-gray-100/80">
                                                    <Typography variant="small" color="blue-gray" className="font-normal opacity-60 text-black">
                                                        {exif.value}
                                                    </Typography>
                                                </td>
                                            </tr>
                                        );
                                    return null;
                                })}
                        </table>
                    </WrapperGrayZone>
                </div>
                <Footer />
            </div>
        </>
    );
}
