import { Chip, IconButton, Popover, PopoverContent, PopoverHandler, Tooltip } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PieChart } from "react-minimal-pie-chart";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DefaultBannerEzoom from "../../../../assets/images/elements/default-banner.png";
import DefaultPictureEzoom from "../../../../assets/images/elements/default-profile.png";
import SharingBox from "../../../../components/Box/SharingBox";
import { OpenReportPortfolio } from "../../../../components/Modals/ModalPortfolioReport";
import ModalSignInUp from "../../../../components/Modals/ModalSignInUp";
import PortfolioAPI from "../../../../services/API/Clients/PortfolioAPI";
import UserAPI from "../../../../services/API/Clients/UserAPI";
import { handleError } from "../../../../services/Errors/handleErrors";
import { updatePortfolio, updateRepartition, updateSubscribed } from "../../../../services/redux/actions/formAction";
import { formatLongNumber } from "../../../../services/redux/actions/formatNumber";
import { RootState } from "../../../../services/redux/store";
import { Storage } from "../../../../services/storage";
import { Link } from "react-router-dom";
import { updateGeneral } from "../../../../services/redux/actions/generalActions";
import CustomFontAwesomeIcon from "../../../../components/CustomFontAwesomeIcon";
import { isValidHttpUrl } from "../../../../services/hooks/isValidUrl";

const myIds = Storage.getId();

export default function HeaderDesktop({
    actualUrl,
    isPersonnal,
    handleLinkClick,
}: Readonly<{
    actualUrl: string;
    isPersonnal: boolean;
    handleLinkClick: (url: string) => void;
}>) {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const general = useSelector((state: RootState) => state.general);
    const portfolio = useSelector((state: RootState) => state.form);
    const [openInvitation, setOpenInvitation] = useState(false);
    const myId = Storage.getId();
    const dispatch = useDispatch();

    const chooseUrl = () => {
        if (isPersonnal) {
            switch (window.location.pathname.split("/")[2]) {
                case "photos":
                    return `/portfolio/edit/photos`;
                case "about":
                    return `/portfolio/edit/about`;
                case "performances":
                    return `/portfolio/edit/performances`;
                case "performance":
                    return `/portfolio/edit/performance/${window.location.pathname.split("/")[3]}`;
                case "opinion":
                    return `/portfolio/edit/photos`;
                case "contact":
                    return `/portfolio/edit/photos`;
                default:
                    return `/portfolio/edit/photos`;
            }
        } else {
            switch (window.location.pathname.split("/")[3]) {
                case "photos":
                    return `/portfolio/edit/photos`;
                case "about":
                    return `/portfolio/edit/about`;
                case "performances":
                    return `/portfolio/edit/performances`;
                case "performance":
                    return `/portfolio/edit/performance/${window.location.pathname.split("/")[4]}`;
                case "opinion":
                    return `/portfolio/edit/photos`;
                case "contact":
                    return `/portfolio/edit/photos`;
                default:
                    return `/portfolio/edit/photos`;
            }
        }
    };

    const followPortfolio = async () => {
        if (myId) {
            const datas = {
                owner: `/api/users/${myId}`,
                portfolio: `/api/public/portfolios/${id}`,
            };
            if (portfolio.isSubscribed) {
                const responseUnfollow = await PortfolioAPI.unfollow(datas);
                if (responseUnfollow.status === 200 || responseUnfollow.status === 201 || responseUnfollow.status === 204) {
                    toast.success(t("portfolio_unfollow_success"));
                    dispatch(updateSubscribed(false));
                    const regetPortfolio = await PortfolioAPI.portfolio(portfolio.id);
                    if (regetPortfolio.status === 200) {
                        dispatch(updatePortfolio(regetPortfolio.body));
                    } else {
                        handleError(regetPortfolio);
                    }
                    const regetRepas = await PortfolioAPI.getRepartition(portfolio.id);
                    if (regetRepas.status === 200) {
                        dispatch(updateRepartition(regetRepas.body));
                    } else {
                        handleError(regetRepas);
                    }
                } else {
                    handleError(responseUnfollow);
                }
            } else {
                const responseFollow = await PortfolioAPI.follow(datas);
                if (responseFollow.status === 200 || responseFollow.status === 201) {
                    toast.success(t("portfolio_follow_success"));
                    dispatch(updateSubscribed(true));
                    const regetPortfolio = await PortfolioAPI.portfolio(portfolio.id);
                    if (regetPortfolio.status === 200) {
                        dispatch(updatePortfolio(regetPortfolio.body));
                    } else {
                        handleError(regetPortfolio);
                    }
                    const regetRepas = await PortfolioAPI.getRepartition(portfolio.id);
                    if (regetRepas.status === 200) {
                        dispatch(updateRepartition(regetRepas.body));
                    } else {
                        handleError(regetRepas);
                    }
                } else {
                    handleError(responseFollow);
                }
            }
        }
    };

    const handleDisabled = async () => {
        if (!general?.me?.id) return;
        const dataListDisables = {
            "dialogToClose": "portfolio_welcome"
        }
        const response = await UserAPI.setDialogs(general?.me?.id, dataListDisables);
        if (response.status === 200 || response.status === 201) {
            navigate("/portfolio/edit/photos")
        } else {
            handleError(response);
        }
    }

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
    };

    return (
        <>
            <ModalSignInUp open={openInvitation} setOpen={setOpenInvitation} />
            <div className={`${(!general.me?.closedDialogs.includes("portfolio_welcome") && general?.me?.portfolio?.id === portfolio?.id) ? "hidden md:flex" : "hidden" } z-40 fixed top-0 left-0 bg-black/50 w-screen h-screen`}>
            </div>
            <div
                className="hidden md:flex relative bg-cover aspect-[18/6]"
                style={{
                    backgroundPositionY: `${100 - (portfolio.bannerOffset ?? 0)}%`,
                    backgroundColor: "#cbd5e1",
                    backgroundImage: `url(${isValidHttpUrl(portfolio.banner) ? portfolio.banner ?? DefaultBannerEzoom : DefaultBannerEzoom})`,
                }}
            >
                <div className="absolute left-1 md:left-8 -bottom-10 md:-bottom-20 h-20 md:h-40 md:w-[70%]">
                    <div className="flex flex-row gap-4">
                        <div className="w-20 h-20 md:w-40 md:h-40 bg-white rounded-full">
                            <img
                                onContextMenu={handleContextMenu}
                                alt="vector"
                                className="w-full h-full min-w-[160px] object-cover object-center rounded-full border-4 border-white"
                                src={portfolio.loading ? DefaultPictureEzoom : portfolio.picture ?? DefaultPictureEzoom}
                            />
                        </div>
                        <div className="flex flex-col justify-center mt-4 md:mt-16 min-w-0">
                            {portfolio.loading ? (
                                <div className="h-10 bg-slate-300 animate-pulse rounded-lg mt-2"></div>
                            ) : (
                                <div className="text-xl md:text-4xl font-bold mt-6 flex items-end gap-2 w-full min-w-0">
                                    <span className="truncate min-w-0">{portfolio.name}</span>
                                    {portfolio.owner.verified && (
                                        <CustomFontAwesomeIcon icon="circle-check" className="text-base text-orange-500 mb-2" />
                                    )}
                                </div>
                            )}
                            <div className="hidden md:flex flex-row gap-2 mt-1">
                                {portfolio.loading ? (
                                    <div className="h-6 animate-pulse flex gap-2">
                                        <div className="bg-slate-300 w-12 h-6 rounded-lg"></div>
                                        <div className="bg-slate-300 w-12 h-6 rounded-lg"></div>
                                        <div className="bg-slate-300 w-12 h-6 rounded-lg"></div>
                                    </div>
                                ) : (
                                    <>
                                        {portfolio.plan === "PRO" && (
                                            <Chip
                                                className="w-fit h-fit bg-black text-white"
                                                variant="ghost"
                                                value={t("pro")}
                                                icon={<CustomFontAwesomeIcon icon="crown" className="h-3 ml-0.5" />}
                                                size="sm"
                                            />
                                        )}
                                        {portfolio.plan === "PREMIUM" && (
                                            <Chip
                                                className="w-fit h-fit bg-white text-black border border-black"
                                                color="orange"
                                                variant="ghost"
                                                value={t("premium")}
                                                icon={<CustomFontAwesomeIcon icon="crown" className="h-3 ml-0.5" />}
                                                size="sm"
                                            />
                                        )}

                                        <Popover placement="bottom">
                                            <PopoverHandler>
                                                <Chip
                                                    className="w-fit h-fit cursor-pointer"
                                                    color="purple"
                                                    variant="ghost"
                                                    value={`${portfolio.subscriberCount ?? 0} ${t("subs")}`}
                                                    icon={<CustomFontAwesomeIcon icon="users" className="h-3 ml-0.5" />}
                                                    size="sm"
                                                />
                                            </PopoverHandler>
                                            <PopoverContent>
                                                <div className="flex flex-row gap-2 items-center">
                                                    {portfolio &&
                                                    portfolio.repartition &&
                                                    (portfolio.repartition.users !== 0 || portfolio.repartition.photographers !== 0) ? (
                                                        <PieChart
                                                            data={[
                                                                { title: t("users"), value: portfolio.repartition.users ?? 0, color: "rgb(239, 147, 53)" },
                                                                {
                                                                    title: t("photographs"),
                                                                    value: portfolio.repartition.photographers ?? 0,
                                                                    color: "rgb(98, 81, 153)",
                                                                },
                                                            ]}
                                                            style={{ width: "100px", height: "100px" }}
                                                        />
                                                    ) : (
                                                        <PieChart
                                                            data={[{ title: t("users"), value: 100, color: "#d4d4d4" }]}
                                                            style={{ width: "100px", height: "100px" }}
                                                        />
                                                    )}
                                                    <div className="flex flex-col gap-2">
                                                        <div className="flex flex-row gap-2 items-center">
                                                            <div className="w-4 h-4 bg-orange-400 rounded-full"></div>
                                                            <span>{t("users")}</span>
                                                            <span>{portfolio.repartition.users ?? 0}%</span>
                                                        </div>
                                                        <div className="flex flex-row gap-2 items-center">
                                                            <div className="w-4 h-4 bg-purple-400 rounded-full"></div>
                                                            <span>{t("photographs")}</span>
                                                            <span>{portfolio.repartition.photographers ?? 0}%</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </PopoverContent>
                                        </Popover>
                                        {(portfolio.avgNote !== -1 && portfolio.features.actions.testimonial) && (
                                            <Chip
                                                className="w-fit h-fit"
                                                color="purple"
                                                variant="ghost"
                                                value={`${portfolio.avgNote === -1 ? t("nonote") : Number(portfolio.avgNote).toFixed(2)}`}
                                                icon={<CustomFontAwesomeIcon icon="star" className="h-3 ml-0.5" />}
                                                size="sm"
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hidden md:flex flex-row justify-end gap-4 mt-2 px-4 pt-16 md:pt-0">
                {!portfolio.loading && myIds && myIds === portfolio.owner.id && (
                    <Tooltip
                        className="hidden md:flex bg-white p-0 mb-1"
                        placement="top-start"
                        content={(
                            <div className="bg-orange-50/20 text-sm text-black px-4 py-3 font-sans rounded-lg">
                                <p className="flex flex-col">
                                    <span className="whitespace-nowrap" dangerouslySetInnerHTML={{ __html: t("datainfoPortfolio") }} />
                                    <button
                                        className="ml-auto border-2 bg-orange-500 hover:bg-orange-200 border-transparent inline w-fit text-white hover:text-black py-1 pl-3 pr-4 mt-2 rounded-full"
                                        style={{
                                            transition: "all .25s ease-in-out",
                                        }}
                                        onClick={() => handleDisabled()}
                                    >
                                        <span className="flex items-center justify-between"><CustomFontAwesomeIcon icon="check-circle" className="mr-1" /> {t("understand")}</span>
                                    </button>
                                </p>
                                <div className="absolute -bottom-2 right-4 w-0 h-0 border-l-8 border-l-transparent border-r-8 border-r-transparent border-t-8 border-t-[#fbf2e1]"></div>
                            </div>
                        )}
                        open={(!general.me?.closedDialogs.includes("portfolio_welcome") && general?.me?.portfolio?.id === portfolio?.id) ? true : false}
                    >
                        <Link to={chooseUrl()}>
                            <button 
                                color="white"
                                className="hidden md:flex border border-gray-400/50 shadow hover:shadow-lg hover:rounded-full transition-all duration-300 rounded-full bg-white hover:bg-gray-50 text-black items-center gap-2 w-auto py-2 px-4"
                            >
                                <CustomFontAwesomeIcon icon="pen" /> {t("modify")}
                            </button>
                        </Link>
                    </Tooltip>
                )}

                {myIds !== portfolio.owner.id && (
                    <button
                        className="border inline w-fit hover:text-black bg-white hover:bg-gray-50 py-2 mt-0.5 px-4 rounded-full"
                        onClick={() => !Storage.getToken() ? setOpenInvitation(!openInvitation) : followPortfolio()}
                    >
                        {portfolio.isSubscribed ? (
                            <span className="flex items-center gap-2">
                                <CustomFontAwesomeIcon icon="user-minus" /> {t("unfollow")}
                            </span>
                        ) : (
                            <span className="flex items-center gap-2">
                                <CustomFontAwesomeIcon icon="user-plus" /> {t("follow")}
                            </span>
                        )}
                    </button>
                )}
                {portfolio.public && (
                    <SharingBox isPicture={false} />
                )}
                {myIds !== portfolio.owner.id && (
                    <Popover placement="bottom-end">
                        <PopoverHandler>
                            <IconButton 
                                color="white"
                                variant="outlined"
                                className="hidden md:flex border-gray-400/50 shadow hover:shadow-lg hover:rounded-full transition-all duration-300 rounded-full bg-white hover:bg-gray-50 text-black"
                            >
                                <CustomFontAwesomeIcon icon="ellipsis-vertical" />
                            </IconButton>
                        </PopoverHandler>
                        <PopoverContent className="p-0">
                            <button
                                className="py-2 px-4 rounded-lg hover:bg-orange-500 hover:text-white w-full"
                                onClick={() => !Storage.getToken() ? setOpenInvitation(!openInvitation) : portfolio?.id && OpenReportPortfolio(portfolio.id, t)}
                            >
                                {t("report")}
                            </button>
                        </PopoverContent>
                    </Popover>
                )}
            </div>

            <div className="hidden md:flex border-b border-gray-200 w-full mx-auto bg-white mt-12 md:mt-16">
                <ul className="flex flex-row flex-nowrap -mb-px text-sm font-medium text-center text-gray-500 justify-center items-end w-full">
                    {portfolio && portfolio.features && portfolio.features.pages && portfolio.features.pages.photos && (
                        <li className="md:mr-2 h-14">
                            <button
                                onClick={() => handleLinkClick(`${isPersonnal ? `/${portfolio.path}/photos` : `/portfolio/${id}/photos`}`)}
                                className={`${
                                    actualUrl === "photos"
                                        ? "border-orange-400 text-orange-400"
                                        : "border-transparent hover:text-gray-600 hover:border-gray-300"
                                } border-b-2 flex-grow inline-flex items-center flex-row gap-2 justify-center py-4 px-2 md:p-4 rounded-t-lg group`}
                            >
                                <CustomFontAwesomeIcon icon="images" />
                                <span className="hidden md:flex">{t("photos")}</span>
                                <div
                                    className={`${
                                        actualUrl === "photos"
                                            ? "text-orange-700 bg-white-100 border border-orange-300"
                                            : "text-gray-500 bg-white-100 border border-gray-500"
                                    } ml-1 flex justify-center items-center font-medium py-1 px-2 bg-white rounded-full`}
                                >
                                    <div className="text-xs font-normal leading-none max-w-full flex-initial">
                                        {formatLongNumber(portfolio.pictureCount ?? 0)}
                                    </div>
                                </div>
                            </button>
                        </li>
                    )}
                    {portfolio && portfolio.features && portfolio.features.pages && portfolio.features.pages.about && (
                        <li className="mr-2 h-14">
                            <button
                                onClick={() => handleLinkClick(`${isPersonnal ? `/${portfolio.path}/about` : `/portfolio/${id}/about`}`)}
                                className={`${
                                    actualUrl === "about" ? "border-purple-400 text-purple-400" : "border-transparent hover:text-gray-600 hover:border-gray-300"
                                } border-b-2 flex-grow inline-flex items-center flex-row gap-2 justify-center py-4 px-2 md:p-4 h-14 rounded-t-lg group`}
                            >
                                <CustomFontAwesomeIcon icon="circle-info" />
                                <span className="hidden md:flex">{t("about")}</span>
                            </button>
                        </li>
                    )}
                    {portfolio && portfolio.features && portfolio.features.pages && portfolio.features.pages.services && (
                        <li className="mr-2 h-14">
                            <button
                                onClick={() => handleLinkClick(`${isPersonnal ? `/${portfolio.path}/performances` : `/portfolio/${id}/performances`}`)}
                                className={`${
                                    actualUrl === "performances" || actualUrl === "performance"
                                        ? "border-purple-400 text-purple-400"
                                        : "border-transparent hover:text-gray-600 hover:border-gray-300"
                                } border-b-2 flex-grow inline-flex items-center flex-row gap-2 justify-center py-4 px-2 md:p-4 rounded-t-lg group`}
                            >
                                <CustomFontAwesomeIcon icon="list-check" />
                                <span className="hidden md:flex">{t("performances")}</span>
                                <div
                                    className={`${
                                        actualUrl === "performances" || actualUrl === "performance"
                                            ? "text-purple-700 bg-white-100 border border-purple-300"
                                            : "text-gray-500 bg-white-100 border border-gray-500"
                                    } ml-1 flex justify-center items-center font-medium py-1 px-2 bg-white rounded-full`}
                                >
                                    <div className="text-xs font-normal leading-none max-w-full flex-initial">
                                        {formatLongNumber((portfolio && portfolio?.services && portfolio?.services.pagination && portfolio?.services?.pagination.totalItems) ?? 0)}
                                    </div>
                                </div>
                            </button>
                        </li>
                    )}
                    {portfolio && portfolio.features && portfolio.features.pages && portfolio.features.pages.testimonials && (
                        <li className="mr-2 h-14">
                            <button
                                onClick={() => handleLinkClick(`${isPersonnal ? `/${portfolio.path}/opinion` : `/portfolio/${id}/opinion`}`)}
                                className={`${
                                    actualUrl === "opinion"
                                        ? "border-purple-400 text-purple-400"
                                        : "border-transparent hover:text-gray-600 hover:border-gray-300"
                                } border-b-2 flex-grow inline-flex items-center flex-row gap-2 justify-center py-4 px-2 md:p-4 rounded-t-lg group`}
                            >
                                <CustomFontAwesomeIcon icon="comments" />
                                <span className="hidden md:flex">{t("opinion")}</span>
                                <div
                                    className={`${
                                        actualUrl === "opinion"
                                            ? "text-purple-700 bg-white-100 border border-purple-300"
                                            : "text-gray-500 bg-white-100 border border-gray-500"
                                    } ml-1 flex justify-center items-center font-medium py-1 px-2 bg-white rounded-full`}
                                >
                                    <div className="text-xs font-normal leading-none max-w-full flex-initial">{portfolio.opinions.data.length ?? 0}</div>
                                </div>
                            </button>
                        </li>
                    )}
                    {portfolio && portfolio.features && portfolio.features.pages && portfolio.features.pages.contact && (
                        <li className="mr-2 h-14">
                            <button
                                onClick={() => handleLinkClick(`${isPersonnal ? `/${portfolio.path}/contact` : `/portfolio/${id}/contact`}`)}
                                className={`${
                                    actualUrl === "contact" || actualUrl === "offers" || actualUrl === "appointment" || actualUrl === "inquiry"
                                        ? "border-purple-400 text-purple-400"
                                        : "border-transparent hover:text-gray-600 hover:border-gray-300"
                                } border-b-2 flex-grow inline-flex items-center flex-row gap-2 justify-center py-4 px-2 md:p-4 h-14 rounded-t-lg group`}
                            >
                                <CustomFontAwesomeIcon icon="envelope" />
                                <span className="hidden md:flex">{t("contact")}</span>
                            </button>
                        </li>
                    )}
                </ul>
            </div>
        </>
    );
}
