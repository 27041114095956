import { SearchPicturesType } from "../../../components/Interfaces/PictureType";
import { Galleries, Locale, PortfolioState, Region, RepartitionType, SearchServicesType, Setup, SocialNetwork } from "../../../components/Interfaces/PortfolioType";

export const updateName = (name: string): any => ({
    type: "UPDATE_NAME",
    payload: name,
});

export const updateDescription = (description: string): any => ({
    type: "UPDATE_DESCRIPTION",
    payload: description,
});

export const updatePublished = (published: boolean): any => ({
    type: "UPDATE_PUBLISHED",
    payload: published,
});

export const updateRegion = (label: string, id: string): any => ({
    type: "UPDATE_REGION",
    payload: {
        id,
        label,
    },
});

export const updateRegions = (regions: Region[]): any => ({
    type: "UPDATE_REGIONS",
    payload: regions,
});

export const updateSocialNetwork = (socialNetwork: SocialNetwork[]): any => ({
    type: "UPDATE_SOCIALNETWORK",
    payload: socialNetwork,
});

export const updateBody = (body: Setup[]): any => ({
    type: "UPDATE_BODY",
    payload: body,
});

export const updateLens = (lens: Setup[]): any => ({
    type: "UPDATE_LENS",
    payload: lens,
});

export const updateFlash = (flash: Setup[]): any => ({
    type: "UPDATE_FLASH",
    payload: flash,
});

export const updateLocales = (locale: Locale[]): any => ({
    type: "UPDATE_LOCALES",
    payload: locale,
});

export const updatePhotoStyle = (style: { enumValue: string, frLabel: string, enLabel: string }[]): any => ({
    type: "UPDATE_PHOTOSTYLES",
    payload: style,
});

export const updatePortfolio = (portfolio: PortfolioState): any => ({
    type: "UPDATE_PORTFOLIO",
    payload: portfolio,
});

export const updateRepartition = (repartition: RepartitionType): any => ({
    type: "UPDATE_REPARTITION",
    payload: repartition,
});

export const updateServices = (services: SearchServicesType): any => ({
    type: "UPDATE_SERVICES",
    payload: services,
});

export const updatePictures = (pictures: SearchPicturesType): any => ({
    type: "UPDATE_PICTURES",
    payload: pictures,
});

export const updateGalleries = (galleries: Galleries[]): any => ({
    type: "UPDATE_GALLERIES",
    payload: galleries,
});

export const updatePicture = (picture: File) => {
    const newPicture = new File([picture], picture.name, { 
        type: picture.type, 
        lastModified: picture.lastModified 
    });
    return {
        type: "UPDATE_PROFILPICTURE",
        payload: newPicture,
    };
};

export const updateOpinion = (opinions: { pagination: { current: number, last: number, totalItems: number, parPage: number }, data: [] }): any => ({
    type: "UPDATE_OPINIONS",
    payload: opinions,
});

export const updateOpinionNote = (value: number): any => ({
    type: "UPDATE_OPINIONS_NOTE",
    payload: value,
});

export const updateBackground = (background: { id: string, minPath: string }): any => ({
    type: "UPDATE_BACKGROUNDPICTURE",
    payload: background,
});

export const updateSubscribed = (isSubscribed: boolean): any => ({
    type: "UPDATE_SUBSCRIBED",
    payload: isSubscribed,
});

export const updateHideLocality = () => ({
    type: "UPDATE_HIDE_LOCALITY",
});