import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip, Typography } from "@material-tailwind/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import GradientButton from "../../../../components/Form/Button/GradientButton";
import { Storage } from "../../../../services/storage";
import { HashLink } from "react-router-hash-link";
import CustomFontAwesomeIcon from "../../../../components/CustomFontAwesomeIcon";

export default function Prices() {
    const { t } = useTranslation();
    const [statePrices, setStatePrices] = React.useState({
        prenium: true,
        pro: true,
    });
    
    const isLogin = Storage.getToken();

    return (
        <div className="bg-white" id="tarif">
            <p className="text-left md:text-center text-4xl m-auto leading-normal px-4 md:max-w-[70vw]">
                {t("amateurorpro")}
            </p>
            <div className="my-12 md:max-w-6xl mx-auto">
                <div className="gap-14 h-auto text-black flex flex-col md:flex-row mx-auto justify-between w-full">
                    <div data-entrance="fade" className="w-full md:w-1/3 bg-gray-50 rounded-lg shadow-xl px-4 py-4 h-fit">
                        <div className="md:h-64 gap-4 md:gap-0 flex flex-col justify-between">
                            <p className="text-left md:text-center font-sans w-fit border-2 border-transparent text-black py-2 px-4 rounded-lg mx-auto">{t("standard")}</p>
                            <p className="text-left md:text-center font-sans w-fit border-2 border-transparent text-black px-4 rounded-lg mx-auto text-xs">{t("standard_p")}</p>
                            <p className="text-left md:text-center text-orange-500 font-sans font-base flex justify-center items-center gap-2 mb-8">
                                <span className="flex items-center justify-center flex-row gap-1">
                                    <span>{t("free")}</span>
                                </span>
                            </p>
                        </div>
                        <ul className="font-serif">
                            <div className="flex flex-row gap-2 w-full px-2 py-2 text-sm items-start">
                                <CustomFontAwesomeIcon icon="check" className="text-purple-500 font-bold pt-1" />
                                <p dangerouslySetInnerHTML={{ __html: t("professionalportfolio_long") }}></p>
                            </div>
                            <div className="flex flex-row gap-2 w-full px-2 py-2 text-sm items-start">
                                <CustomFontAwesomeIcon icon="check" className="text-purple-500 font-bold pt-1" />
                                <p dangerouslySetInnerHTML={{ __html: t("limiteduploads_long") }}></p>
                            </div>
                            <div className="flex flex-row gap-2 w-full px-2 py-2 text-sm items-start">
                                <CustomFontAwesomeIcon icon="check" className="text-purple-500 font-bold pt-1" />
                                <p dangerouslySetInnerHTML={{ __html: t("likeandsavepictures_long") }}></p>
                            </div>
                            <div className="flex flex-row gap-2 w-full px-2 py-2 text-sm items-start">
                                <CustomFontAwesomeIcon icon="check" className="text-purple-500 font-bold pt-1" />
                                <p dangerouslySetInnerHTML={{ __html: t("followphotographers_long") }}></p>
                            </div>
                            <div className="flex flex-row gap-2 w-full px-2 py-2 text-sm items-start">
                                <CustomFontAwesomeIcon icon="check" className="text-purple-500 font-bold pt-1" />
                                <p>
                                    {t("simplysell_long")}
                                </p>
                            </div>
                        </ul>
                    </div>

                    <div data-entrance="fade" data-entrance-delay="300" className="w-full md:w-1/3 bg-gray-50 rounded-lg shadow-xl px-4 py-4 h-fit">
                        <div className="h-64 flex flex-col justify-around">
                            <div className="text-left md:text-center font-sans mb-8">
                                <p className="w-fit bg-white border border-black text-black py-2 px-4 rounded-lg mx-auto"><CustomFontAwesomeIcon icon="crown" className="mr-2" />{t("premium")}</p>
                                <p className="w-fit border-2 border-transparent text-black py-2 px-4 rounded-lg mx-auto text-xs">{t("premium_p")}</p>
                            </div>
                            <div className="flex flex-row items-center justify-around">
                                <span className={`text-sm font-medium ${statePrices.prenium ? "text-gray-500" : "text-purple-500"}`}>{t("monthly")}</span>
                                <label className="relative inline-flex items-center cursor-pointer">
                                    <input
                                        type="checkbox"
                                        onChange={() => setStatePrices({ ...statePrices, prenium: !statePrices.prenium })}
                                        value=""
                                        className="sr-only peer"
                                        checked={statePrices.prenium} // Ajoutez cette ligne pour définir l'état initial
                                    />
                                    <div className="w-11 h-6 bg-gray-300 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-orange-600"></div>
                                </label>
                                <span className={`text-sm font-medium ${!statePrices.prenium ? "text-gray-500" : "text-purple-500"}`}>{t("yearly")}</span>
                            </div>
                            <div className="flex flex-col my-8">
                                <p className={`${statePrices.prenium ? "opacity-100" : "opacity-0"} text-left md:text-center text-gray-500 font-sans font-base flex justify-center gap-2 text-xs line-through`}>
                                    {t("chf")}
                                    <span>10.00 {t("ttc")}</span>
                                    <span>{t("monthly_label")}</span>
                                </p>
                                <p className="text-left md:text-center text-orange-500 font-sans font-base flex justify-center gap-2">
                                    {!statePrices.prenium ? (
                                        <div className="flex flex-col justify-center items-center">
                                            <span className="flex items-center justify-center flex-row gap-1">
                                                {t("chf")}
                                                <span>10.00 {t("ttc")}</span>
                                                <span>{t("monthly_label")}</span>
                                            </span>
                                            <span className="text-xs font-bold text-gray-500 invisible">
                                                {t("orYearly")} {" "}
                                                {t("chf")}
                                                <span>90.00 {t("ttc")}</span>
                                                <span>{t("yearly_label")}</span>
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="flex flex-col justify-center items-center">
                                            <span className="flex items-center justify-center flex-row gap-1">
                                                {t("chf")}
                                                <span>7.50 {t("ttc")}</span>
                                                <span>{t("monthly_label")}</span>
                                                <Chip color="orange" size="sm" value="-25%" />
                                            </span>
                                            <span className="text-xs font-bold text-gray-500">
                                                {t("orYearly")} {" "}
                                                {t("chf")}
                                                <span> 90.00 {t("ttc")}</span>
                                                <span>{t("yearly_label")}</span>
                                            </span>
                                        </div>
                                    )}
                                </p>
                            </div>
                        </div>
                        <ul className="font-serif">
                            <div className="flex flex-row gap-2 w-full px-2 py-2 text-sm items-start">
                                <CustomFontAwesomeIcon icon="check" className="text-purple-500 font-bold pt-1" />
                                <p dangerouslySetInnerHTML={{ __html: t("professionalportfolio_long") }}></p>
                            </div>
                            <div className="flex flex-row gap-2 w-full px-2 py-2 text-sm items-start">
                                <CustomFontAwesomeIcon icon="check" className="text-purple-500 font-bold pt-1" />
                                <p dangerouslySetInnerHTML={{ __html: t("unlimiteduploads_long") }}></p>
                            </div>
                            <div className="flex flex-row gap-2 w-full px-2 py-2 text-sm items-start">
                                <CustomFontAwesomeIcon icon="check" className="text-purple-500 font-bold pt-1" />
                                <p dangerouslySetInnerHTML={{ __html: t("likeandsavepictures_long") }}></p>
                            </div>
                            <div className="flex flex-row gap-2 w-full px-2 py-2 text-sm items-start">
                                <CustomFontAwesomeIcon icon="check" className="text-purple-500 font-bold pt-1" />
                                <p dangerouslySetInnerHTML={{ __html: t("followphotographers_long") }}></p>
                            </div>
                            <div className="flex flex-row gap-2 w-full px-2 py-2 text-sm items-start">
                                <CustomFontAwesomeIcon icon="check" className="text-purple-500 font-bold pt-1" />
                                <p>
                                    {t("sellpictures_long")}
                                </p>
                            </div>
                            <div className="flex flex-row gap-2 w-full px-2 py-2 text-sm items-start">
                                <CustomFontAwesomeIcon icon="check" className="text-purple-500 font-bold pt-1" />
                                <p>
                                    <span className='font-bold'>{t("boostvisibility_long")}</span>
                                </p>
                            </div>
                            <div className="flex flex-row gap-2 w-full px-2 py-2 text-sm items-start">
                                <CustomFontAwesomeIcon icon="check" className="text-purple-500 font-bold pt-1" />
                                <p className="font-bold" dangerouslySetInnerHTML={{ __html: t("accountstats_long") }}></p>
                            </div>
                            <div className="flex flex-row gap-2 w-full px-2 py-2 text-sm items-start">
                                <CustomFontAwesomeIcon icon="check" className="text-purple-500 font-bold pt-1" />
                                <p className="font-bold" dangerouslySetInnerHTML={{ __html: t("customurl_long") }}></p>
                            </div>
                        </ul>
                        <hr className="my-2 border-t-orange-500" />
                        <div className="flex flex-col w-full justify-center items-center gap-3">
                            <Link
                                to={isLogin ? "/subscription/premium" : "/signup?redirect=premium"}
                                className="px-4 py-2 bg-orange-500 text-white rounded-full hover:bg-orange-200 transition-all w-fit mx-auto"
                                dangerouslySetInnerHTML={{ __html: t("obtainPremium") }}
                            ></Link>
                            <HashLink 
                                to="/tarifs#detailTarifs"  
                                className="text-sm text-orange-500 hover:text-orange-200 transition-all mx-auto w-full text-center mb-1"
                            >
                                {t("detail")}
                            </HashLink>
                            <span className="flex gap-2 text-xs mx-auto text-center -mt-2" dangerouslySetInnerHTML={{ __html: t("renewSubscription") }}></span>
                        </div>
                    </div>

                    <div data-entrance="fade" data-entrance-delay="600" className="w-full md:w-1/3 bg-gray-50 rounded-lg shadow-xl px-4 py-4 h-fit relative">
                        <div className="absolute -top-8 sm:-top-6 md:-top-12 right-1 2xl:-top-8 2xl:-right-8 bg-gradient-to-br from-purple-800 to-purple-500 rounded-md flex justify-center items-center p-2 px-6 text-center text-white rotate-6 2xl:rotate-12">
                            <p className="text-xs" dangerouslySetInnerHTML={{ __html: t("choosePhotographer")}}>
                            </p>
                        </div>
                        <div className="h-64 flex flex-col justify-around">
                            <div className="text-left md:text-center font-sans mb-8">
                                <p className="w-fit bg-black text-white py-2 px-4 rounded-lg mx-auto"><CustomFontAwesomeIcon icon="crown" className="mr-2" />{t("pro")}</p>
                                <p className="w-fit border-2 border-transparent text-black py-2 px-4 rounded-lg mx-auto text-xs">{t("pro_p")}</p>
                            </div>
                            <div className="flex flex-row items-center justify-around">
                                <span className={`text-sm font-medium ${statePrices.pro ? "text-gray-500" : "text-purple-500"}`}>{t("monthly")}</span>
                                <label className="relative inline-flex items-center cursor-pointer">
                                    <input
                                        type="checkbox"
                                        onChange={() => setStatePrices({ ...statePrices, pro: !statePrices.pro })}
                                        value=""
                                        className="sr-only peer"
                                        checked={statePrices.pro}
                                    />
                                    <div className="w-11 h-6 bg-gray-300 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-orange-600"></div>
                                </label>

                                <span className={`text-sm font-medium ${!statePrices.pro ? "text-gray-500" : "text-purple-500"}`}>{t("yearly")}</span>
                            </div>
                            <p className={`${statePrices.pro ? "opacity-100" : "opacity-0"} text-left md:text-center text-gray-500 font-sans font-base mt-8 flex justify-center gap-2 text-xs line-through`}>
                                {t("chf")}
                                <span>24.00 {t("ttc")}</span>
                                <span>{t("monthly_label")}</span>
                            </p>
                            <p className="text-left md:text-center text-orange-500 font-sans font-base mb-8 flex justify-center gap-2">
                                {!statePrices.pro ? (
                                    <div className="flex flex-col justify-center items-center">
                                        <span className="flex items-center justify-center flex-row gap-1">
                                            {t("chf")}
                                            <span> 24.00 {t("ttc")}</span>
                                            <span>{t("monthly_label")}</span>
                                        </span>
                                        <span className="text-xs font-bold text-gray-500 invisible">
                                            {t("orYearly")} {" "}
                                            {t("chf")}
                                            <span> 216.00 {t("ttc")}</span>
                                            <span>{t("yearly_label")}</span>
                                        </span>
                                    </div>
                                ) : (
                                    <div className="flex flex-col justify-center items-center">
                                        <span className="flex items-center justify-center flex-row gap-1">
                                            {t("chf")}
                                            <span> 18.00 {t("ttc")}</span>
                                            <span>{t("monthly_label")}</span>
                                            <Chip color="orange" size="sm" value="-25%" />
                                        </span>
                                        <span className="text-xs font-bold text-gray-500">
                                            {t("orYearly")} {" "}
                                            {t("chf")}
                                            <span> 216.00 {t("ttc")}</span>
                                            <span>{t("yearly_label")}</span>
                                        </span>
                                    </div>
                                )}
                            </p>
                        </div>
                        <div className="font-serif flex flex-col">
                            <div className="flex flex-row gap-2 w-full px-2 py-2 text-sm items-start">
                                <CustomFontAwesomeIcon icon="check" className="text-purple-500 font-bold pt-1" />
                                <p dangerouslySetInnerHTML={{ __html: t("professionalportfolio_long") }}></p>
                            </div>
                            <div className="flex flex-row gap-2 w-full px-2 py-2 text-sm items-start">
                                <CustomFontAwesomeIcon icon="check" className="text-purple-500 font-bold pt-1" />
                                <p dangerouslySetInnerHTML={{ __html: t("unlimiteduploads_long") }}></p>
                            </div>
                            <div className="flex flex-row gap-2 w-full px-2 py-2 text-sm items-start">
                                <CustomFontAwesomeIcon icon="check" className="text-purple-500 font-bold pt-1" />
                                <p dangerouslySetInnerHTML={{ __html: t("likeandsavepictures_long") }}></p>
                            </div>
                            <div className="flex flex-row gap-2 w-full px-2 py-2 text-sm items-start">
                                <CustomFontAwesomeIcon icon="check" className="text-purple-500 font-bold pt-1" />
                                <p dangerouslySetInnerHTML={{ __html: t("followphotographers_long") }}></p>
                            </div>
                            <div className="flex flex-row gap-2 w-full px-2 py-2 text-sm items-start">
                                <CustomFontAwesomeIcon icon="check" className="text-purple-500 font-bold pt-1" />
                                <p>
                                    {t("sellpictures_long") }
                                </p>
                            </div>
                            <div className="flex flex-row gap-2 w-full px-2 py-2 text-sm items-start">
                                <CustomFontAwesomeIcon icon="check" className="text-purple-500 font-bold pt-1" />
                                <p>
                                    {t("boostmorevisibility_long_p1")}
                                    <span className='font-bold px-0.5'>{t("boostmorevisibility_long_p2")}</span>
                                    {t("boostmorevisibility_long_p3")}
                                </p>
                            </div>
                            <div className="flex flex-row gap-2 w-full px-2 py-2 text-sm items-start">
                                <CustomFontAwesomeIcon icon="check" className="text-purple-500 font-bold pt-1" />
                                <p dangerouslySetInnerHTML={{ __html: t("accountstats_long_light") }}></p>
                            </div>
                            <div className="flex flex-row gap-2 w-full px-2 py-2 text-sm items-start">
                                <CustomFontAwesomeIcon icon="check" className="text-purple-500 pt-1" />
                                <p dangerouslySetInnerHTML={{ __html: t("customurl_long_light") }}></p>
                            </div>                            
                            <div className="flex flex-row gap-2 w-full px-2 py-2 text-sm items-start">
                                <CustomFontAwesomeIcon icon="check" className="text-purple-500 pt-1" />
                                <p dangerouslySetInnerHTML={{ __html: t("bevisible") }}></p>
                            </div>
                            <div className="flex flex-row gap-2 w-full px-2 py-2 text-sm items-start">
                                <CustomFontAwesomeIcon icon="check" className="text-purple-500 font-bold pt-1" />
                                <p className="font-bold">
                                    {t("sellprestations")}
                                </p>
                            </div>
                            <div className="flex flex-row gap-2 w-full px-2 py-2 text-sm items-start">
                                <CustomFontAwesomeIcon icon="check" className="text-purple-500 font-bold pt-1" />
                                <p dangerouslySetInnerHTML={{ __html: t("createpersonnalgaleries_long") }}></p>
                            </div>
                            <hr className="my-2 border-t-purple-500" />
                            <div className="flex flex-col w-full justify-center items-center gap-3">
                                <Link
                                    to={isLogin ? "/subscription/pro" : "/signup?redirect=pro"}
                                    className="px-4 py-2 bg-purple-500 text-white rounded-full hover:bg-purple-200 transition-all w-fit mx-auto"
                                    dangerouslySetInnerHTML={{ __html: t("obtainPro") }}
                                ></Link>
                                <HashLink to="/tarifs#detailTarifs" 
                                    className="text-sm text-purple-500 hover:text-purple-200 transition-all mx-auto w-full text-center mb-1"
                                >
                                    {t("detail")}
                                </HashLink>
                                <span className="flex gap-2 text-xs mx-auto text-center -mt-2" dangerouslySetInnerHTML={{ __html: t("renewSubscription") }}></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rounded-lg bg-gray-50 shadow-xl p-4 md:max-w-6xl mx-auto text-black mb-8 text-center">
                <p className="w-fit border-2 border-transparent text-black py-2 px-4 rounded-lg mx-auto text-base font-bold">{t("workWithMoreThanOne")}</p>
                <p className="w-fit border-2 border-transparent text-black py-2 px-4 rounded-lg mx-auto text-sm" dangerouslySetInnerHTML={{ __html: t("workWithMoreThanOne_long")}}></p>
            </div>
            <div className="text-left md:text-center px-4">
                <h4 data-entrance="fade" className="text-3xl mb-8">
                    {t("join_us_for_free")}
                </h4>
                <span data-entrance="fade">
                    <Link to="/signup" className="w-fit mx-auto text-left md:text-center">
                        <GradientButton text="signup" />
                    </Link>
                </span>
            </div>
        </div>
    );
}
