import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardBody, CardHeader, Typography } from "@material-tailwind/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import BannerDefaultEzoom from "../../../../assets/images/elements/default-banner.png";
import SwipeGaleryUnique from "../../../../components/Box/Gallery/SwipeGaleryUnique";
import PictureWithoutInfinite from "../../../../components/Box/Photo/PictureWithoutInfinite";
import GradientButton from "../../../../components/Form/Button/GradientButton";
import { PerformanceType } from "../../../../components/Interfaces/PerformanceType";
import ServiceAPI from "../../../../services/API/Clients/ServiceAPI";

import JsonIconDico from "../../../../components/Interfaces/IconDico.json";
import { handleError } from "../../../../services/Errors/handleErrors";
import { DisplayText } from "../../../../services/Search/DisplayText";
import { useSelector } from "react-redux";
import { RootState } from "../../../../services/redux/store";
import CustomFontAwesomeIcon from "../../../../components/CustomFontAwesomeIcon";

export default function PerformanceDetail() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const portfolio = useSelector((state: RootState) => state.form);
    const { perf } = useParams();
    const [isHover, setIsHover] = React.useState(false);
    const [performance, setPerformance] = React.useState<PerformanceType>({
        id: "",
        banner: {
            id: "",
            minPath: "",
            width: 0,
            height: 0,
        },
        bannerOffset: 0,
        title: "",
        priceType: "",
        portfolio: {
            id: "",
            name: "",
            picture: "",
            plan: "",
            publicLink: "",
            isSubscribed: false
        },
        type: {
            enumValue: "",
            frLabel: "",
            enLabel: "",
        },
        pictures: [],
        galleries: [],
        published: false,
        createdAt: "",
        keywords: []
    })

    const fetchDataPerf = async () => {
        if (!perf) return;
        const response = await ServiceAPI.getOne(perf);
        if (response.status === 200) {
            const myData = response.body
            const formattedPictures = response.body.pictures.map((item: { picture: { id: string, minPath: string, width: number, height: number, isFavorited: boolean } }) => {
                return {
                    id: item.picture.id,
                    minPath: item.picture.minPath,
                    width: item.picture.width,
                    height: item.picture.height,
                    portfolio: {
                        name: portfolio.name,
                        picture: portfolio.picture,
                    },
                    isFavorited: item.picture.isFavorited
                }
            });
            const formattedGallery = response.body.galleries.map((item: { gallery: { id: string, title: string, banner: { id: string, width: number, height: number, minPath: string }, bannerOffset: number, date: string } }) => item.gallery);
            const newData: PerformanceType = {
                ...myData,
                pictures: formattedPictures,
                galleries: formattedGallery
            }
            setPerformance(newData)
        } else {
            handleError(response);
        }
    }

    useEffect(() => {
        fetchDataPerf()
    }, [perf]);

    const iconValue = () => {
        return JsonIconDico[performance.type.enumValue as keyof typeof JsonIconDico] as IconProp ?? "home";
    }

    return (
        <div className="mx-auto px-2 md:px-4 py-6 flex flex-col md:flex-row flex-wrap w-full max-w-6xl">
            <div 
                className="flex flex-row items-center cursor-pointer"
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                onClick={() => {
                    const currentPage = window.location.pathname.split("/")[3];
                    if (currentPage === "performance")
                        navigate(`/portfolio/${performance.portfolio.id}/performances`)
                    else
                        navigate(`/${window.location.pathname.split("/")[1]}/performances`)
                }}
            >
                <div
                    className={`w-6 text-sm h-6 mr-2 flex items-center justify-center border transition-all ${isHover ? " hover:border-purple-50 hover:text-purple-50" : "border-purple-500 text-purple-500"} rounded-lg`}
                >
                    <CustomFontAwesomeIcon icon="arrow-left" />
                </div>
                <Typography className={`hidden md:flex text-lg font-bold text-center ${isHover ? "text-gray-500" : "text-black"} transition-all`}>{t("backtoperf")}</Typography>
            </div>
            <div className="w-full h-72 mt-4">
                <Card shadow={false} className="relative grid h-72 w-full items-end justify-center overflow-hidden text-center">
                    <CardHeader
                        floated={false}
                        shadow={false}
                        color="transparent"
                        className="absolute inset-0 m-0 h-full w-full rounded-none bg-cover bg-center"
                        style={{ backgroundImage: `url("${performance?.banner?.minPath ?? BannerDefaultEzoom}")`, backgroundPositionY: `${100 - (performance?.bannerOffset ?? 0)}%`, }}
                    >
                        <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/90 via-black/20" />
                        {!performance.published &&
                            <div className="bg-orange-500 text-white text-xs font-bold px-2 py-1 rounded-full w-fit absolute left-2 bottom-2">
                                {t("unpublished")}
                            </div>
                        }
                    </CardHeader>
                    <CardBody className="absolute mx-auto top-1/2 -translate-y-1/2 flex flex-col justify-center items-center w-full">
                        <Typography className="text-2xl md:text-3xl mb-3 text-white font-sans font-bold" style={{ textShadow: "#000000BB 1px 0 10px" }}>
                            {performance.title}
                        </Typography>
                        <Typography variant="h6" className="text-white mb-3 font-bold text-lg" style={{ textShadow: "#000000BB 1px 0 10px" }}>
                            {t(performance.priceType)} {performance.priceValue && `${t("chf")} ${performance.priceValue.toFixed(2)} `}{performance.priceCeil && `à ${t("chf")} ${performance.priceCeil.toFixed(2)}`}
                        </Typography>
                        <div className="flex flex-col md:flex-row items-center justify-center">
                            <button className="w-fit" onClick={() => {
                                const currentPage = window.location.pathname.split("/")[3];
                                let actualLink
                                if (currentPage === "performance")
                                    actualLink = `/portfolio/${performance.portfolio.id}/offers`;
                                else
                                    actualLink = `/${window.location.pathname.split("/")[2]}/offers`
                                navigate(actualLink)
                            }}>
                                <GradientButton text={t("askdeal")} />
                            </button>
                            <button className="w-fit" onClick={() => {
                                const currentPage = window.location.pathname.split("/")[3];
                                let actualLink
                                if (currentPage === "performance")
                                    actualLink = `/portfolio/${performance.portfolio.id}/appointment`;
                                else
                                    actualLink = `/${window.location.pathname.split("/")[2]}/appointment`
                                navigate(actualLink)
                            }}>
                                <Typography
                                    variant="p"
                                    className="px-4 py-2 font-bold rounded-full mt-4 md:mt-0 md:ml-4 bg-gray-50 hover:bg-gray-50/75 hover:text-black transition"
                                >
                                    {t("takerdv")}
                                </Typography>
                            </button>
                        </div>
                    </CardBody>
                </Card>
            </div>
            <div className="w-full flex flex-col gap-4 mt-4">
                <div className="flex flex-col md:flex-row w-full gap-4">
                    <div className={`w-full ${performance.galleries.length > 0 ? "md:w-2/3" : "md:w-full"} flex flex-col gap-4`}>
                        <div className="w-full flex justify-between">
                            <div>
                                {performance.description && (
                                    <>
                                        <Typography className="text-base font-bold text-left mb-4">{t("caption")}</Typography>
                                        <Typography className="text-sm text-justify">
                                            <DisplayText text={performance.description} />
                                        </Typography>
                                    </>
                                )}
                            </div>
                            {performance.galleries.length === 0 && (
                                <div className="w-fit mb-4 hidden md:flex flex-row gap-2">
                                    <Link to={`/photographers/service/${performance.type.enumValue}`} className="text-xs inline-flex items-center font-extrabold leading-sm uppercase px-3 py-1 bg-purple-200/30 text-purple-700 rounded-full h-fit">
                                        <CustomFontAwesomeIcon icon={iconValue() as any} className="mr-2" /> {performance.type.frLabel}
                                    </Link>
                                    {performance.keywords.length > 0 && performance.keywords.map((key) => (
                                        <div key={key?.customLabel ?? key.keyword?.enumValue} className="text-xs inline-flex items-center leading-sm uppercase px-3 py-1 bg-purple-200/20 text-purple-700 rounded-full h-fit">
                                            {key.customLabel ?? key.keyword?.frLabel}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className={`w-fit mb-6 flex md:hidden ${performance.description && "mt-4"} flex-row gap-2`}>
                            <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-purple-200/30 text-purple-700 rounded-full">
                                Catégorie
                            </div>
                        </div>
                    </div>
                    <div className={`w-full ${performance.galleries.length > 0 ? "md:w-1/3" : "hidden"} flex flex-col`}>
                        <div className="w-fit mb-4 hidden md:flex flex-row gap-2">
                            <Link to={`/photographers/service/${performance.type.enumValue}`} className="text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-purple-200/30 text-purple-700 rounded-full">
                                <CustomFontAwesomeIcon icon={iconValue() as any} className="mr-2" /> {performance.type.frLabel}
                            </Link>
                            {performance.keywords.length > 0 && performance.keywords.map((key) => (
                                <div key={key?.customLabel ?? key?.keyword?.enumValue} className="text-xs inline-flex items-center leading-sm uppercase px-3 py-1 bg-purple-200/20 text-purple-700 rounded-full">
                                    {key?.customLabel ?? key?.keyword?.frLabel}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                
                <div className="flex flex-col-reverse md:flex-row w-full gap-4">
                    <div className={`w-full ${performance.galleries.length > 0 ? "md:w-2/3" : "md:w-full"} flex flex-col`}>
                        {performance.pictures.length > 0 && (
                            <div className="rounded block">
                                <div className="flex flex-row flex-wrap justify-start md:justify-between items-center mb-4">
                                    <Typography className="text-base font-bold text-left">{t("photos")}</Typography>
                                </div>
                                <PictureWithoutInfinite pictures={performance.pictures.map(picture => ({
                                    ...picture,
                                    portfolio: {
                                        ...picture.portfolio,
                                        publicLink: `portfolio/${performance.portfolio.id}`
                                    }
                                }))} heart={true} />
                            </div>
                        )}
                    </div>
                    {performance.galleries.length > 0 && (
                        <div className="w-full md:w-1/3 flex flex-col">
                            <Typography className="text-base font-bold text-left w-full mb-4">{t("galeries")}</Typography>
                            <SwipeGaleryUnique
                                galeries={performance.galleries.map((gallerie) => {
                                    return {
                                        id: gallerie.id,
                                        title: gallerie.title,
                                        banner: {
                                            minPath: gallerie?.banner?.minPath ?? BannerDefaultEzoom,
                                        },
                                        bannerOffset: gallerie.bannerOffset,
                                    };
                                })}
                            />
                        </div>
                    )}
                </div>
            </div>

        </div>
    );
}
