import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Tooltip, Typography } from "@material-tailwind/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DefaultBannerEzoom from "../../../assets/images/elements/default-banner.png";
import DefaultPictureProfil from "../../../assets/images/elements/default-profile.png";
import { useCart } from "../../../providers/CartProvider";
import { usePictureEdit } from "../../../providers/PictureEditProvider";
import { usePictureModal } from "../../../providers/PictureModalProvider";
import FavoriteAPI from "../../../services/API/Clients/FavoriteAPI";
import PicturesAPI from "../../../services/API/Clients/PicturesAPI";
import { handleError } from "../../../services/Errors/handleErrors";
import { Storage } from "../../../services/storage";
import CustomFontAwesomeIcon from "../../CustomFontAwesomeIcon";
import { PictureSearch } from "../../Interfaces/PictureType";
import ModalNsfw from "../../Modals/ModalNsfw";
import ModalSignInUp from "../../Modals/ModalSignInUp";
import { handleBuyIt } from "./components/PictureModalBuyIt";
import { handleDelete } from "./components/PictureModalDelete";

interface PictureAloneProps {
    margin?: string;
    data: {
        id: string;
        nsfw: boolean;
        watermarkUptoDate: boolean;
        canBeBought: boolean;
        validationStatus: string;
        portfolio: {
            id: string;
            name: string;
            plan: string;
            isSubscribed: boolean;
            picture?: string;
            publicLink: string;
        };
        minPath: string;
        isFavorited: boolean;
        isOwned: boolean;
    };
    index: number;
    heart: boolean;
    printNsfw: boolean;
    handleChangeNsfw: (arg: boolean) => void;
    pictures: PictureSearch[];
    loadMore: () => void;
    paddingIsZero?: boolean;
    pictureStyle?: React.CSSProperties;
    style?: React.CSSProperties;
}

interface HoverInfos {
    begin: Date;
    type: "hover" | "touch";
    position: { x: number, y: number };
}

const HOVER_THRESHOLD_MS = 100;
const HOVER_THRESHOLD_DISTANCE = 10;

export default function PictureAlone({
    margin,
    data,
    heart,
    pictures,
    loadMore,
    pictureStyle = {},
    style = {},
    paddingIsZero = false
}: Readonly<PictureAloneProps>) {
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const general = useSelector((state: any) => state.general);
    const picturesNsfwReducer = useSelector((state: any) => state.picturesNsfwReducer);
    const [heartColor, setHeartColor] = React.useState(data.isFavorited);
    const [hoverActive, setHoverActive] = React.useState<HoverInfos | null>(null);
    const [userId, setUserId] = React.useState<string | null>(null);
    const [openNsfw, setOpenNsfw] = React.useState(false);
    const [openInvitation, setOpenInvitation] = React.useState(false);

    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        setUserId(Storage.getId());
    }, []);

    useEffect(() => {
        setUserId(Storage.getId());
    }, [openNsfw]);

    const handleLoveOrUnlove = async (id: string, isHeart: boolean) => {
        if (!userId) {
            setOpenInvitation(true)
            return;
        }
        const undo = heartColor;
        setHeartColor(!heartColor);
        const data = {
            picture: `/api/public/pictures/${id}`,
            owner: `/api/users/${userId}`,
        };
        if (isHeart) {
            const response = await FavoriteAPI.fav(data);
            if (response.status === 200 || response.status === 201) {
                toast.success(t("addToFav"))
                setHeartColor(true);
            } else {
                handleError(response);
                setHeartColor(undo);
            }
        } else {
            const response = await FavoriteAPI.unFav(data);
            if (response.status === 200 || response.status === 201 || response.status === 204) {
                toast.success(t("removeFromFav"));
                setHeartColor(false);
            } else {
                handleError(response);
                setHeartColor(undo);
            }
        }
    };

    const { addToCart } = useCart();

    const handleAddToCart = async () => {
        if (!userId) {
            setOpenInvitation(true)
            return;
        }
        const response = await PicturesAPI.getPicture(data.id);
        if (response.status === 200) {
            const price = response.body.contract.basePrice;
            addToCart({ minPath: data.minPath, id: data.id, price: price });
        } else {
            handleError(response);
        }
    };

    const handleOpenNsfw = (arg: boolean) => {
        setOpenNsfw(false);
        if (arg === true) {
            const nsfw = picturesNsfwReducer.pictures;
            nsfw.push(data.id);

            setOpen(
                {
                    id: data.id,
                    context: pictures,
                    loadMore: loadMore
                }
            )
        }
    };

    const handleOpen = () => {
        setOpenNsfw(true);
    }

    const { open, setOpen } = usePictureModal();

    const { setEditedPicture } = usePictureEdit();

    const onEditPicture = () => {
        setEditedPicture({ id: data.id });
    }

    const handleToBuy = async () => {
        const response = await PicturesAPI.getPicture(data.id);
        if (response.status === 200) {
            handleBuyIt(handleAddToCart, data.id, response.body, t)
        } else {
            handleError(response);
        }
    }

    useEffect(() => {
        const deleteSuccess = Storage.getDelete();
        if (deleteSuccess) {
            toast.success(t("deleteSuccess"));
            Storage.clearDelete();
        }
    }, [t]);

    const navigateTo = (link: string) => {
        setOpen(undefined);
        navigate(`/${link}/photos`);
    }

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
    };

    const beginHover = (type: "hover" | "touch", position: { x: number, y: number }) => {
        setHoverActive({ begin: new Date(), type, position });
    }

    const endHover = () => {
        setHoverActive(null);
    }

    const mobileClick = (e: React.TouchEvent<HTMLButtonElement>, callback: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void) => {
        if (!hoverActive?.begin) return;

        e.stopPropagation();

        const now = new Date();
        const diff = now.getTime() - hoverActive.begin.getTime();

        const distance = Math.sqrt(
            Math.pow(e.changedTouches[0].clientX - hoverActive.position.x, 2) +
            Math.pow(e.changedTouches[0].clientY - hoverActive.position.y, 2)
        );

        if (diff < HOVER_THRESHOLD_MS && distance < HOVER_THRESHOLD_DISTANCE) {
            callback(e as unknown as React.MouseEvent<HTMLImageElement, MouseEvent>);
        }
    }

    const handleClick = (e: React.MouseEvent<HTMLImageElement, MouseEvent>): void => {
        if (data.nsfw && !picturesNsfwReducer?.pictures?.includes(data.id)) {
            return;
        }
        const target = e.target as HTMLElement;
        if (target.closest(".picture-interactions")) return;

        setOpen({
            id: data.id,
            context: pictures,
            loadMore: loadMore
        });
    };

    return (
        <>
            <ModalSignInUp open={openInvitation} setOpen={setOpenInvitation} />
            <ModalNsfw open={openNsfw} setOpen={handleOpenNsfw} />
            <button
                ref={buttonRef}
                className={`block relative rounded-lg overflow-hidden`}
                onMouseEnter={(e) => beginHover("hover", { x: e.clientX, y: e.clientY })}
                onMouseLeave={endHover}
                onTouchStart={(e) => beginHover("touch", { x: e.touches[0]?.clientX ?? 0, y: e.touches[0]?.clientY ?? 0 })}
                onTouchEnd={e => {
                    mobileClick(e, handleClick);
                    setTimeout(endHover, 700)
                }}
                style={{ margin, padding: paddingIsZero ? 0 : 0, width: `100%`, height: `100%`, ...style }}
            >
                <img
                    onContextMenu={handleContextMenu}
                    alt={`picNumber-${data.id}`}
                    className="block w-full object-cover object-center transition-all"
                    src={data.minPath === "https://ezoomstorage.s3.eu-central-2.amazonaws.com/path/to/min/picture" ? DefaultBannerEzoom : data.minPath}
                    onClick={handleClick}
                    style={pictureStyle}
                // onClick={hoverActive?.type === "hover" ? handleClick : () => null}
                />
                {(general?.me && general?.me?.enableNSFWFilter || general?.me?.enableNSFWFilter === undefined)
                    ? data.nsfw && !picturesNsfwReducer?.pictures?.includes(data.id) ? (
                        <button className={`absolute top-0 left-0 w-full h-full opacity-100 transition-all`} onClick={() => handleOpen()}>
                            <div
                                className={`${hoverActive ? "opacity-100" : "opacity-0"
                                    } absolute bottom-0 z-10 h-full w-full flex flex-col gap-2 justify-center items-center transition-all`}
                            >
                                <CustomFontAwesomeIcon icon="eye-slash" className="text-white text-4xl" />
                                <Typography className="text-white text-2xl">{t("nsfw")}</Typography>
                            </div>
                            <div className="absolute bottom-0 w-full h-full rounded-lg bg-gradient-to-b backdrop-blur-lg"></div>
                        </button>
                    ) : (
                        <></>
                    ) : (
                        <></>
                    )
                }
                {heart && (
                    <>
                        <div
                            className={`absolute top-0 left-0 w-full h-full ${hoverActive ? "opacity-100" : "opacity-0"} transition-all`}
                            onClick={(e) => {
                                if ((general?.me && general?.me?.enableNSFWFilter || general?.me?.enableNSFWFilter === undefined) && data.nsfw && !picturesNsfwReducer?.pictures?.includes(data.id)) {
                                    return
                                }
                                const target = e.target as HTMLElement;
                                if (target.closest(".picture-interactions")) return;

                                setOpen({
                                    id: data.id,
                                    context: pictures,
                                    loadMore: loadMore
                                })
                            }}
                        >
                            <div
                                className="absolute bottom-0 w-full h-1/2 bg-gradient-to-b from-white/0 to-black/20 rounded-b-lg"></div>
                        </div>
                        <div className={`${hoverActive ? "flex" : "hidden"} absolute bottom-2 left-3 w-full`}>
                            <button
                                onClick={() => navigateTo(data?.portfolio?.publicLink)}
                                className="text-white font-sans font-bold break-words truncate overflow-hidden w-[60%] text-left flex items-center gap-2 picture-interactions min-w-0"
                            >
                                <Avatar
                                    size="sm"
                                    src={data?.portfolio?.picture ?? DefaultPictureProfil}
                                    className="hover:opacity-70 transition-all"
                                />
                                <span className={`${(buttonRef.current?.clientWidth ?? 0) > 250 ? "flex" : "hidden"} hover:opacity-70 transition-all truncate min-w-0 whitespace-nowrap pt-1`}>
                                    {data?.portfolio?.name}
                                </span>
                            </button>
                        </div>
                        <div className={`absolute bottom-0 right-1 flex flex-row gap-3 p-1 md:p-2 picture-interactions`}>
                            {data.canBeBought && !data.isOwned && (
                                <button 
                                onTouchEnd={e => {
                                    mobileClick(e, () =>!Storage.getToken() ? setOpenInvitation(!openInvitation) : handleToBuy());
                                }}
                                onClick={e => {
                                    if (hoverActive?.type === 'touch') return;
                                    !Storage.getToken() ? setOpenInvitation(!openInvitation) : handleToBuy()
                                }}
                                >
                                    <CustomFontAwesomeIcon icon="shopping-cart" className="text-orange-500 hover:text-orange-100 transition-all text-xl" />
                                </button>
                            )}
                            <button
                                onTouchEnd={e => {
                                    mobileClick(e, () => handleLoveOrUnlove(data.id, !heartColor));
                                }}
                                onClick={e => {
                                    if (hoverActive?.type === 'touch') return;
                                    handleLoveOrUnlove(data.id, !heartColor);
                                }}
                            >
                                <FontAwesomeIcon icon={heartColor ? solidHeart as IconProp : regularHeart as IconProp} className={`${heartColor ? "text-indian-500" : "text-white"} text-xl hover:opacity-70 transition-all`} />
                            </button>
                            {(!data.watermarkUptoDate || data.validationStatus === "pending") && data.isOwned && (
                                <Tooltip
                                    placement="bottom"
                                    className={`border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10 ${hoverActive ? "flex" : "hidden"}`}
                                    content={
                                        <div className="w-80">
                                            <Typography
                                                variant="small"
                                                className="font-normal opacity-80 text-black"
                                            >
                                                {!data.watermarkUptoDate ? t("creating_watermark") : t("waiting_validation_d")}
                                            </Typography>
                                        </div>
                                    }
                                >
                                    <div>
                                        <CustomFontAwesomeIcon icon="clock" className="text-orange-500 text-xl hover:opacity-70 transition-all rounded-full" />
                                    </div>
                                </Tooltip>
                            )}
                            {data.validationStatus === "rejected" && data.isOwned && (
                                <Tooltip
                                    placement="bottom"
                                    className={`border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10 ${hoverActive ? "flex" : "hidden"}`}
                                    content={
                                        <div className="w-80">
                                            <Typography
                                                variant="small"
                                                className="font-normal opacity-80 text-black"
                                            >
                                                {t("photo_refused_small")}
                                            </Typography>
                                        </div>
                                    }>
                                    <div>
                                        <CustomFontAwesomeIcon icon="circle-xmark" className="text-indian-500 text-xl hover:opacity-70 transition-all" />
                                    </div>
                                </Tooltip>
                            )}
                            {data.isOwned && (
                                <div className={`flex gap-3 ${hoverActive ? "flex" : "hidden"}`}>
                                    <button
                                        onClick={onEditPicture}
                                    >
                                        <CustomFontAwesomeIcon icon="pen" className="text-white text-xl hover:opacity-70 transition-all" />
                                    </button>
                                    <button
                                        onClick={() => handleDelete(data.id, t, navigate)}
                                    >
                                        <CustomFontAwesomeIcon icon="trash" className="text-white text-xl hover:opacity-70 transition-all" />
                                    </button>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </button >
        </>
    );
}
