import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer/Footer";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import TitlePage from "../../components/TitlePage";
import WrapperGrayZone from "../../components/WrapperGrayZone";

export default function CGU() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("CG")}`;
    const [searchBar, setSearchBar] = useState(false);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    return (
        <div className="relative min-h-full">
            <HeaderForAnimation />
            <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} isVisible={isVisible} setIsVisible={setIsVisible} />
            <div className="flex flex-col h-full bg-white -mt-4 pt-4 pb-[340px] md:pb-36">
                <div className="flex flex-row md:flex-col items-center justify-between w-full sticky md:static top-2 bg-white z-30 border-b border-gray-200">
                    <TitlePage mt={0} mb={0}>
                        {t("CG_t")}
                    </TitlePage>
                </div>
                <WrapperGrayZone pt="0" px="8">
                    <div className="mx-auto py-6">
                        <div className="-m-1 flex flex-col flex-wrap md:-m-2">
                            <div className="w-full md:w-2/3 mx-auto"></div>
                            <div className="max-w-[1280px] w-full mx-auto">
                                <div className="flex flex-col gap-2 mb-4">
                                    <span className="flex gap-2 text-lg font-bold text-black">{t("mentionslegales")}</span>
                                    <span className="flex gap-2 text-base" dangerouslySetInnerHTML={{ __html: t("mentionslegales_d") }}></span>
                                </div>

                                <span className="font-bold italic">{t("cgu_preambule")}</span>

                                <h1 className="mt-10 text-xl font-bold">{t("cgu_title_1")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_1") }}></p>
                                <p className="mt-4 font-bold italic">{t("cgu_text_1_resume")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("cgu_title_2")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_2") }}></p>
                                <p className="mt-4 font-bold italic">{t("cgu_text_2_resume")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("cgu_title_3")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_3_1") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_3_2") }}></p>
                                <p className="mt-4 font-bold italic">{t("cgu_text_3_resume")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("cgu_title_4")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_4_1") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_4_2") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_4_3") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_4_4") }}></p>
                                <p className="mt-4 font-bold italic">{t("cgu_text_4_resume")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("cgu_title_5")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_5_1") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_5_2") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_5_3") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_5_4") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_5_5") }}></p>

                                <h1 className="mt-10 text-xl font-bold">{t("cgu_title_6")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_6_1") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_6_2") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_6_3") }}></p>
                                <p className="mt-4 font-bold italic">{t("cgu_text_6_resume")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("cgu_title_7")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_7_1") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_7_2") }}></p>
                                <ul className="list-decimal pl-8">
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_7_ul_1") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_7_ul_2") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_7_ul_3") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_7_ul_4") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_7_ul_5") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_7_ul_6") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_7_ul_7") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_7_ul_8") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_7_ul_9") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_7_ul_10") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_7_ul_11") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_7_ul_12") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_7_ul_13") }}></li>
                                </ul>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_7_3") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_7_4") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_7_5") }}></p>
                                <p id="venteOctroiContenu" className="mt-4 font-bold italic">{t("cgu_text_7_resume")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("cgu_title_8")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8") }}></p>
                                <h2 className="mt-4 text-lg underline">{t("cgu_subtitle_8_1")}</h2>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_subtext_8_1") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_subtext_8_2") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_subtext_8_3") }}></p>
                                <h2 className="mt-4 text-lg underline">{t("cgu_subtitle_8_2")}</h2>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_subtext_8_4") }}></p>
                                <ul className="list-decimal pl-8">
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_1_1") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_1_2") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_1_3") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_1_4") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_1_5") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_1_6") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_1_7") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_1_8") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_1_9") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_1_10") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_1_11") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_1_12") }}></li>
                                </ul>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_subtext_8_5") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_subtext_8_6") }}></p>
                                <p className="mt-4 font-bold italic" dangerouslySetInnerHTML={{ __html: t("cgu_subresume_8_1") }}></p>
                                <p className="mt-4 font-bold italic" dangerouslySetInnerHTML={{ __html: t("cgu_subresume_8_2") }}></p>
                                <p className="mt-4 font-bold italic" dangerouslySetInnerHTML={{ __html: t("cgu_subresume_8_3") }}></p>
                                <h2 className="mt-4 text-lg underline">{t("cgu_subtitle_8_3")}</h2>
                                <ul className="list-decimal pl-8">
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_2_1") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_2_2") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_2_3") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_2_4") }}></li>
                                </ul>
                                <p className="mt-4 font-bold italic" id="commissionVenteLicenceContenuSite" dangerouslySetInnerHTML={{ __html: t("cgu_subresume_8_4") }}></p>
                                <h2 className="mt-4 text-lg underline">{t("cgu_subtitle_8_4")}</h2>
                                <ul className="list-decimal pl-8">
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_3_1") }}></li>
                                    <ul className="list-[lower-alpha] pl-8">
                                        <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_3_1_1") }}></li>
                                        <ul className="list-roman pl-8">
                                            <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_3_1_1_1") }}></li>
                                            <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_3_1_1_2") }}></li>
                                            <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_3_1_1_3") }}></li>
                                        </ul>
                                        <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_3_1_2") }}></li>
                                        <ul className="list-roman pl-8">
                                            <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_3_1_2_1") }}></li>
                                            <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_3_1_2_2") }}></li>
                                            <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_3_1_2_3") }}></li>
                                        </ul>
                                        <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_3_1_3") }}></li>
                                        <ul className="list-roman pl-8">
                                            <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_3_1_3_1") }}></li>
                                            <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_3_1_3_2") }}></li>
                                            <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_3_1_3_3") }}></li>
                                        </ul>
                                    </ul>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_3_2") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_3_3") }}></li>
                                </ul>
                                <p className="mt-4 font-bold italic">{t("cgu_subresume_8_5")}</p>
                                <h2 className="mt-4 text-lg underline">{t("cgu_subtitle_8_5")}</h2>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_subtext_8_7") }}></p>

                                <ul className="list-decimal pl-8">
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_4_1") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_4_2") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_4_3") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_4_4") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_4_5") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_4_6") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_8_ul_4_7") }}></li>
                                </ul>
                                <p className="mt-4 font-bold italic" id="plainteDroitAuteur" dangerouslySetInnerHTML={{ __html: t("cgu_subresume_8_6") }}></p>

                                <h1 className="mt-10 text-xl font-bold">{t("cgu_title_10")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_10_1") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_10_2") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_10_3") }}></p>
                                <p className="mt-4 font-bold italic">{t("cgu_resume_10")}</p>
                                <h2 className="mt-4 text-lg underline">{t("cgu_subtitle_10_1")}</h2>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_subtext_10_1_1") }}></p>
                                <ul className="list-[lower-alpha] pl-8">
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_subtext_10_ul_1_1") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_subtext_10_ul_1_2") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_subtext_10_ul_1_3") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_subtext_10_ul_1_4") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_subtext_10_ul_1_5") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_subtext_10_ul_1_6") }}></li>
                                </ul>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_subtext_10_1_2") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_subtext_10_1_3") }}></p>
                                <h2 className="mt-4 text-lg underline">{t("cgu_subtitle_10_2")}</h2>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_subtext_10_2_1") }}></p>
                                <ul className="list-[lower-alpha] pl-8">
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_subtext_10_ul_2_1") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_subtext_10_ul_2_2") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_subtext_10_ul_2_3") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_subtext_10_ul_2_4") }}></li>
                                </ul>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_subtext_10_2_2") }}></p>

                                <h1 className="mt-10 text-xl font-bold">{t("cgu_title_11")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_11") }}></p>
                                <p className="mt-4 font-bold italic">{t("cgu_resume_11")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("cgu_title_12")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_12_1") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_12_2") }}></p>
                                <p className="mt-4 font-bold italic">{t("cgu_resume_12")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("cgu_title_13")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_13") }}></p>
                                <p id="resiliation" className="mt-4 font-bold italic">{t("cgu_resume_13")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("cgu_title_14")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_14_1") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_14_2") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_14_3") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_14_4") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_14_5") }}></p>
                                <p className="mt-4 font-bold italic">{t("cgu_resume_14")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("cgu_title_15")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_15") }}></p>

                                <h1 className="mt-10 text-xl font-bold">{t("cgu_title_16")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_16_1") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_16_2") }}></p>

                                <h1 className="mt-10 text-xl font-bold">{t("cgu_title_17")}</h1>
                                <ul className="list-decimal pl-8">
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_ul_17_1") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_ul_17_2") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_ul_17_3") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_ul_17_4") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_ul_17_5") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_ul_17_6") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_ul_17_7") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_ul_17_8") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgu_text_ul_17_9") }}></li>
                                </ul>

                                <p className="mt-10" dangerouslySetInnerHTML={{ __html: t("cgu_last_modif") }}></p>

                                <hr className="my-16" id="startCGV" />
                                <h1 className="mt-10 text-2xl font-bold">{t("cgv_title")}</h1>
                                <h1 className="mt-10 text-xl font-bold">{t("cgv_title_1")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_text_1_1") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_text_1_2") }}></p>
                                <ul className="list-disc pl-8">
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_text_ul_1_1") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_text_ul_1_2") }}></li>
                                </ul>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_text_1_3") }}></p>
                                <h1 className="mt-10 text-xl font-bold">{t("cgv_title_2")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_text_2_1") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_text_2_2") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_text_2_3") }}></p>
                                <h1 className="mt-10 text-xl font-bold">{t("cgv_title_3")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_text_3_1") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_text_3_2") }}></p>
                                <h1 className="mt-10 text-xl font-bold">{t("cgv_title_4")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_text_4_1") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_text_4_2") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_text_4_3") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_text_4_4") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_text_4_5") }}></p>
                                <h1 className="mt-10 text-xl font-bold">{t("cgv_title_5")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_text_5") }}></p>
                                <h2 className="mt-4 text-lg underline">{t("cgv_subtitle_5_1")}</h2>
                                <ul className="list-decimal pl-8">
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_ul_5_1_1") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_ul_5_1_2") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_ul_5_1_3") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_ul_5_1_4") }}></li>
                                </ul>
                                <h2 className="mt-4 text-lg underline">{t("cgv_subtitle_5_2")}</h2>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_5_2_1") }}></p>
                                <ul className="list-decimal pl-8">
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_ul_5_2_1") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_ul_5_2_2") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_ul_5_2_3") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_ul_5_2_4") }}></li>
                                </ul>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_5_2_2") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_5_2_3") }}></p>
                                <h2 className="mt-4 text-lg underline">{t("cgv_subtitle_5_3")}</h2>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_5_3") }}></p>
                                <h2 className="mt-4 text-lg underline">{t("cgv_subtitle_5_4")}</h2>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_5_4_1") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_5_4_2") }}></p>
                                <h2 className="mt-4 text-lg underline">{t("cgv_subtitle_5_5")}</h2>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_5_5") }}></p>
                                <h2 className="mt-4 text-lg underline">{t("cgv_subtitle_5_6")}</h2>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_5_6_1") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_5_6_2") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_5_6_3") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_5_6_4") }}></p>

                                <h1 className="mt-10 text-xl font-bold">{t("cgv_title_6")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_text_6") }}></p>
                                <h2 className="mt-4 text-lg underline">{t("cgv_subtitle_6_1")}</h2>
                                <ul className="list-decimal pl-8">
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_ul_6_1_1") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_ul_6_1_2") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_ul_6_1_3") }}></li>
                                </ul>
                                <h2 className="mt-4 text-lg underline">{t("cgv_subtitle_6_2")}</h2>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_6_2_1") }}></p>
                                <ul className="list-decimal pl-8">
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_ul_6_2_1") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_ul_6_2_2") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_ul_6_2_3") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_ul_6_2_4") }}></li>
                                </ul>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_6_2_2") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_6_2_3") }}></p>
                                <h2 className="mt-4 text-lg underline">{t("cgv_subtitle_6_3")}</h2>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_6_3_1") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_6_3_2") }}></p>
                                <h2 className="mt-4 text-lg underline">{t("cgv_subtitle_6_4")}</h2>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_6_4_1") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_6_4_2") }}></p>
                                <h2 className="mt-4 text-lg underline">{t("cgv_subtitle_6_5")}</h2>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_6_5_1") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_6_5_2") }}></p>
                                <h2 className="mt-4 text-lg underline">{t("cgv_subtitle_6_6")}</h2>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_6_6") }}></p>
                                <h2 className="mt-4 text-lg underline">{t("cgv_subtitle_6_7")}</h2>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_6_7_1") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_6_7_2") }}></p>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_subtext_6_7_3") }}></p>

                                <h1 className="mt-10 text-xl font-bold">{t("cgv_title_7")}</h1>
                                <ul className="list-decimal pl-8">
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_text_ul_7_1") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_text_ul_7_2") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_text_ul_7_3") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_text_ul_7_4") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_text_ul_7_5") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_text_ul_7_6") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_text_ul_7_7") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_text_ul_7_8") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_text_ul_7_9") }}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("cgv_text_ul_7_10") }}></li>
                                </ul>

                                <p className="mt-10" dangerouslySetInnerHTML={{ __html: t("cgv_last_modif") }}></p>

                            </div>
                        </div>
                    </div>
                </WrapperGrayZone>
            </div>
            <Footer />
        </div>
    );
}
