import { Storage } from "../../storage";
import APIClient from "../APIClients";

const token = Storage.getToken();

export default class ContractsAPI {
    public static async contracts(id: string) {
        return APIClient.ezoom.get(`/contracts?user=${id}`);
    }

    public static async newContract(data: {}) {
        return APIClient.ezoom.post(`/contracts`, data);
    }

    public static async getContract (id: string) {
        return APIClient.ezoom.get(`/contracts/${id}`);
    }

    public static async getContractPdf (id: string) {
        return APIClient.ezoom.getPdf(`/contracts/${id}`);
    }

    public static async putContract(data: {}, id: string) {
        return APIClient.ezoom.put(`/contracts/${id}`, data);
    }

    public static async deleteContract(id: string) {
        return APIClient.ezoom.delete(`/contracts/${id}`);
    }

    public static async staticContract(id: string, picture?: string) {
        if (!token)
            return APIClient.noInterceptor.getPdf(`/public/static-contracts/${id}${picture ? `?image=${picture}` : ""}`);
        else
            return APIClient.ezoom.getPdf(`/public/static-contracts/${id}${picture ? `?image=${picture}` : ""}`);
    }
}
