import Creatable from "react-select/creatable";
import { SingleValue } from "react-select";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InlineInput } from "./InlineInput";

interface Choice {
    value: string;
    label: string;
}

interface DualInputProps {
    leftChoices: Choice[];
    rightChoicesGetter?: (leftChoice: string) => Promise<Choice[]>;
    onChange: (value: [string, string]) => void;
    value: [string, string];
}

export const DualInput = ({ leftChoices, rightChoicesGetter, onChange, value }: DualInputProps) => {
    const [currValue, setCurrValue] = useState<[string, string]>(value);
    const [rightChoices, setRightChoices] = useState<Choice[]>([]);

    const getRightChoices = async (leftChoice: string) => {
        if (rightChoicesGetter) {
            const resp = await rightChoicesGetter(leftChoice);
            setRightChoices(resp);
        }
    };

    const onLeftChange = (v: SingleValue<Choice>) => {
        getRightChoices(v?.value ?? "");
        setCurrValue([v?.value ?? "", value[1]]);
    };

    const onRightChange = (v: string) => {
        setCurrValue([currValue[0], v]);
    };

    useEffect(() => {
        getRightChoices(currValue[0]);
    }, []);

    useEffect(() => {
        onChange(currValue);
    }, [currValue]);

    const t = useTranslation().t;

    return (
        <div className="flex flex-row mt-2 gap-2 items-center">
            <Creatable
                isClearable
                options={leftChoices}
                className="w-1/2 bg-white"
                value={leftChoices.find((brand) => brand.value === currValue[0])}
                onChange={(e) => onLeftChange(e)}
                placeholder={t("brand")}
                menuPortalTarget={document.body}
                menuPosition="fixed"
                formatCreateLabel={(inputValue) => t("createElement", { element: inputValue })}
            />
            <div className="w-1/2 bg-white">
                <InlineInput 
                    value={currValue[1]} 
                    onChange={onRightChange} 
                    values={rightChoices.map((choice) => choice.value)} 
                    label="modelTab"
                />
            </div>
        </div>
    );
};
