import { Avatar, Input, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import DefaultProfilePicture from "../../assets/images/elements/default-profile.png";
import UserAPI from "../../services/API/Clients/UserAPI";
import { handleError } from "../../services/Errors/handleErrors";
import { RootState } from "../../services/redux/store";
import { Storage } from "../../services/storage";
import CustomFontAwesomeIcon from "../CustomFontAwesomeIcon";
import GradientButtonButton from "../Form/Button/GradientButtonButton";
import InputCountry from "../Form/Input/InputCountry";
import InputPhone from "../Form/Input/InputPhone";
import InputPlace from "../Form/Input/InputPlace";

const myId = Storage.getId();

export default function CardMe() {
    const { t } = useTranslation();
    const [uuid, setUuid] = useState<string>("");
    const general = useSelector((state: RootState) => state.general);
    const [datasToEdit, setDatasToEdit] = useState<{
        id: string;
        company: string;
        firstname: string;
        lastname: string;
        country: string;
        adress: {
            id: string;
            label: string;
        };
        email: string;
        phone: string;
        iban: string;
        swiftbic: string;
    }>({
        id: "",
        company: "",
        firstname: "",
        lastname: "",
        country: "",
        adress: {
            id: "",
            label: ""
        },
        email: "",
        phone: "",
        iban: "",
        swiftbic: "",
    });

    const getDatas = async () => {
        if (!myId) return
        const response = await UserAPI.getUser(myId)
        if (response.status === 200 || response.status === 201) {
            const datas = {
                id: myId,
                company: response.body.company ?? "",
                firstname: response.body.firstname,
                lastname: response.body.lastname,
                country: response.body?.country?.code ?? "",
                adress: {
                    id: response.body?.place?.id ?? "",
                    label: response.body?.place?.label ?? ""
                },
                birth: response.body.birthdate ?? "",
                email: response.body.email ?? "",
                phone: response.body.phone ?? "",
                iban: response.body.iban ?? "",
                swiftbic: response.body.bic ?? "",
            }
            setDatasToEdit({ ...datas });
        } else {
            handleError(response);
        }

    }

    const onChange = (arg: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        if (arg.target.name === "adress") {
            setDatasToEdit({
                ...datasToEdit,
                adress: { ...datasToEdit.adress, id: arg.target.value },
            });
        } else
            setDatasToEdit({
                ...datasToEdit,
                [arg.target.name]: arg.target.value,
            });
    }

    const handleChangePhoneNumber = (value: string) => {
        setDatasToEdit({
            ...datasToEdit,
            phone: value,
        });
    }

    const isValidIBAN = (iban: string): boolean => {
        const ibanPattern = /^[A-Z0-9]+$/;
        // remove spaces
        iban = iban.replace(/ /g, "");
        return ibanPattern.test(iban) && iban.length >= 15 && iban.length <= 34;
    }

    const verification = () => {
        const namePattern = /^[a-zA-ZÀ-ÿ\s-]+$/;
        const currentDate = new Date();

        if (datasToEdit.firstname === "") {
            toast.warning(t("firstnameempty"));
            return true;
        }
        if (!namePattern.test(datasToEdit.firstname)) {
            toast.warning(t("firstnameinvalid"));
            return true;
        }
        if (datasToEdit.lastname === "") {
            toast.warning(t("lastnameempty"));
            return true;
        }
        if (!namePattern.test(datasToEdit.lastname)) {
            toast.warning(t("lastnameinvalid"));
            return true;
        }
        if (datasToEdit.iban !== "" && !isValidIBAN(datasToEdit.iban)) {
            toast.warning(t("ibaninvalid"));
            return true;
        }
        return false;
    }

    const submitChanges = async () => {

        if (verification())
            return;

        const datas = {
            firstname: datasToEdit.firstname,
            lastname: datasToEdit.lastname,
            ...(datasToEdit.company !== "" && { company: datasToEdit.company }),
            ...(datasToEdit.iban !== "" && { iban: datasToEdit.iban.replace(/ /g, "") }),
            ...(datasToEdit.swiftbic !== "" && { bic: datasToEdit.swiftbic }),
            ...(datasToEdit.adress.id !== "" && { place: `/api/public/places/${datasToEdit.adress.id}` }),
            ...(datasToEdit.adress.id === "" && { place: null }),
            ...(datasToEdit.country !== "" && { country: `/api/public/countries/${datasToEdit.country}` }),
            ...(datasToEdit.phone !== "" && { phone: datasToEdit.phone.replace(/\s/g, "") }),
        }
        const response = await UserAPI.putUser(datas, general?.me?.id ?? "");
        if (response.status === 200) {
            toast.success(t("datasupdated"));
        } else {
            handleError(response);
        }
    };

    useEffect(() => {
        getDatas();
        setUuid(uuidv4());
    }, []);

    return (
        <>
            <div className="flex flex-col gap-4 rounded-3xl bg-gray-100 w-full px-6 py-4">
                <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
                    <h1 className="text-lg font-bold">{t("payementdata")}</h1>
                    <div className="flex flew-row items-center gap-2 w-fit">
                            <Avatar size="sm" src={general?.me?.portfolio?.picture ?? DefaultProfilePicture} />
                            <div className="flex flex-col">
                                <p className="text-xs font-bold flex flex-row gap-2">
                                    <span>
                                        {general?.me?.portfolio?.name ?? "" }
                                    </span>
                                    {general?.me?.verified && <CustomFontAwesomeIcon icon="check-circle" className="text-gray-500" />}
                                </p>
                                <Typography variant="small" color="blue-gray" className="font-normal text-xs text-gray-500">
                                    {t("photographer")}
                                </Typography>
                            </div>
                        </div>
                </div>
                <div className="flex flex-col gap-4 rounded-lg bg-gray-100 w-full">
                    <div className="w-full">
                        <Input
                            type="text"
                            color="purple"
                            label={t("society")}
                            name="company"
                            value={datasToEdit.company}
                            className="bg-white"
                            onChange={(e) => setDatasToEdit({ ...datasToEdit, company: e.target.value })}
                        />
                    </div>
                    <div className="w-full flex flex-col md:flex-row gap-3">
                        <Input
                            type="text"
                            color="purple"
                            label={t("firstname")}
                            name="firstname"
                            value={datasToEdit.firstname}
                            className="bg-white"
                            onChange={(e) => setDatasToEdit({ ...datasToEdit, firstname: e.target.value })}
                        />
                        <Input
                            type="text"
                            color="purple"
                            label={t("name")}
                            name="lastname"
                            value={datasToEdit.lastname}
                            className="bg-white"
                            onChange={(e) => setDatasToEdit({ ...datasToEdit, lastname: e.target.value })}
                        />
                    </div>
                    <div className="w-full flex flex-col md:flex-row gap-3">
                        <div className="w-full md:w-1/3">
                            <InputCountry
                                 onSelect={(country) => onChange({ target: { name: "country", value: country } } as any)}
                                title="country"
                                value={datasToEdit.country ?? ""}
                            />
                        </div>
                        <div className="w-full md:w-2/3">
                            <InputPlace
                                onSelect={(id, label) => setDatasToEdit({ ...datasToEdit, adress: { id: id, label: label ?? "" } })}
                                uuid={uuid}
                                title="adress"
                                value={datasToEdit.adress}
                            />
                        </div>
                    </div>
                    <div className="w-full flex flex-col md:flex-row gap-3">
                        <InputPhone
                            handleChangePhoneNumber={handleChangePhoneNumber}
                            value={datasToEdit.phone}
                        />
                        <Input
                            type="text"
                            color="purple"
                            label={t("mail")}
                            name="email"
                            value={datasToEdit.email}
                            className="bg-white"
                            required
                            onChange={(e) => setDatasToEdit({ ...datasToEdit, email: e.target.value })}
                        />
                    </div>
                    <div className="w-full flex flex-col md:flex-row gap-3">
                        <div className="w-full md:w-3/4">
                            <Input
                                type="text"
                                color="purple"
                                label={t("iban")}
                                name="iban"
                                value={datasToEdit.iban}
                                containerProps={{ className: "min-w-[30px]" }}
                                className="bg-white"
                                onChange={(e) => setDatasToEdit({ ...datasToEdit, iban: e.target.value })}
                            />
                        </div>
                        <div className="w-full md:w-1/4">
                            <Input
                                type="text"
                                color="purple"
                                label={t("swiftbic")}
                                name="bic"
                                value={datasToEdit.swiftbic}
                                className="bg-white"
                                containerProps={{ className: "min-w-[30px]" }}
                                onChange={(e) => setDatasToEdit({ ...datasToEdit, swiftbic: e.target.value })}
                            />
                        </div>
                    </div>
                </div>
                <p className="font-normal text-xs" dangerouslySetInnerHTML={{ __html: t("verifyYourInformations")}}>
                </p>
                <div className="w-fit justify-center mx-auto" onClick={() => submitChanges()}>
                    <GradientButtonButton text="save" />
                </div>
            </div>
        </>
    );
}
