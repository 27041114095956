import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";

import Footer from "../../components/Footer/Footer";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import SearchResult from "../../components/SearchResult/SearchResult";
import {
    PortfolioDrawerForFilters,
    PortfolioResult,
} from "../../components/SearchResult/impl/PortfolioResult";
import MobileFilterButton from "../../components/SearchResult/mobile/MobileFilterButton";
import TitlePageNoMaxSize from "../../components/TitlePageNoMaxSize";
import WrapperGrayZone from "../../components/WrapperGrayZone";
import {
    getPageName,
    getPageOverride,
} from "../../services/Search/PhotographerSearchUtils";
import { RootState } from "../../services/redux/store";

export default function Photographers() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("photographs")}`;
    const [searchBar, setSearchBar] = useState(true);
    const [isVisible, setIsVisible] = useState(true);
    const [reloadDate, setReloadDate] = useState<Date>(new Date());

    const general = useSelector((state: RootState) => state.general);

    const { type, value } = useParams();

    useEffect(() => {
        setReloadDate(new Date());
    }, [type, value]);

    const pageName =
        (type && value && getPageName(general, type, value)) ?? t("searching");

        const topPaddingTop = (): string => {
            if (isVisible) {
            if (searchBar)
                return "top-[108px] pt-[7px]";
            else
                return "top-[60px] pt-[7px]";
            } else {
            if (searchBar)
                return "top-[48px] pt-[7px]";
            else
                return "top-[0px] pt-[7px]";
            }
        }

    return (
        <div className="min-h-screen bg-gray-50 relative">
            <PortfolioDrawerForFilters />
            <HeaderForAnimation />
            <HeaderMobile
                searchBar={searchBar}
                openSearchBar={setSearchBar}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
            />
            <PortfolioResult
                overrides={getPageOverride(type, value)}
                defaultSort="popular"
                reloadDate={reloadDate}
            >
                <div className="flex flex-col h-full bg-white -mt-4 pt-4 pb-[340px] md:pb-0">
                    <div
                        className={`${topPaddingTop()} flex flex-row md:flex-col items-end justify-between w-full sticky top-0 pt-6 pb-2 md:static bg-white z-30 border-b border-gray-200`}
                    >
                        <TitlePageNoMaxSize mt={0}>
                            {/* <CustomFontAwesomeIcon
                                icon="users"
                                className="text-purple-500 mr-2"
                            /> */}
                            {pageName}
                        </TitlePageNoMaxSize>
                        <div className="flex md:hidden flex-row items-center justify-end gap-2 w-1/2 md:mt-4 pr-2">
                            <MobileFilterButton />
                        </div>
                    </div>
                    <WrapperGrayZone pt="4" px="4">
                        <SearchResult />
                    </WrapperGrayZone>
                </div>
            </PortfolioResult>
            <Footer />
        </div>
    );
}
