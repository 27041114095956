import { IconButton, Typography } from "@material-tailwind/react";
import CustomFontAwesomeIcon from "../../components/CustomFontAwesomeIcon";
import { PelliclePicture, UploadedPicturesChunk } from "./pictureChunks";

interface PictureCardProps {
    onSelected: (id: string, place?: { id?: string, label?: string }) => void
    onDelete: (id: string) => void
    picture: PelliclePicture
    lateralOpen: boolean
    selectedIndex: number | null
}
function PictureCard({ onSelected, onDelete, picture, lateralOpen, selectedIndex }: Readonly<PictureCardProps>) {
    return (
        <div className={`w-1/2 ${lateralOpen ? "md:w-1/3" : "md:w-1/4"} relative p-4`}>
            <div className="relative w-full" style={{ paddingBottom: '100%' }}>
                <button
                    onClick={() => onSelected(picture.id, picture?.place)}
                    className="absolute inset-0 overflow-hidden rounded-lg w-full h-full"
                >
                    <img
                        src={picture.minPath}
                        alt={`Preview ${picture.id}`}
                        className="w-full h-full object-cover object-center hover:opacity-80 transition-all cursor-pointer"
                    />
                    {!picture.placeholder && !selectedIndex && (
                        <div className="absolute top-0 right-1 cursor-pointer z-20">
                            <IconButton
                                size="lg"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (picture.deleting) return;
                                    onDelete(picture.id);
                                }}
                                color="white"
                                className="bg-white hover:bg-gray-100 h-7 w-7 mt-1 mr-0.5"
                            >
                                {picture.deleting ? (
                                    <CustomFontAwesomeIcon icon="circle-notch" spin color="black" className="cursor-pointer" />
                                ) : (
                                    <CustomFontAwesomeIcon icon="x" color="black" className="cursor-pointer" />
                                )}
                            </IconButton>
                        </div>
                    )}
                    {picture.placeholder && (
                        <div className="absolute bottom-0 right-0 py-2 px-3 bg-black/40 rounded-tl-lg cursor-pointer z-20">
                            <CustomFontAwesomeIcon icon="circle-notch" spin color="white" className="cursor-pointer" />
                        </div>
                    )}
                    {selectedIndex !== null && (
                        <button
                            className="absolute top-0 left-0 w-full h-full bg-black/70 flex items-center justify-center"
                            onClick={() => onSelected(picture.id)}
                        >
                            <Typography color="white" className="text-center text-7xl">
                                {selectedIndex}
                            </Typography>
                        </button>
                    )}
                </button>
            </div>
        </div>
    );
    
}

interface DateChunkProps {
    chunk: UploadedPicturesChunk
    onSelected: (id: string, place?: { id?: string, label?: string }) => void
    onDelete: (id: string) => void
    lateralOpen: boolean
    selectedIds: string[]
}

export function DateChunk({ chunk, onSelected, onDelete, lateralOpen, selectedIds }: Readonly<DateChunkProps>) {
    return <div key={chunk.date}>
        <div className="flex flex-row w-full h-fit items-center mt-2">
            <Typography className="text-gray-500 w-fit mr-4">{chunk.date}</Typography>
            <hr className="w-full" />
        </div>
        <div className="flex flex-row flex-wrap mt-4 justify-start">
            {chunk.pictures.map((picture) => {
                const selectedIdx = selectedIds.indexOf(picture.id);

                return (<PictureCard
                    key={picture.id}
                    onSelected={onSelected}
                    onDelete={onDelete}
                    picture={picture}
                    lateralOpen={lateralOpen}
                    selectedIndex={selectedIdx !== -1 ? selectedIdx + 1 : null}
                />)
            })}
        </div>
    </div>;
}
