import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { SortOption } from "../types/global";
import CustomFontAwesomeIcon from "../../CustomFontAwesomeIcon";

interface DropDownProps {
    sortOptions: SortOption[];
    defaultSort?: string;
    setSort: (sort: string | undefined) => void;
    sort: string | undefined;
    left: boolean;
}

export default function DropDownTitle({ sortOptions, defaultSort, setSort, sort, left }: DropDownProps) {
    const selectedValue = sortOptions.find((v) => v.key === sort);

    const { t } = useTranslation();
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className={`${sort !== defaultSort ? "text-white border-transparent bg-gradient-to-r from-orange-500 to-orange-100" : ""} border flex px-4 py-1 rounded-full flex-row items-center gap-2 bg-white hover:bg-gray-50 transition-all`}>
                    <span className="md:hidden flex items-center gap-2">
                        {t("sortby")}
                        {sort !== defaultSort ?
                            <CustomFontAwesomeIcon icon="times" className="-mr-1 h-3 w-3" onClick={(e: any) => {
                                e.stopPropagation();
                                setSort(defaultSort);
                            }} /> :
                            <CustomFontAwesomeIcon icon="chevron-down" className="-mr-1 h-3 w-3" aria-hidden="true" />
                        }
                    </span>
                    <span className="hidden md:flex items-center gap-2">
                        {selectedValue ? t(selectedValue.value) : t("sortby")}
                        {sort !== defaultSort ?
                            <CustomFontAwesomeIcon icon="times" className="-mr-1 h-3 w-3" onClick={(e: any) => {
                                e.stopPropagation();
                                setSort(defaultSort);
                            }} /> :
                            <CustomFontAwesomeIcon icon="chevron-down" className="-mr-1 h-3 w-3" aria-hidden="true" />
                        }
                    </span>
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className={`absolute ${left ? 'left-0' : 'right-0'} z-50 flex-col px-2 py-0.5 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none font-comfortaa font-thin`}>
                    <div className="py-1">
                        <button onClick={() => setSort(defaultSort)} className={`${sort === defaultSort && "bg-orange-500 text-white"} my-1 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg`}>
                            {t("default")}
                            {sort === defaultSort && <CustomFontAwesomeIcon icon="check" className="ml-2" />}
                        </button>
                        {sortOptions.map((item) => (
                            <Menu.Item key={item.key}>
                                <button onClick={() => setSort(item.key)} className={`${sort === item.key && "bg-orange-500 text-white"} my-1 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg`}>
                                    {t(item.value)}
                                    {sort === item.key && <CustomFontAwesomeIcon icon="check" className="ml-2" />}
                                </button>
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
}
