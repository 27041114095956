import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";

import DefaultProfile from "../../../assets/images/elements/default-profile.png";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Storage } from "../../../services/storage";
import CustomFontAwesomeIcon from "../../CustomFontAwesomeIcon";

export default function ProfilDropDown({ isOpen, handleOpen }: Readonly<{ isOpen: boolean; handleOpen: () => void }>) {
    const { t } = useTranslation();
    const general = useSelector((state: any) => state.general);
    const portfolio = useSelector((state: any) => state.general);

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button 
                    className="inline-flex justify-center rounded-md text-sm text-gray-900 items-center ml-2"
                    style={{ width: "40px", height: "40px" }}
                >
                    <div className="w-[40px] h-[40px] rounded-full">
                        <img
                            alt={(portfolio && portfolio.me && portfolio.me && portfolio.me.lastname) ?? "Anonymous"}
                            src={(portfolio && portfolio.me && portfolio.me.portfolio && portfolio.me.portfolio?.picture) ?? DefaultProfile}
                            className="h-full w-full object-center object-cover rounded-full border-2 border-transparent hover:border-orange-500"
                        />
                    </div>
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className={`absolute right-0 z-50 flex-col px-4 py-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none font-comfortaa font-thin`}
                >
                    <div className="py-1">
                        <Menu.Item>
                            <Link to="/upload">
                                <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg">
                                    <CustomFontAwesomeIcon icon="plus" className="mr-2" />
                                    {t("import")}
                                </button>
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link to="/my-portfolio">
                                <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg">
                                    <CustomFontAwesomeIcon icon="images" className="mr-2" />
                                    {t("myportfolio")}
                                </button>
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link to="/favorites">
                                <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg">
                                    <CustomFontAwesomeIcon icon="heart" className="mr-2" />
                                    {t("myfavs")}
                                </button>
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link to="/sales">
                                <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg">
                                    <CustomFontAwesomeIcon icon="money-bill" className="mr-2" />
                                    {t("sales")}
                                </button>
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link to="/purchases">
                                <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg">
                                    <CustomFontAwesomeIcon icon="basket-shopping" className="mr-2" />
                                    {t("purchases")}
                                </button>
                            </Link>
                        </Menu.Item>
                        {(general?.me?.plan === "PREMIUM" || general?.me?.plan === "PRO") && (
                            <Menu.Item>
                                <Link to="/statistics">
                                    <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg">
                                        <CustomFontAwesomeIcon icon="chart-simple" className="mr-2" />
                                        {t("statistics")}
                                    </button>
                                </Link>
                            </Menu.Item>
                        )}
                        <hr className="my-2" />
                        <Menu.Item>
                            <Link to="/account/data">
                                <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg">
                                    <CustomFontAwesomeIcon icon="gear" className="mr-2" />
                                    {t("settings")}
                                </button>
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg"
                                onClick={() => {
                                    Storage.emptyStorage()
                                    window.location.href = ("/");
                                }}
                            >
                                <CustomFontAwesomeIcon icon="right-from-bracket" className="mr-2" />
                                {t("disconnect")}
                            </button>
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
}
