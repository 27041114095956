import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Avatar, IconButton, Tooltip, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import PaginationCallNextOne from "../../../components/Tables/PaginationCallNextOne";
import { Pagination } from "../../../components/Interfaces/PortfolioType";
import ContractsAPI from "../../../services/API/Clients/ContractAPI";
import { toast } from "react-toastify";
import { handleError } from "../../../services/Errors/handleErrors";
import { useState } from "react";
import PictureModalEdit from "../../../components/Box/Photo/components/PictureModalEdit";
import { Link } from "react-router-dom";
import CustomFontAwesomeIcon from "../../../components/CustomFontAwesomeIcon";

function chooseIcon(icon: string) {
    if (icon === "validated") return "check";
    return icon === "pending" ? "clock" : "x";
}

export default function Photos({
    sales,
    callNextPhotos,
}: Readonly<{
    sales: {
        pagination: Pagination;
        data: {
            id: string;
            picture: {
                minPath: string;
                id: string;
            };
            saleCount: number,
            available: string,
            originalCount: number,
            basePrice: number,
            status: string;
            requiredActions?: string[];
            validationMessage?: string
        }[];
    };
    callNextPhotos: Function;
}>) {
    const { t } = useTranslation();
    const [editData, setEditData] = useState<{ open: boolean; id: string }>({
        open: false,
        id: "",
    });

    const getContratLicence = async (id: string, picture: string) => {
        const response = await ContractsAPI.staticContract(id, picture);
        if (response.status === 200) {
            const url = window.URL.createObjectURL(response.body);
            const link = document.createElement("a");
            link.href = url;
            link.target = "_blank";
            link.rel = "noopener noreferrer";
            link.click();
        } else {
            handleError(response);
        }
    };

    const closeModal = (arg?: string) => {
        setEditData({ ...editData, open: false });
        if (arg === "withReload") {
            callNextPhotos();
        }
    }

    const openModalEdit = (id: string) => {
        setEditData({ open: true, id });
    }

    const handleReturnString = (requiredActions?: string[], validationMessage?: string): string => {
        const saleRefuseText = t("saleRefuse");
        const actionsText = requiredActions && requiredActions.length > 0 
            ? requiredActions.map((action) => t(action)).join(" ; \n") 
            : "";
        const validationMessageText = validationMessage ? ". " + t("allDetails") + validationMessage : "";
    
        return [saleRefuseText, actionsText, validationMessageText].filter(Boolean).join("\n\n");
    };
    
    return (
        <>
            <PictureModalEdit open={editData.open} onClose={closeModal} actualId={editData?.id ?? ""}/>
            {sales.data.length > 0 ? (
                <>
                    <div className="w-full overflow-x-auto">
                        <table className="w-full min-w-max table-auto text-left mt-4">
                            <thead>
                                <tr>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 rounded-tl">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="leading-none opacity-90 font-extrabold rounded-tl text-center"
                                        >
                                            {t("status")}
                                        </Typography>
                                    </th>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                        <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                            {t("preview_alt")}
                                        </Typography>
                                    </th>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 rounded-tr">
                                        <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                            {t("picid")}
                                        </Typography>
                                    </th>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 rounded-tr">
                                        <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                            {t("remaining_copies")}
                                        </Typography>
                                    </th>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 rounded-tr">
                                        <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                            {t("price")}
                                        </Typography>
                                    </th>
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 rounded-tr text-center">
                                        <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                            {t("contract")}
                                        </Typography>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {sales.data.map((item, index) => (
                                    <tr key={item.id} className={`${index % 2 ? "bg-blue-gray-50/75" : "bg-white"} hover:bg-gray-200 transition-all`}>
                                        <td className="p-4 text-center">
                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                <Tooltip
                                                    content={`${
                                                        item.status === "validated"
                                                            ? t("saleAccepted")
                                                            : item.status === "pending"
                                                            ? t("saleWait")
                                                            : handleReturnString(item.requiredActions, item.validationMessage)
                                                    }`}
                                                    className={`${
                                                        item.status === "validated"
                                                            ? "bg-green-700"
                                                            : item.status === "pending"
                                                            ? "bg-orange-500"
                                                            : "bg-red-700"
                                                    } w-80`}
                                                    placement="right"
                                                >
                                                    <IconButton
                                                        color={`${item.status === "validated" ? "green" : item.status === "pending" ? "orange" : "red"}`}
                                                    >
                                                        <CustomFontAwesomeIcon icon={chooseIcon(item.status)} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Typography>
                                        </td>
                                        <td className="p-4">
                                            <Avatar src={item.picture.minPath} variant="rounded" />
                                        </td>
                                        <td className="p-4">
                                            <Typography color="blue-gray" className="font-normal text-xs text-gray-500">
                                                Réf. {item.picture.id}
                                            </Typography>
                                        </td>
                                        <td className="p-4">
                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                {item.available === "UNLIMITED" ? t("unlimited") : `${item.originalCount - item.saleCount}/${item.originalCount}`}
                                            </Typography>
                                        </td>
                                        <td className="p-4">
                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                {t("chf")} {item.basePrice.toFixed(2)}
                                            </Typography>
                                        </td>
                                        <td className="p-4 text-center flex gap-2 justify-center">
                                            <IconButton
                                                onClick={() => getContratLicence(item.id, item.picture.id)}
                                                variant="outlined"
                                                color="orange"
                                                className="rounded-full hover:border-gray-400 text-orange-400 hover:text-gray-400 transition-all"
                                            >
                                                <CustomFontAwesomeIcon icon="file-pdf" />
                                            </IconButton>
                                            <Link to={`/download/${item.picture.id}`}>
                                                <IconButton
                                                    variant="outlined"
                                                    color="orange"
                                                    className="rounded-full text-orange-400 hover:text-gray-400 hover:border-gray-400 transition-all"
                                                >
                                                    <CustomFontAwesomeIcon icon="download" />
                                                </IconButton>
                                            </Link>
                                            <IconButton
                                                variant="outlined"
                                                color="orange"
                                                className="rounded-full text-orange-400 hover:text-gray-400 hover:border-gray-400 transition-all"
                                                onClick={() => openModalEdit(item.picture.id)}
                                            >
                                                <CustomFontAwesomeIcon icon="pen" />
                                            </IconButton>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <PaginationCallNextOne pagination={sales.pagination} callNextPicture={callNextPhotos} />
                </>
            ) : (
                <Alert className="w-2/3 mx-auto my-4 bg-gray-300 text-black text-sm text-center">{t("nosale")}</Alert>
            )}
        </>
    );
}
