import { Storage } from "../../storage";
import APIClient from "../APIClients";

const token = Storage.getToken()

export default class StyleAPI {
    public static async styles() {
        if (token)
            return APIClient.ezoom.get(`/public/photo-styles`);
        else
            return APIClient.noInterceptor.get(`/public/photo-styles`)
    }

    public static async stylesMostUsed() {
        if (token)
            return APIClient.ezoom.get(`/public/most-used-photo-styles`);
        else
            return APIClient.noInterceptor.get(`/public/most-used-photo-styles`)
    }
}
