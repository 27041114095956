import { Dialog } from "@headlessui/react";
import ModalWrapper from "../../../../components/Modals/ModalWrapper";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GalleryInterfacePerformance } from "../../../../components/Interfaces/GalleryInterface";
import DefaultBannerEzoom from "../../../../assets/images/elements/default-banner.png";
import { useEffect, useState } from "react";
import GalleryAPI from "../../../../services/API/Clients/GalleryAPI";
import { GalleriesProps, GalleryProps } from "../../../../components/Interfaces/GalleryProps";
import PaginationCallNextOne from "../../../../components/Tables/PaginationCallNextOne";
import CustomFontAwesomeIcon from "../../../../components/CustomFontAwesomeIcon";

export default function ModalSelectGallery({
    open,
    setOpen,
    galleries,
    setGalleries,
}: Readonly<{ open: boolean; setOpen: () => void; galleries: GalleryInterfacePerformance[]; setGalleries: Function }>) {
    const { t } = useTranslation();
    const formData = useSelector((state: any) => state.form);
    const [listGallery, setListGallery] = useState<GalleriesProps>({
        data: [],
        pagination: {
            current: 0,
            last: 0,
            totalItems: 0,
            parPage: 0,
        },
    });
    const [tmpGalleries, setTmpGalleries] = useState<GalleryInterfacePerformance[]>([]);

    const fetchData = async () => {
        const response = await GalleryAPI.getPublicsPerf(formData.id)
        if (response.status === 200) {
            const data = response.body
            setListGallery(data);
        } else {
            console.error(response);
        }
    }

    const fetchPageNumber = async (page: number) => {
        const response = await GalleryAPI.getPublicsPaginationPerf(formData.id, page)
        if (response.status === 200) {
            const data = response.body
            setListGallery(data);
        } else {
            console.error(response);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleSelectGalleries = (newGallery: GalleryProps) => async () => {
        const oldGallery = tmpGalleries;
        if (oldGallery.length > 0) {
            if (oldGallery.find((gallery) => gallery.id === newGallery.id)) {
                const newGalleries = oldGallery.filter((gallery) => gallery.id !== newGallery.id);
                setTmpGalleries(newGalleries);
            } else {
                const newGalleries = [...oldGallery, newGallery];
                setTmpGalleries(newGalleries);
            }
        } else {
            const newGalleries = [newGallery];
            setTmpGalleries(newGalleries);
        }
    };
    
    useEffect(() => {
        if ( galleries.length > 0 && formData.galleries.data.length > 0 && tmpGalleries.length === 0) {
            const tmp: GalleryInterfacePerformance[] = []
            galleries.forEach(item => {
                const found = formData.galleries.data.find((element: any) => element.id === item.id);
                if (found) {
                    tmp.push(found);
                }
            })
            setTmpGalleries(tmp);
        }
    }, [galleries, formData])

    const handleValidate = () => {
        setGalleries(tmpGalleries);
        setOpen();
    };

    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <Dialog.Panel className="transform overflow-hidden rounded-2xl bg-white p-6 py-12 text-left align-middle shadow-xl transition-all max-w-3xl max-h-screen overflow-y-auto">
                <Dialog.Title as="h3" className="text-2xl font-medium leading-6 text-gray-900 text-center">
                    {t("selectGalleries")}
                </Dialog.Title>
                <CustomFontAwesomeIcon icon="x" className="absolute top-4 right-4 text-gray-500 cursor-pointer" onClick={() => setOpen()} />

                {listGallery && listGallery.pagination.totalItems > 0 ? (
                    <div className="w-full flex flex-col gap-4">
                        <div className="grid grid-cols-4 gap-2 mt-4">
                            {listGallery.data.map((gallery) => (
                                <button className="aspect-square relative" key={gallery.id} onClick={handleSelectGalleries(gallery)}>
                                    <img src={gallery?.banner?.minPath ?? DefaultBannerEzoom} alt={gallery.id} className="object-cover h-full w-full rounded-lg" />
                                    {tmpGalleries.find((tmpGallerie: GalleryInterfacePerformance) => tmpGallerie.id === gallery.id) && (
                                        <div className="absolute top-0 left-0 w-full h-full bg-black/20 rounded-lg transition-all flex justify-center items-center">
                                            <span className="text-white text-4xl font-bold">✓</span>
                                        </div>
                                    )}
                                </button>
                            ))}
                        </div>
                        <PaginationCallNextOne
                            callNextPicture={fetchPageNumber}
                            pagination={listGallery.pagination}
                        />
                    </div>
                ) : (
                    <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100 mt-4">
                        <CustomFontAwesomeIcon icon="image" className="mt-12 text-4xl text-gray-500/50 mb-4" />
                        <span className="text-base text-gray-700 mb-12 text-center" dangerouslySetInnerHTML={{ __html: t("noGalleryToChoose")}}></span>
                    </div>
                )}

                <div className="flex justify-center gap-4 mt-4">
                    <button
                        type="button"
                        className="rounded-full text-white bg-orange-500 hover:bg-orange-200 px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
                        onClick={() => handleValidate()}
                    >
                        {t("validate")}
                    </button>
                    <button
                        type="button"
                        className="rounded-full border bg-white px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
                        onClick={() => setOpen()}
                    >
                        {t("cancel")}
                    </button>
                </div>
            </Dialog.Panel>
        </ModalWrapper>
    );
}
