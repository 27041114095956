import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../../components/Footer/Footer";
import HeaderForAnimation from "../../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../../components/NavBar/HeaderMobile";
import { GalleryResult } from "../../../components/SearchResult/impl/GalleryResult";
import { PictureDrawerForFilters } from "../../../components/SearchResult/impl/PictureResult";
import MobileFilterButton from "../../../components/SearchResult/mobile/MobileFilterButton";
import SearchResult from "../../../components/SearchResult/SearchResult";
import TitlePageNoMaxSize from "../../../components/TitlePageNoMaxSize";
import WrapperGrayZone from "../../../components/WrapperGrayZone";

export default function Galeries() {
  const { t } = useTranslation();
  document.title = `ezoom | ${t("photos")}`;
  const [searchBar, setSearchBar] = useState(true);
  const [isVisible, setIsVisible] = useState(true);

  const topPaddingTop = (): string => {
    if (isVisible) {
      if (searchBar)
          return "top-[108px] pt-[7px]";
      else
          return "top-[60px] pt-[7px]";
      } else {
      if (searchBar)
          return "top-[48px] pt-[7px]";
      else
          return "top-[0px] pt-[7px]";
      }
  }

  return (
    <div className="min-h-screen bg-gray-50 relative">
      <PictureDrawerForFilters />
      <HeaderForAnimation />
      <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} isVisible={isVisible} setIsVisible={setIsVisible} />
      <GalleryResult defaultSort="popular">
        <div className="flex flex-col h-full bg-white -mt-4 pt-4 pb-[340px] md:pb-0">
          <div
            className={`${topPaddingTop()} flex flex-row md:flex-col items-center justify-between w-full sticky top-0 pb-2 md:static bg-white z-30 border-b border-gray-200`}
          >
            <TitlePageNoMaxSize mt={0} mb={0}>
              {/* <CustomFontAwesomeIcon icon="images" className="text-orange-500" />{" "} */}
              {t("galeries")}
            </TitlePageNoMaxSize>
            <div className="flex md:hidden flex-row items-center justify-end gap-2 w-1/2 md:mt-4 pr-2">
              <MobileFilterButton />
            </div>
          </div>
          <WrapperGrayZone pt="4" pb="32" px="4">
            <SearchResult />
          </WrapperGrayZone>
        </div>
      </GalleryResult>
      <Footer />
    </div>
  );
}
