import { Reducer } from "@reduxjs/toolkit";

interface PicturesNsfwType {
    pictures: string[];
}

const initialState: PicturesNsfwType = {
    pictures: [],
};

const picturesNsfwReducer: Reducer<PicturesNsfwType, any> = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_PICTURES_NSFW":
            return { ...state, pictures: action.payload };
        default:
            return state;
    }
};

export default picturesNsfwReducer;
