import { Checkbox } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import TitleHelper from "./components/TitleHelper";
import LinkGeneric from "./components/LinkGeneric";

export default function Adult({ filterAdult, handleFilterAdult, disabled = false }: Readonly<{ filterAdult: any; handleFilterAdult: () => void, disabled?: boolean }>) {
    const { t } = useTranslation();
    return (
        <div className={`pb-4 border-b border-gray-300 flex flex-col w-full mt-4 ${disabled && "text-gray-500"}`}>
            <TitleHelper title="nsfw" infobulle="nsfw_i" />
            <div className="flex flex-row items-center gap-2">
                <p className={`text-sm ${filterAdult ? disabled ? "text-gray-500" : "text-black" : "text-gray-500"} transition-all mb-2 text-justify pr-2`}>{t("nsfw_d")}</p>
                <Checkbox className="bg-white" color={filterAdult ? "orange" : "gray"} disabled={disabled} checked={filterAdult} onChange={handleFilterAdult} />
            </div>
            <LinkGeneric link="/CGU#conduiteDesUtilisateurs" title="learnmorepolicy" internLink={true} isBlank={true} />
        </div>
    );
}
