import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip, IconButton, Popover, PopoverContent, PopoverHandler } from "@material-tailwind/react";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PieChart } from "react-minimal-pie-chart";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DefaultBannerEzoom from "../../../../assets/images/elements/default-banner.png";
import { useCart } from "../../../../providers/CartProvider";
import { usePictureEdit } from "../../../../providers/PictureEditProvider";
import GalleryAPI from "../../../../services/API/Clients/GalleryAPI";
import PicturesAPI from "../../../../services/API/Clients/PicturesAPI";
import { handleError } from "../../../../services/Errors/handleErrors";
import { Storage } from "../../../../services/storage";
import { DateDiffBetweenTwo } from "../../../DateDiffBetweenTwo";
import { PictureType } from "../../../Interfaces/PictureType";
import ModalSignInUp from "../../../Modals/ModalSignInUp";
import CardUserWithData from "../../CardUserWithData";
import GaleryForPhoto from "../../Gallery/GaleryForPhoto";
import SharingBox from "../../SharingBox";
import ExifDataList, { hasAvailableExif } from "../ExifDataList";
import { Link } from "react-router-dom";
import { DisplayText } from "../../../../services/Search/DisplayText";
import CustomFontAwesomeIcon from "../../../CustomFontAwesomeIcon";

export default function PictureModalWithoutFullScreen({
    dataActualItem,
    repartition,
    actualId,
    loading,
    openReportModal,
    handleChangeFav,
    onSaveEdit,
    handleBuyIt,
    handleDelete,
    setOpen
}: Readonly<{
    dataActualItem: PictureType;
    repartition: { users: number; photographers: number };
    actualId: string;
    loading: boolean;
    openReportModal: Function;
    handleChangeFav: Function;
    handleBuyIt: Function;
    handleDelete: Function;
    onSaveEdit: () => void;
    setOpen: Function;
}>) {
    const general = useSelector((state: any) => state.general);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [openPopover, setOpenPopover] = React.useState(false);
    const [openInvitation, setOpenInvitation] = React.useState(false);
    const [galleries, setGalleries] = React.useState<{
        id: string
        title: string
        owner: {
            id: string
            plan: string
            fullname: string
            verified: boolean
        }
        published: boolean
        createdAt: string
        favoriteCount: number
        viewCount: number
        banner: {
            publicPath: string
        }
        bannerOffset: number
        private: boolean
        place: {
            id: string
            label: string
        }
        date: string
        portfolio: {
            id: string
            name: string
            path: string
            picture: string
            plan: string
            publicLink: string
            isSubscribed: boolean
        }
        isFavorited: boolean
        isOwned: boolean
    }[]>([]);

    const fetchData = async () => {
        if (Storage.getToken() && dataActualItem.license !== "own-license" && dataActualItem.license !== "all-rights-reserved" && dataActualItem.license !== "" && dataActualItem.license) {
            await PicturesAPI.downloadPicture(actualId);
        }
    };

    useEffect(() => {
        fetchData();
    }, [dataActualItem]);

    const { setEditedPicture } = usePictureEdit();

    const onEditPicture = () => {
        setEditedPicture({ id: actualId, onSaveEdit });
    };

    const { addToCart } = useCart();

    const handleAddToCart = (onClose: Function) => {
        const item = { id: actualId, minPath: dataActualItem.minPath, price: dataActualItem?.contract?.basePrice ?? 0 };
        addToCart(item);
        onClose();
    };

    useEffect(() => {
        const fetchGalleries = async () => {
            if (dataActualItem?.galleries?.length > 0) {
                try {
                    const promises = dataActualItem.galleries.map((gallery) => (GalleryAPI.getGallerie(gallery.parent.id)).then((res) => res.body));
                    const results: any = await Promise.all(promises);
                    setGalleries(results);
                } catch (error) {
                    console.error(error);
                }
            } else {
                setGalleries([]);
            }
        };
        fetchGalleries();
    }, [dataActualItem]);

    const handleDownloadPicture = async (id: string) => {
        const response = await PicturesAPI.downloadPicture(id);
        if (response.status === 200) {
            // const imageUrl = response.body.originalPath;
            // const link = document.createElement("a");
            // link.href = imageUrl;
            // link.download = imageUrl;
            // link.target = "_blank";
            // link.click();
            const link = document.createElement("a");
            link.href = response.body.originalPath;
            link.download = "image.jpg"; // Nom du fichier téléchargé
            document.body.appendChild(link); // Nécessaire pour Firefox
            link.click(); // Simule un clic
            document.body.removeChild(link); // Nettoie le DOM
        } else {
            handleError(response);
        }
    }

    const handleCloseModal = () => {
        setOpenInvitation(false);
        setOpen(false);
    }
    

    return (
        <>
            <ModalSignInUp open={openInvitation} setOpen={setOpenInvitation} closeModal={handleCloseModal} />
            <div className="flex flex-col bg-gray-50 pt-2">
                <div className="flex flex-row flex-wrap md:flex-nowrap justify-between md:px-8 items-center w-screen h-28 md:h-20 max-w-[1280px] mx-auto">
                    <div className="w-1/2 md:w-1/3 flex flex-row justify-start my-1 md:my-0 grow">
                        {!loading ? (
                            dataActualItem?.portfolio?.name &&
                            <button onClick={() => setOpen(false)} className="w-full">
                                <CardUserWithData data={dataActualItem.portfolio} isVerified={dataActualItem?.portfolio?.verified} />
                            </button>
                        ) : (
                            <div className="flex flex-row items-center gap-2 px-2 w-auto rounded-xl py-2 animate-pulse bg-gray-200 w-full">
                                <div className="rounded-full h-[48px] w-[48px] bg-gray-500/50"></div>
                                <div className="flex flex-col">
                                    <p className="text-lg font-bold flex flex-row gap-2 items-center bg-gray-400/50 h-6 mb-1 rounded w-24">
                                        <span></span>
                                    </p>
                                    <Chip className={`bg-gray-400/50 h-5 w-12`} variant="ghost" value="" />
                                </div>
                            </div>
                        )}
                        <div className="w-fit flex md:hidden flex-row items-center justify-end md:my-0 gap-2 grow mt-2 pr-2">
                            {dataActualItem?.portfolio?.id !== general?.me?.portfolio?.id && dataActualItem?.canBeBought && (
                                <>
                                    {loading ? (
                                        <IconButton color="white" className={`ring-1 bg-gray-400/50 animate-pulse ring-gray-500/40 rounded-full  transition-all`}>
                                            <></>
                                        </IconButton>
                                    ) : (
                                        <>
                                            <IconButton
                                                color="orange"
                                                className="hidden md:flex hover:rounded-full transition-all duration-300 rounded-full hover:bg-orange-200"
                                                onClick={() => !Storage.getToken() ? setOpenInvitation(!openInvitation) : handleBuyIt(handleAddToCart, actualId, dataActualItem, t)}
                                            >
                                                <CustomFontAwesomeIcon icon="cart-shopping" />
                                            </IconButton>
                                            <IconButton
                                                color="orange"
                                                className="md:hidden hover:rounded-full transition-all duration-300 rounded-full hover:bg-orange-200"
                                                onClick={() => !Storage.getToken() ? setOpenInvitation(!openInvitation) : handleBuyIt(handleAddToCart, actualId, dataActualItem, t)}
                                                size="sm"
                                            >
                                                <CustomFontAwesomeIcon icon="cart-shopping" />
                                            </IconButton>
                                        </>
                                    )}
                                </>
                            )}
                            {loading ? (
                                <IconButton color="white" className={`ring-1 bg-gray-400/50 animate-pulse ring-gray-500/40 rounded-full  transition-all`}>
                                    <></>
                                </IconButton>
                            ) : (
                                <>
                                    <IconButton
                                        onClick={() => !Storage.getToken() ? setOpenInvitation(!openInvitation) : handleChangeFav()}
                                        color={dataActualItem.isFavorited ? "red" : "white"}
                                        variant={dataActualItem.isFavorited ? "filled" : "outlined"}
                                        className={`${dataActualItem.isFavorited ? "ring-1 ring-red-500/50 hover:bg-red-400" : "border-gray-400/50 shadow hover:shadow-lg hover:rounded-full transition-all duration-300 rounded-full bg-white hover:bg-gray-50 text-black"
                                            } rounded-full  transition-all hidden md:flex`}
                                    >
                                        <CustomFontAwesomeIcon icon="heart" />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => !Storage.getToken() ? setOpenInvitation(!openInvitation) : handleChangeFav()}
                                        color={dataActualItem.isFavorited ? "red" : "white"}
                                        variant={dataActualItem.isFavorited ? "filled" : "outlined"}
                                        className={` ${dataActualItem.isFavorited ? "ring-1 ring-red-500/50 hover:bg-red-400" : " border-gray-400/50 shadow hover:shadow-lg hover:rounded-full transition-all duration-300 rounded-full bg-white hover:bg-gray-50 text-black"
                                            } rounded-full  transition-all md:hidden`}
                                        size="sm"
                                    >
                                        <CustomFontAwesomeIcon icon="heart" />
                                    </IconButton>
                                </>
                            )}
                            {dataActualItem?.portfolio?.id === general?.me?.portfolio?.id && (
                                <>
                                    {loading ? (
                                        <IconButton color="white" className={`ring-1 bg-gray-400/50 animate-pulse ring-gray-500/40 rounded-full  transition-all`}>
                                            <></>
                                        </IconButton>
                                    ) : (
                                        <>
                                            <IconButton
                                                color="white"
                                                variant="outlined"
                                                className="hidden md:flex border-gray-400/50 shadow hover:shadow-lg hover:rounded-full transition-all duration-300 rounded-full bg-white hover:bg-gray-50 text-black"
                                                onClick={() => onEditPicture()}
                                            >
                                                <CustomFontAwesomeIcon icon="pen" />
                                            </IconButton>
                                            <IconButton
                                                color="white"
                                                variant="outlined"
                                                className="md:hidden border-gray-400/50 shadow hover:shadow-lg hover:rounded-full transition-all duration-300 rounded-full bg-white hover:bg-gray-50 text-black"
                                                onClick={() => onEditPicture()}
                                                size="sm"
                                            >
                                                <CustomFontAwesomeIcon icon="pen" />
                                            </IconButton>
                                        </>
                                    )}
                                </>
                            )}
                            {dataActualItem?.portfolio?.id === general?.me?.portfolio?.id && (
                                <>
                                    {loading ? (
                                        <IconButton color="white" className={`ring-1 bg-gray-400/50 animate-pulse ring-gray-500/40 rounded-full  transition-all`}>
                                            <></>
                                        </IconButton>
                                    ) : (
                                        <>
                                            <IconButton
                                                color="white"
                                                variant="outlined"
                                                className="hidden md:flex hover:rounded-full transition-all duration-300 rounded-full shadow hover:shadow-lg  border-indian-500 bg-white hover:bg-gray-50 text-indian-500"
                                                onClick={() => handleDelete(actualId, t, navigate)}
                                            >
                                                <CustomFontAwesomeIcon icon="trash" />
                                            </IconButton>
                                            <IconButton
                                                color="white"
                                                variant="outlined"
                                                className="md:hidden hover:rounded-full transition-all duration-300 rounded-full shadow hover:shadow-lg  border-indian-500 bg-white hover:bg-gray-50 text-indian-500"
                                                onClick={() => handleDelete(actualId, t)}
                                                size="sm"
                                            >
                                                <CustomFontAwesomeIcon icon="trash" />
                                            </IconButton>
                                        </>
                                    )}
                                </>
                            )}
                            {dataActualItem.license.startsWith("cc") && (
                                <>
                                    {loading ? (
                                        <IconButton color="white" className={`ring-1 bg-gray-400/50 animate-pulse ring-gray-500/40 rounded-full  transition-all`}>
                                            <></>
                                        </IconButton>
                                    ) : (
                                        <>
                                            <IconButton
                                                color="white"
                                                variant="outlined"
                                                className="hidden md:flex border-gray-400/50 shadow hover:shadow-lg hover:rounded-full transition-all duration-300 rounded-full bg-white hover:bg-gray-50 text-black"
                                                onClick={() => !Storage.getToken() ? setOpenInvitation(!openInvitation) : handleDownloadPicture(actualId)}
                                            >
                                                <CustomFontAwesomeIcon icon="download" />
                                            </IconButton>
                                            <IconButton
                                                color="white"
                                                variant="outlined"
                                                className="md:hidden border-gray-400/50 shadow hover:shadow-lg hover:rounded-full transition-all duration-300 rounded-full bg-white hover:bg-gray-50 text-black"
                                                size="sm"
                                                onClick={() => !Storage.getToken() ? setOpenInvitation(!openInvitation) : handleDownloadPicture(actualId)}
                                            >
                                                <CustomFontAwesomeIcon icon="download" />
                                            </IconButton>
                                        </>
                                    )}
                                </>
                            )}
                            <SharingBox loading={loading} idPicture={dataActualItem?.id} isPicture={true} />
                            {dataActualItem?.portfolio?.id !== general?.me?.portfolio?.id && (
                                <>
                                    {loading ? (
                                        <IconButton color="white" className={`ring-1 bg-gray-400/50 animate-pulse ring-gray-500/40 rounded-full transition-all`}>
                                            <></>
                                        </IconButton>
                                    ) : (
                                        <Popover placement="top-end">
                                            <PopoverHandler className="z-10 rounded-full ring-1 ring-gray-400/50 transition-all duration-300 hover:bg-gray-50 active:ring-1 active:ring-gray-800">
                                                <IconButton
                                                    color="white"
                                                    size={window.innerWidth < 768 ? "sm" : "md"}
                                                    onClick={() => !Storage.getToken() ? setOpenInvitation(!openInvitation) : setOpenPopover(!openPopover)}
                                                >
                                                    <CustomFontAwesomeIcon icon="ellipsis-vertical" />
                                                </IconButton>
                                            </PopoverHandler>
                                            <PopoverContent className="z-50 p-0 shadow-blue-gray-500/10">
                                                <div className={`transition-all p-0 rounded-lg font-sans text-sm font-normal text-blue-gray-500 focus:outline-none break-words whitespace-normal z-40`}>
                                                    <button
                                                        className="py-2 px-4 rounded-lg hover:bg-orange-500 hover:text-white w-full"
                                                        onClick={() => !Storage.getToken() ? setOpenInvitation(!openInvitation) : openReportModal(actualId, t)}
                                                    >
                                                        {t("report")}
                                                    </button>
                                                </div>
                                            </PopoverContent>
                                        </Popover>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <div className="w-full md:w-2/3 flex flex-col-reverse md:flex-row-reverse justify-between">
                        <div className="w-1/2 hidden md:flex flex-row items-center justify-end md:my-0 gap-2 grow mt-2">
                            {dataActualItem?.portfolio?.id !== general?.me?.portfolio?.id && dataActualItem?.canBeBought && (
                                <>
                                    {loading ? (
                                        <IconButton color="white" className={`ring-1 bg-gray-400/50 animate-pulse ring-gray-500/40 rounded-full  transition-all`}>
                                            <></>
                                        </IconButton>
                                    ) : (
                                        <>
                                            <IconButton
                                                color="orange"
                                                className="hidden md:flex hover:rounded-full transition-all duration-300 rounded-full hover:bg-orange-200"
                                                onClick={() => !Storage.getToken() ? setOpenInvitation(!openInvitation) : handleBuyIt(handleAddToCart, actualId, dataActualItem, t)}
                                            >
                                                <CustomFontAwesomeIcon icon="cart-shopping" />
                                            </IconButton>
                                            <IconButton
                                                color="orange"
                                                className="md:hidden hover:rounded-full transition-all duration-300 rounded-full hover:bg-orange-200"
                                                onClick={() => !Storage.getToken() ? setOpenInvitation(!openInvitation) : handleBuyIt(handleAddToCart, actualId, dataActualItem, t)}
                                                size="sm"
                                            >
                                                <CustomFontAwesomeIcon icon="cart-shopping" />
                                            </IconButton>
                                        </>
                                    )}
                                </>
                            )}
                            {loading ? (
                                <IconButton color="white" className={`ring-1 bg-gray-400/50 animate-pulse ring-gray-500/40 rounded-full  transition-all`}>
                                    <></>
                                </IconButton>
                            ) : (
                                <>
                                    <IconButton
                                        onClick={() => !Storage.getToken() ? setOpenInvitation(!openInvitation) : handleChangeFav()}
                                        color={dataActualItem.isFavorited ? "red" : "white"}
                                        variant={dataActualItem.isFavorited ? "filled" : "outlined"}
                                        className={`${dataActualItem.isFavorited ? "ring-1 ring-red-500/50 hover:bg-red-400" : "border-gray-400/50 shadow hover:shadow-lg hover:rounded-full transition-all duration-300 rounded-full bg-white hover:bg-gray-50 text-black"
                                            } rounded-full  transition-all hidden md:flex`}
                                    >
                                        <CustomFontAwesomeIcon icon="heart" />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => !Storage.getToken() ? setOpenInvitation(!openInvitation) : handleChangeFav()}
                                        color={dataActualItem.isFavorited ? "red" : "white"}
                                        variant={dataActualItem.isFavorited ? "filled" : "outlined"}
                                        className={` ${dataActualItem.isFavorited ? "ring-1 ring-red-500/50 hover:bg-red-400" : " border-gray-400/50 shadow hover:shadow-lg hover:rounded-full transition-all duration-300 rounded-full bg-white hover:bg-gray-50 text-black"
                                            } rounded-full  transition-all md:hidden`}
                                        size="sm"
                                    >
                                        <CustomFontAwesomeIcon icon="heart" />
                                    </IconButton>
                                </>
                            )}
                            {dataActualItem?.portfolio?.id === general?.me?.portfolio?.id && (
                                <>
                                    {loading ? (
                                        <IconButton color="white" className={`ring-1 bg-gray-400/50 animate-pulse ring-gray-500/40 rounded-full  transition-all`}>
                                            <></>
                                        </IconButton>
                                    ) : (
                                        <>
                                            <IconButton
                                                color="white"
                                                variant="outlined"
                                                className="hidden md:flex border-gray-400/50 shadow hover:shadow-lg hover:rounded-full transition-all duration-300 rounded-full bg-white hover:bg-gray-50 text-black"
                                                onClick={() => onEditPicture()}
                                            >
                                                <CustomFontAwesomeIcon icon="pen" />
                                            </IconButton>
                                            <IconButton
                                                color="white"
                                                variant="outlined"
                                                className="md:hidden border-gray-400/50 shadow hover:shadow-lg hover:rounded-full transition-all duration-300 rounded-full bg-white hover:bg-gray-50 text-black"
                                                onClick={() => onEditPicture()}
                                                size="sm"
                                            >
                                                <CustomFontAwesomeIcon icon="pen" />
                                            </IconButton>
                                        </>
                                    )}
                                </>
                            )}
                            {dataActualItem?.portfolio?.id === general?.me?.portfolio?.id && (
                                <>
                                    {loading ? (
                                        <IconButton color="white" className={`ring-1 bg-gray-400/50 animate-pulse ring-gray-500/40 rounded-full  transition-all`}>
                                            <></>
                                        </IconButton>
                                    ) : (
                                        <>
                                            <IconButton
                                                color="white"
                                                variant="outlined"
                                                className="hidden md:flex hover:rounded-full transition-all duration-300 rounded-full shadow hover:shadow-lg  border-indian-500 bg-white hover:bg-gray-50 text-indian-500"
                                                onClick={() => handleDelete(actualId, t, navigate)}
                                            >
                                                <CustomFontAwesomeIcon icon="trash" />
                                            </IconButton>
                                            <IconButton
                                                color="white"
                                                variant="outlined"
                                                className="md:hidden hover:rounded-full transition-all duration-300 rounded-full shadow hover:shadow-lg  border-indian-500 bg-white hover:bg-gray-50 text-indian-500"
                                                onClick={() => handleDelete(actualId, t)}
                                                size="sm"
                                            >
                                                <CustomFontAwesomeIcon icon="trash" />
                                            </IconButton>
                                        </>
                                    )}
                                </>
                            )}
                            {dataActualItem.license.startsWith("cc") && (
                                <>
                                    {loading ? (
                                        <IconButton color="white" className={`ring-1 bg-gray-400/50 animate-pulse ring-gray-500/40 rounded-full  transition-all`}>
                                            <></>
                                        </IconButton>
                                    ) : (
                                        <>
                                            <IconButton
                                                color="white"
                                                variant="outlined"
                                                className="hidden md:flex border-gray-400/50 shadow hover:shadow-lg hover:rounded-full transition-all duration-300 rounded-full bg-white hover:bg-gray-50 text-black"
                                                onClick={() => !Storage.getToken() ? setOpenInvitation(!openInvitation) : handleDownloadPicture(actualId)}
                                            >
                                                <CustomFontAwesomeIcon icon="download" />
                                            </IconButton>
                                            <IconButton
                                                color="white"
                                                variant="outlined"
                                                className="md:hidden border-gray-400/50 shadow hover:shadow-lg hover:rounded-full transition-all duration-300 rounded-full bg-white hover:bg-gray-50 text-black"
                                                size="sm"
                                                onClick={() => !Storage.getToken() ? setOpenInvitation(!openInvitation) : handleDownloadPicture(actualId)}
                                            >
                                                <CustomFontAwesomeIcon icon="download" />
                                            </IconButton>
                                        </>
                                    )}
                                </>
                            )}
                            <SharingBox loading={loading} idPicture={dataActualItem?.id} isPicture={true} />
                            {dataActualItem?.portfolio?.id !== general?.me?.portfolio?.id && (
                                <>
                                    {loading ? (
                                        <IconButton color="white" className={`ring-1 bg-gray-400/50 animate-pulse ring-gray-500/40 rounded-full transition-all`}>
                                            <></>
                                        </IconButton>
                                    ) : (
                                        <Popover placement="top-end">
                                            <PopoverHandler className="z-10 rounded-full ring-1 ring-gray-400/50 transition-all duration-300 hover:bg-gray-50 active:ring-1 active:ring-gray-800">
                                                <IconButton
                                                    color="white"
                                                    size={window.innerWidth < 768 ? "sm" : "md"}
                                                    onClick={() => !Storage.getToken() ? setOpenInvitation(!openInvitation) : setOpenPopover(!openPopover)}
                                                >
                                                    <CustomFontAwesomeIcon icon="ellipsis-vertical" />
                                                </IconButton>
                                            </PopoverHandler>
                                            <PopoverContent className="z-50 p-0 shadow-blue-gray-500/10">
                                                <div className={`transition-all p-0 rounded-lg font-sans text-sm font-normal text-blue-gray-500 focus:outline-none break-words whitespace-normal z-40`}>
                                                    <button
                                                        className="py-2 px-4 rounded-lg hover:bg-orange-500 hover:text-white w-full"
                                                        onClick={() => !Storage.getToken() ? setOpenInvitation(!openInvitation) : openReportModal(actualId, t)}
                                                    >
                                                        {t("report")}
                                                    </button>
                                                </div>
                                            </PopoverContent>
                                        </Popover>
                                    )}
                                </>
                            )}
                        </div>
                        <div className="w-full md:w-1/2 flex flex-row items-center justify-center gap-2">
                            {loading ? (
                                <>
                                    <Chip className={`bg-gray-400/50 h-5 w-12`} variant="ghost" value="" />
                                    <Chip className={`bg-gray-400/50 h-5 w-12`} variant="ghost" value="" />
                                </>
                            ) : (
                                <>
                                    <Chip
                                        className="w-fit h-fit"
                                        color="gray"
                                        variant="ghost"
                                        value={`${dataActualItem.viewCount} ${t("views")}`}
                                        icon={<CustomFontAwesomeIcon icon="eye" className="h-4 mt-0.5" />}
                                    />
                                    <Popover placement="bottom">
                                        <PopoverHandler>
                                            <Chip
                                                className="w-fit h-fit cursor-pointer"
                                                color="gray"
                                                variant="ghost"
                                                value={`${dataActualItem.favoriteCount} ${t("favs")}`}
                                                icon={<CustomFontAwesomeIcon icon="heart" className="h-4 mt-0.5" />}
                                            />
                                        </PopoverHandler>
                                        <PopoverContent className="z-40">
                                            <div className="flex flex-row gap-2 items-center">
                                                {repartition.users !== 0 || repartition.photographers !== 0 ? (
                                                    <PieChart
                                                        data={[
                                                            { title: t("users"), value: repartition.users ?? 0, color: "rgb(239, 147, 53)" },
                                                            { title: t("photographs"), value: repartition.photographers ?? 0, color: "rgb(98, 81, 153)" },
                                                        ]}
                                                        style={{ width: "100px", height: "100px" }}
                                                    />
                                                ) : (
                                                    <PieChart
                                                        data={[{ title: t("users"), value: 100, color: "#d4d4d4" }]}
                                                        style={{ width: "100px", height: "100px" }}
                                                    />
                                                )}
                                                <div className="flex flex-col gap-2">
                                                    <div className="flex flex-row gap-2 items-center">
                                                        <div className="w-4 h-4 bg-orange-400 rounded-full"></div>
                                                        <span>{t("users")}</span>
                                                        <span>{repartition.users ?? 0}%</span>
                                                    </div>
                                                    <div className="flex flex-row gap-2 items-center">
                                                        <div className="w-4 h-4 bg-purple-400 rounded-full"></div>
                                                        <span>{t("photographs")}</span>
                                                        <span>{repartition.photographers ?? 0}%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </PopoverContent>
                                    </Popover>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row justify-center w-screen max-w-7xl mx-auto">
                    <div className="flex flex-col justify-left items-center w-full md:w-1/2 px-6 mt-4 mb-4">
                        {loading ? (
                            <div className="bg-gray-400/50 w-full h-32 rounded-lg animate-pulse"></div>
                        ) : (
                            <div className="flex flex-col w-full p-4 rounded-lg bg-gray-200">
                                <p className={`text-base mb-4 ${dataActualItem && dataActualItem.description && dataActualItem.description === "" && "italic"}`}>
                                    {dataActualItem && dataActualItem.description && dataActualItem.description !== ""
                                        ? <DisplayText text={dataActualItem.description} />
                                        : t("notgivend")}
                                </p>
                                <div className="flex flex-row gap-2">
                                    <CustomFontAwesomeIcon icon="map-marker-alt" className="text-gray-600" />
                                    <p className="text-sm">{dataActualItem && dataActualItem.place ? dataActualItem.place.label : t("notgiven")}</p>
                                    <CustomFontAwesomeIcon icon="calendar-alt" className="ml-2 text-gray-600" />
                                    <p className="text-sm">
                                        {dataActualItem && dataActualItem.takeDate ? moment(dataActualItem && dataActualItem.takeDate).format("DD.MM.YYYY") : t("notgiven")}
                                    </p>
                                </div>
                                <p className="text-sm text-gray-500 italic mt-4">
                                    {dataActualItem &&
                                        dataActualItem.uploadedAt &&
                                        t("posted", { time: DateDiffBetweenTwo(moment(dataActualItem.publishedAt), moment()) })}
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="flex flex-col justify-left items-center w-full md:w-1/2 px-6 md:mt-4 mb-4">
                        {loading ? (
                            <div className="bg-gray-400/50 w-full h-16 rounded-lg animate-pulse"></div>
                        ) : (
                            <>
                                {hasAvailableExif(dataActualItem.exifData, {
                                    width: dataActualItem.width ?? 0,
                                    height: dataActualItem.height ?? 0,
                                }) && (
                                    <div className="flex flex-row flex-wrap w-full items-center p-4 rounded-lg bg-gray-200 mb-4">
                                        <ExifDataList
                                            size={{ width: dataActualItem.width ?? 0, height: dataActualItem.height ?? 0 }}
                                            data={dataActualItem.exifData}
                                        />
                                    </div>
                                )}
                                <div className="flex flex-row w-full items-start p-4 rounded-lg bg-gray-200">
                                    <CustomFontAwesomeIcon icon="tags" className="text-gray-600 mt-1" />
                                    <div className="flex flex-row flex-wrap w-full items-center">
                                        {dataActualItem?.category?.enumValue && (
                                            <Link to={dataActualItem?.category?.enumValue && `/search/category/${dataActualItem.category.enumValue}`} onClick={() => setOpen(false)}>
                                                <div className="ml-2 text-xs inline-flex items-center font-extrabold leading-sm uppercase px-3 py-1 bg-orange-200/30 text-orange-700 rounded-full">
                                                    {dataActualItem?.category?.frLabel}
                                                </div>
                                            </Link>
                                        )}
                                        {dataActualItem?.typeStyle?.frLabel && dataActualItem?.typeStyle?.frLabel !== "" && (
                                            <Link to={dataActualItem?.typeStyle?.enumValue && `/search/typestyle/${dataActualItem.typeStyle.enumValue}`} onClick={() => setOpen(false)}>
                                                <div className="ml-2 text-xs inline-flex items-center font-extrabold leading-sm uppercase px-3 py-1 bg-orange-200/30 text-orange-700 rounded-full">
                                                    {dataActualItem?.typeStyle?.frLabel ?? ""}
                                                </div>
                                            </Link>
                                        )}
                                        {dataActualItem?.keywords?.length > 0 &&
                                            dataActualItem?.keywords?.map((tag) => {
                                                if (tag?.keyword)
                                                    return (
                                                        <Link to={`/search/keyword/${tag.keyword.enumValue}`} key={tag.index} onClick={() => setOpen(false)}>
                                                            <div className="ml-2 text-xs inline-flex items-center leading-sm uppercase px-3 py-1 bg-orange-200/30 text-orange-700 rounded-full">
                                                                {tag.keyword.frLabel ?? tag.keyword?.frLabel}
                                                            </div>
                                                        </Link>
                                                    )
                                                else
                                                    return (
                                                        <Link to={`/search/keyword/${tag.customLabel}`} key={tag.index} onClick={() => setOpen(false)}>
                                                            <div className="ml-2 text-xs inline-flex items-center leading-sm uppercase px-3 py-1 bg-orange-200/30 text-orange-700 rounded-full">
                                                                {tag.customLabel ?? ""}
                                                            </div>
                                                        </Link>
                                                    )
                                            })}
                                    </div>
                                </div>
                                {galleries?.length > 0 && (
                                    <div className="flex flex-row flex-wrap w-full items-center p-4 rounded-lg bg-gray-200 mt-4">
                                        <p className={`text-base mb-4 font-bold`}>{t("ingallery")}</p>
                                        {/* {dataActualItem?.galleries?.map((gallery) => {
                                            return (
                                                <GaleryForPhoto
                                                    key={gallery.index}
                                                    img={DefaultBannerEzoom}
                                                    name={gallery.parent?.title}
                                                    offset={0}
                                                />
                                            )
                                        })} */}
                                        {galleries?.map((gallery) => {
                                            return (
                                                <GaleryForPhoto
                                                    key={gallery.id}
                                                    gallery={gallery}
                                                    onClick={() => setOpen(false)}
                                                />
                                            )
                                        })}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
