import LogoEzoomBeta from "../assets/images/logos/ezoombeta.png";
import LogoEzoomBetaWhite from "../assets/images/logos/ezoombetawhite.png";

import LogoEzoom from "../assets/images/logos/SVG/logo.svg";
import LogoEzoomWhite from "../assets/images/logos/SVG/whitelogo.svg";
import LogoEzoomPNG from "../assets/images/logos/logo.png";

const isBeta = true;

export default function RenderLogo({
    className,
    classNameBeta,
    isWhite = false,
    isPng = false,
}: {
    className: string;
    classNameBeta: string;
    isWhite?: boolean;
    isPng?: boolean;
}) {
    if (isBeta) {
        if (isWhite)
            return (
                <img
                    src={LogoEzoomBetaWhite}
                    alt="logo"
                    // className="mx-auto w-2/3 py-8 md:w-1/3"
                    className={classNameBeta}
                />
            );
        else
            return (
                <img
                    src={LogoEzoomBeta}
                    alt="logo"
                    // className="h-8 md:h-9 absolute top-4 left-4 md:top-8 md:left-8"
                    className={classNameBeta}
                />
            );
    } else {
        if (isPng) {
            return (
                <img
                    src={LogoEzoomPNG}
                    alt="logo"
                    // className="w-[200px] cursor-pointer hover:cursor-pointer"
                    className={className}
                />
            );
        } else {
            if (isWhite)
                return (
                    <LogoEzoomWhite 
                        // className="h-8 md:h-9 absolute top-4 left-4 md:top-8 md:left-8"
                        className={className}
                    />
                );
            else 
                return (
                    <LogoEzoom
                        // className="mx-auto w-2/3 py-8 md:w-1/3"
                        className={className}
                    />
                );
        }
    }
}
