// GlobalLayout.js
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CartProvider } from '../providers/CartProvider';
import { PictureEditProvider } from '../providers/PictureEditProvider';
import { PictureModalProvider } from '../providers/PictureModalProvider';
import ScrollToTop from '../components/Scroll/ScrollToTop';

const Providers = () => {
    const clientId = process.env.REACT_APP_GOOGLE_CODE_OAUTH ?? "";

    return (
        <CartProvider>
            <PictureEditProvider>
                <PictureModalProvider>
                    <GoogleOAuthProvider clientId={clientId}>
                        <ScrollToTop />
                        <Outlet />
                        <ToastContainer
                            position="bottom-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                        />
                    </GoogleOAuthProvider>
                </PictureModalProvider>
            </PictureEditProvider>
        </CartProvider>
    );
};

export default Providers;
