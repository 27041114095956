import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import MiniLogo from "../../assets/images/logos/SVG/logo_e.svg";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BackgroundDefault from "../../assets/images/elements/default-banner.png";
import { PictureType } from "../../components/Interfaces/PictureType";
import Loader from "../../components/Loader";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import PicturesAPI from "../../services/API/Clients/PicturesAPI";
import Footer from "../../components/Footer/Footer";
import { handleError } from "../../services/Errors/handleErrors";
import CustomFontAwesomeIcon from "../../components/CustomFontAwesomeIcon";
import { PictureBackgroundType } from "../../components/Interfaces/PictureBackgroundType";

export default function SignupConfirm() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("inscription_confirmation")}`;
    const [searchBar, setSearchBar] = React.useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [loading, setLoading] = React.useState(true);
    const [backgroundPicture, setBackgroundPicture] = useState<PictureBackgroundType | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await PicturesAPI.backgroundPicture();
            if (response.status === 200) {
                setBackgroundPicture(response.body);
                setLoading(false);
            } else {
                handleError(response);
            }
        }
        fetchData();
    }, [])

    useEffect(() => {
        setLoading(false);
    }, []);

    return (
        <div className="min-h-full">
            <Loader loading={loading} />
            <HeaderForAnimation />
            <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} isVisible={isVisible} setIsVisible={setIsVisible} />
            <div
                className="hidden md:block h-full min-h-screen"
                style={{
                    backgroundImage: `url('${backgroundPicture?.picture?.publicPath ?? BackgroundDefault}')`,
                    backgroundAttachment: "fixed",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "100vmax",
                    zIndex: -1,
                    position: "fixed",
                    top: 0,
                    left: "50%",
                    transform: "translate(-50%, 0)",
                    pointerEvents: "none",
                    backgroundPositionX: "center",
                }}
            ></div>
            <div className="relative min-h-screen flex justify-start items-start">
                <div className="relative w-screen min-h-screen md:min-h-fit mt-0 mb-0 md:h-fit md:w-2/3 xl:w-1/3 bg-white rounded-lg md:mt-12 md:mb-16 px-8 py-12 mx-auto shadow-xl flex justify-center flex-col max-w-3xl">
                    <Link to="/" className="absolute top-4 right-4 flex md:hidden">
                        <IconButton color="orange" className="w-10 h-10 rounded-0 md:rounded-lg flex items-center justify-center">
                            <CustomFontAwesomeIcon icon="x" className="text-white h-4 w-4 mx-auto my-3" aria-hidden="true" />
                        </IconButton>
                    </Link>
                    <MiniLogo className="w-14 h-14 my-2.5 mx-auto" />
                    <h1 className="text-3xl mb-2 text-center">{t("success_register")}</h1>
                    <p className="text-center text-gray-500 text-sm gap-2 mb-4 mt-2" dangerouslySetInnerHTML={{ __html: t("sucess_register_long") }}></p>
                </div>
            </div>
            <Footer isAbsolute={false} />
        </div>
    );
}
